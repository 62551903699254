define("budgeta/admin/admin-app/pages/companyEdit/pages/companyUserList/columns", ["exports", "react", "react-router-dom", "@sibp/ui-components", "budgeta/admin/admin-app/routes", "budgeta/admin/utils/const", "budgeta/services/ember-service-container"], function (_exports, _react, _reactRouterDom, _uiComponents, _routes, _const, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.columns = void 0;
  /* eslint-disable react-hooks/rules-of-hooks */

  var columns = _exports.columns = function columns(companyId) {
    var disableLink = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    var intl = new _emberServiceContainer.default().lookup("intl");
    var history = _reactRouterDom.default.useHistory();
    var userStatuses = Object.values(_const.COMPANY_USER_STATUS);
    var userTypes = Object.values(_const.COMPANY_USER_TYPE);
    return [{
      header: intl.t("admin_app.form.full_name"),
      accessor: "user.fullName",
      dataType: _uiComponents.default.TABLE_SORTER.DATA_TYPES.STRING,
      cell: function cell(_ref) {
        var value = _ref.value,
          userDetails = _ref.otherProps;
        return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_uiComponents.default.COMPONENTS.Link, {
          text: value,
          disabled: disableLink,
          onClick: function onClick() {
            history.push(_reactRouterDom.default.generatePath(_routes.ROUTES.COMPANY_USER_EDIT, {
              userId: userDetails.user._id,
              companyId: companyId
            }));
          }
        }), userDetails.roles.includes(_const.COMPANY_USER_ROLE.COMPANY_ADMIN) && _react.default.createElement("span", {
          className: "sibp-badge"
        }, intl.t("admin_app.company_admin_role")));
      },
      sortDir: 1
    }, {
      header: intl.t("admin_app.form.email"),
      accessor: "user.email",
      dataType: _uiComponents.default.TABLE_SORTER.DATA_TYPES.STRING
    }, {
      header: intl.t("admin_app.form.user_type"),
      accessor: "userType",
      dataType: _uiComponents.default.TABLE_SORTER.DATA_TYPES.STRING,
      cell: function cell(_ref2) {
        var value = _ref2.value;
        var userType = userTypes.find(function (s) {
          return s.value === value;
        });
        return userType ? userType.text : "";
      }
    }, {
      header: intl.t("admin_app.form.expiration_date"),
      accessor: "expDate",
      dataType: _uiComponents.default.TABLE_SORTER.DATA_TYPES.DATE,
      cell: function cell(_ref3) {
        var value = _ref3.value;
        return value ? new Date(value).toLocaleDateString() : "";
      }
    }, {
      header: intl.t("admin_app.form.status"),
      accessor: "status",
      dataType: _uiComponents.default.TABLE_SORTER.DATA_TYPES.STRING,
      cell: function cell(_ref4) {
        var value = _ref4.value;
        var userStatus = userStatuses.find(function (s) {
          return s.value === value;
        });
        return userStatus ? userStatus.text : "";
      }
    }];
  };
});