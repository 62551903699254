define("budgeta/components/main-component", ["exports", "budgeta/services/ember-service-container", "budgeta/config/environment", "budgeta/utils/const"], function (_exports, _emberServiceContainer, _environment, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable no-useless-escape, no-empty */
  var _default = _exports.default = Ember.Component.extend({
    classNames: ["main-view"],
    collapsed: Ember.computed.alias("controllertmp.collapsed"),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var _this = this,
        aside,
        stuck,
        stuckBrother,
        stuckWidthRegExp,
        stuckLeftRegExp;
      _this.$().on("click", "#select-revisions", function () {
        if (_this.get("controllertmp.selectedRoot.versions")) {
          _this.get("controllertmp.selectedRoot.versions").reload();
        }
      });
      _this.$("aside").on("transitionend", function (e) {
        if (e.target.tagName === "ASIDE") {
          Ember.$(window).trigger("resize");
        }
      });
      aside = _this.$("aside");
      stuckWidthRegExp = new RegExp(/width\: \d+px \!important;/g);
      stuckLeftRegExp = new RegExp(/left\: \d+\.{0,1}\d+px;/g);
      _this.$(".divSeparator").draggable({
        axis: "x",
        containment: "parent",
        helper: "clone",
        start: function start() {
          stuck = Ember.$(".stuck");
          stuckBrother = stuck.closest(".panel-body").find(".grid-row").not(".stuck").eq(0);
        },
        stop: function stop() {
          stuck = undefined;
          stuckBrother = undefined;
        },
        drag: function drag(event, ui) {
          var leftWidth = Math.floor(ui.offset.left),
            oldStuckStyle,
            newStuckWidth,
            newStuckLeft;
          if (leftWidth > 200 && leftWidth <= 500) {
            if (leftWidth < 300) {
              aside.addClass("small-aside");
            } else {
              aside.removeClass("small-aside");
            }
            aside.css({
              width: leftWidth
            });
            _this.set("controllertmp.leftSideWidth", leftWidth);
            Ember.$(ui.helper).css("opacity", "1");
          } else {
            Ember.$(ui.helper).css("opacity", "0");
          }
          if (stuck && stuck.length > 0 && stuckBrother && stuckBrother.length > 0) {
            newStuckWidth = stuckBrother.outerWidth();
            newStuckLeft = stuckBrother.offset().left;
            oldStuckStyle = stuck.attr("style").replace(stuckWidthRegExp, "").replace(stuckLeftRegExp, "");
            stuck.attr("style", oldStuckStyle + "; " + "width: " + newStuckWidth + "px !important;" + "left: " + newStuckLeft + "px");
          }
        }
      });
      _this.$(".divSeparator").on("dblclick", function () {
        Ember.$(".inner-content").css({
          width: "calc(100% - 360px)"
        });
        Ember.$("aside.secondary").css({
          width: 320
        });
      });
      Ember.$(window).on("focus.main", function () {
        Ember.run(function () {
          try {
            if (_this.get("controllertmp")) {
              _this.get("controllertmp.activityLog").log("window-focus"); // this will verify that there's a live connection to the server

              // compare client version to latest released versions
              if (_const.default.FF_NEW_VERSION_NOTY === "true" && (_environment.default.environment === "production" || _environment.default.environment === "staging")) {
                Ember.$.ajax("".concat(_environment.default.serverAddress, "/").concat(_environment.default.apiPath, "/clientVersion"), {
                  type: "GET",
                  success: function success(data) {
                    if (data.version && _environment.default.APP.version !== data.version) {
                      noty({
                        timeout: 0,
                        text: intl.t("general.text_1695611609041")
                      });
                    }
                  }
                });
              }
            }
          } catch (e) {}
        });
      });
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$(window).off("focus.main");
    },
    keyUp: function keyUp(event) {
      if (event.ctrlKey && event.altKey && event.which === 84) {
        this.get("controllertmp").send("openTestModal");
      }
    }
  });
});