define("budgeta/controllers/admin/activity", ["exports", "@babel/runtime/helpers/esm/createForOfIteratorHelper", "budgeta/services/ember-service-container", "budgeta/mixins/query-param-delegate-fix", "budgeta/config/environment", "budgeta/utils/dashboard"], function (_exports, _createForOfIteratorHelper2, _emberServiceContainer, _queryParamDelegateFix, _environment, _dashboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
  var _default = _exports.default = Ember.Controller.extend(_queryParamDelegateFix.default, {
    queryParams: ["fromDate", "toDate", "userId", "selectedDomain"],
    fromDate: Date.create().format(Date.ISO8601_DATE),
    toDate: Date.create().format(Date.ISO8601_DATE),
    userId: null,
    selectedDomain: null,
    reportData: null,
    today: new Date(),
    minDate: Ember.computed("fromDate", function () {
      return typeof this.get("fromDate") === "string" ? new Date(this.get("fromDate")) : this.get("fromDate");
    }),
    maxDay: Ember.computed("toDate", function () {
      return typeof this.get("toDate") === "string" ? new Date(this.get("toDate")) : this.get("toDate");
    }),
    dateType: "day",
    columnsLayout: [100],
    activityList: Ember.computed("model", "selectedDomain", function () {
      var model = this.get("model");
      var swapActionMap = function swapActionMap(originalMap) {
        var swaped = {};
        for (var action in originalMap) {
          swaped[originalMap[action]] = action;
        }
        return swaped;
      };
      var buildActivities = function buildActivities(model) {
        var actionMap = swapActionMap(model.actionMap);
        var userMap = model.users;
        var activitiesRecords = model.dataByDates;
        var modifiedModel = [];
        // iterate dates in object
        for (var dateData in activitiesRecords) {
          //iterate users in object
          for (var userId in activitiesRecords[dateData]) {
            var fullName = userMap[userId].firstName + " " + userMap[userId].lastName;
            //iterate records in records array
            var _iterator = (0, _createForOfIteratorHelper2.default)(activitiesRecords[dateData][userId].records),
              _step;
            try {
              for (_iterator.s(); !(_step = _iterator.n()).done;) {
                var action = _step.value;
                if (actionMap[action.action]) {
                  modifiedModel.push({
                    email: userMap[userId].email,
                    name: fullName,
                    userId: userId,
                    timestamp: dateData,
                    action: actionMap[action.action],
                    data: action.data || ""
                  });
                }
              }
            } catch (err) {
              _iterator.e(err);
            } finally {
              _iterator.f();
            }
          }
        }
        return modifiedModel;
      };
      return buildActivities(model);
    }),
    userList: Ember.computed("activityList", "activityList.length", "selectedDomain", function () {
      var userList = Ember.A([]),
        userListMap = {};
      var activityList = this.get("activityList");
      if (!Ember.isEmpty(activityList)) {
        activityList.forEach(function (item) {
          if (!userListMap[item.userId]) {
            userListMap[item.userId] = {};
          }
          userListMap[item.userId] = {
            id: item.userId,
            email: item.email
          };
        });
      }
      for (var key in userListMap) {
        userList.pushObject(userListMap[key]);
      }
      return userList;
    }),
    domainList: Ember.computed("activityList", "activityList.length", function () {
      var domainList = Ember.A([]);
      var activityList = this.get("activityList");
      if (!Ember.isEmpty(activityList)) {
        activityList.forEach(function (item) {
          domainList.addObject(item.email.split("@")[1]);
        });
      }
      return domainList.map(function (item) {
        return {
          id: item,
          text: item
        };
      });
    }),
    isDayType: Ember.computed("dateType", function () {
      return this.get("dateType") === "day";
    }),
    isWeekType: Ember.computed("dateType", function () {
      return this.get("dateType") === "week";
    }),
    isMonthType: Ember.computed("dateType", function () {
      return this.get("dateType") === "month";
    }),
    is3monthType: Ember.computed("dateType", function () {
      return this.get("dateType") === "3month";
    }),
    is6monthType: Ember.computed("dateType", function () {
      return this.get("dateType") === "6month";
    }),
    isYearType: Ember.computed("dateType", function () {
      return this.get("dateType") === "year";
    }),
    activityCountChart: Ember.computed("activityList", function () {
      var model = this.get("activityList");
      var highchartData = Ember.A([]);
      var userMap = {};
      if (model && model.length > 0) {
        for (var i = 0; i < model.length; i++) {
          if (!userMap[model[i].email]) {
            userMap[model[i].email] = 0;
          }
          userMap[model[i].email] = userMap[model[i].email] + 1;
        }
      }
      for (var key in userMap) {
        highchartData.pushObject({
          name: key,
          y: userMap[key]
        });
      }
      return Ember.Object.create({
        highchartType: "pie-chart",
        highchartData: highchartData,
        highchartCurrency: [""],
        highchartCategories: highchartData
      });
    }),
    activityCountRange: "day",
    isCountDayType: Ember.computed("activityCountRange", function () {
      return this.get("activityCountRange") === "day";
    }),
    isCountWeekType: Ember.computed("activityCountRange", function () {
      return this.get("activityCountRange") === "week";
    }),
    isCountMonthType: Ember.computed("activityCountRange", function () {
      return this.get("activityCountRange") === "month";
    }),
    activityCountRangeChart: Ember.computed("activityList", "activityCountRange", function () {
      var model = this.get("activityList");
      var highchartData = [{
        name: intl.t("general.name_1695611601934"),
        data: []
      }];
      var highchartCategories = [];
      var dateMap = {};
      if (model && model.length > 0) {
        for (var i = 0; i < model.length; i++) {
          var date = void 0;
          if (this.get("isCountDayType")) {
            date = Date.create(model[i].timestamp).beginningOfDay().valueOf();
          } else if (this.get("isCountWeekType")) {
            date = Date.create(model[i].timestamp).beginningOfWeek().valueOf();
          } else {
            date = Date.create(model[i].timestamp).beginningOfMonth().valueOf();
          }
          if (!dateMap[date]) {
            dateMap[date] = Ember.A([]);
          }
          dateMap[date].addObject(model[i].email);
        }
      }
      for (var key in dateMap) {
        if (this.get("isCountDayType")) {
          highchartCategories.push(Date.create(key * 1).format("{dd}-{MM}-{yy}"));
        } else if (this.get("isCountWeekType")) {
          var _date = Date.create(key * 1);
          var month = Date.create(key * 1).beginningOfMonth();
          highchartCategories.push(month.format("{Mon}") + intl.t("general._week__1698234256047") + _date.weeksSince(month));
        } else {
          var _date2 = Date.create(key * 1);
          highchartCategories.push(_date2.format("{Mon}"));
        }
        highchartData[0].data.push(dateMap[key].length);
      }
      highchartCategories = highchartCategories.reverse();
      highchartData[0].data = highchartData[0].data.reverse();
      return Ember.Object.create({
        highchartType: "column-chart",
        highchartData: highchartData,
        highchartCurrency: [""],
        highchartCategories: highchartCategories,
        colorPalette: _dashboard.default.getColorPalette(),
        labelsOnColums: true
      });
    }),
    userActivityCountChart: Ember.computed("activityList", function () {
      var model = this.get("activityList");
      var highchartData = [{
        name: intl.t("general.name_1695611601934"),
        data: []
      }];
      var highchartCategories = [];
      var dateMap = {};
      if (model && model.length > 0) {
        for (var i = 0; i < model.length; i++) {
          var date = Date.create(model[i].timestamp).beginningOfDay().valueOf();
          if (!dateMap[date]) {
            dateMap[date] = {};
          }
          dateMap[date][model[i].email] = 1;
        }
      }
      for (var key in dateMap) {
        highchartCategories.push(Date.create(key * 1).format(Date.ISO8601_DATE));
        highchartData[0].data.push(Object.keys(dateMap[key]).length);
      }
      highchartCategories = highchartCategories.reverse();
      highchartData[0].data = highchartData[0].data.reverse();
      return Ember.Object.create({
        highchartType: "column-chart",
        highchartData: highchartData,
        highchartCurrency: [""],
        highchartCategories: highchartCategories
      });
    }),
    userActivityByUserChart: Ember.computed("activityList", function () {
      var model = this.get("activityList");
      var highchartData = [];
      var highchartCategories = [];
      var dateMap = {};
      if (model && model.length > 0) {
        for (var i = 0; i < model.length; i++) {
          var date = Date.create(model[i].timestamp).beginningOfDay().valueOf();
          if (!dateMap[date]) {
            dateMap[date] = {};
          }
          dateMap[date][model[i].email] = (dateMap[date][model[i].email] || 0) + 1;
        }
      }
      for (var key in dateMap) {
        highchartCategories.push(Date.create(key * 1).format(Date.ISO8601_DATE));
      }
      this.get("userList").forEach(function (userItem) {
        var userData = {
          name: userItem.email,
          data: [],
          stack: "activity"
        };
        for (var _key in dateMap) {
          userData.data.push(dateMap[_key][userItem.email] || 0);
        }
        userData.data = userData.data.reverse();
        highchartData.push(userData);
      });
      highchartCategories = highchartCategories.reverse();
      return Ember.Object.create({
        highchartType: "column-chart",
        highchartData: highchartData,
        highchartCurrency: [""],
        highchartCategories: highchartCategories,
        highchartStacking: "normal"
      });
    }),
    getUserNameById: function getUserNameById(userId) {
      return this.get("userList").findBy("id", userId).email;
    },
    compressedReportsData: Ember.computed("reportData", function () {
      return window.LZString.compressToBase64(this.get("reportData"));
    }),
    formatData: function formatData(list) {
      list.forEach(function (item) {
        item.timeFormated = moment(item.timestamp).format("l LT");
        item.dataFormated = item.data ? item.data.name : item.data;
      });
      return list;
    },
    downloadPath: Ember.computed(function () {
      return "".concat(_environment.default.serverAddress, "/").concat(_environment.default.apiPath, "/excel");
    }),
    getChartsData: function getChartsData() {
      var chartsData = this.get("activityCountRangeChart");
      chartsData.highchartData[0].name = intl.t("general.activity-1", {
        var1: this.get("activityCountRange")
      });
      var height = 550;
      var width = 720;
      var charts = _dashboard.default.getHighchartsCharts([chartsData], height, width, true);
      var svgs = [];
      var images = [];
      charts.forEach(function (chart) {
        svgs.push(chart);
      });
      var canvas = document.createElement("canvas");
      for (var i = 0; i < svgs.length; i++) {
        canvas.width = width;
        canvas.height = height;
        canvg(canvas, svgs[i].image);
        svgs[i].image = canvas.toDataURL("image/png").replace("data:image/png;base64,", "");
        images.push(svgs[i]);
      }
      return images;
    },
    getReport: function getReport() {
      return {
        name: intl.t("general.name_1695611601914"),
        sheets: [{
          viewType: "User Activity",
          name: intl.t("general.name_1695611601914"),
          tablesOfActivity: [{
            columns: [{
              key: "title",
              label: ""
            }, {
              key: "value",
              label: ""
            }],
            data: [{
              title: intl.t("general.title_1695611597474"),
              value: this.get("fromDate")
            }, {
              title: intl.t("general.title_1695611597463"),
              value: this.get("toDate")
            }, {
              title: intl.t("general.title_1695611597452"),
              value: this.get("dateType")
            }, {
              title: intl.t("general.title_1695611597441"),
              value: this.get("userId") ? this.getUserNameById(this.get("userId")) : "All users"
            }, {
              title: intl.t("general.title_1695611597430"),
              value: this.get("selectedDomain") ? this.get("selectedDomain") : "All domains"
            }]
          }, {
            columns: [{
              key: "name",
              label: intl.t("general.title_1695611597441")
            }, {
              key: "y",
              label: intl.t("general.label_1695611611925")
            }],
            data: this.get("activityCountChart.highchartData")
          }, {
            columns: [{
              key: "timeFormated",
              label: intl.t("general.label_1695611611914")
            }, {
              key: "email",
              label: intl.t("general.label_1695611611904")
            }, {
              key: "name",
              label: intl.t("general.label_1695611611893")
            }, {
              key: "action",
              label: intl.t("general.label_1695611611884")
            }, {
              key: "dataFormated",
              label: intl.t("general.label_1695611611874")
            }],
            data: this.formatData(this.get("activityList"))
          }],
          charts: this.getChartsData()
        }]
      };
    },
    userSelected: Ember.computed("userId", function () {
      return this.get("userList").findBy("id", this.get("userId"));
    }),
    domainSelected: Ember.computed("selectedDomain", function () {
      return this.get("domainList").findBy("id", this.get("selectedDomain"));
    }),
    actions: {
      changeType: function changeType(newType) {
        this.set("dateType", newType);
        var fromDate = Date.create(),
          toDate = Date.create(),
          toDateFormat = toDate.format(Date.ISO8601_DATE);
        if (newType === "day") {
          this.setProperties({
            fromDate: toDateFormat,
            toDate: toDateFormat
          });
          return;
        }
        if (newType === "week") {
          this.setProperties({
            fromDate: fromDate.rewind("1 weeks").format(Date.ISO8601_DATE),
            toDate: toDateFormat
          });
          return;
        }
        if (newType === "month") {
          this.setProperties({
            fromDate: fromDate.rewind("1 months").format(Date.ISO8601_DATE),
            toDate: toDateFormat
          });
          return;
        }
        if (newType === "3month") {
          this.setProperties({
            fromDate: fromDate.rewind("3 months").format(Date.ISO8601_DATE),
            toDate: toDateFormat
          });
          return;
        }
        if (newType === "6month") {
          this.setProperties({
            fromDate: fromDate.rewind("6 months").format(Date.ISO8601_DATE),
            toDate: toDateFormat
          });
          return;
        }
        if (newType === "year") {
          this.setProperties({
            fromDate: fromDate.rewind("1 years").format(Date.ISO8601_DATE),
            toDate: toDateFormat
          });
          return;
        }
      },
      changeCountType: function changeCountType(newType) {
        this.set("activityCountRange", newType);
      },
      prev: function prev() {
        var dateType = this.get("dateType");
        if (dateType === "day") {
          this.setProperties({
            fromDate: 1 .dayBefore(Date.create(this.get("fromDate"))).format(Date.ISO8601_DATE),
            toDate: 1 .dayBefore(Date.create(this.get("toDate"))).format(Date.ISO8601_DATE)
          });
          return;
        }
        if (dateType === "week") {
          this.setProperties({
            fromDate: 1 .weekBefore(Date.create(this.get("fromDate"))).format(Date.ISO8601_DATE),
            toDate: 1 .weekBefore(Date.create(this.get("toDate"))).format(Date.ISO8601_DATE)
          });
          return;
        }
        if (dateType === "month") {
          this.setProperties({
            fromDate: 1 .monthBefore(Date.create(this.get("fromDate"))).format(Date.ISO8601_DATE),
            toDate: 1 .monthBefore(Date.create(this.get("toDate"))).format(Date.ISO8601_DATE)
          });
          return;
        }
        if (dateType === "3month") {
          this.setProperties({
            fromDate: 3 .monthBefore(Date.create(this.get("fromDate"))).format(Date.ISO8601_DATE),
            toDate: 3 .monthBefore(Date.create(this.get("toDate"))).format(Date.ISO8601_DATE)
          });
          return;
        }
        if (dateType === "6month") {
          this.setProperties({
            fromDate: 6 .monthBefore(Date.create(this.get("fromDate"))).format(Date.ISO8601_DATE),
            toDate: 6 .monthBefore(Date.create(this.get("toDate"))).format(Date.ISO8601_DATE)
          });
          return;
        }
        if (dateType === "year") {
          this.setProperties({
            fromDate: 1 .yearBefore(Date.create(this.get("fromDate"))).format(Date.ISO8601_DATE),
            toDate: 1 .yearBefore(Date.create(this.get("toDate"))).format(Date.ISO8601_DATE)
          });
          return;
        }
      },
      next: function next() {
        var dateType = this.get("dateType");
        if (dateType === "day") {
          this.setProperties({
            fromDate: 1 .dayAfter(Date.create(this.get("fromDate"))).format(Date.ISO8601_DATE),
            toDate: 1 .dayAfter(Date.create(this.get("toDate"))).format(Date.ISO8601_DATE)
          });
          return;
        }
        if (dateType === "week") {
          this.setProperties({
            fromDate: 1 .weekAfter(Date.create(this.get("fromDate"))).format(Date.ISO8601_DATE),
            toDate: 1 .weekAfter(Date.create(this.get("toDate"))).format(Date.ISO8601_DATE)
          });
          return;
        }
        if (dateType === "month") {
          this.setProperties({
            fromDate: 1 .monthAfter(Date.create(this.get("fromDate"))).format(Date.ISO8601_DATE),
            toDate: 1 .monthAfter(Date.create(this.get("toDate"))).format(Date.ISO8601_DATE)
          });
          return;
        }
        if (dateType === "3month") {
          this.setProperties({
            fromDate: 3 .monthAfter(Date.create(this.get("fromDate"))).format(Date.ISO8601_DATE),
            toDate: 3 .monthAfter(Date.create(this.get("toDate"))).format(Date.ISO8601_DATE)
          });
          return;
        }
        if (dateType === "6month") {
          this.setProperties({
            fromDate: 6 .monthAfter(Date.create(this.get("fromDate"))).format(Date.ISO8601_DATE),
            toDate: 6 .monthAfter(Date.create(this.get("toDate"))).format(Date.ISO8601_DATE)
          });
          return;
        }
        if (dateType === "year") {
          this.setProperties({
            fromDate: 1 .yearAfter(Date.create(this.get("fromDate"))).format(Date.ISO8601_DATE),
            toDate: 1 .yearAfter(Date.create(this.get("toDate"))).format(Date.ISO8601_DATE)
          });
          return;
        }
      },
      selectUser: function selectUser(user) {
        var userId = this.get("userList").findBy("email", user);
        if (userId && userId.id) {
          this.set("userId", userId.id);
        }
      },
      exportExcel: function exportExcel() {
        var report = this.getReport();
        this.set("reportData", JSON.stringify(report));
        Ember.run.scheduleOnce("afterRender", function () {
          Ember.$("form.excel-data").submit();
        });
      },
      userChange: function userChange(user) {
        var userId = user ? user.id : null;
        this.set("userId", userId);
      },
      domainChange: function domainChange(domain) {
        var domainId = domain ? domain.id : null;
        this.set("selectedDomain", domainId);
      }
    }
  });
});