define("budgeta/templates/import-management-banner-step-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "bu96jceZ",
    "block": "{\"statements\":[[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"step-header\"],[\"flush-element\"],[\"append\",[\"unknown\",[\"stepHeader\"]],false],[\"close-element\"],[\"text\",\"\\n\"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"intro\"],[\"flush-element\"],[\"append\",[\"helper\",[\"t\"],[\"import-all.step3-intro\"],null],false],[\"close-element\"],[\"text\",\"\\n\"],[\"block\",[\"if\"],[[\"get\",[\"displayThousands\"]]],null,1],[\"block\",[\"if\"],[[\"get\",[\"highlighted\"]]],null,0]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"mt20\"],[\"flush-element\"],[\"text\",\"\\n    \"],[\"append\",[\"helper\",[\"svg-icon\"],null,[[\"classNames\",\"name\"],[\"help-icon\",\"info\"]]],false],[\"text\",\"\\n    \"],[\"open-element\",\"span\",[]],[\"static-attr\",\"class\",\"banner-highlighted-info\"],[\"flush-element\"],[\"append\",[\"helper\",[\"t\"],[\"import-all.highlight-imported\"],null],true],[\"close-element\"],[\"text\",\"\\n  \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"budgeta-checkbox\"],[\"flush-element\"],[\"text\",\"\\n    \"],[\"append\",[\"helper\",[\"input\"],null,[[\"type\",\"checked\",\"id\"],[\"checkbox\",[\"get\",[\"thousands\"]],\"import-as-thousands-checkbox\"]]],false],[\"text\",\"\\n    \"],[\"open-element\",\"label\",[]],[\"static-attr\",\"for\",\"import-as-thousands-checkbox\"],[\"flush-element\"],[\"text\",\" \"],[\"append\",[\"helper\",[\"t\"],[\"import-all.import-as-thousands\"],null],false],[\"text\",\" \"],[\"close-element\"],[\"text\",\"\\n  \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/import-management-banner-step-3.hbs"
    }
  });
});