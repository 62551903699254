define("budgeta/controllers/main/budget/trx", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "@babel/runtime/helpers/esm/toConsumableArray", "budgeta/services/ember-service-container", "budgeta/utils/const", "budgeta/utils/budget-utils", "lodash/lodash", "budgeta/utils/assumption"], function (_exports, _objectSpread2, _toConsumableArray2, _emberServiceContainer, _const, _budgetUtils, _lodash, _assumption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/new-module-imports, ember/no-side-effects, ember/avoid-leaking-state-in-ember-objects, no-prototype-builtins, ember/no-observers */
  var _default = _exports.default = Ember.Controller.extend({
    mainController: Ember.inject.controller("main"),
    treeManager: Ember.inject.service(),
    budgetService: Ember.inject.service(),
    setupGuide: Ember.inject.service(),
    performanceLogger: Ember.inject.service(),
    viewSelections: Ember.inject.service(),
    automation: Ember.inject.service(),
    intl: Ember.inject.service(),
    cache: Ember.inject.service(),
    allTransactions: Ember.A([]),
    lastScrollTop: 0,
    selectAll: false,
    dimensionsMap: undefined,
    columnsLayout: [100],
    disableActions: Ember.computed("budgetService.selectedRoot.isPartialShare", "budgetService.sharedReadOnlyOrVersion", function () {
      return this.get("budgetService.sharedReadOnlyOrVersion") || this.get("budgetService.selectedRoot.isPartialShare");
    }),
    filterToValueMapping: {
      budgetLines: function budgetLines(trx) {
        var computedBudgetLine = Ember.get(trx, "computedBudgetLine");
        if (computedBudgetLine) {
          return computedBudgetLine.get("name");
        }
        return "";
      },
      statementOptions: function statementOptions(trx) {
        return Ember.get(trx, "statement");
      },
      descriptions: function descriptions(trx) {
        return Ember.get(trx, "description");
      },
      accountIds: function accountIds(trx) {
        return Ember.get(trx, "accountId");
      },
      fiscalDates: function fiscalDates(trx) {
        if (Ember.get(trx, "statement") === "pnl" || Ember.get(trx, "statement") === "both" || Ember.get(trx, "statement") === "stat") {
          if (Ember.get(trx, "fiscalDate")) {
            return Ember.get(trx, "fiscalDate").format(_const.default.SugarMonthYearDisplayFormat);
          }
        }
        return "";
      },
      trxDates: function trxDates(trx) {
        var date = new Date(Ember.get(trx, "trxDate"));
        if (Ember.get(trx, "statement") === "pnl" || !date) {
          return "";
        }
        return moment(date.endOfMonth().reset()).format(_const.default.MomentFullDateDisplayFormat);
      },
      balanceSheetLines: function balanceSheetLines(trx) {
        var balanceSheetLines = this.get("balanceSheetLines");
        if (Ember.get(trx, "computedBsLine") && balanceSheetLines.findBy("id", Ember.get(trx, "computedBsLine"))) {
          return Ember.get(balanceSheetLines.findBy("id", Ember.get(trx, "computedBsLine")), "text");
        } else {
          return Ember.get(trx, "computedBsLine");
        }
      },
      transactionTypes: function transactionTypes(trx) {
        return Ember.get(trx, "type");
      },
      sources: function sources(trx) {
        return Ember.get(trx, "sourceToDisplay");
      },
      books: function books(trx) {
        return Ember.get(trx, "books");
      },
      importFilters: function importFilters(trx) {
        return Ember.get(trx, "importFilters");
      },
      trxIds: function trxIds(trx) {
        return Ember.get(trx, "trxId");
      },
      categories: function categories(trx) {
        return Ember.get(trx, "computedCategory");
      },
      created: function created(trx) {
        return Ember.get(trx, "created") ? moment(Ember.get(trx, "created")).format(_const.default.MomentDateAndTimeDisplayFormat) : "";
      },
      createdBy: function createdBy(trx) {
        return Ember.get(trx, "createdBy.displayName") ? Ember.get(trx, "createdBy.displayName") : "Unknown ";
      },
      modified: function modified(trx) {
        return Ember.get(trx, "modified") ? moment(Ember.get(trx, "modified")).format(_const.default.MomentDateAndTimeDisplayFormat) : "";
      },
      modifiedBy: function modifiedBy(trx) {
        return Ember.get(trx, "modifiedBy.displayName");
      },
      notes: function notes(trx) {
        return Ember.get(trx, "note");
      },
      amounts: function amounts(trx) {
        return Ember.get(trx, "amount");
      },
      currencies: function currencies(trx) {
        return Ember.get(trx, "currency");
      },
      unitTypes: function unitTypes(trx) {
        return _assumption.default.getUnitTypeAsPctCurrency(Ember.get(trx, "unitType"));
      }
    },
    total: Ember.computed("filteredTransactions", "filteredTransactions.length", "recalcTotal", function () {
      var total = 0,
        root = this.get("model.root"),
        currency = this.get("model.forecastAttributes.currency");
      this.get("filteredTransactions").forEach(function (t) {
        var amount = (t.get("amount") || 0) * 1;
        if (t.get("currency") && t.get("currency") !== currency) {
          amount = _const.default.convertCurrency(root, amount, t.get("currency"), currency, t.get("fiscalDate"));
        }
        total += amount;
      });
      return total;
    }),
    recalcTotal: undefined,
    dropDownChanged: false,
    changed: false,
    addTrxToFilters: function addTrxToFilters(ctrl, trx, filters) {
      if (!trx.isDestroyed) {
        trx.set("filters", {});
        filters = filters || ctrl.get("filters");
        var filterToValueMapping = ctrl.get("filterToValueMapping");
        // For each filter group, we will create a filter instance
        for (var key in filterToValueMapping) {
          if (filterToValueMapping.hasOwnProperty(key) && filters[key]["transactions"]) {
            var trxValue = filterToValueMapping[key].call(ctrl, trx);
            var transactionsOfFilters = filters[key]["transactions"][trxValue] || {
              size: 0
            };
            transactionsOfFilters.size += 1; // Adding the Trx Id to the value of the filter
            trx.set("filters.".concat(key), transactionsOfFilters); // setting the group the trx is assigned to
            filters[key]["transactions"][trxValue] = transactionsOfFilters;
          }
        }
      }
    },
    checkSimpleFilter: function checkSimpleFilter(filterName, trx) {
      var allTransactionMap = this.get("filters.".concat(filterName, ".transactions")); // Retreving all the trxs divided by values
      var filterToValueMapping = this.get("filterToValueMapping");
      var trxValue = filterToValueMapping[filterName].call(this, trx);
      var filter = this.get("filters.".concat(filterName, ".filters"));
      var hasFilter = filter.findBy("name", trxValue);
      var transctionsSet;
      if (!hasFilter) {
        transctionsSet = {
          size: 0
        }; // Creating the new Transactions set for the new Value
        var newObject;
        // need to add a new Object
        newObject = {
          selected: false,
          name: trxValue,
          transactions: transctionsSet
        };
        filter.addObject(newObject);
        this.get("filterMap.".concat(filterName, ".filters")).addObject(newObject);
        this.set("filters.".concat(filterName, ".filters"), filter.sortBy("name"));
        if (allTransactionMap) {
          allTransactionMap[trxValue] = transctionsSet; // Creating a new set for the new value
        }
      }
    },
    minTrxDate: Ember.computed("model.root.closedActuals.length", function () {
      if (this.get("model.root.closedActuals.length")) {
        return new Date(Ember.get(this.get("model.root.closedActuals").sort(), "lastObject")).addMonths(1);
      } else {
        return new Date("01-01-2000");
      }
    }),
    calcAllTransactionsDebounced: function calcAllTransactionsDebounced() {
      var _this = this;
      _this.set("viewSelections.transactionsLoaded", false);
      var allTrx = _budgetUtils.default.getTransactionsRecursiveFaster(_this.get("model")) || [];
      _this.get("setupGuide").notifyPropertyChange("recalcGuide");
      allTrx.map(function (trx) {
        if (Ember.get(trx, "fiscalDateDisabled")) {
          if (Ember.get(trx, "fiscalDate")) {
            Ember.set(trx, "fiscalDateFinal", moment(Ember.get(trx, "fiscalDate")).format(_const.default.MomentMonthYearDisplayFormat));
          } else {
            Ember.set(trx, "fiscalDateFinal", "");
          }
        }
        if (Ember.get(trx, "trxDateDisabled")) {
          if (Ember.get(trx, "trxDate")) {
            var tempDate = new Date(Ember.get(trx, "trxDate"));
            if (Ember.get(trx, "statement") === "bs") {
              tempDate.endOfMonth();
            }
            Ember.set(trx, "trxDateFinal", moment(tempDate).format(_const.default.MomentFullDateDisplayFormat));
          } else {
            Ember.set(trx, "trxDateFinal", "");
          }
        }
        return trx;
      });
      allTrx = allTrx.filter(function (trx) {
        return !(trx.get("budgetLineId") && trx.get("computedBudgetLine.isNameEncrypted"));
      });
      if (this.get("budgetService.selectedRoot.isPartialShare")) {
        var hasPastTrx = false;
        var hasPastPnlTrx = false;
        var hasPastBalanceTrx = false;
        var hasPastModelTrx = false;
        allTrx = (allTrx || []).filter(function (trx) {
          if (trx.get("statement") === "bs" || !trx.get("budgetLineId") || trx.get("computedBudgetLine.absoluteRoot") === trx.get("computedBudgetLine")) {
            return false;
          }
          if (trx.get("isPastTrx") && !trx.get("isBudgetTransaction")) {
            hasPastTrx = true;
            if (trx.get("statement") === "both" || trx.get("statement") === "pnl" || trx.get("statement") === "cash") {
              hasPastPnlTrx = true;
            } else if (trx.get("statement") === "bs") {
              hasPastBalanceTrx = true;
            } else if (trx.get("statement") === "stat") {
              hasPastModelTrx = true;
            }
          }
          return true;
        });
        _this.set("budgetService.hasPastActuals", hasPastTrx); // remove with feature flag
        _this.set("budgetService.hasPastActualsPnl", hasPastPnlTrx);
        _this.set("budgetService.hasPastActualsBalance", hasPastBalanceTrx);
        _this.set("budgetService.hasPastActualsModel", hasPastModelTrx);
      }
      _this.set("allTransactions", allTrx);
      _this.set("viewSelections.transactionsLoaded", true);
      _this.set("showGlobalOptions", false);
      _this.get("performanceLogger").measureAction(_this.get("mainController"));
    },
    calcAllTransactions: Ember.observer("model", "budgetService.selectedRoot", function () {
      if (!this.get("model") || !this.get("budgetService.selectedRoot")) {
        return;
      }
      Ember.run.debounce(this, "calcAllTransactionsDebounced", 100);
    }),
    filteredModelLinesSameUnitTypes: Ember.computed("filteredTransactions.@each.unitType", function () {
      var currencyId = 1;
      var precentageId = 2;
      var numberId = 3;
      var getUnitType = function getUnitType(unit) {
        if (!unit) return;
        var isCurrency = function isCurrency(responseIfTrue) {
          var currencySymbols = new Set(Object.keys(_const.default.currency_symbols));
          if (currencySymbols.has(unit)) {
            return responseIfTrue;
          }
          return false;
        };
        var isPrecentage = function isPrecentage(responseIfTrue) {
          if (unit === "%") {
            return responseIfTrue;
          }
          return false;
        };
        var isNumber = function isNumber(responseIfTrue) {
          if (unit.includes("#") || unit.includes(".")) {
            return responseIfTrue;
          }
          return false;
        };
        return isCurrency(currencyId) || isPrecentage(precentageId) || isNumber(numberId);
      };
      var types = this.get("filteredTransactions").map(function (trx) {
        return getUnitType(trx.get("unitType"));
      });
      return new Set(types.filter(function (type) {
        return type;
      }));
    }),
    filteredModelLinesSameUnitTypeForDisplay: Ember.computed("filteredModelLinesSameUnitTypes", "isModelDetailsView", function () {
      /**
       * Will display total if all model unit types are the same and not precentage:
       * example:
       * [#, #.##] - will show total
       * [USD, EUR] - will show total
       * [%, %] - will not show total
       * [#, USD] - will not show total
       */
      var precentageId = 2;
      if (this.get("isModelDetailsView")) {
        var uniqueTypes = this.get("filteredModelLinesSameUnitTypes");
        if (uniqueTypes.size < 2 && !uniqueTypes.has(precentageId)) {
          return uniqueTypes.size === 1 ? (0, _toConsumableArray2.default)(uniqueTypes)[0] : true;
        }
      }
      return false;
    }),
    statementOptions: Ember.computed("budgetService.pnlName", function () {
      var pnlName = this.get("budgetService.pnlName");
      var options = [{
        id: "both",
        text: intl.t("general.trx-1", {
          var1: pnlName
        })
      }, {
        id: "pnl",
        text: pnlName
      }, {
        id: "cash",
        text: intl.t("general.name_1695611602487")
      }];
      return options;
    }),
    typeOptions: [{
      id: "balance",
      text: intl.t("general.text_1695611610237")
    }, {
      id: "trx",
      text: intl.t("general.text_1695611610226")
    }],
    modelObserver: Ember.observer("model", function () {
      this.set("lastScrollTop", 0);
      if (!Ember.isEmpty(this.get("model"))) {
        this.set("mainController.selectedBudget", this.get("model"));
        this.set("mainController.navLevel", this.get("mainController.navLevel") < 1 ? 1 : this.get("mainController.navLevel"));
      }
    }),
    addBudgetLineFilter: undefined,
    hasImportFilters: Ember.computed("filters.importFilters.filters.length", function () {
      return this.get("filters.importFilters.filters").findBy("name") ? true : false;
    }),
    actualsDetailsType: Ember.computed.alias("viewSelections.actualsDetailsType"),
    dateHeaderByDetailsType: Ember.computed("actualsDetailsType", function () {
      return this.get("intl").t("trx.".concat(this.get("actualsDetailsType"), "-month"));
    }),
    lineHeaderByDetailsType: Ember.computed("actualsDetailsType", function () {
      var lineNames = {
        pnl: intl.t("general.budget_1698234256689"),
        model: intl.t("general.model_1698234256161")
      };
      return this.get("intl").t("trx.".concat(lineNames[this.get("actualsDetailsType")], "-line"));
    }),
    currencyHeaderByDetailsType: Ember.computed("actualsDetailsType", function () {
      var lineNames = {
        pnl: intl.t("general.currency_1698234256688"),
        balance: intl.t("general.currency_1698234256688"),
        model: "unit-type"
      };
      return this.get("intl").t("trx.".concat(lineNames[this.get("actualsDetailsType")]));
    }),
    isPnlDetailsView: Ember.computed("viewSelections.currentPath", "actualsDetailsType", function () {
      return this.get("isDetailsView") && this.get("actualsDetailsType") === "pnl";
    }),
    isBalanceDetailsView: Ember.computed("viewSelections.currentPath", "actualsDetailsType", function () {
      return this.get("isDetailsView") && this.get("actualsDetailsType") === "balance";
    }),
    isModelDetailsView: Ember.computed("viewSelections.currentPath", "actualsDetailsType", function () {
      return this.get("isDetailsView") && this.get("actualsDetailsType") === "model";
    }),
    isDetailsView: Ember.computed("viewSelections.currentPath", function () {
      return this.get("viewSelections.currentPath") === "main.budget.trx";
    }),
    previewTransactions: Ember.computed("budgetService.hasPastData", "allTransactions", "allTransactions.length", "viewSelections.transactionsViewTypePnl", "viewSelections.transactionsViewTypeBalance", "viewSelections.transactionsViewTypeModel", "viewSelections.currentPath", "actualsDetailsType", "budgetService.selectedRoot.absoluteRoot.budgetAttributes.startDate", function () {
      var _this2 = this;
      var allTransactions = this.get("allTransactions") || [];
      if (this.get("isDetailsView")) {
        allTransactions = allTransactions.filter(function (trx) {
          var isPnl = _this2.get("isPnlDetailsView") && (trx.get("statement") === "both" || trx.get("statement") === "pnl" || trx.get("statement") === "cash");
          var isBS = _this2.get("isBalanceDetailsView") && trx.get("statement") === "bs";
          var isModel = _this2.get("isModelDetailsView") && trx.get("statement") === "stat";
          return isPnl || isBS || isModel;
        });
      }
      var viewType = this.get("isPnlDetailsView") && this.get("viewSelections.transactionsViewTypePnl") || this.get("isBalanceDetailsView") && this.get("viewSelections.transactionsViewTypeBalance") || this.get("isModelDetailsView") && this.get("viewSelections.transactionsViewTypeModel");
      return _budgetUtils.default.filterTransactionsByPeriod(allTransactions, viewType, {
        filterBudgetTransactions: true
      });
    }),
    filters: Ember.computed("allTransactions", "budgetService.pnlName", "budgetService.dimensions.length", "viewSelections.transactionsViewType", "actualsDetailsType", "viewSelections.transactionsViewTypePnl", "viewSelections.transactionsViewTypeBalance", "viewSelections.transactionsViewTypeModel", "addBsFilter", function () {
      var _this3 = this;
      var filterGroups = {};
      var filterValueMapping = this.get("filterToValueMapping");

      // For each filter group, we will create a filter instance
      for (var key in filterValueMapping) {
        if (filterValueMapping.hasOwnProperty(key)) {
          filterGroups[key] = {
            transactions: {}
          }; // Creating a filter group
        }
      }
      filterGroups = Ember.Object.create(filterGroups);
      var previewTransactions = this.get("previewTransactions").filter(function (t) {
        return !t.get("isBudgetTransaction");
      });
      var filters = Ember.Object.create({
        departments: Ember.A([]),
        statementOptions: Ember.A([]),
        descriptions: Ember.A([]),
        accountIds: Ember.A([]),
        fiscalDates: Ember.A([]),
        trxDates: Ember.A([]),
        budgetLines: {
          filters: Ember.A([]),
          sortBy: false,
          sortOrder: true,
          selectList: Ember.A([])
        },
        balanceSheetLines: Ember.A([]),
        transactionTypes: Ember.A([]),
        sources: Ember.A([]),
        books: Ember.A([]),
        importFilters: Ember.A([]),
        trxIds: {
          filters: Ember.A([]),
          sortBy: false,
          sortOrder: true,
          selectList: Ember.A([])
        },
        categories: Ember.A([]),
        created: Ember.A([]),
        createdBy: Ember.A([]),
        modified: Ember.A([]),
        modifiedBy: Ember.A([]),
        notes: Ember.A([]),
        amounts: Ember.A([]),
        currencies: Ember.A([]),
        unitTypes: Ember.A([])
      });
      var budgetLinesMap = {},
        dimensionsMap = {},
        dimensionsDisplayNameMap = {},
        fiscalDates = {},
        trxDates = {},
        createdDates = {},
        modifiedDates = {},
        balanceSheetLines = this.get("balanceSheetLines");
      (this.get("budgetService.dimensions") || []).forEach(function (d) {
        if (!dimensionsMap[Ember.get(d, "uniqueId")]) {
          filterGroups[Ember.get(d, "uniqueId")] = {
            transactions: {}
          };
          dimensionsMap[Ember.get(d, "uniqueId")] = {
            values: {}
          };
          dimensionsDisplayNameMap[Ember.get(d, "uniqueId")] = {};
          (Ember.get(d, "values") || []).forEach(function (dimValue) {
            Ember.set(dimensionsMap[Ember.get(d, "uniqueId")], "values.".concat(dimValue.uniqueId), dimValue.name || "");
            Ember.set(dimensionsDisplayNameMap[Ember.get(d, "uniqueId")], "".concat(dimValue.uniqueId), dimValue.displayName || "");
            filterGroups[Ember.get(d, "uniqueId")]["transactions"][dimValue.uniqueId] = {
              size: 0
            };
          });
          filterGroups[Ember.get(d, "uniqueId")]["transactions"][""] = {
            size: 0
          };
        }
        Ember.set(filters, Ember.get(d, "uniqueId"), {
          filters: Ember.A([]),
          sortBy: false,
          sortOrder: true,
          selectList: Ember.A([]),
          transactions: filterGroups[Ember.get(d, "uniqueId")]["transactions"]
        });
      });
      this.set("dimensionsMap", dimensionsMap); // Storing the dimensions Map
      previewTransactions.forEach(function (trx) {
        _this3.addTrxToFilters(_this3, trx, filterGroups);
        filters.get("descriptions").addObject(Ember.get(trx, "description"));
        filters.get("accountIds").addObject(Ember.get(trx, "accountId"));
        if (Ember.get(trx, "statement") === "pnl" || Ember.get(trx, "statement") === "both" || Ember.get(trx, "statement") === "stat") {
          fiscalDates[Ember.get(trx, "fiscalDate") ? moment(new Date(Ember.get(trx, "fiscalDate")).endOfMonth().reset()).format(_const.default.MomentFullDateDisplayFormat) : "empty"] = Ember.get(trx, "fiscalDate");
        }
        if (Ember.get(trx, "statement") === "bs" || Ember.get(trx, "statement") === "cash") {
          fiscalDates["empty"] = null;
        }
        if (Ember.get(trx, "statement") === "pnl") {
          trxDates["empty"] = null;
        }
        if (Ember.get(trx, "statement") !== "pnl") {
          trxDates[Ember.get(trx, "trxDate") ? moment(new Date(Ember.get(trx, "trxDate")).endOfMonth().reset()).format(_const.default.MomentFullDateDisplayFormat) : "empty"] = Ember.get(trx, "trxDate");
        }
        modifiedDates[Ember.get(trx, "modified") ? Ember.get(trx, "modified").format(_const.default.SugerDateAndTimeDisplayFormat) : "empty"] = Ember.get(trx, "modified");
        createdDates[Ember.get(trx, "created") ? Ember.get(trx, "created").format(_const.default.SugerDateAndTimeDisplayFormat) : "empty"] = Ember.get(trx, "created");
        if (Ember.get(trx, "computedBsLine") && balanceSheetLines.findBy("id", Ember.get(trx, "computedBsLine"))) {
          filters.get("balanceSheetLines").addObject(Ember.get(balanceSheetLines.findBy("id", Ember.get(trx, "computedBsLine")), "text"));
        } else {
          filters.get("balanceSheetLines").addObject(Ember.get(trx, "computedBsLine"));
        }
        filters.get("modifiedBy").addObject(Ember.get(trx, "modifiedBy.displayName"));
        filters.get("createdBy").addObject(Ember.get(trx, "createdBy.displayName") ? Ember.get(trx, "createdBy.displayName") : "Unknown ");
        filters.get("notes").addObject(Ember.get(trx, "note"));
        filters.get("amounts").addObject(Ember.get(trx, "amount"));
        filters.get("currencies").addObject(Ember.get(trx, "currency"));
        filters.get("unitTypes").addObject(_assumption.default.getUnitTypeAsPctCurrency(Ember.get(trx, "unitType")));
        filters.get("sources").addObject(Ember.get(trx, "sourceToDisplay"));
        filters.get("books").addObject(Ember.get(trx, "books"));
        filters.get("importFilters").addObject(Ember.get(trx, "importFilters"));
        filters.get("transactionTypes").addObject(Ember.get(trx, "type"));
        filters.get("statementOptions").addObject(Ember.get(trx, "statement"));
        if (Ember.get(trx, "computedDepartmentList")) {
          filters.get("departments").addObjects(Ember.get(trx, "computedDepartmentList").split("; "));
        } else {
          filters.get("departments").addObject("");
        }
        if (Ember.get(trx, "budgetLineId")) {
          var computedBudgetLine = Ember.get(trx, "computedBudgetLine");
          if (computedBudgetLine) {
            (computedBudgetLine.get("forecastAttributes.dimensions") || Ember.A([])).forEach(function (budgetDimension) {
              if (dimensionsMap[Ember.get(budgetDimension, "dimension")]) {
                trx.set("filters.".concat(Ember.get(budgetDimension, "dimension")), Ember.A([]));
                var values = (Ember.get(budgetDimension, "values") || Ember.A([])).mapBy("value").filter(function (v) {
                  return v;
                });
                for (var i = 0; i < values.length; i++) {
                  Ember.set(dimensionsMap, "".concat(Ember.get(budgetDimension, "dimension"), ".").concat(values[i]), values[i]);
                  var trxSet = filterGroups[Ember.get(budgetDimension, "dimension")]["transactions"][values[i]];
                  if (trxSet) {
                    trxSet.size += 1;
                    trx.get("filters.".concat(Ember.get(budgetDimension, "dimension"))).addObject(trxSet);
                  }
                }
                if (!values.length) {
                  Ember.set(dimensionsMap, "".concat(Ember.get(budgetDimension, "dimension"), "._empty"), "");
                  var _trxSet = filterGroups[Ember.get(budgetDimension, "dimension")]["transactions"][""];
                  if (_trxSet) {
                    _trxSet.size += 1;
                    trx.get("filters.".concat(Ember.get(budgetDimension, "dimension"))).addObject(_trxSet);
                  }
                }
              }
            });
          }
          budgetLinesMap[Ember.get(trx, "budgetLineId")] = computedBudgetLine;
        }
        filters.get("categories").addObject(Ember.get(trx, "computedCategory"));
        filters.get("trxIds.filters").addObject({
          selected: false,
          name: Ember.get(trx, "trxId"),
          transactions: filterGroups["trxIds"]["transactions"][Ember.get(trx, "trxId")]
        });
      });
      filters.set("descriptions", {
        filters: filters.descriptions.map(function (item) {
          return {
            selected: false,
            name: item,
            transactions: filterGroups["descriptions"]["transactions"][item]
          };
        }).sortBy("name"),
        sortBy: true,
        sortOrder: true,
        selectList: Ember.A([]),
        transactions: filterGroups["descriptions"]["transactions"]
      });
      filters.set("accountIds", {
        filters: filters.accountIds.map(function (item) {
          return {
            selected: false,
            name: item,
            transactions: filterGroups["accountIds"]["transactions"][item]
          };
        }).sortBy("name"),
        sortBy: false,
        sortOrder: true,
        selectList: Ember.A([]),
        transactions: filterGroups["accountIds"]["transactions"]
      });
      for (var _key in fiscalDates) {
        var date = fiscalDates[_key] ? fiscalDates[_key].format(_const.default.SugarMonthYearDisplayFormat) : "";
        filters.fiscalDates.addObject({
          selected: false,
          name: fiscalDates[_key],
          dateStr: date,
          transactions: filterGroups["fiscalDates"]["transactions"][date]
        });
      }
      for (var _key2 in trxDates) {
        var _date = trxDates[_key2] ? moment(new Date(trxDates[_key2]).endOfMonth().reset()).format(_const.default.MomentFullDateDisplayFormat) : "";
        filters.trxDates.addObject({
          selected: false,
          name: trxDates[_key2],
          dateStr: _date,
          transactions: filterGroups["trxDates"]["transactions"][_date]
        });
      }
      for (var _key3 in createdDates) {
        var _date2 = createdDates[_key3] ? moment(createdDates[_key3]).format(_const.default.MomentDateAndTimeDisplayFormat) : "";
        filters.created.addObject({
          selected: false,
          name: createdDates[_key3],
          dateStr: _date2,
          transactions: filterGroups["created"]["transactions"][_date2]
        });
      }
      for (var _key4 in modifiedDates) {
        var _date3 = modifiedDates[_key4] ? moment(modifiedDates[_key4]).format(_const.default.MomentDateAndTimeDisplayFormat) : "";
        filters.modified.addObject({
          selected: false,
          name: modifiedDates[_key4],
          dateStr: _date3,
          transactions: filterGroups["modified"]["transactions"][_date3]
        });
      }
      filters.set("fiscalDates", {
        filters: filters.fiscalDates.sortBy("name"),
        sortBy: false,
        sortOrder: true,
        selectList: Ember.A([]),
        transactions: filterGroups["fiscalDates"]["transactions"]
      });
      filters.set("trxDates", {
        filters: filters.trxDates.sortBy("name"),
        sortBy: false,
        sortOrder: true,
        selectList: Ember.A([]),
        transactions: filterGroups["trxDates"]["transactions"]
      });
      filters.set("created", {
        filters: filters.created.sortBy("name"),
        sortBy: false,
        sortOrder: true,
        selectList: Ember.A([]),
        transactions: filterGroups["created"]["transactions"]
      });
      filters.set("modified", {
        filters: filters.modified.sortBy("name"),
        sortBy: false,
        sortOrder: true,
        selectList: Ember.A([]),
        transactions: filterGroups["modified"]["transactions"]
      });
      var addBsFilter = this.get("addBsFilter");
      var bsFilters = filters.balanceSheetLines.map(function (item) {
        var bsItem = balanceSheetLines.findBy("text", item);
        var itemId = bsItem && Ember.get(bsItem, "id");
        return {
          selected: addBsFilter && itemId === addBsFilter,
          name: item,
          bsId: itemId || null,
          transactions: filterGroups["balanceSheetLines"]["transactions"][item]
        };
      }).sortBy("name");
      if (addBsFilter && !bsFilters.some(function (line) {
        return line.selected;
      })) {
        var bsItem = balanceSheetLines.findBy("id", addBsFilter);
        bsFilters.push({
          selected: true,
          name: bsItem.text,
          bsId: addBsFilter,
          transactions: null
        });
      }
      filters.set("balanceSheetLines", {
        filters: bsFilters,
        sortBy: false,
        sortOrder: true,
        selectList: bsFilters.some(function (f) {
          return f.selected;
        }) ? bsFilters : Ember.A([]),
        transactions: filterGroups["balanceSheetLines"]["transactions"]
      });
      filters.set("categories", {
        filters: filters.categories.map(function (item) {
          return {
            selected: false,
            name: item,
            transactions: filterGroups["categories"]["transactions"][item]
          };
        }).sortBy("name"),
        sortBy: false,
        sortOrder: true,
        selectList: Ember.A([]),
        transactions: filterGroups["categories"]["transactions"]
      });
      filters.set("createdBy", {
        filters: filters.createdBy.map(function (item) {
          return {
            selected: false,
            name: item,
            transactions: filterGroups["createdBy"]["transactions"][item]
          };
        }).sortBy("name"),
        sortBy: false,
        sortOrder: true,
        selectList: Ember.A([]),
        transactions: filterGroups["createdBy"]["transactions"]
      });
      filters.set("modifiedBy", {
        filters: filters.modifiedBy.map(function (item) {
          return {
            selected: false,
            name: item,
            transactions: filterGroups["modifiedBy"]["transactions"][item]
          };
        }).sortBy("name"),
        sortBy: false,
        sortOrder: true,
        selectList: Ember.A([]),
        transactions: filterGroups["modifiedBy"]["transactions"]
      });
      filters.set("notes", {
        filters: filters.notes.map(function (item) {
          return {
            selected: false,
            name: item,
            transactions: filterGroups["notes"]["transactions"][item]
          };
        }).sortBy("name"),
        sortBy: false,
        sortOrder: true,
        selectList: Ember.A([]),
        transactions: filterGroups["notes"]["transactions"]
      });
      filters.set("amounts", {
        filters: filters.amounts.map(function (item) {
          return {
            selected: false,
            name: item,
            transactions: filterGroups["amounts"]["transactions"][item]
          };
        }).sortBy("name"),
        sortBy: false,
        sortOrder: true,
        selectList: Ember.A([]),
        transactions: filterGroups["amounts"]["transactions"]
      });
      filters.set("currencies", {
        filters: filters.currencies.map(function (item) {
          return {
            selected: false,
            name: item,
            transactions: filterGroups["currencies"]["transactions"][item]
          };
        }).sortBy("name"),
        sortBy: false,
        sortOrder: true,
        selectList: Ember.A([]),
        transactions: filterGroups["currencies"]["transactions"]
      });
      filters.set("unitTypes", {
        filters: filters.unitTypes.map(function (item) {
          return {
            selected: false,
            name: item,
            transactions: filterGroups["unitTypes"]["transactions"][item]
          };
        }).sortBy("name"),
        sortBy: false,
        sortOrder: true,
        selectList: Ember.A([]),
        transactions: filterGroups["unitTypes"]["transactions"]
      });
      filters.set("sources", {
        filters: filters.sources.map(function (item) {
          return {
            selected: false,
            name: item,
            transactions: filterGroups["sources"]["transactions"][item]
          };
        }).sortBy("name"),
        sortBy: false,
        sortOrder: true,
        selectList: Ember.A([]),
        transactions: filterGroups["sources"]["transactions"]
      });
      filters.set("books", {
        filters: filters.books.map(function (item) {
          return {
            selected: false,
            name: item,
            transactions: filterGroups["books"]["transactions"][item]
          };
        }).sortBy("name"),
        sortBy: false,
        sortOrder: true,
        selectList: Ember.A([]),
        transactions: filterGroups["books"]["transactions"]
      });
      filters.set("importFilters", {
        filters: filters.importFilters.map(function (item) {
          return {
            selected: false,
            name: item,
            transactions: filterGroups["importFilters"]["transactions"][item]
          };
        }).sortBy("name"),
        sortBy: false,
        sortOrder: true,
        selectList: Ember.A([]),
        transactions: filterGroups["importFilters"]["transactions"]
      });
      filters.set("departments", {
        filters: filters.departments.map(function (item) {
          return {
            selected: false,
            name: item
          };
        }).sortBy("name"),
        sortBy: false,
        sortOrder: true,
        selectList: Ember.A([])
      });
      filters.set("transactionTypes", {
        filters: filters.transactionTypes.map(function (item) {
          return {
            selected: false,
            name: item === "trx" ? intl.t("general.text_1695611610226") : intl.t("general.text_1695611610237"),
            id: item,
            transactions: filterGroups["transactionTypes"]["transactions"][item]
          };
        }).sortBy("name"),
        sortBy: false,
        sortOrder: true,
        selectList: Ember.A([]),
        transactions: filterGroups["transactionTypes"]["transactions"]
      });
      var pnlName = this.get("budgetService.pnlName");
      filters.set("statementOptions", {
        filters: filters.statementOptions.map(function (item) {
          return {
            selected: false,
            name: item === "both" ? intl.t("general.trx-1", {
              var1: pnlName
            }) : item === "pnl" ? pnlName : item === "cash" ? "Cash" : "Balance sheet",
            statementId: item,
            transactions: filterGroups["statementOptions"]["transactions"][item]
          };
        }).sortBy("name"),
        sortBy: false,
        sortOrder: true,
        selectList: Ember.A([]),
        transactions: filterGroups["statementOptions"]["transactions"]
      });
      var addBudgetLineFilter = this.get("addBudgetLineFilter");
      for (var _key5 in budgetLinesMap) {
        var thisBudgetLine = budgetLinesMap[_key5];
        if (thisBudgetLine) {
          var tempBudgetSelect = {
            selected: addBudgetLineFilter && addBudgetLineFilter.indexOf(thisBudgetLine.get("id")) !== -1,
            name: thisBudgetLine.get("name"),
            title: thisBudgetLine.get("uniqueName"),
            budgetId: thisBudgetLine.get("id"),
            budget: thisBudgetLine,
            transactions: filterGroups["budgetLines"]["transactions"][thisBudgetLine.get("name")]
          };
          filters.get("budgetLines.filters").push(tempBudgetSelect);
          if (tempBudgetSelect.selected) {
            filters.get("budgetLines.selectList").push(tempBudgetSelect);
          }
        }
      }
      filters.set("budgetLines.transactions", filterGroups["budgetLines"]["transactions"]);
      for (var _key6 in dimensionsMap) {
        var thisDimension = dimensionsMap[_key6];
        if (thisDimension) {
          for (var keyValue in thisDimension) {
            if (keyValue === "values") {
              continue;
            }
            var tempDimensionValueSelect = {
              selected: false,
              name: thisDimension.values[keyValue],
              title: dimensionsDisplayNameMap[_key6][keyValue],
              budgetId: keyValue,
              transactions: filterGroups[_key6]["transactions"][keyValue === "_empty" ? "" : keyValue]
            };
            filters.get("".concat(_key6, ".filters")).push(tempDimensionValueSelect);
          }
        }
      }
      var fromLine = this.get("addBudgetLineFilter.firstObject");
      if (fromLine && !filters.get("budgetLines.filters").findBy("budgetId", fromLine)) {
        var fromBudgetLine = this.get("store").peekRecord("budget", fromLine);
        var _tempBudgetSelect = {
          selected: true,
          name: fromBudgetLine && fromBudgetLine.get("name"),
          title: fromBudgetLine && fromBudgetLine.get("uniqueName"),
          budget: fromBudgetLine,
          budgetId: fromLine
        };
        filters.get("budgetLines.filters").insertAt(0, _tempBudgetSelect);
        if (_tempBudgetSelect.selected) {
          filters.get("budgetLines.selectList").push(_tempBudgetSelect);
        }
      }
      filters.set("budgetLines.filters", (filters.get("budgetLines.filters") || Ember.A([])).sortBy("name"));
      filters.set("trxIds.transactions", filterGroups["trxIds"]["transactions"]);
      return filters;
    }),
    hasAnyFilter: Ember.computed("filteredColumns", "filteredColumns.length", function () {
      return this.get("filteredColumns.length") > 0;
    }),
    filteredColumns: Ember.computed("filters.departments.selectList.length", "filters.statementOptions.selectList.length", "filters.descriptions.selectList.length", "filters.accountIds.selectList.length", "filters.fiscalDates.selectList.length", "filters.trxDates.selectList.length", "filters.balanceSheetLines.selectList.length", "filters.transactionTypes.selectList.length", "filters.sources.selectList.length", "filters.books.selectList.length", "filters.importFilters.selectList.length", "filters.trxIds.selectList.length", "filters.notes.selectList.length", "filters.categories.selectList.length", "filters.amounts.selectList.length", "filters.created.selectList.length", "filters.createdBy.selectList.length", "filters.modified.selectList.length", "filters.modifiedBy.selectList.length", "filters.currencies.selectList.length", "filters.unitTypes.selectList.length", "filters.budgetLines.selectList.length", "addBsFilter", "recalcDimension", "actualsDetailsType", function () {
      var filteredColumns = Ember.A([]);
      var filters = this.get("filters");
      for (var key in filters) {
        if (this.get("filters.".concat(key, ".selectList.length"))) {
          filteredColumns.push(key);
        }
      }
      return filteredColumns;
    }),
    blockRecalc: false,
    recalcDimension: true,
    filterMap: Ember.computed("filters", "filteredColumns", "dimensionArr", "filteredTransactions", function () {
      var filters = this.get("filters");
      var filterMap = {
        descriptions: {
          mapKeys: {},
          filters: filters["descriptions"].filters
        },
        accountIds: {
          mapKeys: {},
          filters: filters["accountIds"].filters
        },
        statementOptions: {
          mapKeys: {},
          filters: filters["statementOptions"].filters
        },
        fiscalDates: {
          mapKeys: {},
          filters: filters["fiscalDates"].filters
        },
        trxDates: {
          mapKeys: {},
          filters: filters["trxDates"].filters
        },
        amounts: {
          mapKeys: {},
          filters: filters["amounts"].filters
        },
        currencies: {
          mapKeys: {},
          filters: filters["currencies"].filters
        },
        unitTypes: {
          mapKeys: {},
          filters: filters["unitTypes"].filters
        },
        budgetLines: {
          mapKeys: {},
          filters: filters["budgetLines"].filters
        },
        balanceSheetLines: {
          mapKeys: {},
          filters: filters["balanceSheetLines"].filters
        },
        transactionTypes: {
          mapKeys: {},
          filters: filters["transactionTypes"].filters
        },
        sources: {
          mapKeys: {},
          filters: filters["sources"].filters
        },
        books: {
          mapKeys: {},
          filters: filters["books"].filters
        },
        importFilters: {
          mapKeys: {},
          filters: filters["importFilters"].filters
        },
        trxIds: {
          mapKeys: {},
          filters: filters["trxIds"].filters
        },
        departments: {
          mapKeys: {},
          filters: filters["departments"].filters
        },
        categories: {
          mapKeys: {},
          filters: filters["categories"].filters
        },
        created: {
          mapKeys: {},
          filters: filters["created"].filters
        },
        createdBy: {
          mapKeys: {},
          filters: filters["createdBy"].filters
        },
        modified: {
          mapKeys: {},
          filters: filters["modified"].filters
        },
        modifiedBy: {
          mapKeys: {},
          filters: filters["modifiedBy"].filters
        },
        notes: {
          mapKeys: {},
          filters: filters["notes"].filters
        }
      };
      var dimensionsArr = this.get("dimensionsArr");
      dimensionsArr.forEach(function (dimensionKey) {
        return filterMap[dimensionKey] = {
          mapKeys: {},
          filters: filters[dimensionKey].filters,
          byAttr: "budgetId"
        };
      });
      this.get("filteredTransactions").forEach(function (trx) {
        var _trx$get;
        var statement = trx.get("statement");
        var formattedFiscalDate = ["cash", "bs"].includes(statement) || !Ember.get(trx, "formattedFiscalDate") ? "" : Ember.get(trx, "formattedFiscalDate");
        var formattedDate = statement === "pnl" || !Ember.get(trx, "formattedDate") ? "" : Ember.get(trx, "formattedDate");
        filterMap["descriptions"].mapKeys[Ember.get(trx, "description")] = true;
        filterMap["accountIds"].mapKeys[Ember.get(trx, "accountId")] = true;
        filterMap["statementOptions"].mapKeys[Ember.get(trx, "statement")] = true;
        filterMap["fiscalDates"].mapKeys[formattedFiscalDate] = true;
        filterMap["trxDates"].mapKeys[formattedDate] = true;
        filterMap["amounts"].mapKeys[Ember.get(trx, "amount")] = true;
        filterMap["currencies"].mapKeys[Ember.get(trx, "currency")] = true;
        filterMap["unitTypes"].mapKeys[_assumption.default.getUnitTypeAsPctCurrency(Ember.get(trx, "unitType"))] = true;
        filterMap["budgetLines"].mapKeys[Ember.get(trx, "budgetLineId")] = true;
        filterMap["balanceSheetLines"].mapKeys[Ember.get(trx, "computedBsLine")] = true;
        filterMap["transactionTypes"].mapKeys[Ember.get(trx, "type")] = true;
        filterMap["sources"].mapKeys[Ember.get(trx, "sourceToDisplay")] = true;
        filterMap["books"].mapKeys[Ember.get(trx, "books")] = true;
        filterMap["importFilters"].mapKeys[Ember.get(trx, "importFilters")] = true;
        filterMap["trxIds"].mapKeys[Ember.get(trx, "trxId")] = true;
        (_trx$get = trx.get("computedDepartmentList")) === null || _trx$get === void 0 || _trx$get.split("; ").forEach(function (name) {
          filterMap["departments"].mapKeys[name] = true;
        });
        filterMap["categories"].mapKeys[Ember.get(trx, "computedCategory")] = true;
        filterMap["created"].mapKeys[Ember.get(trx, "created") ? moment(Ember.get(trx, "created")).format(_const.default.MomentDateAndTimeDisplayFormat) : ""] = true;
        filterMap["createdBy"].mapKeys[Ember.get(trx, "createdBy.displayName")] = true;
        filterMap["modified"].mapKeys[Ember.get(trx, "modified") ? moment(Ember.get(trx, "modified")).format(_const.default.MomentDateAndTimeDisplayFormat) : ""] = true;
        filterMap["modifiedBy"].mapKeys[Ember.get(trx, "modifiedBy.displayName")] = true;
        filterMap["notes"].mapKeys[Ember.get(trx, "note")] = true;
        var budgetDimensions = trx.get("computedBudgetLine.forecastAttributes.dimensions") || [];
        var dimensionsArrClone = dimensionsArr.slice(0);
        budgetDimensions.forEach(function (budgetDimension) {
          var values = budgetDimension.values || [];
          values.forEach(function (value) {
            if (filterMap[budgetDimension.dimension]) {
              filterMap[budgetDimension.dimension].mapKeys[value.value] = true;
            }
          });
          dimensionsArrClone.removeObject(budgetDimension.dimension);
        });
        dimensionsArrClone.forEach(function (d) {
          return filterMap[d].mapKeys["_empty"] = true;
        });
      });
      var buildFilterFunc = function buildFilterFunc(attr, filterAttr, byAttr) {
        var key = "name";
        if (attr === "fiscalDates" || attr === "trxDates" || attr === "created" || attr === "modified") {
          key = "dateStr";
        } else if (attr === "balanceSheetLines") {
          key = "bsId";
        } else if (attr === "statementOptions") {
          key = "statementId";
        } else if (attr === "budgetLines") {
          key = "budgetId";
        } else if (attr === "transactionTypes") {
          key = "id";
        }
        if (byAttr) {
          key = byAttr;
        }
        return function (filter) {
          return filterAttr["mapKeys"][filter[key]];
        };
      };
      for (var attr in filterMap) {
        var filterAttr = filterMap[attr];
        var filterFunc = buildFilterFunc(attr, filterAttr, filterAttr.byAttr);
        filterAttr["filters"] = filterAttr["filters"].filter(filterFunc);
      }
      return filterMap;
    }),
    recalcFilterMap: true,
    dimensionsArr: Ember.computed("budgetService.dimensions", function () {
      var _this$get;
      var dimensions = (_this$get = this.get("budgetService.dimensions")) !== null && _this$get !== void 0 ? _this$get : [];
      return dimensions.map(function (dimension) {
        return Ember.get(dimension, "uniqueId");
      });
    }),
    filteredTransactions: Ember.computed("previewTransactions", "filteredColumns", "filters.departments.selectList", "filters.statementOptions.selectList", "filters.descriptions.selectList", "filters.accountIds.selectList", "filters.fiscalDates.selectList", "filters.trxDates.selectList", "filters.balanceSheetLines.selectList", "filters.transactionTypes.selectList", "filters.sources.selectList", "filters.books.selectList", "filters.importFilters.selectList", "filters.trxIds.selectList", "filters.notes.selectList", "filters.categories.selectList", "filters.amounts.selectList", "filters.created.selectList", "filters.createdBy.selectList", "filters.modified.selectList", "filters.modifiedBy.selectList", "filters.currencies.selectList", "filters.unitTypes.selectList", "filters.budgetLines.selectList", "hasAnyFilter", "recalcDimension", "sortByAttr", "sortOrder", function () {
      var _this4 = this;
      if (this.get("blockRecalc") || this.get("disableSortAndFilter")) {
        return this.get("oldFilteredTransactions");
      }
      var dimensionsArr = this.get("dimensionsArr");
      var filterDimensions = _lodash.default.intersection(this.get("filteredColumns"), dimensionsArr);
      var filteredTransactions = Ember.A(this.get("previewTransactions"));
      var transactionsKeyForFilter = {
        departments: "computedDepartmentList",
        statementOptions: "statement",
        descriptions: "description",
        accountIds: "accountId",
        fiscalDates: "formattedFiscalDate",
        trxDates: "formattedDate",
        budgetLines: "budgetLineId",
        balanceSheetLines: "computedBsLine",
        transactionTypes: "type",
        sources: "sourceToDisplay",
        trxIds: "trxId",
        created: "created",
        createdBy: "createdBy.displayName",
        modified: "modified",
        modifiedBy: "modifiedBy.displayName",
        amounts: "amount",
        notes: "note",
        currencies: "currency",
        unitTypes: "unitType",
        categories: "computedCategory",
        books: "books",
        importFilters: "importFilters"
      };
      if (this.get("hasAnyFilter")) {
        var statementOptions = this.get("statementOptions");
        var filterDimensionValues = [];
        var filteredColumns = this.get("filteredColumns") || [];
        filteredColumns = filteredColumns.filter(function (column) {
          return !filterDimensions.includes(column);
        });
        var mappedFilteredColumns = filteredColumns.map(function (column) {
          var selectedFilters = _this4.get("filters.".concat(column, ".filters")).filterBy("selected", true);
          var mappedSelectedFilters;
          switch (column) {
            case "statementOptions":
              mappedSelectedFilters = selectedFilters.map(function (state) {
                return statementOptions.findBy("text", state.name).id;
              });
              break;
            case "fiscalDates":
            case "trxDates":
              mappedSelectedFilters = selectedFilters.mapBy("dateStr");
              break;
            case "modified":
            case "created":
              mappedSelectedFilters = selectedFilters.map(function (filter) {
                return filter.name ? filter.name.format(_const.default.SugerDateAndTimeDisplayFormat) : "";
              });
              break;
            case "balanceSheetLines":
              mappedSelectedFilters = selectedFilters.mapBy("bsId");
              break;
            case "transactionTypes":
              mappedSelectedFilters = selectedFilters.mapBy("id");
              break;
            case "budgetLines":
              mappedSelectedFilters = selectedFilters.map(function (item) {
                return item.budgetId;
              });
              break;
            default:
              mappedSelectedFilters = selectedFilters.mapBy("name");
          }
          return {
            name: column,
            values: mappedSelectedFilters
          };
        });
        if (filterDimensions.length) {
          filterDimensions.forEach(function (d) {
            filterDimensionValues.pushObject({
              dimensionId: d,
              values: _this4.get("filters.".concat(d, ".filters")).filterBy("selected", true).map(function (item) {
                return item.budgetId;
              })
            });
          });
        }
        if (mappedFilteredColumns.length || filterDimensions) {
          filteredTransactions = filteredTransactions.filter(function (trx) {
            var formattedFiscalDate = trx.get("statement") === "cash" || trx.get("statement") === "bs" || !Ember.get(trx, "formattedFiscalDate") ? "" : Ember.get(trx, "formattedFiscalDate");
            var formattedDate = trx.get("statement") === "pnl" || !Ember.get(trx, "formattedDate") ? "" : Ember.get(trx, "formattedDate");

            // if the transaction does not meet some of the filters
            var transactionDoesNotMatchFilters = mappedFilteredColumns.some(function (_ref) {
              var _transactionsKeyForFi;
              var name = _ref.name,
                values = _ref.values;
              var key = (_transactionsKeyForFi = transactionsKeyForFilter[name]) !== null && _transactionsKeyForFi !== void 0 ? _transactionsKeyForFi : name;
              var trxValue;
              switch (name) {
                case "departments":
                  return _lodash.default.intersection(values, trx.get("computedDepartmentList").split("; ")).length === 0;
                case "fiscalDates":
                  trxValue = formattedFiscalDate;
                  break;
                case "trxDates":
                  trxValue = formattedDate;
                  break;
                case "created":
                case "modified":
                  if (!Ember.get(trx, key) || !Ember.get(trx, key) && !values.includes("")) {
                    return true;
                  }
                  trxValue = new Date(Ember.get(trx, key)).format(_const.default.SugerDateAndTimeDisplayFormat);
                  break;
                case "budgetLines":
                  if (Ember.get(trx, "statement") == "bs") return true;
                  trxValue = Ember.get(trx, key);
                  break;
                case "unitTypes":
                  trxValue = _assumption.default.getUnitTypeAsPctCurrency(Ember.get(trx, "unitType"));
                  break;
                default:
                  trxValue = Ember.get(trx, key);
              }
              return !values.includes(trxValue);
            });
            if (transactionDoesNotMatchFilters) return false;
            var foundDimension = true;
            filterDimensionValues.forEach(function (dimension) {
              var budgetDimensionValues = (trx.get("computedBudgetLine.forecastAttributes.dimensions") || Ember.A([])).findBy("dimension", dimension.dimensionId) || {};
              budgetDimensionValues = (budgetDimensionValues.values || Ember.A([])).mapBy("value").filter(function (v) {
                return v;
              });
              if (!budgetDimensionValues.length) {
                budgetDimensionValues = ["_empty"];
              }
              if (dimension && dimension.values && dimension.values.length) {
                if (!_lodash.default.intersection(dimension.values, budgetDimensionValues).length) {
                  foundDimension = false;
                }
              }
            });
            if (!foundDimension) {
              return false;
            }
            return true;
          });
        }
        var selected = filteredTransactions.filterBy("selected");
        this.set("showGlobalOptions", selected.length ? true : false);
      }
      var sortByKeyMatrix = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, transactionsKeyForFilter), {}, {
        fiscalDates: "fiscalDate",
        trxDates: "trxDate",
        budgetLines: "computedBudgetLine.name"
      });
      var keyToSortBy = sortByKeyMatrix[this.get("sortByAttr")];
      if (keyToSortBy) {
        filteredTransactions = filteredTransactions.sortBy(keyToSortBy);
        if (!this.get("sortOrder")) {
          filteredTransactions.reverse();
        }
      }
      this.set("oldFilteredTransactions", filteredTransactions);
      Ember.run.next(function () {
        _this4.set("recalcFilterMap", !_this4.get("recalcFilterMap"));
      });
      this.get("automation").setParam("transactionsTable", filteredTransactions.map(function (x) {
        return x.toJSON();
      }));
      this.get("automation").setParam("allTransactionsTable", this.get("allTransactions").map(function (x) {
        return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, x.toJSON()), {}, {
          budgetLineId: x.get("budgetLineId")
        });
      }));
      return filteredTransactions;
    }),
    budgetLines: Ember.computed("treeManager.budgetsList.length", "isModelDetailsView", "actualsDetailsType", function () {
      var _this5 = this;
      return this.get("treeManager.budgetsList").filter(function (line) {
        return !Ember.get(line, "budget.isNoGrouping") && (!_this5.get("isModelDetailsView") && !line.get("budget.isModelLine") || _this5.get("isModelDetailsView") && line.get("budget.budgetType") === "assumption");
      }).map(function (item) {
        return Ember.Object.create({
          id: Ember.computed.alias("budget.id"),
          name: Ember.computed.alias("budget.name"),
          budget: item.get("budget")
        });
      });
    }),
    balanceSheetLines: Ember.computed(function () {
      return _const.default.BALANCE_SHEET_LINES.map(function (item) {
        return {
          id: item.name,
          text: item.displayName
        };
      });
    }),
    sortByAttr: "descriptions",
    sortOrder: true,
    disableSortAndFilter: false,
    // Indication if a sort and filter are needed (changes when doing manual actual add)
    showGlobalOptions: false,
    showGlobalDeleteOption: false,
    currencyOptions: Ember.computed("mainController.currencyOptions", "isModelDetailsView", function () {
      if (this.get("isModelDetailsView")) {
        return _assumption.default.getModelCurrencyOptions(_const.default.currencyOptions(this.get("model")));
      }
      return this.get("mainController.currencyOptions");
    }),
    saveAll: function saveAll(_ref2) {
      var _this6 = this;
      var isTransition = _ref2.isTransition,
        afterSave = _ref2.afterSave;
      if (!this.get("budgetService.sharedReadOnlyOrVersion")) {
        var dirty = this.get("allTransactions").filter(function (t) {
          return t.get("hasDirtyAttributes") && !t.get("isSaving");
        });
        if (dirty.length) {
          Ember.$("header .busy").show();
          Ember.run.next(function () {
            var promises = dirty.map(function (t) {
              return t.save();
            });
            _this6.get("cache").clearActualsCache();
            _this6.get("budgetService").updateTransactions();
            _this6.notifyPropertyChange("recalcTotal");
            return Ember.RSVP.all(promises).then(function () {
              afterSave === null || afterSave === void 0 || afterSave();
              if (!isTransition) {
                Ember.$("header .busy").hide();
              }
            });
          });
        }
      }
      return new Ember.RSVP.Promise(function (resolve) {
        resolve();
      });
    },
    actions: {
      lineToggle: function lineToggle() {
        var _this7 = this;
        Ember.run.next(function () {
          var selected = _this7.get("filteredTransactions").filterBy("selected");
          var selectedLocked = selected.filterBy("disableAll");
          _this7.set("showGlobalDeleteOption", selected.length && selectedLocked.length === 0 ? true : false);
          _this7.set("showGlobalOptions", selected.length ? true : false);
        });
      },
      sort: function sort(sortByAttr) {
        _budgetUtils.default.sortByAttribute(this, sortByAttr);
      },
      selectAll: function selectAll(removeAll) {
        this.set("selectAll", removeAll ? !removeAll : !this.get("selectAll"));
        var selectAll = this.get("selectAll");
        this.get("filteredTransactions").forEach(function (trx) {
          if (!Ember.get(trx, "disableAll")) {
            Ember.set(trx, "selected", selectAll);
          }
        });
        this.send("lineToggle");
      },
      submit: function submit(afterSave) {
        Ember.run.debounce(this, "saveAll", {
          afterSave: afterSave,
          isTransition: false
        }, 1000);
      },
      updateFiltersOnDelete: function updateFiltersOnDelete(selectedList) {
        var _this8 = this;
        var filterToValueMapping = this.get("filterToValueMapping");
        var filters = this.get("filters"); // Getting the filters object

        for (var key in filterToValueMapping) {
          if (filterToValueMapping.hasOwnProperty(key) && filters[key]["transactions"]) {
            // The function will remove all the transactions that appear in the filters
            selectedList.forEach(function (trx) {
              var trxValue = filterToValueMapping[key].call(_this8, trx);
              var transactionsList = filters[key]["transactions"][trxValue];
              // If the transactions list exist for the given value
              if (transactionsList) {
                transactionsList.size -= 1;
              }
            });
          }
        }
        selectedList.forEach(function (trx) {
          var dimList = trx.get("computedBudgetLine.forecastAttributes.dimensions") || [];
          dimList.forEach(function (dimension) {
            var dimId = dimension.dimension;
            var budgetDimensionValues = (dimension.values || Ember.A([])).mapBy("value").filter(function (v) {
              return v;
            });
            if (!budgetDimensionValues.length && filters[dimId] && filters[dimId]["transactions"][""]) {
              filters[dimId]["transactions"][""].size -= 1;
            } else {
              budgetDimensionValues.forEach(function (val) {
                if (filters[dimId] && filters[dimId]["transactions"] && filters[dimId]["transactions"][val]) {
                  filters[dimId]["transactions"][val].size -= 1;
                }
              });
            }
          });
        });
      }
    }
  });
});