define("budgeta/utils/dimensions", ["exports", "@babel/runtime/helpers/esm/createForOfIteratorHelper", "@babel/runtime/helpers/esm/objectSpread2", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/defineProperty", "budgeta/services/ember-service-container", "budgeta/utils/budget-utils", "budgeta/utils/const", "ember-inflector"], function (_exports, _createForOfIteratorHelper2, _objectSpread2, _toConsumableArray2, _defineProperty2, _emberServiceContainer, _budgetUtils, _const, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/new-module-imports, no-prototype-builtins */

  var DimensionsUtils = {
    resetFilterSelection: function resetFilterSelection() {
      var valueToSet = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "all";
      var viewSelections = new _emberServiceContainer.default().lookup("viewSelections");
      viewSelections.get("dimensions").forEach(function (dimension) {
        Ember.set(dimension, "selectedValue", valueToSet);
      });
    },
    getValueIdToValueNameAndDimensionName: function getValueIdToValueNameAndDimensionName() {
      var absoluteRootDimensions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var intoDimensionData = function intoDimensionData(dimension) {
        return {
          dimensionName: dimension.get("name"),
          values: dimension.get("values")
        };
      };
      var intoDimensionValueIdToData = function intoDimensionValueIdToData(dimensionData) {
        return dimensionData.values.map(function (value) {
          var valueId = value.uniqueId;
          return (0, _defineProperty2.default)({}, valueId, {
            dimensionName: dimensionData.dimensionName,
            name: value.name
          });
        });
      };
      var valuesDataArray = _const.default.flat(absoluteRootDimensions.map(intoDimensionData).map(intoDimensionValueIdToData));
      return Object.assign.apply(Object, [{}].concat((0, _toConsumableArray2.default)(valuesDataArray)));
    },
    getDimensionIdToName: function getDimensionIdToName() {
      var absoluteRootDimensions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return Object.assign.apply(Object, [{}].concat((0, _toConsumableArray2.default)(absoluteRootDimensions.map(function (object) {
        return (0, _defineProperty2.default)({}, object.get("uniqueId"), object.get("name"));
      }))));
    },
    isDimensionUsedValues: function isDimensionUsedValues(dimension, store, withValue, anyValue) {
      var foundAlloctions = false;
      var budgets = store.peekAll("budget");
      var uniqueIdValue = withValue ? Ember.get(withValue, "uniqueId") : false;
      var findDimensionAllocations = function findDimensionAllocations(tempBudget) {
        var budgetDimensions = tempBudget.get("budgetAttributes.dimensions") || [];
        budgetDimensions = Array.isArray(budgetDimensions) ? budgetDimensions : [budgetDimensions];
        var dimensionValues = Ember.get(budgetDimensions.findBy("dimension", dimension.get("uniqueId")) || {}, "values") || [];
        dimensionValues = dimensionValues.filterBy("value");
        if (anyValue && dimensionValues.length) {
          return true;
        }
        if (!uniqueIdValue) {
          if (dimensionValues.length > 1) {
            return true;
          }
        } else {
          return dimensionValues.findBy("value", uniqueIdValue);
        }
      };
      foundAlloctions = budgets.find(function (budget) {
        if (findDimensionAllocations(budget)) {
          return true;
        }
        var budgetScenarios = budget.get("scenarios") || [];
        var foundInScenario = false;
        budgetScenarios.forEach(function (budgetScenario) {
          if (findDimensionAllocations(budgetScenario)) {
            foundInScenario = true;
          }
        });
        return foundInScenario;
      });
      return foundAlloctions ? true : false;
    },
    getDimensionsValueNameToId: function getDimensionsValueNameToId(dimensions) {
      var dimensionValueNameToIdsMap = {};
      dimensions.forEach(function (dimension) {
        dimension.values.forEach(function (dimValueObject) {
          dimensionValueNameToIdsMap[dimValueObject.NAME] = dimValueObject.ID;
        });
      });
      return dimensionValueNameToIdsMap;
    },
    sortDimensionsByLevel: function sortDimensionsByLevel(dimensions) {
      return (0, _toConsumableArray2.default)(dimensions).sort(function (dimA, dimB) {
        return dimA.subLevel && !dimB.subLevel ? 1 : !dimA.subLevel && dimB.subLevel ? -1 : 0;
      });
    },
    mergeDimensionChildren: function mergeDimensionChildren(params) {
      var promises = [];
      params.saveBudgets = [];
      DimensionsUtils.mergeDimensionChildrenHelper(params);
      params.saveBudgets.forEach(function (budget) {
        promises.push(budget.save());
      });
    },
    mergeDimensionChildrenHelper: function mergeDimensionChildrenHelper(params) {
      var store = params.store,
        dimension = params.dimension,
        dimensionTree = params.dimensionTree,
        saveBudgets = params.saveBudgets,
        dimensionGroup = params.dimensionGroup;
      var children = DimensionsUtils.getDimensionChildren({
        parent: dimension,
        dimensionTree: dimensionTree
      }) || [];
      var commonChildren = {};
      children.forEach(function (child) {
        if (!commonChildren[Ember.get(child, "name")]) {
          var duplicateChildren = DimensionsUtils.findDuplicatesByName({
            dimensionTree: dimensionTree,
            siblings: children,
            name: Ember.get(child, "name")
          });
          if (duplicateChildren) {
            commonChildren[Ember.get(child, "name")] = duplicateChildren;
          }
        }
      });
      var changeParent = function changeParent(mergedDimension, deleteDimensions) {
        deleteDimensions.forEach(function (dDimension) {
          DimensionsUtils.changeChildrenParent({
            oldPerent: dDimension,
            newParent: mergedDimension,
            dimensionTree: dimensionTree
          });
        });
      };
      var updateDimensionsReferences = function updateDimensionsReferences(deleteDimensions, mergedDimension) {
        var deleteDimensionsId = deleteDimensions.mapBy("uniqueId"),
          newDimensionId = Ember.get(mergedDimension, "uniqueId");
        DimensionsUtils.updateDimensionsReferences({
          oldDimensionIds: deleteDimensionsId,
          newDimensionId: newDimensionId,
          store: store,
          saveBudgets: saveBudgets,
          dimensionGroup: dimensionGroup
        });
      };
      for (var key in commonChildren) {
        var duplicates = commonChildren[key];
        var mergedDimension = duplicates.pop();
        var deleteDimensions = duplicates;
        changeParent(mergedDimension, deleteDimensions);

        // change budget lines
        updateDimensionsReferences(deleteDimensions, mergedDimension);
        Ember.get(dimensionGroup, "values").removeObjects(deleteDimensions);
        DimensionsUtils.mergeDimensionChildrenHelper({
          store: store,
          dimension: mergedDimension,
          dimensionTree: dimensionTree,
          saveBudgets: saveBudgets,
          dimensionGroup: dimensionGroup
        });
      }
    },
    updateDimensionsReferences: function updateDimensionsReferences(params) {
      var oldDimensionIds = params.oldDimensionIds,
        newDimensionId = params.newDimensionId,
        store = params.store,
        saveBudgets = params.saveBudgets,
        dimensionGroup = params.dimensionGroup;
      var budgets = store.peekAll("budget");
      var replaceDimensionsId = function replaceDimensionsId(tempBudget) {
        return ["dimensions", "filterDimensions"].map(function (prop) {
          return replaceDimensionsIdHelper(tempBudget, prop);
        }).find(function (v) {
          return v;
        });
      };
      var replaceDimensionsIdHelper = function replaceDimensionsIdHelper(tempBudget, prop) {
        var budgetDimensions = tempBudget.get("budgetAttributes.".concat(prop)) || [];
        var dimensionAttribute = budgetDimensions.filter(function (v) {
          return v;
        }).findBy("dimension", dimensionGroup.get("uniqueId")) || {};
        var dimensionValues = Ember.get(dimensionAttribute, "values") || [];
        if (prop === "dimensions") {
          dimensionValues = dimensionValues.filterBy("value");
          var replaceValues = dimensionValues.filter(function (value) {
            return oldDimensionIds.indexOf(Ember.get(value, "value")) !== -1;
          });
          if (replaceValues.length) {
            if (newDimensionId) {
              replaceValues.setEach("value", newDimensionId);
              var dimensionsWithSameUniqueId = dimensionValues.filter(function (value) {
                return Ember.get(value, "value") === newDimensionId;
              });
              if (dimensionsWithSameUniqueId.length > 1) {
                // remove duplicate allocations
                var firstAllocation = dimensionsWithSameUniqueId.pop();
                var duplicateAllocations = dimensionsWithSameUniqueId;
                var sumAllocation = 0;
                duplicateAllocations.forEach(function (item) {
                  sumAllocation += (Ember.get(item, "allocation") || 0) * 1;
                });
                Ember.set(firstAllocation, "allocation", (Ember.get(firstAllocation, "allocation") || 0) * 1 + sumAllocation);
                dimensionValues.removeObjects(duplicateAllocations);
                Ember.set(dimensionAttribute, "values", dimensionValues);
              }
            } else {
              dimensionValues.removeObjects(replaceValues);
              Ember.set(dimensionAttribute, "values", dimensionValues);
            }
            return true;
          }
        } else {
          if (dimensionValues.find(function (v) {
            return v && oldDimensionIds.includes(v);
          })) {
            dimensionValues.removeObjects(oldDimensionIds);
            if (newDimensionId) {
              dimensionValues.addObject(newDimensionId);
            }
            return true;
          }
        }
        return false;
      };
      budgets.forEach(function (budget) {
        var foundInBudget = false;
        if (replaceDimensionsId(budget)) {
          foundInBudget = true;
        }
        var budgetScenarios = budget.get("scenarios") || [];
        budgetScenarios.forEach(function (budgetScenario) {
          if (replaceDimensionsId(budgetScenario)) {
            foundInBudget = true;
          }
        });
        if (foundInBudget) {
          saveBudgets.addObject(budget);
        }
      });
    },
    changeChildrenParent: function changeChildrenParent(params) {
      var newParent = params.newParent,
        oldPerent = params.oldPerent,
        dimensionTree = params.dimensionTree;
      var newParentId = Ember.get(newParent, "uniqueId");
      var children = DimensionsUtils.getDimensionChildren({
        parent: oldPerent,
        dimensionTree: dimensionTree
      });
      children.setEach("parent", newParentId);
    },
    getDimensionChildren: function getDimensionChildren(params) {
      var parent = params.parent,
        dimensionTree = params.dimensionTree;
      var parentId = parent ? Ember.get(parent, "uniqueId") : undefined;
      return dimensionTree.filter(function (item) {
        if (!parent) {
          return !item.hasOwnProperty("parent") || typeof Ember.get(item, "parent") === "undefined" || !Ember.get(item, "parent");
        }
        return Ember.get(item, "parent") === parentId;
      });
    },
    isGroup: function isGroup(dimension, dimensionValues) {
      var parentId = parent ? Ember.get(dimension, "uniqueId") : undefined;
      return dimensionValues.findBy("parent", parentId) ? true : false;
    },
    getDimensionSubtree: function getDimensionSubtree(params) {
      var parent = params.parent,
        dimensionTree = params.dimensionTree;
      if (!params.subtree) {
        params.subtree = [];
        if (parent) {
          params.subtree.push(parent);
        }
      }
      var children = DimensionsUtils.getDimensionChildren({
        parent: parent,
        dimensionTree: dimensionTree
      });
      children.forEach(function (child) {
        params.subtree.push(child);
        DimensionsUtils.getDimensionSubtree({
          parent: child,
          dimensionTree: dimensionTree,
          subtree: params.subtree
        });
      });
      return params.subtree;
    },
    removeDuplicates: function removeDuplicates(arr) {
      return (0, _toConsumableArray2.default)(new Map(arr.map(function (obj) {
        return [obj.id || obj.uniqueId, obj];
      })).values());
    },
    getDimensionValues: function getDimensionValues(filterByValue, byDimensionValues, cacheService) {
      var getAncestorValues = function getAncestorValues(value) {
        return byDimensionValues.filter(function (dimensionValue) {
          return !value || value === "all" || DimensionsUtils.isAncestor(value, Ember.get(dimensionValue, "uniqueId"), byDimensionValues, cacheService) || DimensionsUtils.isAncestor(Ember.get(dimensionValue, "uniqueId"), value, byDimensionValues, cacheService);
        });
      };
      if (!filterByValue) {
        return getAncestorValues(filterByValue);
      }
      return filterByValue.flatMap(function (value) {
        return value === "no" ? [] : getAncestorValues(value);
      });
    },
    buildDimensionsTree: function buildDimensionsTree(filterByValue, options, dim, budgetGroup, thisForecast, byDimension, dimensionsTreeMap, cacheService, accountValues) {
      var _options$dimensions;
      var hash = cacheService === null || cacheService === void 0 ? void 0 : cacheService.hashOptions((0, _objectSpread2.default)((0, _objectSpread2.default)({
        options: options,
        filterByValue: filterByValue,
        byDimension: byDimension
      }, accountValues), {}, {
        dim: dim,
        budgetGroupId: budgetGroup === null || budgetGroup === void 0 ? void 0 : budgetGroup.id,
        dimensionValuesLength: dim.get("valuesLength"),
        budgetScenario: _budgetUtils.default.getSubtreeDimensionValues(thisForecast.budget, {
          cacheService: cacheService,
          scenario: options.scenario,
          categoryDimension: (_options$dimensions = options.dimensions) === null || _options$dimensions === void 0 ? void 0 : _options$dimensions.mapBy("dimension").findBy("isCategory")
        })
      }), {
        fast: true
      });
      if (cacheService !== null && cacheService !== void 0 && cacheService.get("dimensionTree.".concat(hash))) {
        return cacheService.get("dimensionTree.".concat(hash));
      }
      var accountValuesSet = new Set(accountValues);
      var dimensionsTree = [];
      var byDimensionValues = byDimension.get("dimensionValuesOrder");
      var byDimensionName = byDimension.get("name");

      // filterByValue could be null
      filterByValue = Array.isArray(filterByValue) ? filterByValue : [filterByValue];
      var dimensionValues = this.removeDuplicates(this.getDimensionValues(filterByValue, byDimensionValues, cacheService));
      var firstLevel = dimensionValues.filter(function (dimValue) {
        if (dimValue.level !== 0) return false;
        var dimValuesByAccount = (dimensionValues || []).filter(function (d) {
          return accountValuesSet.has(d.uniqueId);
        });
        if (!dimValuesByAccount.length) return true;
        if (dimValuesByAccount.some(function (dimValueByAccount) {
          return DimensionsUtils.isAncestor(dimValue.uniqueId, dimValueByAccount.uniqueId, dimensionValues, cacheService);
        })) {
          return true;
        }
        return false;
      });

      // build each level with children
      firstLevel.forEach(function (firstLevelDimValue) {
        filterByValue.forEach(function (value) {
          var dimLevelObj = DimensionsUtils.buildDimensionWithChildrens(firstLevelDimValue, dim, budgetGroup, thisForecast, dimensionValues, options, byDimensionName, dimensionsTreeMap, value);
          dimensionsTree.addObject(dimLevelObj);
          dimensionsTreeMap[dimLevelObj.id] = dimLevelObj;
        });
      });

      // add "no" child
      if (!filterByValue[0] || filterByValue.includes("no")) {
        var dimLevelNo = DimensionsUtils.buildDimensionWithChildrens({
          id: budgetGroup.id,
          name: intl.t("general.dimensions-1", {
            var1: byDimensionName
          }),
          noSub: true,
          level: 0,
          uniqueId: null
        }, dim, budgetGroup, thisForecast, dimensionValues, options, byDimensionName, dimensionsTreeMap);
        dimensionsTree.addObject(dimLevelNo);
        dimensionsTreeMap[dimLevelNo.id] = dimLevelNo;
      }
      if (cacheService) {
        cacheService.set("dimensionTree.".concat(hash), dimensionsTree);
      }
      dimensionsTree = this.removeDuplicates(dimensionsTree);
      return dimensionsTree;
    },
    buildDimensionWithChildrens: function buildDimensionWithChildrens(dim, dimParent, budgetGroup, thisForecast, dimensionValues, options, byDimensionName, dimensionsTreeMap, filterByValue) {
      var baseDimId = dim.id || budgetGroup.id || thisForecast.id;
      var getDimId = function getDimId(isBenefit) {
        return isBenefit ? "".concat(baseDimId, "#").concat(Ember.get(dim, "name")) : "".concat(baseDimId, "#").concat(Ember.get(dim, "uniqueId") || "no");
      };
      var topDim = _budgetUtils.default.initForecast({
        id: baseDimId,
        name: dim.name || Ember.get(dim, "name"),
        budgetAttributes: Ember.merge({}, thisForecast.budgetAttributes),
        excludeFromTotal: true,
        hideTotal: false,
        isCount: false,
        precision: null,
        type: "by-dimension",
        // Setting by dimension so that will know that it's a special line
        budget: budgetGroup.budget || thisForecast.budget
      });
      topDim.unconvertedActuals = {};
      topDim.originalActualsItems = {};
      topDim.options = options;
      topDim.dimensionValue = dim;
      topDim.parent = dim.level === 0 ? "" : dim.noSub ? topDim.id : "".concat(topDim.id, "#").concat(dim.parent || "no");
      topDim.id = getDimId(dim.benefitRef);
      topDim.children = [];
      dimensionValues === null || dimensionValues === void 0 || dimensionValues.forEach(function (dimChild) {
        if (dimChild.parent === dim.uniqueId) {
          var child = DimensionsUtils.buildDimensionWithChildrens(dimChild, dimParent, budgetGroup, thisForecast, dimensionValues, options, byDimensionName, dimensionsTreeMap);
          topDim.children.addObject(child);
          dimensionsTreeMap[child.id] = child;
        }
      });
      if (!filterByValue || filterByValue === "no") {
        // add no-value for the first level only
        //if topLevel doesn't have children- don't add no-sub
        if (dim.level === 0 && !dim.noSub && topDim.children.length) {
          var childNoSub = DimensionsUtils.buildDimensionWithChildrens({
            id: topDim.id,
            name: intl.t("general.dimensions-2", {
              var1: topDim.id ? "sub " : "",
              var2: byDimensionName
            }),
            noSub: true,
            uniqueId: null
          }, dimParent, budgetGroup, thisForecast, dimensionValues, options, byDimensionName, dimensionsTreeMap);
          topDim.children.addObject(childNoSub);
          dimensionsTreeMap[childNoSub.id] = childNoSub;
        }
      }
      // If the dimension is benefit -> add reference to the id by uniqueId
      if (dim.benefitRef) {
        dimensionsTreeMap[getDimId(false)] = topDim;
      }
      return topDim;
    },
    isAncestor: function isAncestor(parentIds, childId, dimensionTree, cacheService) {
      var result = false,
        cacheHash;
      if (!childId || !parentIds) {
        return result;
      }
      if (!Array.isArray(parentIds)) {
        parentIds = [parentIds];
      }
      return parentIds.some(function (parentId) {
        if (cacheService) {
          cacheHash = _const.default.hashCode(parentId + "-" + childId);
          var cachedResult = cacheService.get("isAncestorCache.".concat(cacheHash));
          if (cachedResult !== undefined) {
            return cachedResult;
          }
        }
        var parent = DimensionsUtils.findDimensionValue(parentId, dimensionTree),
          child = DimensionsUtils.findDimensionValue(childId, dimensionTree);
        // in case parent isn't exist it can be filter "by"
        if (!parent) {
          result = dimensionTree.indexOf(child) !== -1;
          // child isn't exist
        } else if (!child) {
          result = false;
        } else {
          result = DimensionsUtils.findIsAncestor(parent, child, dimensionTree);
        }
        if (cacheService) {
          cacheService.set("isAncestorCache.".concat(cacheHash), result);
        }
        return result;
      });
    },
    /**
     * find if the first element is ancestor of the second element
     */
    findIsAncestor: function findIsAncestor(firstElement, secondElement, dimensionTree) {
      if (!firstElement || !secondElement) {
        return false;
      }
      if (firstElement === secondElement) {
        return true;
      }
      if (Ember.get(secondElement, "parent")) {
        return DimensionsUtils.findIsAncestor(firstElement, DimensionsUtils.findDimensionValue(Ember.get(secondElement, "parent"), dimensionTree), dimensionTree);
      }
      return false;
    },
    findDimensionValue: function findDimensionValue(valueId, dimensionTree) {
      return dimensionTree.findBy("uniqueId", valueId);
    },
    findDuplicatesByName: function findDuplicatesByName(params) {
      var dimensionTree = params.dimensionTree,
        siblings = params.siblings,
        parentId = params.parentId,
        name = params.name,
        atList = params.atList;
      if (typeof atList === "undefined") {
        atList = 2;
      }
      var duplicates;
      if (siblings) {
        duplicates = siblings.filterBy("name", name);
      } else {
        duplicates = dimensionTree.filter(function (item) {
          if (parentId) {
            return Ember.get(item, "parent") === parentId && Ember.get(item, "name") === name;
          }
          return !Ember.get(item, "parent") && Ember.get(item, "name") === name;
        });
      }
      return duplicates && duplicates.length >= atList ? duplicates : undefined;
    },
    normalizeDimensionValue: function normalizeDimensionValue(value) {
      return value ? value.trim().replace(/\s+/g, " ") : value;
    },
    removeEmptyDimensionValues: function removeEmptyDimensionValues(dimension) {
      var emptyValues = (Ember.get(dimension, "values") || []).filter(function (value) {
        return !Ember.get(value, "name");
      });
      (Ember.get(dimension, "values") || Ember.A([])).removeObjects(emptyValues);
    },
    getAndCreateDimensionByType: function getAndCreateDimensionByType(budgetRoot, name, type) {
      var allowHierarchy = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
      var allowAllocation = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
      var dimension = budgetRoot.get("dimensions").findBy("type", type);
      var dimensionId = _const.default.generateUUID();
      if (!dimension) {
        dimension = budgetRoot.store.createRecord("dimension", {
          uniqueId: dimensionId,
          name: name,
          type: type,
          values: [],
          allowHierarchy: allowHierarchy,
          allowAllocation: allowAllocation,
          budget: budgetRoot
        });
      }
      return dimension;
    },
    addDimensionValueToBudgetLine: function addDimensionValueToBudgetLine(budgetLine, dimData) {
      if (!Ember.get(budgetLine, "budgetAttributes.dimensions")) {
        Ember.set(budgetLine, "budgetAttributes.dimensions", []);
      }
      Ember.get(budgetLine, "budgetAttributes.dimensions").pushObject(dimData);
    },
    findParentId: function findParentId(budget, dimensionId) {
      var inheritedDimensions = budget.get("inheritedAttributes.dimensions");
      if (inheritedDimensions) {
        var parentDimension = inheritedDimensions.findBy("dimension", dimensionId);
        return Ember.get(parentDimension, "values.0.value");
      }
    },
    addBudgetDimensions: function addBudgetDimensions(options) {
      var budget = options.budget,
        promises = options.promises;
      var selectedRoot = budget.get("absoluteRoot");
      var dimensionValueName = DimensionsUtils.normalizeDimensionValue(budget.get("name"));
      if (budget.get("wizardType") === "group-department" || budget.get("wizardType") === "group-department-multi") {
        var departmentDimension = DimensionsUtils.getAndCreateDimensionByType(selectedRoot, "Department", "department", true, true);
        var dimensionId = Ember.get(departmentDimension, "uniqueId");
        var parentId = DimensionsUtils.findParentId(budget, dimensionId);
        var newValue = departmentDimension.addValue(dimensionValueName, {
          parent: parentId,
          noSave: true
        });
        var newDimensionValueId = Ember.get(newValue, "uniqueId");
        var dimData = {
          dimension: dimensionId,
          values: [{
            value: newDimensionValueId,
            allocation: 100
          }]
        };
        DimensionsUtils.addDimensionValueToBudgetLine(budget, dimData);
        promises.push(departmentDimension.save());
        return;
      }
      if (budget.get("wizardType") === "group-department-geo" || budget.get("wizardType") === "group-expense-op-bygeo-bydep") {
        var geographyDimension = DimensionsUtils.getAndCreateDimensionByType(selectedRoot, "Geography", "geography", true, true);
        var _dimensionId = Ember.get(geographyDimension, "uniqueId");
        var _parentId = DimensionsUtils.findParentId(budget, _dimensionId);
        var _newValue = geographyDimension.addValue(dimensionValueName, {
          parent: _parentId,
          noSave: true
        });
        var _newDimensionValueId = Ember.get(_newValue, "uniqueId");
        var _dimData = {
          dimension: _dimensionId,
          values: [{
            value: _newDimensionValueId,
            allocation: 100
          }]
        };
        DimensionsUtils.addDimensionValueToBudgetLine(budget, _dimData);
        promises.push(geographyDimension.save());
        return;
      }
    },
    showDuplicateDimensionMappingNoty: function showDuplicateDimensionMappingNoty(dimensionMapping) {
      noty({
        type: "error",
        text: "ID ".concat(Ember.get(dimensionMapping, "financialDimensionValueId"), " was entered more than once; please correct it before proceeding"),
        timeout: null
      });
    },
    showDuplicateDimensionNameNoty: function showDuplicateDimensionNameNoty() {
      noty({
        type: "error",
        text: intl.t("general.text_1695611611418"),
        timeout: 10000
      });
    },
    isDuplicateMapping: function isDuplicateMapping(dimension, dimensionsValuesMapping) {
      return dimensionsValuesMapping.filter(function (item) {
        if (item.get("isNew")) {
          return false;
        }
        return true;
      }).find(function (item) {
        return item.get("financialDimensionValueId") === Ember.get(dimension, "financialDimensionValueId") && item.get("financialDimensionId") === Ember.get(dimension, "financialDimensionId");
      }) ? true : false;
    },
    getDimensionValuesOrder: function getDimensionValuesOrder(dimension) {
      if (!dimension) {
        return;
      }
      var values = Ember.get(dimension, "values");
      var treeMap = {};
      if (!values) {
        return [];
      }
      values.forEach(function (item) {
        treeMap[Ember.get(item, "uniqueId")] = {
          parent: Ember.get(item, "uniqueId") === Ember.get(item, "parent") ? undefined : Ember.get(item, "parent")
        };
      });
      var findLevel = function findLevel(key, treeMap, innerCall) {
        if (innerCall) {
          Ember.set(treeMap[key], "isGroup", true);
        }
        if (!Ember.get(treeMap[key], "parent")) {
          Ember.set(treeMap[key], "level", 0);
          return 0;
        }
        if (treeMap[key].hasOwnProperty("level")) {
          return Ember.get(treeMap[key], "level");
        }
        var level = 1 + findLevel(Ember.get(treeMap[key], "parent"), treeMap, true);
        Ember.set(treeMap[key], "level", level);
        return level;
      };
      for (var key in treeMap) {
        Ember.set(treeMap[key], "level", findLevel(key, treeMap));
      }
      var newValues = [];
      var reorderValues = function reorderValues(parent) {
        var levelValues = [];
        if (!parent) {
          levelValues = values.filter(function (value) {
            return !Ember.get(value, "parent");
          });
        } else {
          levelValues = values.filterBy("parent", Ember.get(parent, "uniqueId"));
        }
        levelValues.forEach(function (item) {
          newValues.push(item);
          reorderValues(item);
        });
      };
      reorderValues();
      return newValues.map(function (item) {
        Ember.set(item, "level", Ember.get(treeMap[Ember.get(item, "uniqueId")], "level"));
        Ember.set(item, "isGroup", Ember.get(treeMap[Ember.get(item, "uniqueId")], "isGroup"));
        return item;
      });
    },
    createCategoryDimension: function createCategoryDimension(store, rootLine) {
      var defaultCategories = store.peekRecord("budget-module", "group-company").get("defaultCategories");
      var name = "Category";
      if ((rootLine.get("dimensions") || Ember.A([])).findBy("name", name)) {
        name = "Budget Category";
      }
      var categoryDimension = store.createRecord("dimension", {
        uniqueId: _const.default.generateUUID(),
        name: name,
        type: "category",
        values: [],
        allowHierarchy: true,
        allowAllocation: false,
        budget: rootLine
      });
      var addCategory = function addCategory(cat, parent) {
        var thisValue = categoryDimension.addValue(cat.name, {
          parent: parent,
          noSave: true
        });
        if (cat.children) {
          cat.children.forEach(function (child) {
            addCategory(child, Ember.get(thisValue, "uniqueId"));
          });
        }
      };
      defaultCategories.forEach(function (cat) {
        return addCategory(cat);
      });
      return categoryDimension;
    },
    setPathForAllDimensionValuesDebounced: function setPathForAllDimensionValuesDebounced(root) {
      Ember.run.debounce(DimensionsUtils, "setPathForAllDimensionValues", root, 500, true);
    },
    setPathForAllDimensionValues: function setPathForAllDimensionValues(root) {
      (root.get("dimensions") || []).forEach(function (dim) {
        return DimensionsUtils.setPathForValues(dim.get("values"));
      });
    },
    setPathForValues: function setPathForValues(values, parent) {
      var path = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
      var _iterator = (0, _createForOfIteratorHelper2.default)(values),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var value = _step.value;
          if (!parent && !Ember.get(value, "parent") || parent && Ember.get(value, "parent") === parent) {
            var thisPath = path.concat(value);
            Ember.set(value, "path", thisPath.mapBy("name"));
            DimensionsUtils.setPathForValues(values, Ember.get(value, "uniqueId"), thisPath);
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    },
    isDimensionValuesEmpty: function isDimensionValuesEmpty(dimension) {
      return Ember.get(dimension, "values.length") === 1 && (DimensionsUtils.isDimensionValueEmptyAllowInheritance(Ember.get(dimension, "values.firstObject")) || DimensionsUtils.isDimensionValueEmptyNotInheritance(Ember.get(dimension, "values.firstObject")));
    },
    isDimensionValuesInheritance: function isDimensionValuesInheritance(dimension) {
      return !Ember.get(dimension, "values.length") || Ember.get(dimension, "values.length") === 1 && Ember.get(dimension, "values.firstObject.value") === undefined;
    },
    dimensionHasValueNotInheritance: function dimensionHasValueNotInheritance(dimension) {
      return Ember.get(dimension, "values.firstObject.value");
    },
    isDimensionValueEmptyAllowInheritance: function isDimensionValueEmptyAllowInheritance(value) {
      return !Ember.get(value, "value");
    },
    isDimensionValueEmptyNotInheritance: function isDimensionValueEmptyNotInheritance(value) {
      return value.hasOwnProperty("value") && Ember.get(value, "value") === null;
    },
    isValueNotEmptyNotInheritance: function isValueNotEmptyNotInheritance(value) {
      return Ember.get(value, "value");
    },
    getDimensionsForLineEdit: function getDimensionsForLineEdit(controller) {
      var dimensions = controller.get("budgetService.dimensions");
      var result = [];
      if (!controller.get("budgetAttributes.dimensions")) {
        controller.set("budgetAttributes.dimensions", []);
      }
      dimensions.forEach(function (thisDimension) {
        var dimData = controller.get("budgetAttributes.dimensions").findBy("dimension", Ember.get(thisDimension, "uniqueId"));
        if (!dimData) {
          dimData = {
            dimension: Ember.get(thisDimension, "uniqueId"),
            values: [{}]
          };
          controller.get("budgetAttributes.dimensions").pushObject(dimData);
        }
        if (!Ember.get(dimData, "values.length")) {
          Ember.set(dimData, "values", [{}]);
        }
        var inherited = controller.get("line.inheritedAttributes.dimensions") && controller.get("line.inheritedAttributes.dimensions").findBy("dimension", Ember.get(dimData, "dimension"));
        result.pushObject({
          dimensionData: thisDimension,
          isMultiValue: Ember.get(dimData, "values.length") > 1,
          placeholder: inherited ? _const.default.getDimensionDisplayValues(inherited, thisDimension) : "",
          values: Ember.get(dimData, "values")
        });
      });
      return result;
    },
    getDimensionFilterOptions: function getDimensionFilterOptions(_ref3) {
      var dimension = _ref3.dimension,
        addAllOption = _ref3.addAllOption,
        addByOption = _ref3.addByOption,
        addNoneOption = _ref3.addNoneOption,
        includeValues = _ref3.includeValues,
        targetValue = _ref3.targetValue,
        targetProperty = _ref3.targetProperty,
        allowDuplicates = _ref3.allowDuplicates,
        allValues = _ref3.allValues;
      var result = [];
      if (addAllOption) {
        var allValue = {
          uniqueId: "all",
          displayName: intl.t("general.select-2-dimension-value-lazy-1", {
            var1: (0, _emberInflector.pluralize)(dimension.get("name"))
          })
        };
        result.pushObject(allValue);
      }
      if (addByOption) {
        result.pushObject({
          uniqueId: "by",
          displayName: intl.t("general.select-2-dimension-value-lazy-2", {
            var1: dimension.get("name")
          })
        });
      }
      if (addNoneOption) {
        result.pushObject({
          uniqueId: "no",
          displayName: intl.t("general.select-2-dimension-value-lazy-3", {
            var1: dimension.get("name")
          })
        });
      }
      dimension.get("values").forEach(function (v) {
        if (!Ember.get(v, "displayName")) {
          var _parent = Ember.get(v, "parent") && dimension.get("values").findBy("uniqueId", Ember.get(v, "parent"));
          Ember.set(v, "displayName", (_parent ? Ember.get(_parent, "name") + " → " : "") + Ember.get(v, "name"));
        }
      });
      // add all dimension values except for ones that were already been used
      result.pushObjects(dimension.get("values").filter(function (v) {
        return (!includeValues || includeValues.includes(Ember.get(v, "uniqueId"))) && (Ember.get(v, "uniqueId") === Ember.get(targetValue, targetProperty) || allowDuplicates || !allValues || !allValues.mapBy(targetProperty).includes(Ember.get(v, "uniqueId")));
      }));
      if (Ember.get(targetValue, targetProperty) && !Array.isArray(Ember.get(targetValue, targetProperty)) && !result.mapBy("uniqueId").includes(Ember.get(targetValue, targetProperty))) {
        Ember.set(targetValue, targetProperty, addAllOption ? "all" : undefined);
      }
      return result;
    }
  };
  var _default = _exports.default = DimensionsUtils;
});