define("budgeta/controllers/dashboard-modal", ["exports", "budgeta/services/ember-service-container", "budgeta/controllers/modal"], function (_exports, _emberServiceContainer, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
  var _default = _exports.default = _modal.default.extend({
    mainBudgetDashboardController: Ember.inject.controller("main/budget/dashboard"),
    trackingEvents: Ember.inject.service(),
    permissions: Ember.inject.service(),
    budgetService: Ember.inject.service(),
    isNonProfitBudget: Ember.computed.alias("budgetService.isNonProfitBudget"),
    widgetTypes: Ember.computed("isNonProfitBudget", function () {
      var widgetTypes = Ember.A([Ember.Object.create({
        title: intl.t("general.title_1695611598297"),
        id: "all",
        active: true
      }), Ember.Object.create({
        title: intl.t("general.title_1695611597810"),
        id: "revenues"
      }), Ember.Object.create({
        title: intl.t("general.title_1695611597727"),
        id: "expenses"
      }), Ember.Object.create({
        title: intl.t("general.title_1695611597579"),
        id: "headcount"
      }), Ember.Object.create({
        title: intl.t("general.title_1695611597643"),
        id: "general"
      }), Ember.Object.create({
        title: intl.t("general.title_1695611597971"),
        id: "saas"
      }), Ember.Object.create({
        title: intl.t("general.title_1695611598244"),
        id: undefined
      }), Ember.Object.create({
        title: intl.t("general.title_1695611597611"),
        id: "kpiChart"
      }), Ember.Object.create({
        title: intl.t("general.title_1695611598161"),
        id: "bookings"
      })]);
      if (this.get("isNonProfitBudget")) {
        return Ember.A([Ember.Object.create({
          title: intl.t("general.title_1695611598297"),
          id: "all",
          active: true
        }), Ember.Object.create({
          title: intl.t("general.title_1695611597810"),
          id: "revenues"
        }), Ember.Object.create({
          title: intl.t("general.title_1695611597727"),
          id: "expenses"
        }), Ember.Object.create({
          title: intl.t("general.title_1695611597579"),
          id: "headcount"
        }), Ember.Object.create({
          title: intl.t("general.title_1695611597643"),
          id: "general"
        }), Ember.Object.create({
          title: intl.t("general.title_1695611598244"),
          id: undefined
        }), Ember.Object.create({
          title: intl.t("general.title_1695611597611"),
          id: "kpiChart"
        }), Ember.Object.create({
          title: intl.t("general.title_1695611597779"),
          id: "bookings"
        })]);
      }
      return widgetTypes;
    }),
    charts: {
      revenues: {
        title: intl.t("general.title_1695611597810"),
        id: "revenues-section",
        chartsContent: [Ember.Object.create({
          title: intl.t("general.title_1695611597810"),
          id: "rev",
          highchartType: "column-chart",
          highchartCurrency: ["$"],
          highchartCategories: ["JAN 16", "FEB 16", "MAR 16", "APR 16", "MAY 16", "JUN 16", "JUL 16", "AUG 16", "SEP 16", "OCT 16", "NOV 16", "DEC 16"],
          highchartStacking: "normal",
          highchartData: [{
            name: intl.t("general.name_1695611602657"),
            data: [1000000, 1020000, 1040400, 1113228, 1191154, 1274535, 1427479, 1598776, 1790630, 1915974, 2050092, 2193598],
            stack: "Budget"
          }, {
            name: intl.t("general.name_1695611602647"),
            data: [2000000, 2040000, 2080800, 2226456, 2382308, 2549069, 2854958, 3197553, 3581259, 3831947, 4100184, 4387196],
            stack: "Budget"
          }, {
            name: intl.t("general.name_1695611602636"),
            data: [500000, 510000, 520200, 556614, 595577, 637267, 713739, 799388, 895315, 957987, 1025046, 1096799],
            stack: "Budget"
          }, {
            name: intl.t("general.name_1695611602626"),
            data: [10000000, 10200000, 10404000, 11132280, 11911540, 12745347, 14274789, 15987764, 17906295, 19159736, 20500918, 21935982],
            stack: "Budget"
          }],
          descreption: intl.t("general.shows_the_revenue_in_each_time_period_1698234256140")
        }), Ember.Object.create({
          title: intl.t("general.title_1695611597800"),
          id: "rev-growth",
          highchartType: "mix-chart",
          highchartCurrency: ["$", "%"],
          highchartCategories: ["JAN 16", "FEB 16", "MAR 16", "APR 16", "MAY 16", "JUN 16", "JUL 16", "AUG 16", "SEP 16", "OCT 16", "NOV 16", "DEC 16"],
          highchartStacking: "normal",
          highchartData: [{
            type: "column",
            name: intl.t("general.title_1695611597600"),
            data: [13500000, 13770000, 14045400, 15028578, 16080578, 17206219, 19270965, 21583481, 24173499, 25865644, 27676239, 29613575],
            yAxis: 0
          }, {
            type: "spline",
            name: intl.t("general.name_1695611602605"),
            data: [9, 10, 10, 8, 8, 8, 8, 8, 8, 8, 4, 4],
            yAxis: 1
          }],
          descreption: intl.t("general.shows_the_revenue_in_each_time_period,_along_with_the_percentage_of_revenue_growth_1698234256140")
        }), Ember.Object.create({
          title: intl.t("general.title_1695611597789"),
          id: "deferred-rev-ar",
          highchartType: "line-chart",
          highchartCategories: ["JAN 16", "FEB 16", "MAR 16", "APR 16", "MAY 16", "JUN 16", "JUL 16", "AUG 16", "SEP 16", "OCT 16", "NOV 16", "DEC 16"],
          highchartData: [{
            name: intl.t("general.title_1695611597558"),
            data: [2000000, 2040000, 2080800, 2122416, 2164864, 2208162, 2252325, 2297371, 2343319, 2390185, 2437989, 2486749]
          }, {
            name: intl.t("general.name_1695611602584"),
            data: [100000, 105000, 110250, 115763, 121551, 127628, 134010, 140710, 147746, 155133, 162889, 171034]
          }],
          descreption: intl.t("general.shows_the_deferred_revenue_and_accounts_receivable_in_each_time_period_1698234256139")
        })]
      },
      bookings: {
        title: intl.t("general.title_1695611598161"),
        id: "booking-section",
        chartsContent: [Ember.Object.create({
          title: intl.t("general.title_1695611598161"),
          id: "bookings-by",
          highchartType: "column-chart",
          highchartCurrency: ["$"],
          highchartCategories: ["JAN 16", "FEB 16", "MAR 16", "APR 16", "MAY 16", "JUN 16", "JUL 16", "AUG 16", "SEP 16", "OCT 16", "NOV 16", "DEC 16"],
          highchartStacking: "normal",
          highchartData: [{
            type: "column",
            name: intl.t("general.name_1695611603299"),
            data: [13500000, 13770000, 14045400, 15028578, 16080578, 17206219, 19270965, 21583481, 24173499, 25865644, 27676239, 29613575],
            yAxis: 0
          }],
          descreption: intl.t("general.shows_the_bookings_in_each_time_period_1698234256141")
        }), Ember.Object.create({
          title: intl.t("general.title_1695611598151"),
          id: "bookings",
          highchartType: "mix-chart",
          highchartCurrency: ["$", "%"],
          highchartCategories: ["JAN 16", "FEB 16", "MAR 16", "APR 16", "MAY 16", "JUN 16", "JUL 16", "AUG 16", "SEP 16", "OCT 16", "NOV 16", "DEC 16"],
          highchartStacking: "normal",
          highchartData: [{
            type: "column",
            name: intl.t("general.name_1695611603299"),
            data: [13500000, 13770000, 14045400, 15028578, 16080578, 17206219, 19270965, 21583481, 24173499, 25865644, 27676239, 29613575],
            yAxis: 0
          }, {
            type: "spline",
            name: intl.t("general.name_1695611603288"),
            data: [9, 10, 10, 8, 8, 8, 8, 8, 8, 8, 4, 4],
            yAxis: 1
          }],
          descreption: intl.t("general.shows_the_bookings_in_each_time_period,_along_with_the_percentage_of_bookings_growth_1698234256141")
        }), Ember.Object.create({
          title: intl.t("general.title_1695611598141"),
          id: "bookings-type",
          highchartType: "column-chart",
          highchartCurrency: ["$"],
          highchartCategories: ["JAN 16", "FEB 16", "MAR 16", "APR 16", "MAY 16", "JUN 16", "JUL 16", "AUG 16", "SEP 16", "OCT 16", "NOV 16", "DEC 16"],
          highchartStacking: "normal",
          highchartData: [{
            name: intl.t("general.name_1695611603277"),
            data: [20, 22, 24, 26, 28, 30, 33, 36, 39, 49, 44, 46],
            stack: "Budget"
          }, {
            name: intl.t("general.name_1695611603267"),
            data: [5, 6, 6, 6, 7, 7, 8, 8, 8, 8, 9, 9],
            stack: "Budget"
          }, {
            name: intl.t("general.name_1695611603255"),
            data: [0, 3, 3, 5, 5, 5, 7, 7, 8, 8, 4, 4],
            stack: "Budget"
          }],
          descreption: "Shows the bookings in each time period, according to bookings type: new, renewal, and expansion."
        }), Ember.Object.create({
          title: intl.t("general.title_1695611598130"),
          id: "bookings-cash-rev",
          highchartType: "column-chart",
          highchartCurrency: ["$"],
          highchartCategories: ["JAN 16", "FEB 16", "MAR 16", "APR 16", "MAY 16", "JUN 16", "JUL 16", "AUG 16", "SEP 16", "OCT 16", "NOV 16", "DEC 16"],
          highchartStacking: null,
          highchartData: [{
            name: intl.t("general.title_1695611597600"),
            data: [140, 140, 140, 150, 160, 170, 190, 220, 240, 260, 270, 290],
            stack: "Budget"
          }, {
            name: intl.t("general.title_1695611598161"),
            data: [20, 20, 20, 20, 20, 30, 30, 40, 40, 50, 50, 0],
            stack: "Budget"
          }, {
            name: intl.t("general.name_1695611602487"),
            data: [80, 100, 110, 230, 130, 140, 150, 160, 170, 180, 190, 200],
            stack: "Budget"
          }],
          descreption: intl.t("general.shows_the_bookings,_revenues,_and_cash_received_in_each_time_period_1698234256141")
        })]
      },
      expenses: {
        title: intl.t("general.title_1695611597727"),
        id: "expenses-section",
        chartsContent: [Ember.Object.create({
          title: intl.t("general.title_1695611597716"),
          id: "operating-expenses",
          highchartType: "column-chart",
          highchartCurrency: ["$"],
          highchartCategories: ["JAN 16", "FEB 16", "MAR 16", "APR 16", "MAY 16", "JUN 16", "JUL 16", "AUG 16", "SEP 16", "OCT 16", "NOV 16", "DEC 16"],
          highchartStacking: "normal",
          highchartData: [{
            name: intl.t("general.name_1695611602233"),
            data: [10000, 9810, 9645, 9871, 10122, 10397, 10697, 11446, 12247, 12973, 13761, 14615],
            stack: "Budget"
          }, {
            name: intl.t("general.name_1695611602264"),
            data: [13333, 13290, 13261, 13355, 13496, 13681, 13906, 14880, 15921, 16679, 17513, 18427],
            stack: "Budget"
          }, {
            name: intl.t("general.name_1695611602254"),
            data: [12000, 12360, 12731, 13113, 11255, 11593, 11941, 12299, 12668, 13934, 15328, 16861],
            stack: "Budget"
          }, {
            name: intl.t("general.name_1695611602243"),
            data: [12500, 12875, 13261, 13659, 14069, 14491, 14926, 15373, 15835, 17418, 19160, 21076],
            stack: "Budget"
          }],
          descreption: intl.t("general.shows_the_total_operating_expenses_in_each_time_period_1698234256139")
        }), Ember.Object.create({
          title: intl.t("general.title_1695611597705"),
          id: "operating-expenses-revenue",
          highchartType: "column-chart",
          highchartCurrency: ["%"],
          highchartCategories: ["JAN 16", "FEB 16", "MAR 16", "APR 16", "MAY 16", "JUN 16", "JUL 16", "AUG 16", "SEP 16", "OCT 16", "NOV 16", "DEC 16"],
          highchartStacking: "normal",
          highchartData: [{
            name: intl.t("general.title_1695611597705"),
            data: [20.90606903, 24.29585187, 27.72473312, 30.74278971, 25.68162315, 20.72684502, 17.78298038, 15.19708137, 12.61070036, 11.26581863, 10.92545847, 9.59059722],
            stack: "Budget"
          }],
          descreption: intl.t("general.shows_the_total_operating_expenses_in_each_time_period_as_a_percentage_of_revenue_1698234256139")
        }), Ember.Object.create({
          title: intl.t("general.title_1695611597696"),
          id: "expenses-by-category",
          highchartType: "pie-chart",
          chartType: "donut",
          ignoreCategories: true,
          highchartCurrency: ["$"],
          disablePiePercentage: true,
          highchartData: [{
            name: intl.t("general.name_1695611602423"),
            y: 116208,
            level: 1,
            color: "#44a3e5"
          }, {
            name: intl.t("general.name_1695611602413"),
            y: 43070,
            level: 0,
            color: "#77D6FF"
          }, {
            name: intl.t("general.name_1695611602402"),
            y: 73138,
            level: 0,
            color: "#5EBDFF"
          }, {
            name: intl.t("general.name_1695611602392"),
            y: 84207,
            level: 1,
            color: "#39da95"
          }, {
            name: intl.t("general.title_1695611600195"),
            y: 65967,
            level: 0,
            color: "#5affa0"
          }, {
            name: intl.t("general.name_1695611602371"),
            y: 18240,
            level: 0,
            color: "#5af2b0"
          }, {
            name: intl.t("general.name_1695611602362"),
            y: 54474,
            level: 1,
            color: "#ff6f60"
          }, {
            name: intl.t("general.name_1695611602352"),
            y: 20967,
            level: 0,
            color: "#FF897A"
          }, {
            name: intl.t("general.name_1695611602341"),
            y: 25267,
            level: 0,
            color: "#FFA293"
          }, {
            name: intl.t("general.name_1695611602329"),
            y: 8240,
            level: 0,
            color: "#FFBCAD"
          }],
          descreption: "Shows the total expenses in a time period, by expense category. For example, the amount of expenses spent on salaries, benefits, and travel."
        })]
      },
      headcount: {
        title: intl.t("general.title_1695611597579"),
        id: "headcount-section",
        chartsContent: [Ember.Object.create({
          title: intl.t("general.title_1695611597579"),
          id: "hc",
          highchartType: "column-chart",
          highchartCategories: ["JAN 16", "FEB 16", "MAR 16", "APR 16", "MAY 16", "JUN 16", "JUL 16", "AUG 16", "SEP 16", "OCT 16", "NOV 16", "DEC 16"],
          highchartStacking: "normal",
          highchartData: [{
            name: intl.t("general.name_1695611602233"),
            data: [20, 21, 22, 23, 24, 25, 26, 26, 26, 27, 28, 29],
            stack: "Budget"
          }, {
            name: intl.t("general.name_1695611602264"),
            data: [30, 31, 32, 34, 36, 38, 40, 40, 40, 42, 44, 46],
            stack: "Budget"
          }, {
            name: intl.t("general.name_1695611602254"),
            data: [5, 5, 5, 5, 6, 6, 6, 6, 6, 6, 6, 6],
            stack: "Budget"
          }, {
            name: intl.t("general.name_1695611602243"),
            data: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
            stack: "Budget"
          }],
          descreption: intl.t("general.shows_the_total_headcount_in_each_time_period_1698234256138")
        }), Ember.Object.create({
          title: intl.t("general.title_1695611598055"),
          id: "revenues-per-employee",
          unavailableInNonProfitBudget: true,
          highchartType: "mix-chart",
          highchartCurrency: ["", "$"],
          highchartCategories: ["JAN 16", "FEB 16", "MAR 16", "APR 16", "MAY 16", "JUN 16", "JUL 16", "AUG 16", "SEP 16", "OCT 16", "NOV 16", "DEC 16"],
          highchartStacking: "normal",
          highchartData: [{
            type: "column",
            name: intl.t("general.name_1695611603015"),
            data: [57, 59, 61, 64, 68, 71, 74, 74, 74, 77, 80, 83],
            yAxis: 0
          }, {
            type: "spline",
            name: intl.t("general.title_1695611598055"),
            data: [236842, 233390, 230252, 234822, 236479, 242341, 260418, 291669, 326669, 335917, 345953, 356790],
            yAxis: 1
          }],
          descreption: intl.t("general.shows_the_total_headcount_in_each_time_period,_along_with_the_average_revenue_per_employee_1698234256141")
        }), Ember.Object.create({
          title: intl.t("general.title_1695611597664"),
          id: "avg-salary-per-employee",
          highchartType: "line-chart",
          highchartCategories: ["JAN 16", "FEB 16", "MAR 16", "APR 16", "MAY 16", "JUN 16", "JUL 16", "AUG 16", "SEP 16", "OCT 16", "NOV 16", "DEC 16"],
          highchartData: [{
            name: intl.t("general.name_1695611602233"),
            data: [10000, 9809.52381, 9644.545455, 9871, 10121.875, 10397.2, 10697.11538, 11445.88462, 12247.11538, 12972.85185, 13760.5, 14614.62069]
          }, {
            name: intl.t("general.name_1695611602264"),
            data: [13333.33333, 13290.32258, 13261.25, 13354.85294, 13495.83333, 13680.5, 13906.225, 14879.675, 15921.25, 16679.40476, 17513.36364, 18427.1087]
          }, {
            name: intl.t("general.name_1695611602254"),
            data: [12000, 12360, 12730.8, 13112.8, 11255.16667, 11592.66667, 11940.5, 12298.66667, 12667.66667, 13934.5, 15328, 16860.66667]
          }, {
            name: intl.t("general.name_1695611602243"),
            data: [12500, 12875, 13261.5, 13659, 14069, 14491, 14925.5, 15373.5, 15834.5, 17418, 19160, 21076]
          }],
          descreption: intl.t("general.shows_the_average_salary_and_wages_cost_per_employee_in_each_time_period_1698234256138")
        }), Ember.Object.create({
          title: intl.t("general.title_1695611597653"),
          id: "hc-new",
          highchartType: "pie-chart",
          highchartCategories: ["JAN 16", "FEB 16", "MAR 16", "APR 16", "MAY 16", "JUN 16", "JUL 16", "AUG 16", "SEP 16", "OCT 16", "NOV 16", "DEC 16"],
          highchartStacking: null,
          highchartData: [{
            name: intl.t("general.name_1695611602233"),
            y: 4
          }, {
            name: intl.t("general.name_1695611602222"),
            y: 9
          }, {
            name: intl.t("general.name_1695611602212"),
            y: 2
          }],
          descreption: intl.t("general.shows_the_number_of_new_hires_planned_in_each_time_period_1698234256138")
        })]
      },
      general: {
        title: intl.t("general.title_1695611597643"),
        id: "general-section",
        chartsContent: [Ember.Object.create({
          title: intl.t("general.title_1695611598012"),
          id: "net-margin",
          highchartType: "mix-chart",
          highchartCurrency: ["$", "%"],
          highchartCategories: ["JAN 16", "FEB 16", "MAR 16", "APR 16", "MAY 16", "JUN 16", "JUL 16", "AUG 16", "SEP 16", "OCT 16", "NOV 16", "DEC 16"],
          highchartStacking: "normal",
          highchartData: [{
            type: "column",
            name: intl.t("general.name_1695611602201"),
            data: [6885000, 7030800, 7179343, 7899225, 8697809, 9559410, 11348966, 13374578, 15665386, 16927742, 18277839, 19721268],
            yAxis: 0
          }, {
            type: "spline",
            name: intl.t("general.name_1695611602191"),
            data: [51, 51, 51, 53, 54, 56, 59, 62, 65, 65, 66, 67],
            yAxis: 1
          }],
          descreption: intl.t("general.shows_the_income_and_margin_in_each_time_period_1698234256140")
        }), Ember.Object.create({
          title: intl.t("general.title_1695611597622"),
          id: "cash",
          highchartType: "line-chart",
          highchartCurrency: ["$"],
          highchartCategories: ["JAN 16", "FEB 16", "MAR 16", "APR 16", "MAY 16", "JUN 16", "JUL 16", "AUG 16", "SEP 16", "OCT 16", "NOV 16", "DEC 16"],
          highchartData: [{
            name: intl.t("general.title_1695611597622"),
            data: [2000000, 1800000, 1620000, 1458000, 1603800, 1764180, 1940598, 2134658, 2348124, 2465530, 2588806, 2718247]
          }],
          descreption: intl.t("general.shows_the_ending_cash_balance_in_each_time_period_1698234256138")
        }), Ember.Object.create({
          title: intl.t("general.title_1695611597992"),
          id: "net-reveunes-margin",
          unavailableInNonProfitBudget: true,
          highchartType: "mix-chart",
          highchartCurrency: ["$", "$", "%"],
          highchartCategories: ["JAN 16", "FEB 16", "MAR 16", "APR 16", "MAY 16", "JUN 16", "JUL 16", "AUG 16", "SEP 16", "OCT 16", "NOV 16", "DEC 16"],
          highchartStacking: null,
          highchartData: [{
            type: "column",
            name: intl.t("general.name_1695611602201"),
            data: [6885000, 7030800, 7179343, 7899225, 8697809, 9559410, 11348966, 13374578, 15665386, 16927742, 18277839, 19721268],
            yAxis: 0
          }, {
            type: "column",
            name: intl.t("general.title_1695611597600"),
            data: [13500000, 13770000, 14045400, 15028578, 16080578, 17206219, 19270965, 21583481, 24173499, 25865644, 27676239, 29613575],
            yAxis: 0
          }, {
            type: "spline",
            name: intl.t("general.name_1695611602191"),
            data: [51, 51, 51, 53, 54, 56, 59, 62, 65, 65, 66, 67],
            yAxis: 1
          }],
          descreption: intl.t("general.shows_the_revenue,_income,_and_margin_in_each_time_period_1698234256140")
        }), Ember.Object.create({
          title: intl.t("general.title_1695611597981"),
          id: "gross-margin",
          unavailableInNonProfitBudget: true,
          highchartType: "mix-chart",
          highchartCurrency: ["$", "%"],
          highchartCategories: ["JAN 16", "FEB 16", "MAR 16", "APR 16", "MAY 16", "JUN 16", "JUL 16", "AUG 16", "SEP 16", "OCT 16", "NOV 16", "DEC 16"],
          highchartStacking: null,
          highchartData: [{
            type: "column",
            name: intl.t("general.name_1695611602857"),
            data: [8750000, 8948750, 9151831, 9988202, 10888991, 11858884, 13763210, 15910494, 18330322, 19847171, 21477212, 23228578],
            yAxis: 0
          }, {
            type: "spline",
            name: intl.t("general.name_1695611602846"),
            data: [65, 65, 65, 66, 68, 69, 71, 74, 76, 77, 78, 78],
            yAxis: 1
          }],
          descreption: intl.t("general.shows_the_gross_margin_amounts_and_percentages_in_each_time_period_1698234256140")
        })]
      },
      saas: {
        title: intl.t("general.title_1695611597971"),
        id: "saas-section",
        unavailableInNonProfitBudget: true,
        chartsContent: [Ember.Object.create({
          title: intl.t("general.title_1695611597960"),
          id: "saas",
          highchartType: "line-chart",
          highchartCategories: ["JAN 16", "FEB 16", "MAR 16", "APR 16", "MAY 16", "JUN 16", "JUL 16", "AUG 16", "SEP 16", "OCT 16", "NOV 16", "DEC 16"],
          highchartData: [{
            name: intl.t("general.name_1695611602836"),
            data: [22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22]
          }, {
            name: intl.t("general.name_1695611602824"),
            data: [-8, -8.32, -8.6352, -8.945672, -9.25148692, -9.5527146162, -9.849423896957, -10.141682538502645, -10.429557300425106, -10.71311394091873, -10.992417231804948, -11.267530973327874]
          }, {
            name: intl.t("general.name_1695611602814"),
            data: [2, 2.08, 2.1588, 2.236418, 2.31287173, 2.38817865405, 2.46235597423925, 2.535420634625661, 2.6073893251062765, 2.6782784852296824, 2.748104307951237, 2.8168827433319685]
          }, {
            name: intl.t("general.name_1695611602803"),
            data: [16, 15.759999999999998, 15.5236, 15.290746, 15.061384810000002, 14.835464037850002, 14.612932077282252, 14.393738096123018, 14.17783202468117, 13.965164544310953, 13.905164544310953, 13.865164544310953]
          }],
          descreption: "Shows the net new MRR (Monthly Recurring Revenue) in each time period, along with their components (new, churned, and expansion)."
        }), Ember.Object.create({
          title: intl.t("general.title_1695611597949"),
          id: "saas2",
          highchartType: "line-chart",
          highchartCategories: ["JAN 16", "FEB 16", "MAR 16", "APR 16", "MAY 16", "JUN 16", "JUL 16", "AUG 16", "SEP 16", "OCT 16", "NOV 16", "DEC 16"],
          highchartData: [{
            name: intl.t("general.title_1695611597949"),
            data: [416, 430, 447, 466, 491, 515, 541, 570, 602, 638, 678, 722]
          }],
          descreption: intl.t("general.shows_the_total_mrr_(monthly_recurring_revenue)_at_the_end_of_each_time_period_1698234256140")
        }), Ember.Object.create({
          title: intl.t("general.title_1695611597938"),
          id: "saas-y",
          highchartType: "line-chart",
          highchartCategories: ["JAN 16", "FEB 16", "MAR 16", "APR 16", "MAY 16", "JUN 16", "JUL 16", "AUG 16", "SEP 16", "OCT 16", "NOV 16", "DEC 16"],
          highchartData: [{
            name: intl.t("general.name_1695611602782"),
            data: [264, 276, 294, 312, 324, 348, 383, 421, 463, 510, 560, 617]
          }, {
            name: intl.t("general.name_1695611602772"),
            data: [-101, -135, -108, -107, -106, -106, -111, -117, -123, -130, -138, -146]
          }, {
            name: intl.t("general.name_1695611602761"),
            data: [24, 30, 26, 16, 84, 41, 43, 45, 48, 51, 54, 57]
          }, {
            name: intl.t("general.name_1695611602750"),
            data: [187, 171, 211, 221, 302, 283, 315, 350, 388, 430, 476, 527]
          }],
          descreption: "Shows the net new ACV (Annual Contract Value) in each time period, along with their components (new, churned, and expansion)."
        }), Ember.Object.create({
          title: intl.t("general.title_1695611597927"),
          id: "saas-y2",
          highchartType: "line-chart",
          highchartCategories: ["JAN 16", "FEB 16", "MAR 16", "APR 16", "MAY 16", "JUN 16", "JUL 16", "AUG 16", "SEP 16", "OCT 16", "NOV 16", "DEC 16"],
          highchartData: [{
            name: intl.t("general.title_1695611597927"),
            data: [4987, 5158, 5370, 5591, 5892, 6175, 6490, 6840, 7228, 7658, 8134, 8661]
          }],
          descreption: intl.t("general.shows_the_total_acv_(annual_contract_value)_at_the_end_of_each_time_period_1698234256140")
        })]
      },
      kpiChart: {
        title: intl.t("general.title_1695611597611"),
        id: "kpi-section",
        chartsContent: [Ember.Object.create({
          id: "kpiChart",
          highchartType: "kpi-chart",
          highchartCurrency: "$",
          highchartData: {
            revenues: {
              title: intl.t("general.title_1695611597600"),
              type: "revenues",
              data: "$8.2M",
              addCurrency: true
            },
            "net-profit": {
              title: intl.t("general.title_1695611597896"),
              type: "net-profit",
              data: "$4.7M",
              addCurrency: true
            },
            employees: {
              title: intl.t("general.title_1695611597579"),
              type: "employees",
              data: "11"
            },
            "end-of-cash": {
              title: intl.t("general.title_1695611597569"),
              type: "end-of-cash",
              data: "July 2017"
            },
            "deferred-revenue": {
              title: intl.t("general.title_1695611597558"),
              type: "deferred-revenue",
              data: "$6.4M",
              addCurrency: true
            },
            "ending-cash-balance": {
              title: intl.t("general.title_1695611597548"),
              type: "ending-cash-balance",
              data: "$-14.1M",
              addCurrency: true
            }
          },
          kpiSelection: [{
            name: intl.t("general.title_1695611597600"),
            type: "revenues",
            selected: true
          }, {
            name: intl.t("general.title_1695611597896"),
            type: "net-profit",
            selected: true
          }, {
            name: intl.t("general.title_1695611599982"),
            type: "employees",
            selected: true
          }, {
            name: intl.t("general.title_1695611597569"),
            type: "end-of-cash",
            selected: true
          }, {
            name: intl.t("general.title_1695611599257"),
            type: "ending-cash-balance",
            selected: true
          }, {
            name: intl.t("general.title_1695611597558"),
            type: "deferred-revenue",
            selected: true
          }],
          title: intl.t("general.title_1695611597537"),
          descreption: "Shows key financial data: total revenue, net profit, and deferred revenue; timing of end of cash; exit headcount; and ending cash balance."
        }), Ember.Object.create({
          id: "single-kpi",
          highchartType: "single-kpi",
          highchartData: {
            name: intl.t("general.name_1695611602107"),
            data: "July 2017"
          },
          title: intl.t("general.title_1695611597527"),
          descreption: intl.t("general.shows_the_expected_end_of_cash_1698234256138")
        })]
      }
    },
    chartsNonProfit: {
      revenues: {
        title: intl.t("general.title_1695611597810"),
        id: "revenues-section",
        chartsContent: [Ember.Object.create({
          title: intl.t("general.title_1695611597810"),
          id: "rev",
          highchartType: "column-chart",
          highchartCurrency: ["$"],
          highchartCategories: ["JAN 16", "FEB 16", "MAR 16", "APR 16", "MAY 16", "JUN 16", "JUL 16", "AUG 16", "SEP 16", "OCT 16", "NOV 16", "DEC 16"],
          highchartStacking: "normal",
          highchartData: [{
            name: intl.t("general.name_1695611602657"),
            data: [1000000, 1020000, 1040400, 1113228, 1191154, 1274535, 1427479, 1598776, 1790630, 1915974, 2050092, 2193598],
            stack: "Budget"
          }, {
            name: intl.t("general.name_1695611602647"),
            data: [2000000, 2040000, 2080800, 2226456, 2382308, 2549069, 2854958, 3197553, 3581259, 3831947, 4100184, 4387196],
            stack: "Budget"
          }, {
            name: intl.t("general.name_1695611602636"),
            data: [500000, 510000, 520200, 556614, 595577, 637267, 713739, 799388, 895315, 957987, 1025046, 1096799],
            stack: "Budget"
          }, {
            name: intl.t("general.name_1695611602626"),
            data: [10000000, 10200000, 10404000, 11132280, 11911540, 12745347, 14274789, 15987764, 17906295, 19159736, 20500918, 21935982],
            stack: "Budget"
          }],
          descreption: intl.t("general.shows_the_revenue_in_each_time_period_1698234256140")
        }), Ember.Object.create({
          title: intl.t("general.title_1695611597800"),
          id: "rev-growth",
          highchartType: "mix-chart",
          highchartCurrency: ["$", "%"],
          highchartCategories: ["JAN 16", "FEB 16", "MAR 16", "APR 16", "MAY 16", "JUN 16", "JUL 16", "AUG 16", "SEP 16", "OCT 16", "NOV 16", "DEC 16"],
          highchartStacking: "normal",
          highchartData: [{
            type: "column",
            name: intl.t("general.title_1695611597600"),
            data: [13500000, 13770000, 14045400, 15028578, 16080578, 17206219, 19270965, 21583481, 24173499, 25865644, 27676239, 29613575],
            yAxis: 0
          }, {
            type: "spline",
            name: intl.t("general.name_1695611602605"),
            data: [9, 10, 10, 8, 8, 8, 8, 8, 8, 8, 4, 4],
            yAxis: 1
          }],
          descreption: intl.t("general.shows_the_revenue_in_each_time_period,_along_with_the_percentage_of_revenue_growth_1698234256140")
        }), Ember.Object.create({
          title: intl.t("general.title_1695611597789"),
          id: "deferred-rev-ar",
          highchartType: "line-chart",
          highchartCategories: ["JAN 16", "FEB 16", "MAR 16", "APR 16", "MAY 16", "JUN 16", "JUL 16", "AUG 16", "SEP 16", "OCT 16", "NOV 16", "DEC 16"],
          highchartData: [{
            name: intl.t("general.title_1695611597558"),
            data: [2000000, 2040000, 2080800, 2122416, 2164864, 2208162, 2252325, 2297371, 2343319, 2390185, 2437989, 2486749]
          }, {
            name: intl.t("general.name_1695611602584"),
            data: [100000, 105000, 110250, 115763, 121551, 127628, 134010, 140710, 147746, 155133, 162889, 171034]
          }],
          descreption: intl.t("general.shows_the_deferred_revenue_and_accounts_receivable_in_each_time_period_1698234256139")
        })]
      },
      bookings: {
        title: intl.t("general.title_1695611597779"),
        id: "booking-section",
        chartsContent: [Ember.Object.create({
          title: intl.t("general.title_1695611597769"),
          id: "bookings-by",
          highchartType: "column-chart",
          highchartCurrency: ["$"],
          highchartCategories: ["JAN 16", "FEB 16", "MAR 16", "APR 16", "MAY 16", "JUN 16", "JUL 16", "AUG 16", "SEP 16", "OCT 16", "NOV 16", "DEC 16"],
          highchartStacking: "normal",
          highchartData: [{
            type: "column",
            name: intl.t("general.name_1695611602562"),
            data: [13500000, 13770000, 14045400, 15028578, 16080578, 17206219, 19270965, 21583481, 24173499, 25865644, 27676239, 29613575],
            yAxis: 0
          }],
          descreption: intl.t("general.shows_the_income_stream_in_each_time_period_1698234256139")
        }), Ember.Object.create({
          title: intl.t("general.title_1695611597758"),
          id: "bookings",
          highchartType: "mix-chart",
          highchartCurrency: ["$", "%"],
          highchartCategories: ["JAN 16", "FEB 16", "MAR 16", "APR 16", "MAY 16", "JUN 16", "JUL 16", "AUG 16", "SEP 16", "OCT 16", "NOV 16", "DEC 16"],
          highchartStacking: "normal",
          highchartData: [{
            type: "column",
            name: intl.t("general.name_1695611602562"),
            data: [13500000, 13770000, 14045400, 15028578, 16080578, 17206219, 19270965, 21583481, 24173499, 25865644, 27676239, 29613575],
            yAxis: 0
          }, {
            type: "spline",
            name: intl.t("general.name_1695611602551"),
            data: [9, 10, 10, 8, 8, 8, 8, 8, 8, 8, 4, 4],
            yAxis: 1
          }],
          descreption: intl.t("general.shows_the_income_stream_in_each_time_period,_along_with_the_percentage_of_growth_1698234256139")
        }), Ember.Object.create({
          title: intl.t("general.title_1695611597748"),
          id: "bookings-type",
          highchartType: "column-chart",
          highchartCurrency: ["$"],
          highchartCategories: ["JAN 16", "FEB 16", "MAR 16", "APR 16", "MAY 16", "JUN 16", "JUL 16", "AUG 16", "SEP 16", "OCT 16", "NOV 16", "DEC 16"],
          highchartStacking: "normal",
          highchartData: [{
            name: intl.t("general.name_1695611602540"),
            data: [20, 22, 24, 26, 28, 30, 33, 36, 39, 49, 44, 46],
            stack: "Budget"
          }, {
            name: intl.t("general.name_1695611602530"),
            data: [5, 6, 6, 6, 7, 7, 8, 8, 8, 8, 9, 9],
            stack: "Budget"
          }, {
            name: intl.t("general.name_1695611602520"),
            data: [0, 3, 3, 5, 5, 5, 7, 7, 8, 8, 4, 4],
            stack: "Budget"
          }],
          descreption: intl.t("general.shows_the_income_stream_in_each_time_period,_according_to_type_new,_renewal,_and_expansion_1698234256139")
        }), Ember.Object.create({
          title: intl.t("general.title_1695611597737"),
          id: "bookings-cash-rev",
          highchartType: "column-chart",
          highchartCurrency: ["$"],
          highchartCategories: ["JAN 16", "FEB 16", "MAR 16", "APR 16", "MAY 16", "JUN 16", "JUL 16", "AUG 16", "SEP 16", "OCT 16", "NOV 16", "DEC 16"],
          highchartStacking: null,
          highchartData: [{
            name: intl.t("general.title_1695611597600"),
            data: [140, 140, 140, 150, 160, 170, 190, 220, 240, 260, 270, 290],
            stack: "Budget"
          }, {
            name: intl.t("general.name_1695611602498"),
            data: [20, 20, 20, 20, 20, 30, 30, 40, 40, 50, 50, 0],
            stack: "Budget"
          }, {
            name: intl.t("general.name_1695611602487"),
            data: [80, 100, 110, 230, 130, 140, 150, 160, 170, 180, 190, 200],
            stack: "Budget"
          }],
          descreption: intl.t("general.shows_the_income_stream,_revenues,_and_cash_received_in_each_time_period_1698234256139")
        })]
      },
      expenses: {
        title: intl.t("general.title_1695611597727"),
        id: "expenses-section",
        chartsContent: [Ember.Object.create({
          title: intl.t("general.title_1695611597716"),
          id: "operating-expenses",
          highchartType: "column-chart",
          highchartCurrency: ["$"],
          highchartCategories: ["JAN 16", "FEB 16", "MAR 16", "APR 16", "MAY 16", "JUN 16", "JUL 16", "AUG 16", "SEP 16", "OCT 16", "NOV 16", "DEC 16"],
          highchartStacking: "normal",
          highchartData: [{
            name: intl.t("general.name_1695611602233"),
            data: [10000, 9810, 9645, 9871, 10122, 10397, 10697, 11446, 12247, 12973, 13761, 14615],
            stack: "Budget"
          }, {
            name: intl.t("general.name_1695611602264"),
            data: [13333, 13290, 13261, 13355, 13496, 13681, 13906, 14880, 15921, 16679, 17513, 18427],
            stack: "Budget"
          }, {
            name: intl.t("general.name_1695611602254"),
            data: [12000, 12360, 12731, 13113, 11255, 11593, 11941, 12299, 12668, 13934, 15328, 16861],
            stack: "Budget"
          }, {
            name: intl.t("general.name_1695611602243"),
            data: [12500, 12875, 13261, 13659, 14069, 14491, 14926, 15373, 15835, 17418, 19160, 21076],
            stack: "Budget"
          }],
          descreption: intl.t("general.shows_the_total_operating_expenses_in_each_time_period_1698234256139")
        }), Ember.Object.create({
          title: intl.t("general.title_1695611597705"),
          id: "operating-expenses-revenue",
          highchartType: "column-chart",
          highchartCurrency: ["%"],
          highchartCategories: ["JAN 16", "FEB 16", "MAR 16", "APR 16", "MAY 16", "JUN 16", "JUL 16", "AUG 16", "SEP 16", "OCT 16", "NOV 16", "DEC 16"],
          highchartStacking: "normal",
          highchartData: [{
            name: intl.t("general.title_1695611597705"),
            data: [20.90606903, 24.29585187, 27.72473312, 30.74278971, 25.68162315, 20.72684502, 17.78298038, 15.19708137, 12.61070036, 11.26581863, 10.92545847, 9.59059722],
            stack: "Budget"
          }],
          descreption: intl.t("general.shows_the_total_operating_expenses_in_each_time_period_as_a_percentage_of_revenue_1698234256139")
        }), Ember.Object.create({
          title: intl.t("general.title_1695611597696"),
          id: "expenses-by-category",
          highchartType: "pie-chart",
          chartType: "donut",
          ignoreCategories: true,
          highchartCurrency: ["$"],
          disablePiePercentage: true,
          highchartData: [{
            name: intl.t("general.name_1695611602423"),
            y: 116208,
            level: 1,
            color: "#44a3e5"
          }, {
            name: intl.t("general.name_1695611602413"),
            y: 43070,
            level: 0,
            color: "#77D6FF"
          }, {
            name: intl.t("general.name_1695611602402"),
            y: 73138,
            level: 0,
            color: "#5EBDFF"
          }, {
            name: intl.t("general.name_1695611602392"),
            y: 84207,
            level: 1,
            color: "#39da95"
          }, {
            name: intl.t("general.title_1695611600195"),
            y: 65967,
            level: 0,
            color: "#5affa0"
          }, {
            name: intl.t("general.name_1695611602371"),
            y: 18240,
            level: 0,
            color: "#5af2b0"
          }, {
            name: intl.t("general.name_1695611602362"),
            y: 54474,
            level: 1,
            color: "#ff6f60"
          }, {
            name: intl.t("general.name_1695611602352"),
            y: 20967,
            level: 0,
            color: "#FF897A"
          }, {
            name: intl.t("general.name_1695611602341"),
            y: 25267,
            level: 0,
            color: "#FFA293"
          }, {
            name: intl.t("general.name_1695611602329"),
            y: 8240,
            level: 0,
            color: "#FFBCAD"
          }],
          descreption: "Shows the total expenses in a time period, by expense category. For example, the amount of expenses spent on salaries, benefits, and travel."
        })]
      },
      headcount: {
        title: intl.t("general.title_1695611597579"),
        id: "headcount-section",
        chartsContent: [Ember.Object.create({
          title: intl.t("general.title_1695611597579"),
          id: "hc",
          highchartType: "column-chart",
          highchartCategories: ["JAN 16", "FEB 16", "MAR 16", "APR 16", "MAY 16", "JUN 16", "JUL 16", "AUG 16", "SEP 16", "OCT 16", "NOV 16", "DEC 16"],
          highchartStacking: "normal",
          highchartData: [{
            name: intl.t("general.name_1695611602233"),
            data: [20, 21, 22, 23, 24, 25, 26, 26, 26, 27, 28, 29],
            stack: "Budget"
          }, {
            name: intl.t("general.name_1695611602264"),
            data: [30, 31, 32, 34, 36, 38, 40, 40, 40, 42, 44, 46],
            stack: "Budget"
          }, {
            name: intl.t("general.name_1695611602254"),
            data: [5, 5, 5, 5, 6, 6, 6, 6, 6, 6, 6, 6],
            stack: "Budget"
          }, {
            name: intl.t("general.name_1695611602243"),
            data: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
            stack: "Budget"
          }],
          descreption: intl.t("general.shows_the_total_headcount_in_each_time_period_1698234256138")
        }), Ember.Object.create({
          title: intl.t("general.title_1695611597664"),
          id: "avg-salary-per-employee",
          highchartType: "line-chart",
          highchartCategories: ["JAN 16", "FEB 16", "MAR 16", "APR 16", "MAY 16", "JUN 16", "JUL 16", "AUG 16", "SEP 16", "OCT 16", "NOV 16", "DEC 16"],
          highchartData: [{
            name: intl.t("general.name_1695611602233"),
            data: [10000, 9809.52381, 9644.545455, 9871, 10121.875, 10397.2, 10697.11538, 11445.88462, 12247.11538, 12972.85185, 13760.5, 14614.62069]
          }, {
            name: intl.t("general.name_1695611602264"),
            data: [13333.33333, 13290.32258, 13261.25, 13354.85294, 13495.83333, 13680.5, 13906.225, 14879.675, 15921.25, 16679.40476, 17513.36364, 18427.1087]
          }, {
            name: intl.t("general.name_1695611602254"),
            data: [12000, 12360, 12730.8, 13112.8, 11255.16667, 11592.66667, 11940.5, 12298.66667, 12667.66667, 13934.5, 15328, 16860.66667]
          }, {
            name: intl.t("general.name_1695611602243"),
            data: [12500, 12875, 13261.5, 13659, 14069, 14491, 14925.5, 15373.5, 15834.5, 17418, 19160, 21076]
          }],
          descreption: intl.t("general.shows_the_average_salary_and_wages_cost_per_employee_in_each_time_period_1698234256138")
        }), Ember.Object.create({
          title: intl.t("general.title_1695611597653"),
          id: "hc-new",
          highchartType: "pie-chart",
          highchartCategories: ["JAN 16", "FEB 16", "MAR 16", "APR 16", "MAY 16", "JUN 16", "JUL 16", "AUG 16", "SEP 16", "OCT 16", "NOV 16", "DEC 16"],
          highchartStacking: null,
          highchartData: [{
            name: intl.t("general.name_1695611602233"),
            y: 4
          }, {
            name: intl.t("general.name_1695611602222"),
            y: 9
          }, {
            name: intl.t("general.name_1695611602212"),
            y: 2
          }],
          descreption: intl.t("general.shows_the_number_of_new_hires_planned_in_each_time_period_1698234256138")
        })]
      },
      general: {
        title: intl.t("general.title_1695611597643"),
        id: "general-section",
        chartsContent: [Ember.Object.create({
          title: intl.t("general.title_1695611597589"),
          id: "net-margin",
          highchartType: "mix-chart",
          highchartCurrency: ["$", "%"],
          highchartCategories: ["JAN 16", "FEB 16", "MAR 16", "APR 16", "MAY 16", "JUN 16", "JUL 16", "AUG 16", "SEP 16", "OCT 16", "NOV 16", "DEC 16"],
          highchartStacking: "normal",
          highchartData: [{
            type: "column",
            name: intl.t("general.name_1695611602201"),
            data: [6885000, 7030800, 7179343, 7899225, 8697809, 9559410, 11348966, 13374578, 15665386, 16927742, 18277839, 19721268],
            yAxis: 0
          }, {
            type: "spline",
            name: intl.t("general.name_1695611602191"),
            data: [51, 51, 51, 53, 54, 56, 59, 62, 65, 65, 66, 67],
            yAxis: 1
          }],
          descreption: "Shows the net revenues/expenditures in each time period."
        }), Ember.Object.create({
          title: intl.t("general.title_1695611597622"),
          id: "cash",
          highchartType: "line-chart",
          highchartCurrency: ["$"],
          highchartCategories: ["JAN 16", "FEB 16", "MAR 16", "APR 16", "MAY 16", "JUN 16", "JUL 16", "AUG 16", "SEP 16", "OCT 16", "NOV 16", "DEC 16"],
          highchartData: [{
            name: intl.t("general.title_1695611597622"),
            data: [2000000, 1800000, 1620000, 1458000, 1603800, 1764180, 1940598, 2134658, 2348124, 2465530, 2588806, 2718247]
          }],
          descreption: intl.t("general.shows_the_ending_cash_balance_in_each_time_period_1698234256138")
        })]
      },
      kpiChart: {
        title: intl.t("general.title_1695611597611"),
        id: "kpi-section",
        chartsContent: [Ember.Object.create({
          id: "kpiChart",
          highchartType: "kpi-chart",
          highchartCurrency: "$",
          highchartData: {
            revenues: {
              title: intl.t("general.title_1695611597600"),
              type: "revenues",
              data: "$8.2M",
              addCurrency: true
            },
            "net-profit": {
              title: intl.t("general.title_1695611597589"),
              type: "net-profit",
              data: "$4.7M",
              addCurrency: true
            },
            employees: {
              title: intl.t("general.title_1695611597579"),
              type: "employees",
              data: "11"
            },
            "end-of-cash": {
              title: intl.t("general.title_1695611597569"),
              type: "end-of-cash",
              data: "July 2017"
            },
            "deferred-revenue": {
              title: intl.t("general.title_1695611597558"),
              type: "deferred-revenue",
              data: "$6.4M",
              addCurrency: true
            },
            "ending-cash-balance": {
              title: intl.t("general.title_1695611597548"),
              type: "ending-cash-balance",
              data: "$-14.1M",
              addCurrency: true
            }
          },
          kpiSelection: [{
            name: intl.t("general.title_1695611597600"),
            type: "revenues",
            selected: true
          }, {
            name: intl.t("general.title_1695611597589"),
            type: "net-profit",
            selected: true
          }, {
            name: intl.t("general.title_1695611599982"),
            type: "employees",
            selected: true
          }, {
            name: intl.t("general.title_1695611597569"),
            type: "end-of-cash",
            selected: true
          }, {
            name: intl.t("general.title_1695611599257"),
            type: "ending-cash-balance",
            selected: true
          }, {
            name: intl.t("general.title_1695611597558"),
            type: "deferred-revenue",
            selected: true
          }],
          title: intl.t("general.title_1695611597537"),
          descreption: "Shows key financial data: total revenue, net revenues/expenditures, and deferred revenue; timing of end of cash; exit headcount; and ending cash balance."
        }), Ember.Object.create({
          id: "single-kpi",
          highchartType: "single-kpi",
          highchartData: {
            name: intl.t("general.name_1695611602107"),
            data: "July 2017"
          },
          title: intl.t("general.title_1695611597527"),
          descreption: intl.t("general.shows_the_expected_end_of_cash_1698234256138")
        })]
      }
    },
    modelCharts: Ember.computed("mainBudgetDashboardController.modelCharts", function () {
      return this.get("mainBudgetDashboardController.modelCharts");
    }),
    modelChartsObj: Ember.computed("modelCharts", "modelCharts.length", function () {
      var modelCharts = this.get("modelCharts") || [];
      modelCharts = modelCharts.map(function (chart) {
        var chartType = Ember.get(chart, "chartType") || "basicLine";
        var highchartType = "line-chart";
        if (chartType === "basicLine" || chartType === "basicLineWithLabels") {
          highchartType = "line-chart";
        }
        if (chartType === "basicColumn" || chartType === "stackedColumn" || chartType === "stackedPercenatageColumn") {
          highchartType = "column-chart";
        }
        if (chartType === "lineAndColumn") {
          highchartType = "mix-chart";
        }
        if (chartType === "pie" || chartType === "pieWithLegend" || chartType === "donut") {
          highchartType = "pie-chart";
        }
        if (chartType === "basicArea") {
          highchartType = "area-chart";
        }
        return Ember.Object.create({
          title: Ember.get(chart, "title"),
          id: Ember.get(chart, "id"),
          highchartType: highchartType,
          highchartCategories: Ember.get(chart, "highchartCategories"),
          highchartData: Ember.get(chart, "highchartData"),
          descreption: "",
          limit: true
        });
      });
      return {
        title: intl.t("general.title_1695611597516"),
        id: "models",
        chartsContent: modelCharts
      };
    }),
    hasModels: Ember.computed("modelCharts.length", function () {
      return this.get("modelCharts.length") > 0;
    }),
    showModels: false,
    addDashboardChartCount: function addDashboardChartCount(charts, dashboardCharts, dashboardId) {
      charts.forEach(function (chartsTypes) {
        chartsTypes.chartsContent.forEach(function (chart) {
          var chartTypeCount = 0;
          if (chart.id) {
            var filterCharts = dashboardCharts.filter(function (dashboardChart) {
              return dashboardChart.id === chart.id && dashboardChart.dashboardId === dashboardId;
            });
            chartTypeCount += filterCharts ? filterCharts.length : 0;
          }
          Ember.setProperties(chart, {
            chartTypeCount: chartTypeCount,
            isMissing: !chartTypeCount
          });
        });
      });
    },
    selectedWidgetType: "all",
    selectedWidgets: Ember.computed("modelChartsObj", "isNonProfitBudget", "selectedWidgetType", "mainBudgetDashboardController.charts", "mainBudgetDashboardController.charts.length", function () {
      var widgetType = this.get("selectedWidgetType");
      var widgetList = Ember.A([]);
      var dashboardCharts = this.get("mainBudgetDashboardController.charts"),
        isNonProfitBudget = this.get("isNonProfitBudget"),
        dashboardId = this.get("mainBudgetDashboardController.dashboardId");
      var charts = this.get("charts");
      if (isNonProfitBudget) {
        charts = this.get("chartsNonProfit");
      }
      if (widgetType === "all") {
        for (var key in charts) {
          widgetList.pushObject(Ember.get(charts, key));
        }
        if (this.get("hasModels")) {
          widgetList.pushObject(this.get("modelChartsObj"));
        }
        this.addDashboardChartCount(widgetList, dashboardCharts, dashboardId);
        return widgetList;
      }
      if (widgetType === "models") {
        widgetList.pushObject(this.get("modelChartsObj"));
      } else {
        widgetList = Ember.A([Ember.get(charts, widgetType)]);
      }
      this.addDashboardChartCount(widgetList, dashboardCharts, dashboardId);
      return widgetList;
    }),
    actions: {
      close: function close() {
        this.send("resetSelection");
        this._super();
      },
      confirm: function confirm() {
        this.send("resetSelection");
        this._super();
      },
      changeWidgetType: function changeWidgetType(widget) {
        var oldSelection = this.get("widgetTypes").findBy("active", true);
        if (oldSelection) {
          oldSelection.set("active", false);
        }
        widget.set("active", true);
        this.setProperties({
          showModels: false,
          selectedWidgetType: Ember.get(widget, "id")
        });
        Ember.$(".choose-charts").scrollTop(0);
      },
      selectModels: function selectModels() {
        var oldSelection = this.get("widgetTypes").findBy("active", true);
        if (oldSelection) {
          oldSelection.set("active", false);
        }
        this.setProperties({
          showModels: true,
          selectedWidgetType: "models"
        });
        Ember.$(".choose-charts").scrollTop(0);
      },
      addChart: function addChart(chart) {
        if (this.get("permissions.canCustomizeDashboard")) {
          var scrollTopPosition = Ember.$(".choose-charts").scrollTop();
          Ember.run.scheduleOnce("afterRender", this, function () {
            Ember.$(".choose-charts").scrollTop(scrollTopPosition);
            Ember.run.scheduleOnce("afterRender", this, function () {
              Ember.$(".choose-charts").scrollTop(scrollTopPosition);
            });
          });
          var dashboardCtrl = this.get("mainBudgetDashboardController");

          // update setup guide
          var budget = this.get("mainBudgetDashboardController.budget");
          var option = budget.get("setupGuideOptions").findBy("optionName", "widgets");
          if (!option) {
            budget.get("setupGuideOptions").pushObject({
              optionName: "widgets",
              active: true,
              data: 1
            });
          } else {
            option.data = option.data + 1;
          }
          dashboardCtrl.send("addChart", chart);
        }
      },
      restoreWidgets: function restoreWidgets() {
        this.send("openModal", "modal", {
          model: intl.t("general.model_1695940019044"),
          outlet: "modal2",
          title: intl.t("general.title_1695611597506"),
          templateName: "generic-modal",
          confirmAction: "confirmRestore",
          confirmActionName: intl.t("general.confirmActionName_1695611613164"),
          actionTarget: this
        });
      },
      confirmRestore: function confirmRestore() {
        var dashboardCtrl = this.get("mainBudgetDashboardController");
        dashboardCtrl.send("restoreWidgets");
      },
      resetSelection: function resetSelection() {
        this.setProperties({
          showModels: false,
          selectedWidgetType: "all"
        });
        this.get("widgetTypes").setEach("active", false);
        var allSelection = this.get("widgetTypes").findBy("id", "all");
        allSelection.set("active", true);
      }
    }
  });
});