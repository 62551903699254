define("budgeta/utils/excel", ["exports", "budgeta/services/ember-service-container"], function (_exports, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/new-module-imports */

  var ExcelUtils = {
    datenum: function datenum(v, date1904) {
      if (date1904) {
        v += 1462;
      }
      var epoch = Date.parse(v);
      return (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
    },
    sheet_from_array_of_arrays: function sheet_from_array_of_arrays(data) {
      var ws = {};
      var range = {
        s: {
          c: 10000000,
          r: 10000000
        },
        e: {
          c: 0,
          r: 0
        }
      };
      for (var R = 0; R !== data.length; ++R) {
        for (var C = 0; C !== data[R].length; ++C) {
          if (range.s.r > R) {
            range.s.r = R;
          }
          if (range.s.c > C) {
            range.s.c = C;
          }
          if (range.e.r < R) {
            range.e.r = R;
          }
          if (range.e.c < C) {
            range.e.c = C;
          }
          var cell = data[R][C];
          if (typeof cell === "string" || typeof cell === "number" || cell instanceof Date || cell === null) {
            cell = {
              v: cell
            };
          }
          if (cell.v == null) {
            continue;
          }
          var cell_ref = XLSX.utils.encode_cell({
            c: C,
            r: R
          });
          if (typeof cell.v === "number") {
            cell.t = "n";
          } else if (typeof cell.v === "boolean") {
            cell.t = "b";
          } else if (cell.v instanceof Date) {
            cell.t = "n";
            cell.z = XLSX.SSF._table[17];
            cell.v = ExcelUtils.datenum(cell.v);
          } else {
            cell.t = "s";
          }
          ws[cell_ref] = cell;
        }
      }
      if (range.s.c < 10000000) {
        ws["!ref"] = XLSX.utils.encode_range(range);
      }
      return ws;
    },
    s2ab: function s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i !== s.length; ++i) {
        view[i] = s.charCodeAt(i) & 0xff;
      }
      return buf;
    },
    saveToExcel: function saveToExcel(data, options) {
      var workbook = {
          Sheets: {}
        },
        sheet = ExcelUtils.sheet_from_array_of_arrays(data),
        defaults = {
          sheetName: intl.t("general.sheet_1_1698234257189"),
          fileName: "budget.xlsx"
        };
      options = Ember.$.extend(true, {}, defaults, options || {});
      workbook.SheetNames = [options.sheetName];
      workbook.Sheets[options.sheetName] = sheet;
      var wopts = {
        bookType: "xlsx",
        bookSST: false,
        type: "binary",
        cellStyles: true
      };
      var wbout = XLSX.write(workbook, wopts);
      window.saveAs(new Blob([ExcelUtils.s2ab(wbout)], {
        type: ""
      }), options.fileName);
    }
  };
  var _default = _exports.default = {
    saveToExcel: ExcelUtils.saveToExcel
  };
});