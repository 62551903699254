define("budgeta/components/modal-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    hideCancel: false,
    backText: "Back",
    longTitle: Ember.computed("title", function () {
      return this.get("title.length") > 30;
    }),
    extraLongTitle: Ember.computed("title", function () {
      return this.get("title.length") > 40;
    }),
    steps: Ember.computed("progressSteps.@each.hide", "stepId", function () {
      var _this = this;
      var isSelected = true;
      return (this.get("progressSteps") || Ember.A()).filterBy("hide", false).map(function (step, index) {
        if (step.id === _this.get("stepId")) {
          isSelected = false;
          return {
            index: index + 1,
            title: step.title,
            isCurrent: true
          };
        }
        if (isSelected) {
          return {
            index: index + 1,
            title: step.title,
            selected: true
          };
        }
        return {
          index: index + 1,
          title: step.title,
          selected: false
        };
      });
    }),
    hideBarConnector: Ember.computed("SIwizard", function () {
      return this.get("SIwizard") === "create-si-wizard" ? true : false;
    }),
    isDisabled: Ember.computed("confirmDisabled", "isLoading", function () {
      return this.get("confirmDisabled") || this.get("isLoading");
    }),
    modalDialogStyle: Ember.computed("expandToFullWidth", "shrinkToRegularWidth", function () {
      return this.get("expandToFullWidth") || this.get("shrinkToRegularWidth") ? Ember.String.htmlSafe("") : Ember.String.htmlSafe("display: block");
    }),
    actions: {
      scrollMore: function scrollMore() {
        return this.sendAction("scrollMore");
      },
      next: function next() {
        if (!this.get("isDisabled")) {
          return this.sendAction("next");
        }
      },
      back: function back() {
        return this.sendAction("back");
      },
      close: function close(fromX) {
        return this.sendAction("close", fromX);
      },
      confirm: function confirm() {
        return this.sendAction("confirm");
      },
      continueActionName: function continueActionName() {
        return this.sendAction("continueActionName");
      },
      secondaryButtonAction: function secondaryButtonAction() {
        return this.sendAction("secondaryButtonAction");
      }
    }
  });
});