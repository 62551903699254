define("budgeta/models/object-models/chart", ["exports", "budgeta/services/ember-service-container", "budgeta/utils/dashboard"], function (_exports, _emberServiceContainer, _dashboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initKpiData = _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/no-observers, */

  var initKpiData = _exports.initKpiData = function initKpiData(isNonProfitBudget) {
    return {
      revenues: {
        title: intl.t("general.title_1695611597600"),
        type: "revenues",
        data: "",
        addCurrency: true
      },
      "net-profit": {
        title: isNonProfitBudget ? intl.t("general.title_1695611597589") : intl.t("general.title_1695611597896"),
        type: "net-profit",
        data: "",
        addCurrency: true
      },
      employees: {
        title: intl.t("general.title_1695611597579"),
        type: "employees",
        data: ""
      },
      "end-of-cash": {
        title: intl.t("general.title_1695611597569"),
        type: "end-of-cash",
        data: ""
      },
      "ending-cash-balance": {
        title: intl.t("general.title_1695611599257"),
        type: "ending-cash-balance",
        data: "",
        addCurrency: true
      },
      "deferred-revenue": {
        title: intl.t("general.title_1695611597558"),
        type: "deferred-revenue",
        data: "",
        addCurrency: true
      }
    };
  };
  var _default = _exports.default = Ember.Object.extend({
    controller: undefined,
    id: undefined,
    title: undefined,
    filterOption: undefined,
    init: function init() {
      this._super.apply(this, arguments);
      this.set("scenariosDates", {});
    },
    _dimensions: Ember.computed("controller.dimensions.@each.dimension", function () {
      return this.get("controller.dimensions").map(function (dimension) {
        return dimension.get("dimension");
      });
    }),
    filters: Ember.computed("id", "controller", "controller.dimensions.[]", "_dimensions.@each.valuesLength", function () {
      var chartId = this.get("id");
      if (chartId && this.get("controller") && this.get("controller.dimensions.length")) {
        var dimensions = this.get("controller.dimensions");
        var byDimensions = [{
          value: "all",
          name: intl.t("general.title_1695611598297")
        }];
        var byDimensionsWithoutCategory = [{
          value: "all",
          name: intl.t("general.title_1695611598297")
        }];
        dimensions.forEach(function (dimension) {
          if (!Ember.get(dimension, "dimension.values.length")) {
            return;
          }
          var dName = Ember.get(dimension, "dimension.name"),
            dUniqueId = Ember.get(dimension, "dimension.uniqueId");
          var filterItem = {
            value: dUniqueId,
            name: intl.t("general.chart-1", {
              var1: dName
            })
          };
          var filterItemDetail = {
            value: "".concat(dUniqueId, ":detail"),
            name: intl.t("general.chart-2", {
              var1: dName
            })
          };
          var hasHierarchy = Ember.get(dimension, "dimension.values").findBy("parent") ? true : false;
          if (!hasHierarchy) {
            filterItem = {
              value: dUniqueId,
              name: intl.t("general.chart-2", {
                var1: dName
              })
            };
          }
          byDimensions.pushObject(filterItem);
          if (hasHierarchy) {
            byDimensions.pushObject(filterItemDetail);
          }
          if (!Ember.get(dimension, "dimension.isCategory")) {
            byDimensionsWithoutCategory.pushObject(filterItem);
            if (hasHierarchy) {
              byDimensionsWithoutCategory.pushObject(filterItemDetail);
            }
          }
        });
        var newFilter;
        var departmentDim = dimensions.findBy("dimension.isDepartment");
        var by = "all",
          byPrompt = "All";
        var categoryDim = dimensions.findBy("dimension.isCategory");
        var byCategoryDimensions = [];
        var byCategory, byCategoryPrompt;
        if (categoryDim) {
          var dName = Ember.get(categoryDim, "dimension.name"),
            dUniqueId = Ember.get(categoryDim, "dimension.uniqueId");
          byCategory = dUniqueId;
          byCategoryPrompt = dName;
          byCategoryDimensions.pushObject({
            value: dUniqueId,
            name: intl.t("general.chart-1", {
              var1: dName
            })
          });
          if (Ember.get(categoryDim, "dimension.values").findBy("parent")) {
            byCategoryDimensions.pushObject({
              value: "".concat(dUniqueId, ":detail"),
              name: intl.t("general.chart-2", {
                var1: dName
              })
            });
          }
        }
        switch (chartId) {
          case "hc":
            if (departmentDim) {
              var departmentFilter = byDimensionsWithoutCategory.findBy("value", Ember.get(departmentDim, "dimension.uniqueId"));
              if (departmentFilter) {
                by = Ember.get(departmentFilter, "value");
                byPrompt = Ember.get(departmentFilter, "name");
              }
            }
            newFilter = Ember.Object.create({
              by: by,
              byPrompt: byPrompt,
              byOptions: Ember.A(byDimensionsWithoutCategory)
            });
            break;
          case "operating-expenses":
            if (departmentDim) {
              var _departmentFilter = byDimensions.findBy("value", Ember.get(departmentDim, "dimension.uniqueId"));
              if (_departmentFilter) {
                by = Ember.get(_departmentFilter, "value");
                byPrompt = Ember.get(_departmentFilter, "name");
              }
            }
            newFilter = Ember.Object.create({
              by: by,
              byPrompt: byPrompt,
              byOptions: Ember.A(byDimensions)
            });
            break;
          case "expenses-by-category":
            newFilter = Ember.Object.create({
              by: byCategory,
              byPrompt: byCategoryPrompt,
              byOptions: Ember.A(byCategoryDimensions),
              hideOptions: byCategoryDimensions.length === 0
            });
            break;
          case "avg-salary-per-employee":
            if (departmentDim) {
              var _departmentFilter2 = byDimensionsWithoutCategory.findBy("value", Ember.get(departmentDim, "dimension.uniqueId"));
              if (_departmentFilter2) {
                by = Ember.get(_departmentFilter2, "value");
                byPrompt = Ember.get(_departmentFilter2, "name");
              }
            }
            newFilter = Ember.Object.create({
              by: by,
              byPrompt: byPrompt,
              byOptions: Ember.A(byDimensionsWithoutCategory)
            });
            break;
          case "hc-new":
            newFilter = Ember.Object.create({
              by: "all",
              byPrompt: "All",
              byOptions: Ember.A(byDimensionsWithoutCategory)
            });
            break;
          case "bookings-by":
          case "rev":
            newFilter = Ember.Object.create({
              by: "all",
              byPrompt: "All",
              byOptions: Ember.A(byDimensions)
            });
            break;
          default:
            {
              newFilter = Ember.Object.create({
                by: null,
                hideOptions: true
              });
            }
        }
        return Ember.Object.create(newFilter);
      }
      return {
        by: null,
        byOptions: null
      };
    }),
    filtersObserver: Ember.observer("filters.by", function () {
      if (this.get("controller") && !this.get("controller.emberTest") && this.get("controller.currentPath") === "main.budget.dashboard" && this.get("controller.didFirstCalculation")) {
        this.set("filterOption", this.get("filters.by"));
        this.set("isReady", false);
        Ember.run.debounce(this.get("controller"), this.get("controller").calcDebounce, 200, false);
      } else if (this.get("filters.byOptions") && this.get("filters.by") && !this.get("filters.byOptions").findBy("value", this.get("filters.by"))) {
        this.set("filters.by", "all");
      }
    }),
    chartType: undefined,
    hideByOption: Ember.computed("chartType", "id", function () {
      return this.get("chartType") === "donut" || this.get("id") === "expenses-by-category";
    }),
    highchartType: Ember.computed("id", "chartType", function () {
      var chartId = this.get("id");
      var chartType = this.get("chartType") || _dashboard.default.getDefaultChartType(chartId);
      if (chartType === "basicLine" || chartType === "basicLineWithLabels") {
        return "line-chart";
      }
      if (chartType === "basicColumn" || chartType === "stackedColumn" || chartType === "stackedPercenatageColumn") {
        return "column-chart";
      }
      if (chartType === "lineAndColumn") {
        return "mix-chart";
      }
      if (chartType === "pie" || chartType === "pieWithLegend" || chartType === "donut") {
        return "pie-chart";
      }
      if (chartType === "basicArea") {
        return "area-chart";
      }
      return chartType;
    }),
    highchartStacking: Ember.computed("chartType", "id", function () {
      var chartId = this.get("id");
      var chartFullType = this.get("chartType") || _dashboard.default.getDefaultChartType(chartId);
      if (chartFullType === "stackedColumn") {
        return "normal";
      }
      if (chartFullType === "stackedPercenatageColumn") {
        return "percent";
      }
      return null;
    }),
    highchartLabels: Ember.computed("chartType", "id", function () {
      var chartId = this.get("id");
      var chartFullType = this.get("chartType") || _dashboard.default.getDefaultChartType(chartId);
      if (chartFullType === "basicLineWithLabels") {
        return true;
      }
      return false;
    }),
    isReady: false,
    uniqueId: undefined,
    dashboardId: undefined,
    hide: false,
    menuId: Ember.computed("uniqueId", function () {
      if (this.get("uniqueId")) {
        return this.get("uniqueId") + "-menu";
      }
      return undefined;
    }),
    highchartData: Ember.computed("id", function () {
      if (this.get("id") === "kpiChart") {
        var isNonProfitBudget = this.get("controller.isNonProfitBudget");
        return initKpiData(isNonProfitBudget);
      }
      return {};
    }),
    highchartCurrency: "",
    highchartShowAnimation: true,
    highchartCategories: undefined,
    colorPalette: Ember.computed("id", function () {
      if (this.get("id")) {
        return _dashboard.default.colorSchema[this.get("id")];
      }
      return _dashboard.default.colorSchema["rev"];
    }),
    showLegendAmounts: false,
    scenario: undefined,
    showLegend: false,
    periodType: "Monthly",
    startDate: undefined,
    endDate: undefined,
    enableAdvanced: true,
    enableAdvancedPeriodType: Ember.computed("id", function () {
      if (this.get("id") === "kpiChart" || this.get("id") === "single-kpi" || this.get("id") === "operating-expenses-pie" || this.get("id") === "saas" || this.get("id") === "saas2" || this.get("id") === "saas-y" || this.get("id") === "saas-y2") {
        return false;
      }
      return true;
    }),
    showTitle: Ember.computed("id", "periodType", "highchartType", "startDate", "endDate", "controller.budget.budgetAttributes.startDate", "controller.budget.budgetAttributes.endDate", "scenario", function () {
      var budgetStartDate = Date.create(this.get("controller.budget.budgetAttributes.startDate")).beginningOfMonth(),
        budgetEndDate = Date.create(this.get("controller.budget.budgetAttributes.endDate") || new Date(budgetStartDate).addMonths(11)).beginningOfMonth(),
        chartStartDate = this.get("startDate") || budgetStartDate,
        chartEndDate = this.get("endDate") || budgetEndDate,
        isMonthly = (this.get("periodType") || "Monthly") === "Monthly",
        checkPeriod = isMonthly && chartStartDate && budgetStartDate && chartEndDate && budgetEndDate && (chartStartDate.valueOf() !== budgetStartDate.valueOf() || chartEndDate.valueOf() !== budgetEndDate.valueOf());
      return this.get("id") === "kpiChart" || checkPeriod;
    }),
    extraTitle: Ember.computed("startDate", "endDate", function () {
      if (!this.get("startDate") && !this.get("endDate")) {
        return "";
      }
      var budgetStartDate = Date.create(this.get("controller.budget.budgetAttributes.startDate")).beginningOfMonth(),
        budgetEndDate = Date.create(this.get("controller.budget.budgetAttributes.endDate") || new Date(budgetStartDate).addMonths(11)).beginningOfMonth(),
        chartStartDate = this.get("startDate") || budgetStartDate,
        chartEndDate = this.get("endDate") || budgetEndDate;
      return "".concat(Date.create(chartStartDate).format("{Mon} {yyyy}"), " - ").concat(Date.create(chartEndDate).format("{Mon} {yyyy}"));
    }),
    scenariosDates: null,
    kpiSelection: undefined,
    showKpiSelection: Ember.computed("id", function () {
      return this.get("id") === "kpiChart";
    }),
    pieSumsLastValue: Ember.computed("id", function () {
      var id = this.get("id");
      return id === "hc" || id === "saas" || id === "saas2" || id === "saas-y" || id === "saas-y2";
    }),
    hasFilterOption: Ember.computed("hideByOption", "filters.hideOptions", "filters.byOptions", function () {
      return !this.get("hideByOption") && !this.get("filters.hideOptions") && this.get("filters.byOptions");
    }),
    showNoData: Ember.computed("controller.globalFilterOptions", "id", function () {
      var chartId = this.get("id"),
        globalFilterOptions = this.get("controller.globalFilterOptions");
      return globalFilterOptions && (chartId === "deferred-rev-ar" || chartId === "single-kpi");
    }),
    includeContractos: Ember.computed("id", function () {
      var id = this.get("id");
      return id === "hc" || id === "hc-new" || id === "kpiChart";
    })
  });
});