define("budgeta/components/react/grid-components/filters/filters", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "react", "@sibp/ui-components", "budgeta/services/ember-service-container"], function (_exports, _objectSpread2, _react, _uiComponents, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var emberSeviceContainer = new _emberServiceContainer.default();
  var intl = emberSeviceContainer.lookup("intl");
  var Filters = function Filters(_ref) {
    var _ref$options = _ref.options,
      options = _ref$options === void 0 ? [] : _ref$options,
      _onChange = _ref.onChange,
      onFilterVisibilityChange = _ref.onFilterVisibilityChange;
    return _react.default.createElement("div", {
      className: "filters"
    }, options.map(function (filter) {
      return filter.hidden ? null : _react.default.createElement("div", {
        key: filter.id,
        className: "filter-dropdown ".concat(filter.classNames || "")
      }, filter.isMultiple ? _react.default.createElement(_uiComponents.default.COMPONENTS.DropdownMultiSelect, {
        dataHook: filter.name,
        options: filter.options,
        searchBox: true,
        searchBoxPlaceholder: intl.t("multi-select-dropdown.search-box-placeholder"),
        defaultOption: filter.default,
        currentSelectedValues: filter.currentSelectedValues,
        onChange: function onChange(e) {
          return _onChange({
            id: filter.id,
            name: filter.name,
            value: (0, _objectSpread2.default)({}, e)
          });
        }
      }) : _react.default.createElement(_uiComponents.default.COMPONENTS.DropdownSelect, {
        dataHook: filter.name,
        options: filter.options,
        defaultOption: filter.default,
        currentIndex: filter.currentIndex,
        onChange: function onChange(e) {
          return _onChange({
            id: filter.id,
            name: filter.name,
            value: (0, _objectSpread2.default)({}, e)
          });
        }
      }));
    }), _react.default.createElement(_uiComponents.default.COMPONENTS.VisibilityToggleDropdown, {
      options: options,
      onChange: function onChange(option) {
        return onFilterVisibilityChange(option.id);
      }
    }));
  };
  var _default = _exports.default = Filters;
});