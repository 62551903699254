define("budgeta/utils/grid/grid-filters-utils", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "@babel/runtime/helpers/esm/toConsumableArray", "budgeta/utils/const", "budgeta/services/ember-service-container", "budgeta/utils/grid/grid-utils", "ember-inflector", "budgeta/utils/grid/const"], function (_exports, _objectSpread2, _toConsumableArray2, _const, _emberServiceContainer, _gridUtils, _emberInflector, _const2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var setEmberDimension = function setEmberDimension(dimensionId, valueIds) {
    var viewSelections = new _emberServiceContainer.default().lookup("viewSelections");
    var selectedDimension = viewSelections.get("gridDimensions").findBy("dimension.id", dimensionId);
    Ember.set(selectedDimension, "selectedValue", valueIds);
  };
  var setGridAccounts = function setGridAccounts(valueIds) {
    var viewSelections = new _emberServiceContainer.default().lookup("viewSelections");
    var gridAccounts = valueIds.includes(_const.ALL_ID) ? [] : viewSelections.get("accounts").filter(function (account) {
      return valueIds.some(function (value) {
        return value === account.value;
      });
    }).map(function (account) {
      return account.value;
    });
    viewSelections.set("gridAccounts", gridAccounts);
    return gridAccounts;
  };
  var setGridTags = function setGridTags(valueIds) {
    var viewSelections = new _emberServiceContainer.default().lookup("viewSelections");
    var gridTags = valueIds.includes(_const.ALL_ID) ? [] : viewSelections.get("tagsOptions").filter(function (tag) {
      return valueIds.some(function (value) {
        return value === tag.id;
      });
    });
    viewSelections.set("gridTags", gridTags);
    return gridTags;
  };
  var getGridDimensionsFilters = function getGridDimensionsFilters() {
    var viewSelections = new _emberServiceContainer.default().lookup("viewSelections");
    var intl = new _emberServiceContainer.default().lookup("intl");
    //returns an array of all dimensions as object [{name, default, options}, {} , {} ...]
    return viewSelections.get("gridDimensions").map(function (item) {
      var itemOptions = [];
      //push the first item for all dimensions
      itemOptions.push({
        id: _const.ALL_ID,
        title: intl.t("general.grid-filters-utils-1", {
          var1: (0, _emberInflector.pluralize)(item.dimension.get("name") || "")
        })
      });
      //push the second item for no dimensions
      itemOptions.push({
        id: _const.NO_ID,
        title: intl.t("general.grid-filters-utils-2", {
          var1: (0, _emberInflector.pluralize)(item.dimension.get("name") || "")
        })
      });
      //dynamically create and push all the other items from dimension values
      item.dimension.get("values").forEach(function (option) {
        itemOptions.push({
          id: option.uniqueId,
          title: option.name
        });
      });
      return {
        id: item.dimension.get("id"),
        name: item.dimension.get("name"),
        title: "".concat((0, _emberInflector.pluralize)(item.dimension.get("name"))),
        default: "all",
        options: itemOptions,
        //if its array and it's includes "all" or there's no selection, or if its the string "all" - all option will be checked
        currentSelectedValues: Array.isArray(Ember.get(item, "selectedValue")) ? Ember.get(item, "selectedValue").includes(_const.ALL_ID) || !Ember.get(item, "selectedValue").length ? itemOptions.map(function (o) {
          return o.id;
        }) : Ember.get(item, "selectedValue") : Ember.get(item, "selectedValue") === _const.ALL_ID ? itemOptions.map(function (o) {
          return o.id;
        }) : [Ember.get(item, "selectedValue")],
        isMultiple: true,
        hidden: false,
        isDimension: true,
        shouldFilterBudgetLines: true
      };
    });
  };
  var getGridAccountsFilter = function getGridAccountsFilter() {
    var _viewSelections$get, _viewSelections$get2;
    var viewSelections = new _emberServiceContainer.default().lookup("viewSelections");
    var intl = new _emberServiceContainer.default().lookup("intl");
    var options = viewSelections.get("accounts").map(function (item) {
      return {
        id: item.value,
        title: item.name
      };
    });
    return {
      id: "account",
      name: "gridAccounts",
      title: intl.t("general.grid-filters-utils-3"),
      default: "all",
      options: options,
      isMultiple: true,
      //if there's no selection or there's one with "all", all option will be checked
      currentSelectedValues: (_viewSelections$get = viewSelections.get("gridAccounts")) !== null && _viewSelections$get !== void 0 && _viewSelections$get.includes(_const.ALL_ID) || !((_viewSelections$get2 = viewSelections.get("gridAccounts")) !== null && _viewSelections$get2 !== void 0 && _viewSelections$get2.length) ? viewSelections.get("accounts").map(function (item) {
        return item.value;
      }) : (0, _toConsumableArray2.default)(viewSelections.get("gridAccounts")),
      isAccount: true,
      hidden: false,
      classNames: "dropdown-with-separator",
      shouldFilterBudgetLines: true
    };
  };
  var getGridTagsFilter = function getGridTagsFilter() {
    var _viewSelections$get3, _viewSelections$get4;
    var viewSelections = new _emberServiceContainer.default().lookup("viewSelections");
    var intl = new _emberServiceContainer.default().lookup("intl");
    var options = (0, _toConsumableArray2.default)(viewSelections.get("tagsOptions"));
    return {
      id: "tag",
      name: "gridTags",
      title: intl.t("general.grid-filters-utils-4"),
      default: "all",
      options: options,
      isMultiple: true,
      //if there's no selection or there's one with "all", all option will be checked
      currentSelectedValues: (_viewSelections$get3 = viewSelections.get("gridTags")) !== null && _viewSelections$get3 !== void 0 && _viewSelections$get3.some(function (t) {
        return t.id === _const.ALL_ID;
      }) || !((_viewSelections$get4 = viewSelections.get("gridTags")) !== null && _viewSelections$get4 !== void 0 && _viewSelections$get4.length) ? viewSelections.get("tagsOptions").map(function (item) {
        return item.id;
      }) : viewSelections.get("gridTags").map(function (gridTag) {
        return gridTag.id;
      }),
      isTag: true,
      hidden: false,
      shouldFilterBudgetLines: true
    };
  };
  var isFilterLinesDirty = function isFilterLinesDirty() {
    var viewSelections = new _emberServiceContainer.default().lookup("viewSelections");
    if (!viewSelections.get("isGridView")) {
      return false;
    }
    var gridFilters = getGridFilters();
    var isGridFilteredByData = !!gridFilters.find(function (filter) {
      return filter.shouldFilterBudgetLines && (filter.isMultiple ? filter.currentSelectedValues.length && !filter.currentSelectedValues.includes(_const.ALL_ID) : filter.currentIndex > 0);
    });
    return isGridFilteredByData;
  };
  var getOptions = function getOptions(_ref) {
    var absoluteRoot = _ref.absoluteRoot,
      store = _ref.store,
      scenario = _ref.scenario,
      budgetCurrency = _ref.budgetCurrency;
    var viewSelections = new _emberServiceContainer.default().lookup("viewSelections");
    var budgetService = new _emberServiceContainer.default().lookup("budgetService");
    var budgetViewType = viewSelections.get("gridBudgetOption") === "bud" ? "bud" : "act";
    var actualViewOption = viewSelections.get("gridBudgetOption") === "rollf" ? "rollf" : "ba";
    var gridCurrency = budgetCurrency || (viewSelections.get("gridCurrency") === _const2.GRID_FILTERS.ORIGINAL_CURRENCY_ID ? budgetService.get("selectedRoot.absoluteRoot.budgetAttributes.currency") : viewSelections.get("gridCurrency").toUpperCase());
    var options = _gridUtils.default.getForecastDataOptions({
      store: store,
      absoluteRoot: absoluteRoot
    }, {
      budgetViewType: budgetViewType,
      actualViewOption: actualViewOption,
      selectedPeriod: viewSelections.get("gridPeriod"),
      type: viewSelections.get("gridViewType"),
      currency: gridCurrency,
      scale: viewSelections.get("gridScale"),
      account: viewSelections.get("gridAccounts"),
      selectedScenario: scenario,
      scenario: scenario,
      // need it for forecastUtils.byDimensionEmptyLine
      dimensions: viewSelections.get("gridDimensions"),
      tag: viewSelections.get("gridTags")
    });
    return options;
  };
  var getGridFilters = function getGridFilters() {
    var viewSelections = new _emberServiceContainer.default().lookup("viewSelections");
    var budgetService = new _emberServiceContainer.default().lookup("budgetService");
    var intl = new _emberServiceContainer.default().lookup("intl");
    var budgetCurrencies = budgetService.get("selectedRoot.currencies").slice(1);
    //get options selection lists
    var currencyOptions = budgetCurrencies.map(function (item) {
      return {
        id: item.toLowerCase(),
        title: item
      };
    });
    currencyOptions.push({
      id: _const2.GRID_FILTERS.ORIGINAL_CURRENCY_ID,
      title: intl.t("grid.filters.original_currencies")
    });
    var periodOptions = viewSelections.get("displayPeriodOptions").map(function (item) {
      return {
        id: item.id,
        title: item.text
      };
    });
    var scaleOptions = viewSelections.get("scaleOptions");
    var viewTypeOptions = viewSelections.get("viewTypeOptions");
    var budgetOptions = viewSelections.get("budgetOptions");

    //get current selected option, if not find ( from typing wrong url query) setup defaults
    var verifyQueryStringValue = function verifyQueryStringValue(field, options, defaultValue) {
      var selectedOption = options.findIndex(function (option) {
        var _option$id, _viewSelections$get5;
        return ((_option$id = option.id) === null || _option$id === void 0 ? void 0 : _option$id.toLowerCase()) === ((_viewSelections$get5 = viewSelections.get(field)) === null || _viewSelections$get5 === void 0 ? void 0 : _viewSelections$get5.toLowerCase());
      });
      if (selectedOption === -1) {
        viewSelections.set(field, defaultValue);
        selectedOption = 0;
      }
      return selectedOption;
    };
    var selectedCurrency = verifyQueryStringValue("gridCurrency", currencyOptions, "usd");
    var selectedPeriod = verifyQueryStringValue("gridPeriod", periodOptions, "m");
    var selectedScale = verifyQueryStringValue("gridScale", scaleOptions, "n");
    var selectedViewType = verifyQueryStringValue("gridViewType", viewTypeOptions, "pnl");
    var selectedBudgetOption = verifyQueryStringValue("gridBudgetOption", budgetOptions, "bud");
    var dimensionsFilters = getGridDimensionsFilters();
    var accountsFilter = getGridAccountsFilter();
    var tagsFilter = getGridTagsFilter();

    /*
      Object ready for selection dropdown with the properties:
        id - used for dimensions, queried with id
        name - used for selectors and accses to that field name
        title -  for presentable visible list
        default - if not undefined will mark non default selection with circle, refer to index
        options - selection items in the form of [{ id, title }, {}, {}...]
        currentIndex -  from user selection
        hidden - null or false by defualt
     */
    var nonScenarioBudgetOptionFilter = [{
      id: "grid-budget-option",
      name: "gridBudgetOption",
      title: intl.t("general.grid-filters-utils-5"),
      default: undefined,
      options: budgetOptions,
      currentIndex: selectedBudgetOption,
      classNames: "dropdown-with-separator",
      hidden: false,
      disableHidden: true
    }];
    var filtersResult = [{
      id: "grid-view-type",
      name: "gridViewType",
      title: intl.t("general.grid-filters-utils-6"),
      default: undefined,
      options: viewTypeOptions,
      currentIndex: selectedViewType,
      classNames: budgetService.get("scenario") ? "dropdown-with-separator" : "",
      hidden: false,
      disableHidden: true
    }].concat((0, _toConsumableArray2.default)(budgetService.get("scenario") ? [] : nonScenarioBudgetOptionFilter), [{
      id: "grid-period",
      name: "gridPeriod",
      title: intl.t("general.grid-filters-utils-7"),
      default: undefined,
      options: periodOptions,
      currentIndex: selectedPeriod,
      hidden: false
    }, {
      id: "grid-currency",
      name: "gridCurrency",
      title: intl.t("general.grid-filters-utils-8"),
      default: undefined,
      options: currencyOptions,
      currentIndex: selectedCurrency,
      hidden: false
    }, {
      id: "grid-scale",
      name: "gridScale",
      title: intl.t("general.grid-filters-utils-9"),
      default: undefined,
      options: scaleOptions,
      currentIndex: selectedScale,
      classNames: "dropdown-with-separator",
      hidden: false
    }], (0, _toConsumableArray2.default)(dimensionsFilters), [tagsFilter, accountsFilter]);
    //if visibilty is saved - load hidden prop from it before setting filters state
    var gridFiltersVisibility = viewSelections.get("gridFiltersVisibility");
    filtersResult = filtersResult.map(function (filter) {
      //if dimension is hidden but has other value from sheets or dahsboard - set "all" when getting into grid
      var clearSelectedValues = filter.isDimension && filter.currentSelectedValues.length && gridFiltersVisibility[filter.id];
      if (clearSelectedValues) {
        setEmberDimension(filter.id, "all");
      }
      return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, filter), {}, {
        hidden: gridFiltersVisibility[filter.id],
        currentSelectedValues: clearSelectedValues ? [] : filter.currentSelectedValues
      });
    });
    return filtersResult;
  };
  var _default = _exports.default = {
    getGridFilters: getGridFilters,
    setEmberDimension: setEmberDimension,
    setGridAccounts: setGridAccounts,
    setGridTags: setGridTags,
    getOptions: getOptions,
    isFilterLinesDirty: isFilterLinesDirty
  };
});