define("budgeta/utils/model-store", ["exports", "budgeta/services/ember-service-container", "budgeta/utils/const"], function (_exports, _emberServiceContainer, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/new-module-imports, ember/avoid-leaking-state-in-ember-objects */

  var ModelStoreItem = Ember.Object.extend({
    id: Ember.computed(function () {
      return _const.default.generateUUID();
    }),
    types: [],
    title: null,
    description: null,
    version: "1.0",
    created: new Date(),
    fileName: null,
    selected: false
  });
  var _default = _exports.default = {
    store: [ModelStoreItem.create({
      types: ["SaaS"],
      title: intl.t("general.title_1695611597971"),
      description: "The model calculates the conversion from first time visitor to sign-up users.",
      version: "1.0",
      created: new Date("2017-05-01"),
      fileName: "SaaS",
      unavailableInNonProfitBudget: true
    }), ModelStoreItem.create({
      types: ["Cost of Revenues"],
      title: intl.t("general.title_1695611600217"),
      description: intl.t("general.the_model_calculates_the_cost_of_revenues_based_on_the_monthly_revenue_1698234257366"),
      version: "1.0",
      created: new Date("2017-05-01"),
      fileName: "Cost of Revenues",
      unavailableInNonProfitBudget: true
    }), ModelStoreItem.create({
      types: ["Headcount"],
      title: intl.t("general.title_1695611600206"),
      description: "The model calculates the monthly employee meals expenses, based on headcount, for simplification purposes the assumes that the meals expenses are equal to all employees.",
      version: "1.0",
      created: new Date("2017-05-01"),
      fileName: "Meals"
    }), ModelStoreItem.create({
      types: ["Expenses"],
      title: intl.t("general.title_1695611600195"),
      description: intl.t("general.the_model_calculates_the_monthly_travel_expenses_1698234257366"),
      version: "1.0",
      created: new Date("2017-05-01"),
      fileName: "Travel Abroad"
    }), ModelStoreItem.create({
      types: ["Expenses"],
      title: intl.t("general.title_1695611600184"),
      description: "The model calculates the travel budget by destination and department, including a detailed costs breakdown.",
      version: "1.0",
      created: new Date("2017-09-01"),
      fileName: "Travel by destination and department"
    }), ModelStoreItem.create({
      types: ["Government incentives"],
      title: intl.t("general.title_1695611600173"),
      description: "The model calculates funds from Office of the Chief Scientist (the ‘OCS’), The terms of the OCS grants require the Company to pay the OCS royalties if products are developed using the OCS funding. For those products that are developed and ultimately result in revenues to the Company, royalties are paid to the OCS at a certain rate of net sales of such OCS-funded products.",
      version: "1.0",
      created: new Date("2017-05-01"),
      fileName: "OCS Funding",
      unavailableInNonProfitBudget: true
    }), ModelStoreItem.create({
      types: ["Sales"],
      title: intl.t("general.title_1695611600163"),
      description: intl.t("general.the_model_calculates_the_commissions_based_on_the_monthly_revenue_1698234257366"),
      version: "1.0",
      created: new Date("2017-05-01"),
      fileName: "Sales Commissions",
      unavailableInNonProfitBudget: true
    }), ModelStoreItem.create({
      types: ["Company Indicators"],
      title: intl.t("general.title_1695611600152"),
      description: "The model calculates the Earnings before interest, tax, depreciation and amortization (**EBITDA**) is a measure of a company's operating performance.",
      version: "1.0",
      created: new Date("2017-05-01"),
      fileName: "Ebitda",
      unavailableInNonProfitBudget: true
    }), ModelStoreItem.create({
      types: ["Revenues"],
      title: intl.t("general.title_1695611600142"),
      description: intl.t("general.revenue_model_based_on_the_number_of_customers_1698234257366"),
      version: "1.0",
      created: new Date("2017-05-01"),
      fileName: "Customer based Revenue"
    }), ModelStoreItem.create({
      types: ["Revenues"],
      title: intl.t("general.title_1695611600131"),
      description: intl.t("general.revenue_model_based_on_the_number_of_units_1698234257366"),
      version: "1.0",
      created: new Date("2017-09-01"),
      fileName: "Unit based Revenue"
    })]
  };
});