define("budgeta/generic-file/components/currencies-mapping/currencies-mapping", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/objectWithoutProperties", "@babel/runtime/helpers/esm/objectSpread2", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/slicedToArray", "@babel/runtime/helpers/esm/objectDestructuringEmpty", "@babel/runtime/helpers/esm/extends", "react", "react-redux", "@sibp/ui-components", "lodash/lodash", "budgeta/store/actions/stepsActions", "budgeta/store/actions/budgetActions", "budgeta/services/ember-service-container", "budgeta/utils/const"], function (_exports, _defineProperty2, _objectWithoutProperties2, _objectSpread3, _toConsumableArray2, _slicedToArray2, _objectDestructuringEmpty2, _extends2, _react, _reactRedux, _uiComponents, _lodash, _stepsActions, _budgetActions, _emberServiceContainer, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = CurrenciesMapping;
  var _excluded = ["innerProps"];
  /* eslint-disable no-prototype-builtins, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/no-autofocus */
  var trackEvents = new _emberServiceContainer.default().lookup("trackingEvents");
  var intl = new _emberServiceContainer.default().lookup("intl");
  function CurrenciesMapping(_ref) {
    var rest = (0, _extends2.default)({}, ((0, _objectDestructuringEmpty2.default)(_ref), _ref));
    var defaultCurrenciesToShow = {
      USD: [],
      EUR: [],
      GBP: [],
      CAD: []
    };
    var dispatch = _reactRedux.default.useDispatch();
    var header = {
      text: intl.t("generic-file.step-currencies-mapping.header")
    };
    var options = {
      hideClose: true,
      height: "675px"
    };
    var formattedFile = _reactRedux.default.useSelector(function (state) {
      return state.fileStore.formattedFile;
    });
    var _ReactRedux$useSelect = _reactRedux.default.useSelector(function (state) {
        return state.budgetStore.notAllowedLinesDetails.notAllowedCategory;
      }),
      categoryMismatch = _ReactRedux$useSelect.categoryMismatch,
      categoryEmpty = _ReactRedux$useSelect.categoryEmpty;
    var _ReactRedux$useSelect2 = _reactRedux.default.useSelector(function (state) {
        return state.budgetStore;
      }),
      selectedLines = _ReactRedux$useSelect2.selectedLines,
      _currenciesMapping = _ReactRedux$useSelect2.currenciesMapping,
      _fileCurrenciesToSIBP = _ReactRedux$useSelect2.fileCurrenciesToSIBP,
      currencyColumnKey = _ReactRedux$useSelect2.currencyColumnKey,
      isDimensionMapped = _ReactRedux$useSelect2.isDimensionMapped,
      dimensionUniqueValuesCounter = _ReactRedux$useSelect2.dimensionUniqueValuesCounter,
      mainCurrency = _ReactRedux$useSelect2.mainCurrency;
    var _React$useState = _react.default.useState(_currenciesMapping || defaultCurrenciesToShow),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      currenciesToShow = _React$useState2[0],
      setCurrenciesToShow = _React$useState2[1];
    var _React$useState3 = _react.default.useState([]),
      _React$useState4 = (0, _slicedToArray2.default)(_React$useState3, 2),
      excelCurrenciesOption = _React$useState4[0],
      setExcelCurrenciesOption = _React$useState4[1];
    var _React$useState5 = _react.default.useState(false),
      _React$useState6 = (0, _slicedToArray2.default)(_React$useState5, 2),
      showAddRemoveOptions = _React$useState6[0],
      setAddRemoveOptions = _React$useState6[1];
    var _React$useState7 = _react.default.useState(false),
      _React$useState8 = (0, _slicedToArray2.default)(_React$useState7, 2),
      dropdownIsOpen = _React$useState8[0],
      setDropdownIsOpen = _React$useState8[1];
    var _React$useState9 = _react.default.useState(false),
      _React$useState10 = (0, _slicedToArray2.default)(_React$useState9, 2),
      dropdownOffset = _React$useState10[0],
      setDropdownOffset = _React$useState10[1];
    var _React$useState11 = _react.default.useState(null),
      _React$useState12 = (0, _slicedToArray2.default)(_React$useState11, 2),
      openedIndex = _React$useState12[0],
      setOpenedIndex = _React$useState12[1];
    var currenciesRefs = _react.default.useRef([]);
    var predefinedCurrenciesOptions = Object.keys(_const.default.currency_symbols).map(function (currency) {
      return {
        label: currency,
        value: currency
      };
    }).filter(function (x) {
      return !defaultCurrenciesToShow[x.label];
    });
    _react.default.useEffect(function () {
      var categoryLinesToFilter = [].concat((0, _toConsumableArray2.default)(categoryMismatch), (0, _toConsumableArray2.default)(categoryEmpty));
      var filteredLinedAfterCategoryStep = selectedLines.filter(function (line) {
        return !categoryLinesToFilter.includes(line.location) && formattedFile[line.lineNumber][currencyColumnKey] && (!(typeof formattedFile[line.lineNumber][currencyColumnKey].v === "string") || formattedFile[line.lineNumber][currencyColumnKey].v.trim());
      });

      //all cells are empty or all lines rejected in category step
      if (!filteredLinedAfterCategoryStep.length) {
        handleStepTransition("next", false);
      } else {
        var currenciesMapping = _currenciesMapping ? (0, _objectSpread3.default)({}, _currenciesMapping) : null;

        //when return from category options list may change
        var excelCurrenciesOptions = (0, _toConsumableArray2.default)(new Set(filteredLinedAfterCategoryStep.map(function (line) {
          return formattedFile[line.lineNumber][currencyColumnKey].v.toString().trim();
        })));
        var disabledCurrencies = [];
        if (currenciesMapping) {
          //update currenciesMapping in case changes have been made in category step
          Object.keys(currenciesMapping).forEach(function (currencySymbol) {
            currenciesMapping[currencySymbol] = currenciesMapping[currencySymbol].filter(function (excelCurrency) {
              return excelCurrenciesOptions.includes(excelCurrency.value.toString().trim());
            });
            disabledCurrencies.push.apply(disabledCurrencies, (0, _toConsumableArray2.default)(currenciesMapping[currencySymbol]));
          });
          setCurrenciesToShow(currenciesMapping);
        }
        setExcelCurrenciesOption(excelCurrenciesOptions.map(function (option) {
          return {
            value: option,
            label: option.toString(),
            isDisabled: disabledCurrencies.some(function (disabledOption) {
              return disabledOption.value === option;
            })
          };
        }).sort(function (a, b) {
          return a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1;
        }));
      }
    }, []);
    _react.default.useEffect(function () {
      if (openedIndex !== null && currenciesRefs && currenciesRefs.current[openedIndex]) {
        currenciesRefs.current[openedIndex].scrollIntoViewIfNeeded();
        var currenciesSelectOffsetTop = currenciesRefs.current[openedIndex].getBoundingClientRect().top;
        var modalOffsetTop = currenciesRefs.current[openedIndex].offsetParent.getBoundingClientRect().top;
        setDropdownOffset(currenciesSelectOffsetTop - modalOffsetTop + 55);
      }
    }, [currenciesRefs, openedIndex, currenciesToShow]);
    var handleStepTransition = function handleStepTransition(step, currenciesLinesExist) {
      var activeStep = currenciesLinesExist && step === "next" ? "currenciesMapping" : "accountCategory";
      dispatch(_budgetActions.default.setPreviewActiveStep(activeStep));
      currenciesLinesExist && setMappingDetails();
      switch (step) {
        case "next":
          isAdditionalCurrencyMapped() && trackEvents.trackEvent(trackEvents.events.GENERIC_FILE.CURRENCIES_MAPPED);
          dispatch(_stepsActions.default.nextStep({
            skipDimensionsHierarchyQuestionStep: !isDimensionMapped || !Object.values(dimensionUniqueValuesCounter).some(function (value) {
              return value > 1;
            })
          }));
          break;
        case "prev":
          dispatch(_stepsActions.default.prevStep());
          break;
      }
    };
    var setMappingDetails = function setMappingDetails() {
      var fileCurrenciesToSIBP = {};
      Object.keys(currenciesToShow || {}).forEach(function (SIBPCurrency) {
        (currenciesToShow[SIBPCurrency] || []).forEach(function (excelCurrency) {
          fileCurrenciesToSIBP[excelCurrency.value] = SIBPCurrency;
        });
      });
      if (!_lodash.default.isEqual(_fileCurrenciesToSIBP, fileCurrenciesToSIBP) || !_lodash.default.isEqual(_currenciesMapping, currenciesToShow)) {
        dispatch(_budgetActions.default.setCurrenciesMapping({
          currenciesMapping: currenciesToShow,
          fileCurrenciesToSIBP: fileCurrenciesToSIBP
        }));
        rest.clearDependencies();
      }
    };
    var onCheckboxChanged = function onCheckboxChanged(selected, actionType) {
      var updateSelectionFunction = function updateSelectionFunction() {
        var actionOption = {
          "select-option": function selectOption() {
            return selected || [];
          },
          "deselect-option": function deselectOption() {
            var clearValues = !actionType.option.value ? new Set(selected.map(function (s) {
              return s.value;
            })) : new Set([actionType.option.value]);
            return Object.keys(currenciesToShow).filter(function (o) {
              return currenciesToShow.hasOwnProperty(o) && !clearValues.has(o);
            }).map(function (c) {
              return {
                value: c
              };
            });
          }
        };
        return actionOption[actionType.action]();
      };
      if (actionType.action === "pop-value") return;
      var newCurrenciesList = {};
      var suggestedCurrenciesMap = defaultCurrenciesToShow;
      Object.keys(defaultCurrenciesToShow).forEach(function (currencySynbol) {
        suggestedCurrenciesMap[currencySynbol] = currenciesToShow[currencySynbol];
      });
      if (Array.isArray(selected) && selected.length) {
        var selectedOptions = updateSelectionFunction();
        (actionType.action === "deselect-option" ? selectedOptions : selectedOptions.reverse()).forEach(function (option) {
          newCurrenciesList[option.value] = currenciesToShow[option.value] || [];
        });
      }
      var selectedExcelOptions = _lodash.default.flatten(Object.values((0, _objectSpread3.default)((0, _objectSpread3.default)({}, newCurrenciesList), suggestedCurrenciesMap)));
      var newExcelOptions = excelCurrenciesOption.map(function (option) {
        return (0, _objectSpread3.default)((0, _objectSpread3.default)({}, option), {}, {
          isDisabled: selectedExcelOptions.some(function (selectedOption) {
            return selectedOption.value === option.value;
          })
        });
      });
      setCurrenciesToShow((0, _objectSpread3.default)((0, _objectSpread3.default)({}, newCurrenciesList), suggestedCurrenciesMap));
      setExcelCurrenciesOption(newExcelOptions);
    };
    var CheckboxOption = function CheckboxOption(_ref2) {
      var innerProps = _ref2.innerProps,
        props = (0, _objectWithoutProperties2.default)(_ref2, _excluded);
      var isAllActions = props.data.id === "allAction";
      return isAllActions ? _react.default.createElement("div", innerProps, props.label) : _react.default.createElement("div", innerProps, _react.default.createElement(_uiComponents.default.COMPONENTS.Checkbox, {
        label: props.label,
        dataHook: "option-".concat(props.label),
        checked: props.isSelected
      }));
    };
    var onSelectExcelCurrencyOption = function onSelectExcelCurrencyOption(selected, actionType, currencySymbol) {
      var updateSelectionFunction = function updateSelectionFunction() {
        var actionOption = {
          "select-option": function selectOption() {
            return selected || [];
          },
          "remove-value": function removeValue() {
            return selected || [];
          },
          "pop-value": function popValue() {
            return selected || [];
          },
          "deselect-option": function deselectOption() {
            var clearValues = new Set(selected.map(function (s) {
              return s.value;
            }));
            return (currenciesToShow[currencySymbol] || []).filter(function (o) {
              return !clearValues.has(o.value);
            });
          }
        };
        return actionOption[actionType.action]();
      };
      var newCurrenciesList = (0, _objectSpread3.default)((0, _objectSpread3.default)({}, currenciesToShow), {}, (0, _defineProperty2.default)({}, currencySymbol, updateSelectionFunction()));
      var selectedOptions = _lodash.default.flatten(Object.values(newCurrenciesList));
      var newOptions = excelCurrenciesOption.map(function (option) {
        return (0, _objectSpread3.default)((0, _objectSpread3.default)({}, option), {}, {
          isDisabled: selectedOptions.some(function (selectedOption) {
            return selectedOption.value === option.value;
          })
        });
      });
      setExcelCurrenciesOption(newOptions);
      setCurrenciesToShow(newCurrenciesList);
    };
    var onAddRemoveClick = function onAddRemoveClick() {
      !showAddRemoveOptions && setAddRemoveOptions(!showAddRemoveOptions);
      setDropdownIsOpen(!showAddRemoveOptions);
    };
    var onAddRemoveBlur = function onAddRemoveBlur() {
      setAddRemoveOptions(false);
    };
    var isAdditionalCurrencyMapped = function isAdditionalCurrencyMapped() {
      // if a currency is mapped which is not the main currency
      return Object.keys(currenciesToShow).some(function (currency) {
        return currency.toUpperCase() !== mainCurrency.toUpperCase() && currenciesToShow[currency].length;
      });
    };
    var selectMenuStyle = {
      menu: function menu(base) {
        return (0, _objectSpread3.default)((0, _objectSpread3.default)({}, base), {}, {
          top: "".concat(dropdownOffset, "px"),
          position: "fixed",
          "margin-top": "none",
          width: "302px"
        });
      },
      multiValueLabel: function multiValueLabel(base) {
        return (0, _objectSpread3.default)((0, _objectSpread3.default)({}, base), {}, {
          "font-size": "100%"
        });
      }
    };
    var body = _react.default.createElement("div", {
      className: "currencies-mapping-body-container account-category-body-container"
    }, _react.default.createElement("div", {
      className: "headers"
    }, _react.default.createElement("div", {
      className: "sibp-mapping"
    }, intl.t("generic-file.step-currencies-mapping.sibp-mapping")), _react.default.createElement("div", {
      className: "category"
    }, "".concat(intl.t("generic-file.step-currencies-mapping.currency")))), _react.default.createElement("div", {
      className: "values"
    }, _react.default.createElement("div", {
      className: "add-remove-label"
    }, _react.default.createElement(_uiComponents.default.IMAGES.EyeSvg, null), _react.default.createElement("div", {
      onClick: onAddRemoveClick,
      "data-hook": "add-remove-label"
    }, intl.t("generic-file.step-currencies-mapping.add-remove-select")), _react.default.createElement(_uiComponents.default.IMAGES.ArrowDownSvg, {
      onClick: onAddRemoveClick
    })), showAddRemoveOptions && _react.default.createElement("div", {
      className: "add-remove-select currency-value"
    }, _react.default.createElement(_uiComponents.default.COMPONENTS.Select, {
      dataHook: "add-remove-select",
      controlShouldRenderValue: false,
      hideSelectedOptions: false,
      allowSelectAll: true,
      isMulti: true,
      closeMenuOnSelect: false,
      autoFocus: true,
      autoBlur: true,
      components: {
        Option: CheckboxOption
      },
      options: predefinedCurrenciesOptions,
      menuIsOpen: true,
      onBlur: onAddRemoveBlur,
      placeholder: intl.t("placeholders.searching"),
      isClearable: false,
      onChange: onCheckboxChanged,
      value: predefinedCurrenciesOptions.filter(function (option) {
        return Object.keys(currenciesToShow).includes(option.label);
      }),
      onMenuClose: function onMenuClose() {
        setDropdownIsOpen(false);
      }
    })), _react.default.createElement("div", {
      className: "values currencies ".concat(dropdownIsOpen ? "overflow-hidden" : "overflow-auto")
    }, Object.keys(currenciesToShow).map(function (currencySymbol, i) {
      return _react.default.createElement("div", {
        className: "currency-value",
        key: currencySymbol,
        ref: function ref(element) {
          return currenciesRefs.current[i] = element;
        }
      }, _react.default.createElement("span", {
        className: "name",
        title: currencySymbol
      }, currencySymbol), _react.default.createElement(_uiComponents.default.COMPONENTS.Select, {
        styles: selectMenuStyle,
        dataHook: "".concat(currencySymbol, "-select"),
        menuPlacement: "auto",
        isClearable: false,
        placeholder: "",
        options: excelCurrenciesOption,
        isMulti: true,
        closeMenuOnSelect: false,
        hideSelectedOptions: false,
        allowSelectAll: true,
        onChange: function onChange(selected, actionType) {
          return onSelectExcelCurrencyOption(selected, actionType, currencySymbol);
        },
        value: currenciesToShow[currencySymbol],
        menuPosition: "fixed",
        menuPortalTarget: currenciesRefs.current[i],
        onMenuOpen: function onMenuOpen() {
          setOpenedIndex(i);
          setDropdownIsOpen(true);
        },
        onMenuClose: function onMenuClose() {
          setOpenedIndex(null);
          setDropdownIsOpen(false);
        }
      }));
    }))));
    var buttons = _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      dataHook: "footer-secondary-button",
      className: "footer-button secondary back border big-button",
      text: intl.t("generic-file.back"),
      onClick: function onClick() {
        trackEvents.trackEvent(trackEvents.events.GENERIC_FILE.COMPLETED_CURRENCY_MAPPING);
        handleStepTransition("prev", true);
      }
    }), _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      dataHook: "footer-primary-button",
      className: "footer-button primary approve big-button",
      text: intl.t("generic-file.next"),
      onClick: function onClick() {
        handleStepTransition("next", true);
      },
      disabled: !excelCurrenciesOption.some(function (excelOption) {
        return excelOption.isDisabled;
      })
    }));
    var footer = {
      buttons: buttons
    };
    return _react.default.createElement(_uiComponents.default.COMPONENTS.ModalBase, {
      content: {
        header: header,
        footer: footer,
        body: body
      },
      options: options
    });
  }
});