define("budgeta/generic-file/components/dimensions-mapping/dimensions-mapping", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/slicedToArray", "@babel/runtime/helpers/esm/objectDestructuringEmpty", "@babel/runtime/helpers/esm/extends", "@sibp/ui-components", "react-redux", "budgeta/store/actions/stepsActions", "budgeta/services/ember-service-container", "react", "budgeta/store/actions/budgetActions", "budgeta/admin/store/actions/notifActions", "budgeta/utils/const", "lodash/lodash"], function (_exports, _toConsumableArray2, _slicedToArray2, _objectDestructuringEmpty2, _extends2, _uiComponents, _reactRedux, _stepsActions, _emberServiceContainer, _react, _budgetActions, _notifActions, _const, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MAX_SUGGESTED_DIMENSIONS_ALLOWED = void 0;
  _exports.default = DimensionsMapping;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var trackEvents = new _emberServiceContainer.default().lookup("trackingEvents");
  var MAX_SUGGESTED_DIMENSIONS_ALLOWED = _exports.MAX_SUGGESTED_DIMENSIONS_ALLOWED = 16;
  function DimensionsMapping(_ref) {
    var rest = (0, _extends2.default)({}, ((0, _objectDestructuringEmpty2.default)(_ref), _ref));
    var dispatch = _reactRedux.default.useDispatch();
    var noty = _reactRedux.default.useSelector(function (state) {
      return state.notifStore.noty;
    });
    var previusSuggestedDimensions = _reactRedux.default.useSelector(function (state) {
      return state.budgetStore.suggestedDimensions;
    }) || intl.t("generic-file.step-dimensions-mapping.suggested-dimensions").split(",").map(function (dimensionName) {
      return {
        dimensionName: dimensionName,
        id: _const.default.generateUUID()
      };
    });
    var previousSelectedDimensions = _reactRedux.default.useSelector(function (state) {
      return state.budgetStore.selectedDimensions;
    }) || [];
    var _React$useState = _react.default.useState(previusSuggestedDimensions),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      suggestedDimensions = _React$useState2[0],
      setSuggestedDimensions = _React$useState2[1];
    var _React$useState3 = _react.default.useState(previousSelectedDimensions),
      _React$useState4 = (0, _slicedToArray2.default)(_React$useState3, 2),
      selectedDimensions = _React$useState4[0],
      setSelectedDimensions = _React$useState4[1];
    var _React$useState5 = _react.default.useState(false),
      _React$useState6 = (0, _slicedToArray2.default)(_React$useState5, 2),
      nextButtonEnabled = _React$useState6[0],
      setNextButtonEnabled = _React$useState6[1];
    var _React$useState7 = _react.default.useState(false),
      _React$useState8 = (0, _slicedToArray2.default)(_React$useState7, 2),
      notyVisibility = _React$useState8[0],
      setNotyVisible = _React$useState8[1];
    var _React$useState9 = _react.default.useState(false),
      _React$useState10 = (0, _slicedToArray2.default)(_React$useState9, 2),
      popupOpen = _React$useState10[0],
      setPopupOpen = _React$useState10[1];
    var predefindFields = intl.t("generic-file.step-dimensions-mapping.predefined-fields").split("\n");
    var options = {
      hideClose: true,
      height: "675px"
    };
    _react.default.useEffect(function () {
      var showNoty = selectedDimensions.some(function (dimension) {
        return !isValidDimensionName(dimension.dimensionName, dimension.id);
      });
      setNextButtonEnabled(!showNoty && selectedDimensions.length > 0);
      setNotyVisible(showNoty);
    }, [selectedDimensions]);
    var addNewDimension = function addNewDimension() {
      if (suggestedDimensions.length < MAX_SUGGESTED_DIMENSIONS_ALLOWED) {
        var newDimension = {
          dimensionName: intl.t("generic-file.step-dimensions-mapping.dimension-default-name"),
          id: _const.default.generateUUID()
        };
        setSuggestedDimensions([].concat((0, _toConsumableArray2.default)(suggestedDimensions), [newDimension]));
        setSelectedDimensions([].concat((0, _toConsumableArray2.default)(selectedDimensions), [newDimension]));
      }
    };
    var handleNextButton = function handleNextButton() {
      if (!_lodash.default.isEqual(selectedDimensions, previousSelectedDimensions) || !_lodash.default.isEqual(suggestedDimensions, previusSuggestedDimensions)) {
        dispatch(_budgetActions.default.setSelectedDimensions({
          selectedDimensions: selectedDimensions,
          suggestedDimensions: suggestedDimensions
        }));
        rest.clearDependencies();
      }
      trackEvents.trackEvent(trackEvents.events.GENERIC_FILE.COMPLETED_DIMENSIONS_MAPPING);
      dispatch(_stepsActions.default.nextStep());
    };
    var isValidDimensionName = function isValidDimensionName(dimensionName, id) {
      return !isIncludeInSelected(id) || !predefindFields.some(function (x) {
        return x.toLowerCase() === dimensionName.toLowerCase();
      }) && !isDuplicateDimension(dimensionName);
    };
    var isDuplicateDimension = function isDuplicateDimension(dimensionName) {
      var counter = 0;
      var dimensionNameLowerCase = dimensionName.toLowerCase();
      for (var i = 0; i < selectedDimensions.length; i++) {
        counter += selectedDimensions[i].dimensionName.toLowerCase() === dimensionNameLowerCase ? 1 : 0;
        if (counter > 1) {
          return true;
        }
      }
      return false;
    };
    var isIncludeInSelected = function isIncludeInSelected(id) {
      return selectedDimensions.some(function (dimension) {
        return dimension.id === id;
      });
    };
    var dimensionCheckedHandler = function dimensionCheckedHandler(checked, dimensionName, index) {
      if (checked) {
        setSelectedDimensions([].concat((0, _toConsumableArray2.default)(selectedDimensions), [{
          dimensionName: dimensionName,
          id: index
        }]));
      } else {
        var newSelectedDimensions = selectedDimensions.filter(function (dimension) {
          return dimension.id !== index;
        });
        setSelectedDimensions(newSelectedDimensions);
      }
    };
    var dimensionEditedHandler = function dimensionEditedHandler(value, id) {
      var newValue = value === "" ? new String(intl.t("generic-file.step-dimensions-mapping.dimension-default-name")) : value;
      var newSuggested = updatedDimensionsArray(suggestedDimensions, id, newValue);
      var newSelected = updatedDimensionsArray(selectedDimensions, id, newValue);
      setSuggestedDimensions(newSuggested);
      setSelectedDimensions(newSelected);
    };
    var updatedDimensionsArray = function updatedDimensionsArray(dimensions, id, newValue) {
      var newDimensions = dimensions.map(function (currDimension) {
        return currDimension.id === id ? {
          dimensionName: newValue,
          id: currDimension.id
        } : {
          dimensionName: currDimension.dimensionName,
          id: currDimension.id
        };
      });
      return newDimensions;
    };
    var onTooltipEnter = function onTooltipEnter() {
      setPopupOpen(true);
    };
    var onTooltipLeave = function onTooltipLeave() {
      setPopupOpen(false);
    };
    var predefinedPopupContent = _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("div", {
      className: "predefined-description"
    }, intl.t("generic-file.step-dimensions-mapping.predefined-fields-description")), _react.default.createElement("div", {
      className: "fields"
    }, predefindFields.map(function (field) {
      return _react.default.createElement("div", {
        key: field,
        className: "field"
      }, "".concat(field));
    })));
    var header = {
      text: intl.t("generic-file.step-dimensions-mapping.header"),
      options: {}
    };
    var tooltipPopup = _react.default.createElement("div", {
      className: "tooltip-icon ".concat(popupOpen && "popup-open")
    }, _react.default.createElement(_uiComponents.default.COMPONENTS.Tooltip, null));
    var body = _react.default.createElement("div", {
      className: "dimensions-mapping-body-container"
    }, _react.default.createElement("div", {
      className: "description"
    }, intl.t("generic-file.step-dimensions-mapping.description")), _react.default.createElement("div", {
      className: "adding-part"
    }, _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      className: "add-new-dimension with-border secondary back",
      dataHook: "add-dimension-button",
      text: intl.t("generic-file.step-dimensions-mapping.add-dimension-button"),
      onClick: addNewDimension,
      disabled: suggestedDimensions.length === MAX_SUGGESTED_DIMENSIONS_ALLOWED
    }), _react.default.createElement("div", {
      className: "predefined-label"
    }, intl.t("generic-file.step-dimensions-mapping.predefined-sibp-fields-label")), _react.default.createElement(_uiComponents.default.COMPONENTS.PopupWithLabel, {
      dataHook: "predefined-sibp-fields",
      className: "predefined-fields",
      tooltipContent: tooltipPopup,
      popupContent: predefinedPopupContent,
      onEnter: onTooltipEnter,
      onLeave: onTooltipLeave
    })), _react.default.createElement("div", {
      className: "suggested-dimensions-grid-container"
    }, suggestedDimensions.map(function (dimension) {
      return _react.default.createElement("div", {
        key: dimension.id,
        className: "checkbox-label grid-item"
      }, _react.default.createElement(_uiComponents.default.COMPONENTS.Checkbox, {
        className: isValidDimensionName(dimension.dimensionName, dimension.id) ? "" : "invalid-dimension",
        label: dimension.dimensionName,
        dataHook: "".concat(intl.t("generic-file.step-dimensions-mapping.suggested-dimension-datahook"), "-").concat(dimension.id),
        checked: isIncludeInSelected(dimension.id),
        editable: true,
        onChange: function onChange(checked) {
          return dimensionCheckedHandler(checked, dimension.dimensionName, dimension.id);
        },
        onBlur: function onBlur(newValue) {
          return dimensionEditedHandler(newValue, dimension.id);
        }
      }));
    })));
    var buttons = _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      className: "footer-button secondary back big-button",
      dataHook: "footer-secondary-button",
      text: intl.t("generic-file.back"),
      onClick: function onClick() {
        if (!_lodash.default.isEqual(selectedDimensions, previousSelectedDimensions) || !_lodash.default.isEqual(suggestedDimensions, previusSuggestedDimensions)) {
          dispatch(_budgetActions.default.setSelectedDimensions({
            selectedDimensions: selectedDimensions,
            suggestedDimensions: suggestedDimensions
          }));
          rest.clearDependencies();
        }
        dispatch(_stepsActions.default.prevStep());
      }
    }), _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      dataHook: "footer-primary-button",
      className: "footer-button primary approve big-button",
      text: intl.t("generic-file.next"),
      onClick: handleNextButton,
      disabled: !nextButtonEnabled
    }));
    var footer = {
      buttons: buttons
    };
    var closeNoty = function closeNoty() {
      dispatch(_notifActions.default.hideNoty());
    };
    return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_uiComponents.default.COMPONENTS.Noty, {
      dataHook: "noty-dimensions-mapping",
      text: intl.t("generic-file.step-dimensions-mapping.noty-error-message"),
      visible: notyVisibility,
      errorLevel: noty.errorLevel,
      onClose: closeNoty
    }), _react.default.createElement(_uiComponents.default.COMPONENTS.ModalBase, {
      content: {
        header: header,
        footer: footer,
        body: body
      },
      options: options
    }));
  }
});