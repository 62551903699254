define("budgeta/generic-file/components/account-category/account-category-step", ["exports", "@babel/runtime/helpers/esm/createForOfIteratorHelper", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/objectSpread2", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/slicedToArray", "@babel/runtime/helpers/esm/objectWithoutProperties", "react", "@sibp/ui-components", "react-redux", "lodash/lodash", "budgeta/utils/const", "budgeta/store/actions/stepsActions", "budgeta/store/actions/budgetActions", "budgeta/services/ember-service-container"], function (_exports, _createForOfIteratorHelper2, _defineProperty2, _objectSpread4, _toConsumableArray2, _slicedToArray2, _objectWithoutProperties2, _react, _uiComponents, _reactRedux, _lodash, _const, _stepsActions, _budgetActions, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = AccountCategory;
  var _excluded = ["endTableAction"];
  /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
  var intl = new _emberServiceContainer.default().lookup("intl");
  var trackEvents = new _emberServiceContainer.default().lookup("trackingEvents");
  function AccountCategory(_ref) {
    var endTableAction = _ref.endTableAction,
      rest = (0, _objectWithoutProperties2.default)(_ref, _excluded);
    var dispatch = _reactRedux.default.useDispatch();
    var options = {
      hideClose: true,
      height: "676px"
    };
    var stepName = _reactRedux.default.useSelector(function (state) {
      return state.stepsStore.currentStep;
    });
    var header = {
      text: intl.t("generic-file.step-account-category.header")
    };
    var data = _reactRedux.default.useSelector(function (state) {
      return state.fileStore.formattedFile;
    });
    var _ReactRedux$useSelect = _reactRedux.default.useSelector(function (state) {
        return state.budgetStore;
      }),
      headerLineNumber = _ReactRedux$useSelect.headerLineNumber,
      nameColumnIsAccount = _ReactRedux$useSelect.nameColumnIsAccount,
      nameColumn = _ReactRedux$useSelect.nameColumn,
      selectedLines = _ReactRedux$useSelect.selectedLines,
      columnMapping = _ReactRedux$useSelect.columnMapping,
      categoryMapping = _ReactRedux$useSelect.categoryMapping,
      accountColumnKey = _ReactRedux$useSelect.accountColumnKey,
      budgetRootId = _ReactRedux$useSelect.budgetRootId,
      budgetRootType = _ReactRedux$useSelect.budgetRootType,
      _notAllowedLinesDetails = _ReactRedux$useSelect.notAllowedLinesDetails;
    var notAllowedLinesIndexes = _reactRedux.default.useSelector(function (state) {
      return state.budgetStore.notAllowedLinesDetails.notAllowedCategory;
    });
    var _ReactRedux$useSelect2 = _reactRedux.default.useSelector(function (state) {
        return state.budgetStore;
      }),
      isDimensionMapped = _ReactRedux$useSelect2.isDimensionMapped,
      currencyColumnKey = _ReactRedux$useSelect2.currencyColumnKey,
      dimensionUniqueValuesCounter = _ReactRedux$useSelect2.dimensionUniqueValuesCounter;
    var _React$useState = _react.default.useState(categoryMapping || {}),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      currCategoryMap = _React$useState2[0],
      setCategoryMap = _React$useState2[1];
    var _React$useState3 = _react.default.useState([]),
      _React$useState4 = (0, _slicedToArray2.default)(_React$useState3, 2),
      list = _React$useState4[0],
      setList = _React$useState4[1];
    var _React$useState5 = _react.default.useState(""),
      _React$useState6 = (0, _slicedToArray2.default)(_React$useState5, 2),
      columnHeaderName = _React$useState6[0],
      setColumnHeaderName = _React$useState6[1];
    var _React$useState7 = _react.default.useState({}),
      _React$useState8 = (0, _slicedToArray2.default)(_React$useState7, 2),
      accountToCategoriesMap = _React$useState8[0],
      setAccountToCategoriesMap = _React$useState8[1];
    var _React$useState9 = _react.default.useState({}),
      _React$useState10 = (0, _slicedToArray2.default)(_React$useState9, 2),
      categoryToLines = _React$useState10[0],
      setCategoryToLines = _React$useState10[1];
    var _React$useState11 = _react.default.useState([]),
      _React$useState12 = (0, _slicedToArray2.default)(_React$useState11, 2),
      linesWithEmptyValue = _React$useState12[0],
      setLinesWithEmptyValue = _React$useState12[1];
    var _React$useState13 = _react.default.useState([]),
      _React$useState14 = (0, _slicedToArray2.default)(_React$useState13, 2),
      mismatchList = _React$useState14[0],
      setMismatchList = _React$useState14[1];
    var _React$useState15 = _react.default.useState(false),
      _React$useState16 = (0, _slicedToArray2.default)(_React$useState15, 2),
      allMissingLinesRemoved = _React$useState16[0],
      setMissingFlag = _React$useState16[1];
    var _React$useState17 = _react.default.useState(false),
      _React$useState18 = (0, _slicedToArray2.default)(_React$useState17, 2),
      dropdownIsOpen = _React$useState18[0],
      setDropdownIsOpen = _React$useState18[1];
    var _React$useState19 = _react.default.useState(false),
      _React$useState20 = (0, _slicedToArray2.default)(_React$useState19, 2),
      dropdownOffset = _React$useState20[0],
      setDropdownOffset = _React$useState20[1];
    var _React$useState21 = _react.default.useState(null),
      _React$useState22 = (0, _slicedToArray2.default)(_React$useState21, 2),
      openedIndex = _React$useState22[0],
      setOpenedIndex = _React$useState22[1];
    var categoriesRefs = _react.default.useRef([]);
    var budgetGroups = _const.default.BUDGET_TYPE_TO_GROUPS[budgetRootType];

    //search for account that mapped to few categories (example for mismatch: map "rev" and "derived expense" to Revenue. file: line 1: account1 - rev, line 2: account1 - derived expense )
    var getMismatchLines = function getMismatchLines(accountToCategories, categoryMapping) {
      var excelToCategoryMap = {};

      //excelToCategoryMap => {rev: "group-revenue", simple subscription: "group-revenue", expense: "group-expense" }
      Object.keys(categoryMapping).forEach(function (category) {
        categoryMapping[category].forEach(function (excelCategory) {
          excelToCategoryMap[excelCategory.value] = category;
        });
      });

      //accountToCategories - {account1 : { locations, account, accountsCategories },..., account2: { locations, account, accountsCategories } }
      var mismatchAccountsLines = Object.keys(accountToCategories).reduce(function (mismatchAccountsLines, accountKey) {
        var categoriesMapped = new Set(); //will hold categries curr account mapped
        var excelAccountCategories = (0, _toConsumableArray2.default)(accountToCategories[accountKey].accountsCategory); //excel categories of current account

        //if some account is associate to a few categories
        var mismatchAccount = excelAccountCategories.some(function (excelCategory) {
          excelToCategoryMap[excelCategory] && categoriesMapped.add(excelToCategoryMap[excelCategory]);
          return categoriesMapped.size > 1;
        });
        if (mismatchAccount) {
          mismatchAccountsLines = mismatchAccountsLines.concat((0, _toConsumableArray2.default)(accountToCategories[accountKey].locations));
        }
        return mismatchAccountsLines;
      }, []);
      return mismatchAccountsLines;
    };
    var getNoMappingLines = function getNoMappingLines(catMap, lines) {
      var locations = [];
      lines.forEach(function (_ref2) {
        var value = _ref2.value;
        locations.push.apply(locations, (0, _toConsumableArray2.default)(catMap[value]));
      });
      return locations;
    };
    _react.default.useEffect(function () {
      dispatch(_budgetActions.default.setPreviewActiveStep("accountCategory"));
      var _map = ["accountGroup", "currency", "accountId"].map(function (att) {
          var col = columnMapping.find(function (x) {
            return x.selectedAttribute === att;
          });
          return col && col.key.replace(/[0-9]/g, "");
        }),
        _map2 = (0, _slicedToArray2.default)(_map, 3),
        categoryColumn = _map2[0],
        currencyColumn = _map2[1],
        accountColumn = _map2[2];
      accountColumn = nameColumnIsAccount ? nameColumn.replace(/[0-9]/g, "") : accountColumn;
      var accMap = {};
      var catMap = {};
      var emptyValueLines = [];
      selectedLines.forEach(function (_ref3) {
        var lineNumber = _ref3.lineNumber,
          location = _ref3.location;
        var currentLine = data[lineNumber];
        var currentLineCurrency = currentLine[currencyColumn],
          currentLineAccount = currentLine[accountColumn] && (currentLine[accountColumn].w || currentLine[accountColumn].v).toString().trim(),
          currentLineCategory = currentLine[categoryColumn];
        if (currentLineAccount) {
          accMap[currentLineAccount] = accMap[currentLineAccount] || {
            locations: new Set(),
            currency: new Set(),
            accountsCategory: new Set()
          };
          accMap[currentLineAccount].locations.add(location);
          currentLineCurrency && accMap[currentLineAccount].currency.add(currentLineCurrency.v.toString().trim());
          currentLineCategory && accMap[currentLineAccount].accountsCategory.add(currentLineCategory.v.toString().trim());
          if (currentLineCategory && catMap[currentLineCategory.v.toString().trim()]) {
            catMap[currentLineCategory.v.toString().trim()].push(location);
          } else if (currentLineCategory) {
            catMap[currentLineCategory.v.toString().trim()] = [location];
          } else {
            emptyValueLines.push(location);
          }
        }
      });
      var options = _lodash.default.uniq(_lodash.default.flatten(Object.values(accMap).map(function (x) {
        return (0, _toConsumableArray2.default)(x.accountsCategory);
      })).map(function (x) {
        return {
          label: x.toString(),
          value: x
        };
      }), "value");
      var allCategories = [].concat((0, _toConsumableArray2.default)(budgetGroups.profit), (0, _toConsumableArray2.default)(budgetGroups.balanceCategories));
      if (categoryMapping) {
        options = options.map(function (x) {
          return (0, _objectSpread4.default)((0, _objectSpread4.default)({}, x), {}, {
            isDisabled: _lodash.default.flatten(Object.values(currCategoryMap)).map(function (x) {
              return x && x.value;
            }).includes(x.value)
          });
        });
        var updatedCategoryMap = {};
        var listOfMisMatches = new Set();
        Object.entries(categoryMapping).forEach(function (_ref4) {
          var _ref5 = (0, _slicedToArray2.default)(_ref4, 2),
            sectionKey = _ref5[0],
            values = _ref5[1];
          var currentSection = allCategories.find(function (x) {
            return (x.type || x.name) === sectionKey;
          });
          var listOfValues = values && values.map(function (x) {
            return x.value;
          });
          var currentSelected = options.reduce(function (list, opt) {
            if (listOfValues && listOfValues.includes(opt.value)) {
              list.push({
                value: opt.value,
                label: opt.label
              });
            }
            return list;
          }, []);
          updatedCategoryMap = (0, _objectSpread4.default)((0, _objectSpread4.default)({}, updatedCategoryMap), {}, (0, _defineProperty2.default)({}, currentSection.type || currentSection.name, currentSelected));
        });
        if (notAllowedLinesIndexes) {
          var categoryMismatch = getMismatchLines(accMap, updatedCategoryMap);
          var updatedMismatchList = _lodash.default.intersection(categoryMismatch, notAllowedLinesIndexes.categoryMismatch);
          var valuesList = Object.values(accMap);
          updatedMismatchList.forEach(function (mismatch) {
            var acc = valuesList.find(function (x) {
              return x.locations.has(mismatch);
            });
            var accValues = Array.from(acc.accountsCategory);
            accValues.forEach(listOfMisMatches.add, listOfMisMatches);
          });
          var categoryEmpty = getNoMappingLines(catMap, options.filter(function (x) {
            return !x.isDisabled;
          }));
          if (!categoryEmpty.length) {
            setMissingFlag(true);
          }
        }
        setMismatchList(Array.from(listOfMisMatches));
        setCategoryMap(updatedCategoryMap);
      }
      setLinesWithEmptyValue(emptyValueLines);
      setCategoryToLines(catMap);
      setList(options.sort(function (a, b) {
        return a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1;
      }));
      setAccountToCategoriesMap(accMap);
      data[headerLineNumber][categoryColumn] && data[headerLineNumber][categoryColumn].v && setColumnHeaderName(data[headerLineNumber][categoryColumn].v.toString().trim());
    }, []);
    _react.default.useEffect(function () {
      if (openedIndex != null && categoriesRefs && categoriesRefs.current[openedIndex]) {
        categoriesRefs.current[openedIndex].scrollIntoViewIfNeeded();
        var categoryOffsetTop = categoriesRefs.current[openedIndex].getBoundingClientRect().top;
        var modalOffsetTop = categoriesRefs.current[openedIndex].offsetParent.getBoundingClientRect().top;
        setDropdownOffset(categoryOffsetTop - modalOffsetTop + 55);
        setDropdownIsOpen(true);
      }
    }, [categoriesRefs, openedIndex, currCategoryMap]);
    var addLinesToSelectedList = function addLinesToSelectedList(mismatchLines) {
      var mismatchLinesSet = new Set(mismatchLines);
      Object.values(currCategoryMap).forEach(function (fileCategoriesOfCurrGroup) {
        return (fileCategoriesOfCurrGroup || []).forEach(function (category) {
          category.lines = categoryToLines[category.value].filter(function (line) {
            return !mismatchLinesSet.has(line);
          });
        });
      });
    };
    var isCategoryMappingChanged = function isCategoryMappingChanged(currMapping, prevMapping) {
      if (!prevMapping) return true;
      var allCategories = [].concat((0, _toConsumableArray2.default)(budgetGroups.profit), (0, _toConsumableArray2.default)(budgetGroups.balanceCategories));
      var curr, prev;
      var _iterator = (0, _createForOfIteratorHelper2.default)(allCategories),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var category = _step.value;
          curr = currMapping[category.type || category.name];
          prev = prevMapping[category.type || category.name];
          if (!curr && prev || curr && !prev || curr && prev && (curr.length !== prev.length || !_lodash.default.isEqual(prev.sort(function (a, b) {
            return a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1;
          }), curr.sort(function (a, b) {
            return a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1;
          })))) {
            return true;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      return false;
    };
    var isBalanceCategoryMapped = function isBalanceCategoryMapped() {
      // if at least one balance category is mapped
      var balanceCategoryNames = budgetGroups.balanceCategories.map(function (category) {
        return category.name;
      });
      return Object.keys(currCategoryMap).some(function (mappedCategory) {
        return balanceCategoryNames.includes(mappedCategory) && currCategoryMap[mappedCategory].length;
      });
    };
    var getCategoryDroppedLinesByType = function getCategoryDroppedLinesByType() {
      var categoryMismatch = getMismatchLines(accountToCategoriesMap, currCategoryMap);
      var categoryEmpty = getNoMappingLines(categoryToLines, list.filter(function (x) {
        return !x.isDisabled;
      }));
      return {
        categoryMismatch: categoryMismatch,
        categoryEmpty: categoryEmpty
      };
    };
    var handleNext = function handleNext(categoryMismatch, categoryEmpty) {
      addLinesToSelectedList(categoryMismatch);
      if (!_lodash.default.isEqual(_notAllowedLinesDetails.notAllowedCategory, {
        categoryMismatch: categoryMismatch,
        categoryEmpty: categoryEmpty,
        linesWithEmptyValue: linesWithEmptyValue
      }) || isCategoryMappingChanged(currCategoryMap, categoryMapping)) {
        isBalanceCategoryMapped() && trackEvents.trackEvent(trackEvents.events.GENERIC_FILE.BALANCE_SHEET_MAPPED);
        dispatch(_budgetActions.default.setNotAllowedCategory({
          categoryMismatch: categoryMismatch,
          categoryEmpty: categoryEmpty,
          linesWithEmptyValue: linesWithEmptyValue
        }));
        dispatch(_budgetActions.default.setCategoryMapping(currCategoryMap));
        endTableAction(stepName, {
          rootId: budgetRootId,
          categoryMapping: currCategoryMap,
          accountColumnKey: accountColumnKey
        });
        rest.clearDependencies();
      }
    };
    var handleNextToError = function handleNextToError() {
      trackEvents.trackEvent(trackEvents.events.GENERIC_FILE.CATEGORY_ERROR_PREVIEW);
      var _getCategoryDroppedLi = getCategoryDroppedLinesByType(),
        categoryMismatch = _getCategoryDroppedLi.categoryMismatch,
        categoryEmpty = _getCategoryDroppedLi.categoryEmpty;
      handleNext(categoryMismatch, categoryEmpty);
      dispatch(_stepsActions.default.accountCategorySkip(true));
      dispatch(_stepsActions.default.nextStep({
        hasError: "skip",
        skipCurrencyStep: !currencyColumnKey,
        skipDimensionsHierarchyQuestionStep: !isDimensionMapped || !Object.values(dimensionUniqueValuesCounter).some(function (value) {
          return value > 1;
        })
      }));
    };
    var handleNextToFollowingStep = function handleNextToFollowingStep() {
      trackEvents.trackEvent(trackEvents.events.GENERIC_FILE.COMPLETED_ACCOUNT_GROUP);
      var _getCategoryDroppedLi2 = getCategoryDroppedLinesByType(),
        categoryMismatch = _getCategoryDroppedLi2.categoryMismatch,
        categoryEmpty = _getCategoryDroppedLi2.categoryEmpty;
      handleNext(categoryMismatch, categoryEmpty);
      dispatch(_stepsActions.default.nextStep({
        hasError: categoryMismatch.length > 0 || categoryEmpty.length > 0,
        skipCurrencyStep: !currencyColumnKey,
        skipDimensionsHierarchyQuestionStep: !isDimensionMapped || !Object.values(dimensionUniqueValuesCounter).some(function (value) {
          return value > 1;
        })
      }));
    };
    var onChangeHandler = function onChangeHandler(selected, actionType, category) {
      var updateSelectionFunction = function updateSelectionFunction() {
        var actionOption = {
          "select-option": function selectOption() {
            return selected.map(function (s) {
              return {
                label: s.label,
                value: s.value
              };
            });
          },
          "remove-value": function removeValue() {
            return selected || [];
          },
          "pop-value": function popValue() {
            return selected || [];
          },
          "deselect-option": function deselectOption() {
            var clearValues = new Set(selected.map(function (s) {
              return s.value;
            }));
            return (currCategoryMap[category.type || category.name] || []).filter(function (o) {
              return !clearValues.has(o.value);
            });
          }
        };
        return actionOption[actionType.action]();
      };
      var updatedCategoryMap = (0, _objectSpread4.default)((0, _objectSpread4.default)({}, currCategoryMap), {}, (0, _defineProperty2.default)({}, category.type || category.name, updateSelectionFunction()));
      setList(list.map(function (x) {
        return (0, _objectSpread4.default)((0, _objectSpread4.default)({}, x), {}, {
          isDisabled: _lodash.default.flatten(Object.values(updatedCategoryMap)).map(function (x) {
            return x && x.value;
          }).includes(x.value)
        });
      }));
      setCategoryMap(updatedCategoryMap);
    };
    var selectMenuStyle = {
      menu: function menu(base) {
        return (0, _objectSpread4.default)((0, _objectSpread4.default)({}, base), {}, {
          top: "".concat(dropdownOffset, "px"),
          width: "302px",
          position: "fixed",
          "margin-top": "none",
          "margin-left": "5px"
        });
      },
      multiValueLabel: function multiValueLabel(base) {
        return (0, _objectSpread4.default)((0, _objectSpread4.default)({}, base), {}, {
          "font-size": "100%"
        });
      }
    };
    var body = _react.default.createElement("div", {
      className: "account-category-body-container"
    }, _react.default.createElement("div", {
      className: "headers"
    }, _react.default.createElement("div", {
      className: "sibp-mapping"
    }, intl.t("generic-file.step-account-category.sibp-mapping")), _react.default.createElement("div", {
      className: "category"
    }, "".concat(intl.t("generic-file.step-account-category.account-category"), " ").concat(columnHeaderName ? "(".concat(columnHeaderName, ")") : "")), notAllowedLinesIndexes.categoryEmpty && notAllowedLinesIndexes.categoryEmpty.length && !allMissingLinesRemoved || mismatchList.length ? _react.default.createElement(_uiComponents.default.COMPONENTS.PopupWithLabel, {
      dataHook: "accounts-category-error-popup",
      className: "error-container",
      tooltipContent: _react.default.createElement(_uiComponents.default.IMAGES.WarningSvg, null),
      popupContent: _react.default.createElement("div", {
        className: "message"
      }, notAllowedLinesIndexes.categoryEmpty.length && !allMissingLinesRemoved ? _react.default.createElement("div", {
        className: "missing-msg"
      }, _react.default.createElement("div", {
        className: "desc"
      }, intl.t("generic-file.step-account-category.missing-values-msg"))) : "", mismatchList.length ? _react.default.createElement("ul", {
        className: "mismatch"
      }, _react.default.createElement("div", {
        className: "header"
      }, intl.t("generic-file.step-account-category.mismatch-values")), mismatchList.map(function (category) {
        return _react.default.createElement("li", {
          key: category,
          className: "option"
        }, category);
      })) : "", _react.default.createElement("div", {
        className: "jump",
        onClick: handleNextToError
      }, _react.default.createElement("span", {
        className: "name"
      }, intl.t("generic-file.preview"))))
    }) : ""), _react.default.createElement("div", {
      className: "values ".concat(dropdownIsOpen ? "overflow-hidden" : "overflow-auto")
    }, _react.default.createElement(_uiComponents.default.COMPONENTS.Collapse, {
      classNames: "profit-and-loss",
      label: budgetRootType === _const.default.IMPORT_GENERIC_BUDGET_TYPE.COMPANY_ROOT_TYPE ? intl.t("generic-file.step-budget-properties.company-type-profit-section-title") : intl.t("generic-file.step-budget-properties.non-profit-type-profit-section-title"),
      labelClass: "category",
      dataHook: "account-category-profit",
      collapsed: true
    }, budgetGroups.profit.map(function (category, i) {
      return _react.default.createElement("div", {
        className: "category-value",
        key: category.type,
        ref: function ref(element) {
          return categoriesRefs.current[i] = element;
        }
      }, _react.default.createElement("span", {
        className: "name",
        title: category.text
      }, category.text), _react.default.createElement(_uiComponents.default.COMPONENTS.Select, {
        styles: selectMenuStyle,
        dataHook: "".concat(category.type, "-select"),
        isClearable: false,
        placeholder: "",
        options: list,
        isMulti: true,
        closeMenuOnSelect: false,
        hideSelectedOptions: false,
        allowSelectAll: true,
        autoBlur: true,
        onChange: function onChange(selected, actionType) {
          return onChangeHandler(selected, actionType, category);
        },
        value: currCategoryMap[category.type],
        menuPosition: "fixed",
        menuPortalTarget: categoriesRefs.current[i],
        onMenuOpen: function onMenuOpen() {
          setOpenedIndex(i);
          setDropdownIsOpen(true);
        },
        onMenuClose: function onMenuClose() {
          setOpenedIndex(null);
          setDropdownIsOpen(false);
        }
      }));
    })), _react.default.createElement(_uiComponents.default.COMPONENTS.Collapse, {
      classNames: "balance-sheet",
      label: "Balance Sheet",
      labelClass: "category",
      dataHook: "account-category-balance",
      collapsed: true
    }, budgetGroups.balanceCategories.map(function (category, i) {
      return _react.default.createElement("div", {
        className: "category-value",
        key: category.name,
        ref: function ref(element) {
          return categoriesRefs.current[budgetGroups.profit.length + i] = element;
        }
      }, _react.default.createElement("span", {
        className: "name",
        title: category.displayName
      }, category.displayName), _react.default.createElement(_uiComponents.default.COMPONENTS.Select, {
        styles: selectMenuStyle,
        dataHook: "".concat(category.name, "-select"),
        isClearable: false,
        placeholder: "",
        options: list,
        isMulti: true,
        closeMenuOnSelect: false,
        hideSelectedOptions: false,
        allowSelectAll: true,
        autoBlur: true,
        onChange: function onChange(selected, actionType) {
          return onChangeHandler(selected, actionType, category);
        },
        value: currCategoryMap[category.name],
        menuPosition: "fixed",
        menuPortalTarget: categoriesRefs.current[budgetGroups.profit.length + i],
        onMenuOpen: function onMenuOpen() {
          setOpenedIndex(budgetGroups.profit.length + i);
          setDropdownIsOpen(true);
        },
        onMenuClose: function onMenuClose() {
          setOpenedIndex(null);
          setDropdownIsOpen(false);
        }
      }));
    }))));
    var buttons = _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      dataHook: "footer-secondary-button",
      className: "footer-button secondary back border big-button",
      text: intl.t("generic-file.back"),
      onClick: function onClick() {
        var mismatchLines = getMismatchLines(accountToCategoriesMap, currCategoryMap);
        addLinesToSelectedList(mismatchLines);
        if (isCategoryMappingChanged(currCategoryMap, categoryMapping)) {
          dispatch(_budgetActions.default.setCategoryMapping(currCategoryMap));
          rest.clearDependencies();
        }
        dispatch(_stepsActions.default.prevStep());
      }
    }), _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      dataHook: "footer-primary-button",
      className: "footer-button primary approve big-button",
      text: intl.t("generic-file.next"),
      onClick: handleNextToFollowingStep,
      disabled: _lodash.default.flatten(Object.values(currCategoryMap)).filter(function (x) {
        return x !== null && x !== undefined;
      }).length === 0
    }));
    var footer = {
      buttons: buttons
    };
    return _react.default.createElement(_uiComponents.default.COMPONENTS.ModalBase, {
      content: {
        header: header,
        footer: footer,
        body: body
      },
      options: options
    });
  }
});