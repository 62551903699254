define("budgeta/utils/wizard-template-utils", ["exports", "budgeta/services/ember-service-container"], function (_exports, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var WizardUtil = {
    questionsGroups: {
      "group-company": {
        canBeRoot: true,
        hideMainSection: true,
        addRootName: intl.t("budget_names.company_budget"),
        addRootOrder: 0,
        addRootHelpLink: intl.t("budget_names.company_budget"),
        wizardTitle: "Build your {{name}} budget",
        // TODO: replace it
        youtubeId: "VSQl4kPLgFI",
        addWizardQuestions: [{
          name: intl.t("general.name_1695611605120"),
          title: intl.t("budget_names.models"),
          question: intl.t("wizard.add_models"),
          helpText: intl.t("wizard.select_presets_models"),
          youtubeId: "XQ2PsarNPCk",
          value: 1,
          wizardStepComponent: "wizard-step-models",
          stepAction: "addSelectedModels",
          hide: true
        }],
        template: [{
          name: intl.t("budget_names.revenues"),
          type: "group-revenue",
          wizardType: "group-revenue",
          showOnce: true,
          helpLink: "ID229349",
          showOnAddLine: true,
          wizardQuestion: intl.t("wizard.include-revenues")
        }, {
          name: intl.t("budget_names.cost_of_revenues"),
          type: "group-expense-costofsale",
          wizardType: "group-expense-costofsale",
          showOnce: true,
          helpLink: "ID229350",
          showOnAddLine: true,
          wizardDepends: "group-revenue",
          wizardQuestion: intl.t("wizard.cos-separate-from-opex"),
          wizardQuestionPart1: intl.t("general.does_your__1698234257389"),
          wizardQuestionPart2: " have Cost of Revenue separate from your Operational Expenses?"
        }, {
          name: intl.t("budget_names.operational_expenses"),
          showOnce: true,
          helpLink: "ID229352",
          prompt: intl.t("general.select_grouping_1698234257389"),
          showOnAddLine: true,
          youtubeId: "izXAOWXmmsA",
          wizardQuestion: intl.t("wizard.manage-expenses"),
          children: [{
            name: intl.t("budget_names.no_grouping"),
            id: "group-expense",
            wizardType: "group-expense-op-simple",
            helpLink: "ID247080"
          }, {
            name: intl.t("budget_names.by_department"),
            id: "group-expense",
            wizardType: "group-expense-op-bydepartment",
            helpLink: "ID247056",
            youtubeId: "X4AoRJ8ouJ8"
          }, {
            name: intl.t("budget_names.by_geography"),
            id: "group-expense",
            wizardType: "group-expense-op-bygeo",
            helpLink: "ID247056",
            youtubeId: "M93nb9UeV6E"
          }]
        }, {
          name: intl.t("budget_names.other_income_and_expenses"),
          type: "group-expense-other",
          wizardType: "group-expense-other",
          showOnce: true,
          helpLink: "ID229354",
          showOnAddLine: true,
          wizardQuestion: intl.t("wizard.have-other-income-expenses")
        }, {
          name: intl.t("budget_names.subsidiary"),
          wizardType: "group-subsidiary",
          type: "group-company",
          showOnAddLine: true,
          wizardQuestion: intl.t("wizard.have-subsidiary"),
          wizardQuestionPart1: intl.t("general.does_your__1698234257389"),
          wizardQuestionPart2: " have a subsidiary?"
        }]
      },
      "group-company-non-profit": {
        canBeRoot: true,
        hideMainSection: true,
        addRootName: intl.t("budget_names.company_budget"),
        addRootOrder: 0,
        addRootHelpLink: intl.t("budget_names.company_budget"),
        wizardTitle: "Build your {{name}} budget",
        // TODO: replace it
        youtubeId: "VSQl4kPLgFI",
        addWizardQuestions: [{
          name: intl.t("general.name_1695611605120"),
          title: intl.t("budget_names.models"),
          question: intl.t("wizard.add_models"),
          helpText: intl.t("wizard.select_presets_models"),
          youtubeId: "XQ2PsarNPCk",
          value: 1,
          wizardStepComponent: "wizard-step-models",
          stepAction: "addSelectedModels",
          hide: true
        }],
        template: [{
          name: intl.t("budget_names.revenues"),
          type: "group-revenue",
          wizardType: "group-revenue",
          showOnce: true,
          helpLink: "ID229349",
          showOnAddLine: true,
          wizardQuestion: intl.t("wizard.include-revenues")
        }, {
          name: intl.t("budget_names.operational_expenses"),
          showOnce: true,
          helpLink: "ID229352",
          prompt: intl.t("general.select_grouping_1698234257389"),
          showOnAddLine: true,
          youtubeId: "izXAOWXmmsA",
          wizardQuestion: intl.t("wizard.manage-expenses"),
          children: [{
            name: intl.t("budget_names.no_grouping"),
            id: "group-expense",
            wizardType: "group-expense-op-simple",
            helpLink: "ID247080"
          }, {
            name: intl.t("budget_names.by_department"),
            id: "group-expense",
            wizardType: "group-expense-op-bydepartment",
            helpLink: "ID247056",
            youtubeId: "X4AoRJ8ouJ8"
          }, {
            name: intl.t("budget_names.by_geography"),
            id: "group-expense",
            wizardType: "group-expense-op-bygeo",
            helpLink: "ID247056",
            youtubeId: "M93nb9UeV6E"
          }]
        }, {
          name: intl.t("budget_names.other_income_and_expenses"),
          type: "group-expense-other",
          wizardType: "group-expense-other",
          showOnce: true,
          helpLink: "ID229354",
          showOnAddLine: true,
          wizardQuestion: intl.t("wizard.have-other-income-expenses")
        }, {
          name: intl.t("budget_names.subsidiary"),
          wizardType: "group-subsidiary",
          type: "group-company",
          showOnAddLine: true,
          wizardQuestion: intl.t("wizard.have-subsidiary"),
          wizardQuestionPart1: intl.t("general.does_your__1698234257389"),
          wizardQuestionPart2: " have a subsidiary?"
        }]
      },
      "group-expense": {
        tipId: "ID232106",
        excludeFromWizard: false,
        wizardImportEnabled: true,
        wizardImportQuestion: intl.t("wizard.add_expense_lines"),
        wizardTypeChange: {
          question: intl.t("wizard.manage-expenses"),
          options: [{
            text: intl.t("budget_names.no_grouping"),
            id: "group-expense",
            wizardType: "group-expense-op-simple",
            helpLink: "ID247080"
          }, {
            text: intl.t("budget_names.by_department"),
            id: "group-expense",
            wizardType: "group-expense-op-bydepartment",
            helpLink: "ID247056",
            youtubeId: "X4AoRJ8ouJ8"
          }, {
            text: intl.t("budget_names.by_geography"),
            id: "group-expense",
            wizardType: "group-expense-op-bygeo",
            helpLink: "ID247056",
            youtubeId: "M93nb9UeV6E"
          }]
        },
        allowedChildTypesForImport: ["group-payroll", "expense", "group-expense", "expense-allocated", "expense-calculated", "expense-adjustment", "per-employee", "per-new-employee"],
        template: [{
          name: intl.t("budget_names.salary-and-wages"),
          type: "group-payroll",
          helpLink: "ID229964",
          showOnAddLine: false,
          children: null,
          order: 0,
          wizardQuestion: intl.t("wizard.add-salary-and-wages"),
          wizardQuestionAfter: intl.t("wizard.enter-other-opex")
        }, {
          name: intl.t("budget_names.professional-services"),
          type: "group-ps",
          showOnce: true,
          helpLink: "ID229965",
          children: null,
          wizardIsFirstToggle: true
        }, {
          name: intl.t("budget_names.office_expenses"),
          firstTime: true,
          helpLink: "ID229966",
          type: "expense"
        }, {
          name: intl.t("budget_names.equipment"),
          firstTime: true,
          helpLink: "ID229967",
          type: "expense"
        }, {
          name: intl.t("budget_names.facility_related"),
          firstTime: true,
          helpLink: "ID229968",
          type: "expense"
        }, {
          name: intl.t("budget_names.travel_entertainment"),
          firstTime: true,
          helpLink: "ID229969",
          type: "expense"
        }, {
          name: intl.t("budget_names.programs"),
          firstTime: true,
          helpLink: "ID229970",
          type: "expense"
        }, {
          placeholder: intl.t("placeholders.enter-name"),
          defaultValue: "expense",
          wizardAddLabel: intl.t("wizard_labels.expense_line"),
          showOnAddLine: true,
          children: [{
            name: intl.t("budget_names.expense"),
            id: "expense"
          }, {
            name: intl.t("budget_names.group"),
            id: "group-expense"
          }, {
            name: intl.t("budget_names.salary-and-wages"),
            id: "group-payroll"
          }, {
            name: intl.t("budget_names.professional-services"),
            id: "group-ps"
          }, {
            name: intl.t("budget_names.per-employee-expense"),
            id: "per-employee"
          }, {
            name: intl.t("budget_names.per-new-employee-expense"),
            id: "per-new-employee"
          }, {
            name: intl.t("budget_names.expense-from-model"),
            id: "expense-from-model",
            excludeFromWizard: true
          }, {
            name: intl.t("budget_names.reserve"),
            unavailableInNonProfitBudget: true,
            id: "expense-buffer"
          }, {
            name: intl.t("budget_names.derived_expense"),
            id: "expense-calculated"
          }, {
            name: intl.t("budget_names.allocated_expense"),
            id: "expense-allocated"
          }, {
            name: intl.t("budget_names.past_balances"),
            hasSubGroup: true,
            subGroup: [{
              name: intl.t("budget_names.pre_payments"),
              id: "bs-prepayments"
            }, {
              name: intl.t("budget_names.assets"),
              id: "bs-assets"
            }, {
              name: intl.t("budget_names.accounts_payable"),
              id: "bs-accounts-payable"
            }, {
              name: intl.t("budget_names.accrued_compensation"),
              id: "bs-accrued-comp"
            }, {
              name: intl.t("budget_names.other_current_liabilities"),
              id: "bs-current-liabilities"
            }, {
              name: intl.t("budget_names.other_long_term_liabilities"),
              id: "bs-lt-liabilities"
            }, {
              name: intl.t("budget_names.other_current_assets"),
              id: "bs-current-assets"
            }, {
              name: intl.t("budget_names.other_long_term_assets"),
              id: "bs-lt-assets"
            }, {
              name: intl.t("budget_names.other_shareholders_equity"),
              unavailableInNonProfitBudget: true,
              id: "bs-equity"
            }]
          }, {
            name: intl.t("budget_names.expense-adjustment"),
            id: "expense-adjustment"
          }, {
            name: intl.t("budget_names.pre_payments"),
            id: "bs-prepayments",
            isSubitem: true
          }, {
            name: intl.t("budget_names.assets"),
            id: "bs-assets",
            isSubitem: true
          }, {
            name: intl.t("budget_names.accounts_payable"),
            id: "bs-accounts-payable",
            isSubitem: true
          }, {
            name: intl.t("budget_names.accrued_compensation"),
            id: "bs-accrued-comp",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_current_liabilities"),
            id: "bs-current-liabilities",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_long_term_liabilities"),
            id: "bs-lt-liabilities",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_current_assets"),
            id: "bs-current-assets",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_long_term_assets"),
            id: "bs-lt-assets",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_shareholders_equity"),
            unavailableInNonProfitBudget: true,
            id: "bs-equity",
            isSubitem: true
          }]
        }]
      },
      "group-revenue": {
        wizardTip: "ID229349",
        youtubeId: "Dzew6x9Jmyg",
        wizardImportEnabled: true,
        wizardImportQuestion: intl.t("wizard.how_add_revenue_lines"),
        template: [{
          placeholder: intl.t("placeholders.enter-name"),
          defaultValue: "sales",
          wizardAddLabel: intl.t("wizard_labels.revenue_line"),
          showOnAddLine: true,
          wizardAddQuestion: "".concat(intl.t("wizard.which_lines_to_budget"), "<br/><small>").concat(intl.t("wizard.add_what_you_think"), "<br/>").concat(intl.t("wizard.use_drag_and_drop"), "</small>"),
          children: [{
            name: intl.t("budget_names.revenue"),
            id: "sales"
          }, {
            name: intl.t("budget_names.group"),
            id: "group-revenue",
            overrideTipId: "Revenue group"
          }, {
            name: intl.t("budget_names.perpetual_license"),
            unavailableInNonProfitBudget: true,
            id: "sales-perpetual-license"
          }, {
            name: intl.t("budget_names.simple_subscription"),
            id: "sales-subscription"
          }, {
            name: intl.t("budget_names.services"),
            id: "sales-service"
          }, {
            name: intl.t("budget_names.program_revenue"),
            onlyInNonProfit: true,
            id: "sales-program"
          }, {
            name: intl.t("budget_names.saas_subscription"),
            unavailableInNonProfitBudget: true,
            id: "subscription-saas"
          }, {
            name: intl.t("budget_names.revenue_from_model"),
            id: "revenue-from-model"
          }, {
            name: intl.t("budget_names.revenue_adjustment"),
            id: "revenue-adjustment"
          }, {
            name: intl.t("budget_names.deferred_revenue"),
            id: "past-revenue",
            isSubitem: true
          }, {
            name: intl.t("budget_names.accounts_receivable"),
            id: "bs-accounts-receivable",
            isSubitem: true
          }, {
            name: intl.t("budget_names.past_balances"),
            hasSubGroup: true,
            subGroup: [{
              name: intl.t("budget_names.deferred_revenue"),
              id: "past-revenue"
            }, {
              name: intl.t("budget_names.accounts_receivable"),
              id: "bs-accounts-receivable"
            }]
          }]
        }]
      },
      "group-expense-costofsale": {
        excludeFromWizard: false,
        wizardImportEnabled: true,
        wizardImportQuestion: intl.t("wizard.add_cor_lines"),
        wizardTip: "ID229350",
        template: [{
          name: intl.t("budget_names.professional-services"),
          firstTime: true,
          helpLink: "ID229364",
          type: "group-expense",
          wizardType: "group-department",
          showOnAddLine: true,
          children: null,
          wizardQuestion: intl.t("wizard.have-professional-services-department"),
          wizardQuestionAfter: intl.t("wizard.enter-other-cor")
        }, {
          name: intl.t("budget_names.direct_cost_of_revenues"),
          firstTime: true,
          helpLink: "ID229366",
          defaultValue: "expense",
          showOnAddLine: true,
          wizardIsFirstToggle: true,
          children: [{
            name: intl.t("budget_names.expense"),
            id: "expense"
          }, {
            name: intl.t("budget_names.expense-group"),
            id: "group-expense"
          }]
        }, {
          placeholder: intl.t("placeholders.enter-name"),
          defaultValue: "expense",
          showOnAddLine: true,
          wizardAddLabel: intl.t("wizard_labels.cost_of_revenue_line"),
          children: [{
            name: intl.t("budget_names.expense"),
            id: "expense"
          }, {
            name: intl.t("budget_names.group"),
            id: "group-expense"
          }, {
            name: intl.t("budget_names.salary-and-wages"),
            id: "group-payroll"
          }, {
            name: intl.t("budget_names.professional-services"),
            id: "group-ps"
          }, {
            name: intl.t("budget_names.per-employee-expense"),
            id: "per-employee"
          }, {
            name: intl.t("budget_names.per-new-employee-expense"),
            id: "per-new-employee"
          }, {
            name: intl.t("budget_names.expense-from-model"),
            id: "expense-from-model",
            excludeFromWizard: true
          }, {
            name: intl.t("budget_names.reserve"),
            unavailableInNonProfitBudget: true,
            id: "expense-buffer"
          }, {
            name: intl.t("budget_names.derived_expense"),
            id: "expense-calculated"
          }, {
            name: intl.t("budget_names.allocated_expense"),
            id: "expense-allocated"
          }, {
            name: intl.t("budget_names.past_balances"),
            hasSubGroup: true,
            subGroup: [{
              name: intl.t("budget_names.pre_payments"),
              id: "bs-prepayments"
            }, {
              name: intl.t("budget_names.assets"),
              id: "bs-assets"
            }, {
              name: intl.t("budget_names.accounts_payable"),
              id: "bs-accounts-payable"
            }, {
              name: intl.t("budget_names.accrued_compensation"),
              id: "bs-accrued-comp"
            }, {
              name: intl.t("budget_names.other_current_liabilities"),
              id: "bs-current-liabilities"
            }, {
              name: intl.t("budget_names.other_long_term_liabilities"),
              id: "bs-lt-liabilities"
            }, {
              name: intl.t("budget_names.other_current_assets"),
              id: "bs-current-assets"
            }, {
              name: intl.t("budget_names.other_long_term_assets"),
              id: "bs-lt-assets"
            }, {
              name: intl.t("budget_names.other_shareholders_equity"),
              unavailableInNonProfitBudget: true,
              id: "bs-equity"
            }, {
              name: intl.t("budget_names.deferred_revenue"),
              id: "past-revenue"
            }, {
              name: intl.t("budget_names.accounts_receivable"),
              id: "bs-accounts-receivable"
            }]
          }, {
            name: intl.t("budget_names.expense-adjustment"),
            id: "expense-adjustment"
          }, {
            name: intl.t("budget_names.pre_payments"),
            id: "bs-prepayments",
            isSubitem: true
          }, {
            name: intl.t("budget_names.assets"),
            id: "bs-assets",
            isSubitem: true
          }, {
            name: intl.t("budget_names.accounts_payable"),
            id: "bs-accounts-payable",
            isSubitem: true
          }, {
            name: intl.t("budget_names.accrued_compensation"),
            id: "bs-accrued-comp",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_current_liabilities"),
            id: "bs-current-liabilities",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_long_term_liabilities"),
            id: "bs-lt-liabilities",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_current_assets"),
            id: "bs-current-assets",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_long_term_assets"),
            id: "bs-lt-assets",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_shareholders_equity"),
            unavailableInNonProfitBudget: true,
            id: "bs-equity",
            isSubitem: true
          }, {
            name: intl.t("budget_names.deferred_revenue"),
            id: "past-revenue",
            isSubitem: true
          }, {
            name: intl.t("budget_names.accounts_receivable"),
            id: "bs-accounts-receivable",
            isSubitem: true
          }]
        }]
      },
      "group-payroll": {
        wizardTip: "ID229964",
        wizardAddChildType: "employee",
        wizardAddChildBudgetType: "employee",
        wizardAddChildQuestion: intl.t("wizard.add_employees_of_group"),
        wizardImportEnabled: true,
        wizardImportQuestion: intl.t("wizard.add_employees"),
        importMappingType: "employee",
        template: [{
          placeholder: intl.t("placeholders.enter-name"),
          defaultValue: "per-employee",
          defaultValueForTree: "employee",
          wizardAddLabel: intl.t("wizard_labels.expense_line"),
          wizardAddQuestion: intl.t("wizard.add_employee_expense_for_department"),
          showOnAddLine: true,
          children: [{
            id: "employee",
            name: intl.t("budget_names.employee"),
            excludeFromWizard: true
          }, {
            id: "employee-multi",
            name: intl.t("budget_names.multiple_employees"),
            excludeFromWizard: true
          }, {
            id: "employee-allocated",
            name: intl.t("budget_names.allocated_employee"),
            excludeFromWizard: true
          }, {
            id: "employee-transferred",
            name: intl.t("budget_names.transferred_employee"),
            excludeFromWizard: true
          }, {
            id: "per-employee",
            name: intl.t("budget_names.per-employee-expense")
          }, {
            id: "per-new-employee",
            name: intl.t("budget_names.per-new-employee-expense")
          }, {
            id: "expense",
            name: intl.t("budget_names.expense")
          }]
        }]
      },
      "group-expense-other": {
        excludeFromWizard: false,
        wizardImportEnabled: true,
        wizardImportQuestion: intl.t("wizard.how-add-lines"),
        wizardTip: "ID229354",
        template: [{
          name: intl.t("budget_names.loan"),
          type: "loan",
          children: null,
          wizardIsFirstToggle: true,
          showOnAddLine: false
        }, {
          name: intl.t("budget_names.equity_financing"),
          unavailableInNonProfitBudget: true,
          type: "equity-financing",
          children: null,
          showOnAddLine: false
        }, {
          placeholder: intl.t("placeholders.enter-name"),
          defaultValue: "expense",
          wizardAddLabel: intl.t("wizard_labels.income_or_expense_line"),
          showOnAddLine: true,
          children: [{
            name: intl.t("budget_names.expense"),
            id: "expense"
          }, {
            name: intl.t("budget_names.revenue"),
            id: "sales"
          }, {
            name: intl.t("budget_names.group"),
            id: "group-expense-other"
          }, {
            name: intl.t("budget_names.perpetual_license"),
            unavailableInNonProfitBudget: true,
            id: "equity-financing"
          }, {
            name: intl.t("budget_names.loan"),
            id: "loan"
          }, {
            name: intl.t("budget_names.expense-from-model"),
            id: "expense-from-model"
          }, {
            name: intl.t("budget_names.revenue_from_model"),
            id: "revenue-from-model"
          }, {
            name: intl.t("budget_names.revenue_adjustment"),
            id: "revenue-adjustment"
          }, {
            name: intl.t("budget_names.past_balances"),
            hasSubGroup: true,
            subGroup: [{
              name: intl.t("budget_names.pre_payments"),
              id: "bs-prepayments"
            }, {
              name: intl.t("budget_names.assets"),
              id: "bs-assets"
            }, {
              name: intl.t("budget_names.accounts_payable"),
              id: "bs-accounts-payable"
            }, {
              name: intl.t("budget_names.accrued_compensation"),
              id: "bs-accrued-comp"
            }, {
              name: intl.t("budget_names.other_current_liabilities"),
              id: "bs-current-liabilities"
            }, {
              name: intl.t("budget_names.other_long_term_liabilities"),
              id: "bs-lt-liabilities"
            }, {
              name: intl.t("budget_names.other_current_assets"),
              id: "bs-current-assets"
            }, {
              name: intl.t("budget_names.other_long_term_assets"),
              id: "bs-lt-assets"
            }, {
              name: intl.t("budget_names.other_shareholders_equity"),
              unavailableInNonProfitBudget: true,
              id: "bs-equity"
            }, {
              name: intl.t("budget_names.deferred_revenue"),
              id: "past-revenue"
            }, {
              name: intl.t("budget_names.accounts_receivable"),
              id: "bs-accounts-receivable"
            }]
          }, {
            name: intl.t("budget_names.expense-adjustment"),
            id: "expense-adjustment"
          }, {
            name: intl.t("budget_names.pre_payments"),
            id: "bs-prepayments",
            isSubitem: true
          }, {
            name: intl.t("budget_names.assets"),
            id: "bs-assets",
            isSubitem: true
          }, {
            name: intl.t("budget_names.accounts_payable"),
            id: "bs-accounts-payable",
            isSubitem: true
          }, {
            name: intl.t("budget_names.accrued_compensation"),
            id: "bs-accrued-comp",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_current_liabilities"),
            id: "bs-current-liabilities",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_long_term_liabilities"),
            id: "bs-lt-liabilities",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_current_assets"),
            id: "bs-current-assets",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_long_term_assets"),
            id: "bs-lt-assets",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_shareholders_equity"),
            unavailableInNonProfitBudget: true,
            id: "bs-equity",
            isSubitem: true
          }, {
            name: intl.t("budget_names.deferred_revenue"),
            id: "past-revenue",
            isSubitem: true
          }, {
            name: intl.t("budget_names.accounts_receivable"),
            id: "bs-accounts-receivable",
            isSubitem: true
          }]
        }]
      },
      "group-expense-op-simple": {
        id: "group-expense",
        wizardType: "group-expense-op-simple",
        typeGroup: "group-expense-op",
        tipId: "ID229960",
        excludeFromWizard: false,
        wizardImportEnabled: true,
        wizardImportQuestion: "".concat(intl.t("wizard.how-add-expense-lines"), "<br/><small>").concat(intl.t("wizard.import-from-spreadsheet"), "</small>"),
        wizardTip: "ID229352",
        uncategorizedActualsLabel: intl.t("wizard_labels.uncategorized_expenses"),
        allowedChildTypes: ["group-payroll", "group-ps", "group-expense-op-simple", "expense", "expense-from-model", "expense-allocated", "expense-calculated", "expense-buffer", "expense-adjustment", "per-employee", "per-new-employee"],
        allowedChildTypesForImport: ["expense", "expense-allocated", "expense-calculated", "expense-adjustment", "per-employee", "per-new-employee", "group-expense"],
        template: [{
          name: intl.t("budget_names.salary-and-wages"),
          type: "group-payroll",
          helpLink: "ID229964",
          showOnAddLine: false,
          children: null,
          order: 0,
          wizardQuestion: intl.t("wizard.add-salary-and-wages"),
          wizardQuestionAfter: intl.t("wizard.enter-other-opex")
        }, {
          name: intl.t("budget_names.professional-services"),
          type: "group-ps",
          showOnce: true,
          helpLink: "ID229965",
          children: null,
          wizardIsFirstToggle: true
        }, {
          name: intl.t("budget_names.office_expenses"),
          firstTime: true,
          helpLink: "ID229966",
          defaultValue: "expense",
          children: [{
            name: intl.t("budget_names.expense"),
            id: "expense"
          }, {
            name: intl.t("budget_names.expense-group"),
            id: "group-expense"
          }]
        }, {
          name: intl.t("budget_names.equipment"),
          firstTime: true,
          helpLink: "ID229967",
          defaultValue: "expense",
          children: [{
            name: intl.t("budget_names.expense"),
            id: "expense"
          }, {
            name: intl.t("budget_names.expense-group"),
            id: "group-expense"
          }]
        }, {
          name: intl.t("budget_names.facility_related"),
          firstTime: true,
          helpLink: "ID229968",
          defaultValue: "expense",
          children: [{
            name: intl.t("budget_names.expense"),
            id: "expense"
          }, {
            name: intl.t("budget_names.expense-group"),
            id: "group-expense"
          }, {
            name: intl.t("budget_names.per-employee-expense"),
            id: "per-employee"
          }]
        }, {
          name: intl.t("budget_names.travel_entertainment"),
          firstTime: true,
          helpLink: "ID229969",
          defaultValue: "expense",
          children: [{
            name: intl.t("budget_names.expense"),
            id: "expense"
          }, {
            name: intl.t("budget_names.expense-group"),
            id: "group-expense"
          }, {
            name: intl.t("budget_names.per-employee-expense"),
            id: "per-employee"
          }]
        }, {
          name: intl.t("budget_names.programs"),
          firstTime: true,
          helpLink: "ID229970",
          defaultValue: "expense",
          children: [{
            name: intl.t("budget_names.expense"),
            id: "expense"
          }, {
            name: intl.t("budget_names.expense-group"),
            id: "group-expense"
          }]
        }, {
          name: intl.t("budget_names.expense-adjustment"),
          id: "expense-adjustment",
          defaultValue: "expense-adjustment",
          type: "expense-adjustment"
        }, {
          placeholder: intl.t("placeholders.enter-name"),
          defaultValue: "expense",
          wizardAddLabel: intl.t("wizard_labels.expense_line"),
          showOnAddLine: true,
          children: [{
            name: intl.t("budget_names.expense"),
            id: "expense"
          }, {
            name: intl.t("budget_names.expense-group"),
            id: "group-expense-op-simple"
          }, {
            name: intl.t("budget_names.per-employee-expense"),
            id: "per-employee"
          }, {
            name: intl.t("budget_names.per-new-employee-expense"),
            id: "per-new-employee"
          }, {
            name: intl.t("budget_names.expense-from-model"),
            id: "expense-from-model",
            excludeFromWizard: true
          }, {
            name: intl.t("budget_names.reserve"),
            unavailableInNonProfitBudget: true,
            id: "expense-buffer"
          }, {
            name: intl.t("budget_names.derived_expense"),
            id: "expense-calculated"
          }, {
            name: intl.t("budget_names.allocated_expense"),
            id: "expense-allocated"
          }, {
            name: intl.t("budget_names.past_balances"),
            hasSubGroup: true,
            subGroup: [{
              name: intl.t("budget_names.pre_payments"),
              id: "bs-prepayments"
            }, {
              name: intl.t("budget_names.assets"),
              id: "bs-assets"
            }, {
              name: intl.t("budget_names.accounts_payable"),
              id: "bs-accounts-payable"
            }, {
              name: intl.t("budget_names.accrued_compensation"),
              id: "bs-accrued-comp"
            }, {
              name: intl.t("budget_names.other_current_liabilities"),
              id: "bs-current-liabilities"
            }, {
              name: intl.t("budget_names.other_long_term_liabilities"),
              id: "bs-lt-liabilities"
            }, {
              name: intl.t("budget_names.other_current_assets"),
              id: "bs-current-assets"
            }, {
              name: intl.t("budget_names.other_long_term_assets"),
              id: "bs-lt-assets"
            }, {
              name: intl.t("budget_names.other_shareholders_equity"),
              unavailableInNonProfitBudget: true,
              id: "bs-equity"
            }]
          }, {
            name: intl.t("budget_names.expense-adjustment"),
            id: "expense-adjustment"
          }, {
            name: intl.t("budget_names.pre_payments"),
            id: "bs-prepayments",
            isSubitem: true
          }, {
            name: intl.t("budget_names.assets"),
            id: "bs-assets",
            isSubitem: true
          }, {
            name: intl.t("budget_names.accounts_payable"),
            id: "bs-accounts-payable",
            isSubitem: true
          }, {
            name: intl.t("budget_names.accrued_compensation"),
            id: "bs-accrued-comp",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_current_liabilities"),
            id: "bs-current-liabilities",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_long_term_liabilities"),
            id: "bs-lt-liabilities",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_current_assets"),
            id: "bs-current-assets",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_long_term_assets"),
            id: "bs-lt-assets",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_shareholders_equity"),
            unavailableInNonProfitBudget: true,
            id: "bs-equity",
            isSubitem: true
          }, {
            name: intl.t("budget_names.expense-adjustment"),
            id: "expense-adjustment"
          }, {
            name: intl.t("budget_names.salary-and-wages"),
            id: "group-payroll"
          }, {
            name: intl.t("budget_names.professional-services"),
            id: "group-ps"
          }]
        }]
      },
      "group-expense-op-bygeo": {
        id: "group-expense",
        tipId: "ID229962",
        wizardTip: "ID229352",
        name: intl.t("budget_names.by_geography_expenses"),
        wizardImportEnabled: false,
        wizardAddChildTypeQuestion: intl.t("wizard.manage-geographies"),
        wizardAddChildTypeOptions: [{
          id: "group-expense",
          wizardType: "group-department-geo",
          text: intl.t("budget_names.only_by_geography")
        }, {
          id: "group-expense",
          wizardType: "group-expense-op-bygeo-bydep",
          text: intl.t("budget_names.by_department")
        }],
        // allowedChildTypesForImport: ['group-department',"group-department-multi",'expense','group-expense'],
        template: [{
          placeholder: intl.t("placeholders.enter-name"),
          defaultValue: "group-department-geo",
          showOnAddLine: true,
          children: [{
            name: intl.t("budget_names.geography"),
            id: "group-expense",
            wizardType: "group-department-geo"
          }, {
            name: intl.t("budget_names.geography_by_department"),
            id: "group-expense",
            wizardType: "group-expense-op-bygeo-bydep"
          }, {
            name: intl.t("budget_names.expense-group"),
            id: "group-expense"
          }, {
            name: intl.t("budget_names.expense"),
            id: "expense"
          }, {
            name: intl.t("budget_names.reserve"),
            unavailableInNonProfitBudget: true,
            id: "expense-buffer"
          }, {
            name: intl.t("budget_names.expense-from-model"),
            id: "expense-from-model",
            excludeFromWizard: true
          }, {
            name: intl.t("budget_names.past_balances"),
            hasSubGroup: true,
            subGroup: [{
              name: intl.t("budget_names.pre_payments"),
              id: "bs-prepayments"
            }, {
              name: intl.t("budget_names.assets"),
              id: "bs-assets"
            }, {
              name: intl.t("budget_names.accounts_payable"),
              id: "bs-accounts-payable"
            }, {
              name: intl.t("budget_names.accrued_compensation"),
              id: "bs-accrued-comp"
            }, {
              name: intl.t("budget_names.other_current_liabilities"),
              id: "bs-current-liabilities"
            }, {
              name: intl.t("budget_names.other_long_term_liabilities"),
              id: "bs-lt-liabilities"
            }, {
              name: intl.t("budget_names.other_current_assets"),
              id: "bs-current-assets"
            }, {
              name: intl.t("budget_names.other_long_term_assets"),
              id: "bs-lt-assets"
            }, {
              name: intl.t("budget_names.other_shareholders_equity"),
              unavailableInNonProfitBudget: true,
              id: "bs-equity"
            }]
          }, {
            name: intl.t("budget_names.pre_payments"),
            id: "bs-prepayments",
            isSubitem: true
          }, {
            name: intl.t("budget_names.assets"),
            id: "bs-assets",
            isSubitem: true
          }, {
            name: intl.t("budget_names.accounts_payable"),
            id: "bs-accounts-payable",
            isSubitem: true
          }, {
            name: intl.t("budget_names.accrued_compensation"),
            id: "bs-accrued-comp",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_current_liabilities"),
            id: "bs-current-liabilities",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_long_term_liabilities"),
            id: "bs-lt-liabilities",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_current_assets"),
            id: "bs-current-assets",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_long_term_assets"),
            id: "bs-lt-assets",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_shareholders_equity"),
            unavailableInNonProfitBudget: true,
            id: "bs-equity",
            isSubitem: true
          }]
        }]
      },
      "group-department-geo": {
        name: intl.t("budget_names.geography"),
        canBeRoot: false,
        id: "group-expense",
        wizardType: "group-department-geo",
        wizardTypeChange: null,
        wizardImportQuestion: "".concat(intl.t("general.other_expenses_for_this_geography_1695803193168"), "<br/><small>").concat(intl.t("general.spreadsheet_import_1695803193168"), "</small>"),
        typeGroup: null
      },
      "group-expense-op-bygeo-bydep": {
        typeGroup: null,
        name: intl.t("budget_names.geography_by_department"),
        id: "group-expense",
        wizardType: "group-expense-op-bygeo-bydep",
        tipId: "ID229961",
        excludeFromWizard: false,
        wizardTip: "ID229352",
        wizardAddChildType: "group-department",
        wizardAddChildBudgetType: "group-expense",
        wizardAddChildTypeQuestion: intl.t("wizard.name-your-departments"),
        wizardAddChildBeforeImport: true,
        wizardImportEnabled: false,
        template: [{
          name: intl.t("budget_names.research_development"),
          id: "group-expense",
          wizardType: "group-department",
          firstTime: true,
          excludeFromWizard: true,
          showOnAddLine: false
        }, {
          name: intl.t("budget_names.sales_marketing"),
          id: "group-expense",
          wizardType: "group-department",
          firstTime: true,
          excludeFromWizard: true
        }, {
          name: intl.t("budget_names.general_administration"),
          id: "group-expense",
          wizardType: "group-department",
          firstTime: true,
          excludeFromWizard: true
        }, {
          name: intl.t("budget_names.cross_department_expenses"),
          helpLink: intl.t("general.cross_department_expense_1698234257388"),
          type: "group-expense",
          firstTime: true,
          wizardQuestion: intl.t("wizard.add-cross-department-expenses")
        }, {
          placeholder: intl.t("placeholders.enter-name"),
          defaultValue: "group-department",
          showOnAddLine: true,
          children: [{
            name: intl.t("budget_names.department"),
            id: "group-expense",
            wizardType: "group-department"
          }, {
            name: intl.t("budget_names.multi-department"),
            id: "group-expense",
            wizardType: "group-department-multi"
          }, {
            name: intl.t("budget_names.expense-group"),
            id: "group-expense"
          }, {
            name: intl.t("budget_names.expense"),
            id: "expense"
          }, {
            name: intl.t("budget_names.reserve"),
            unavailableInNonProfitBudget: true,
            id: "expense-buffer"
          }, {
            name: intl.t("budget_names.expense-from-model"),
            id: "expense-from-model",
            excludeFromWizard: true
          }, {
            name: intl.t("budget_names.past_balances"),
            hasSubGroup: true,
            subGroup: [{
              name: intl.t("budget_names.pre_payments"),
              id: "bs-prepayments"
            }, {
              name: intl.t("budget_names.assets"),
              id: "bs-assets"
            }, {
              name: intl.t("budget_names.accounts_payable"),
              id: "bs-accounts-payable"
            }, {
              name: intl.t("budget_names.accrued_compensation"),
              id: "bs-accrued-comp"
            }, {
              name: intl.t("budget_names.other_current_liabilities"),
              id: "bs-current-liabilities"
            }, {
              name: intl.t("budget_names.other_long_term_liabilities"),
              id: "bs-lt-liabilities"
            }, {
              name: intl.t("budget_names.other_current_assets"),
              id: "bs-current-assets"
            }, {
              name: intl.t("budget_names.other_long_term_assets"),
              id: "bs-lt-assets"
            }, {
              name: intl.t("budget_names.other_shareholders_equity"),
              unavailableInNonProfitBudget: true,
              id: "bs-equity"
            }]
          }, {
            name: intl.t("budget_names.pre_payments"),
            id: "bs-prepayments",
            isSubitem: true
          }, {
            name: intl.t("budget_names.assets"),
            id: "bs-assets",
            isSubitem: true
          }, {
            name: intl.t("budget_names.accounts_payable"),
            id: "bs-accounts-payable",
            isSubitem: true
          }, {
            name: intl.t("budget_names.accrued_compensation"),
            id: "bs-accrued-comp",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_current_liabilities"),
            id: "bs-current-liabilities",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_long_term_liabilities"),
            id: "bs-lt-liabilities",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_current_assets"),
            id: "bs-current-assets",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_long_term_assets"),
            id: "bs-lt-assets",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_shareholders_equity"),
            unavailableInNonProfitBudget: true,
            id: "bs-equity",
            isSubitem: true
          }]
        }]
      },
      "group-expense-op-bygeo-bydep-non-profit": {
        typeGroup: null,
        name: intl.t("budget_names.geography_by_department"),
        id: "group-expense",
        wizardType: "group-expense-op-bygeo-bydep",
        tipId: "ID229961",
        excludeFromWizard: false,
        wizardTip: "ID229352",
        wizardAddChildType: "group-department",
        wizardAddChildBudgetType: "group-expense",
        wizardAddChildTypeQuestion: intl.t("wizard.name-your-departments"),
        wizardAddChildBeforeImport: true,
        wizardImportEnabled: false,
        template: [{
          name: intl.t("budget_names.program"),
          id: "group-expense",
          wizardType: "group-department",
          firstTime: true,
          excludeFromWizard: true,
          showOnAddLine: false
        }, {
          name: intl.t("budget_names.general_administration"),
          id: "group-expense",
          wizardType: "group-department",
          firstTime: true,
          excludeFromWizard: true
        }, {
          name: intl.t("budget_names.fundraising"),
          id: "group-expense",
          wizardType: "group-department",
          firstTime: true,
          excludeFromWizard: true
        }, {
          name: intl.t("budget_names.cross_department_expenses"),
          helpLink: intl.t("general.cross_department_expense_1698234257388"),
          type: "group-expense",
          firstTime: true,
          wizardQuestion: intl.t("wizard.add-cross-department-expenses")
        }, {
          placeholder: intl.t("placeholders.enter-name"),
          defaultValue: "group-department",
          showOnAddLine: true,
          children: [{
            name: intl.t("budget_names.department"),
            id: "group-expense",
            wizardType: "group-department"
          }, {
            name: intl.t("budget_names.multi-department"),
            id: "group-expense",
            wizardType: "group-department-multi"
          }, {
            name: intl.t("budget_names.expense-group"),
            id: "group-expense"
          }, {
            name: intl.t("budget_names.expense"),
            id: "expense"
          }, {
            name: intl.t("budget_names.reserve"),
            unavailableInNonProfitBudget: true,
            id: "expense-buffer"
          }, {
            name: intl.t("budget_names.expense-from-model"),
            id: "expense-from-model",
            excludeFromWizard: true
          }, {
            name: intl.t("budget_names.past_balances"),
            hasSubGroup: true,
            subGroup: [{
              name: intl.t("budget_names.pre_payments"),
              id: "bs-prepayments"
            }, {
              name: intl.t("budget_names.assets"),
              id: "bs-assets"
            }, {
              name: intl.t("budget_names.accounts_payable"),
              id: "bs-accounts-payable"
            }, {
              name: intl.t("budget_names.accrued_compensation"),
              id: "bs-accrued-comp"
            }, {
              name: intl.t("budget_names.other_current_liabilities"),
              id: "bs-current-liabilities"
            }, {
              name: intl.t("budget_names.other_long_term_liabilities"),
              id: "bs-lt-liabilities"
            }, {
              name: intl.t("budget_names.other_current_assets"),
              id: "bs-current-assets"
            }, {
              name: intl.t("budget_names.other_long_term_assets"),
              id: "bs-lt-assets"
            }, {
              name: intl.t("budget_names.other_shareholders_equity"),
              unavailableInNonProfitBudget: true,
              id: "bs-equity"
            }]
          }, {
            name: intl.t("budget_names.pre_payments"),
            id: "bs-prepayments",
            isSubitem: true
          }, {
            name: intl.t("budget_names.assets"),
            id: "bs-assets",
            isSubitem: true
          }, {
            name: intl.t("budget_names.accounts_payable"),
            id: "bs-accounts-payable",
            isSubitem: true
          }, {
            name: intl.t("budget_names.accrued_compensation"),
            id: "bs-accrued-comp",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_current_liabilities"),
            id: "bs-current-liabilities",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_long_term_liabilities"),
            id: "bs-lt-liabilities",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_current_assets"),
            id: "bs-current-assets",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_long_term_assets"),
            id: "bs-lt-assets",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_shareholders_equity"),
            unavailableInNonProfitBudget: true,
            id: "bs-equity",
            isSubitem: true
          }]
        }]
      },
      "group-department": {
        typeGroup: "group-department",
        wizardType: "group-department",
        canBeRoot: false,
        hideMainSection: true,
        id: "group-expense",
        name: intl.t("budget_names.department"),
        addRootName: intl.t("budget_names.department_budget"),
        addRootOrder: 2,
        addRootHelpLink: intl.t("budget_names.department_budget"),
        wizardImportEnabled: true,
        wizardImportQuestion: "".concat(intl.t("wizard.how-add-expense-lines"), "<br/><small>").concat(intl.t("wizard.import-from-spreadsheet"), "</small>"),
        wizardTypeChange: {
          question: intl.t("wizard.department_contain_other_departments"),
          options: [{
            id: "group-expense",
            wizardType: "group-department-multi",
            text: intl.t("popup_buttons.yes")
          }, {
            id: "group-expense",
            wizardType: "group-department",
            text: intl.t("popup_buttons.no")
          }],
          helpLink: intl.t("general.multi_department_1698234257388")
        },
        template: [{
          name: intl.t("budget_names.salary-and-wages"),
          type: "group-payroll",
          helpLink: "ID229964",
          order: 0,
          wizardQuestion: intl.t("wizard.add-salary-and-wages-to-department"),
          wizardQuestionAfter: intl.t("wizard.enter-other-dep-expenses")
        }, {
          name: intl.t("budget_names.professional-services"),
          type: "group-ps",
          showOnce: true,
          helpLink: "ID229965",
          wizardIsFirstToggle: true
        }, {
          name: intl.t("budget_names.office_expenses"),
          firstTime: true,
          helpLink: "ID229966",
          defaultValue: "expense",
          children: [{
            name: intl.t("budget_names.expense"),
            id: "expense"
          }, {
            name: intl.t("budget_names.expense-group"),
            id: "group-expense"
          }]
        }, {
          name: intl.t("budget_names.equipment"),
          firstTime: true,
          helpLink: "ID229967",
          defaultValue: "expense",
          children: [{
            name: intl.t("budget_names.expense"),
            id: "expense"
          }, {
            name: intl.t("budget_names.expense-group"),
            id: "group-expense"
          }]
        }, {
          name: intl.t("budget_names.facility_related"),
          firstTime: true,
          helpLink: "ID229968",
          defaultValue: "expense",
          children: [{
            name: intl.t("budget_names.expense"),
            id: "expense"
          }, {
            name: intl.t("budget_names.expense-group"),
            id: "group-expense"
          }, {
            name: intl.t("budget_names.per-employee-expense"),
            id: "per-employee"
          }]
        }, {
          name: intl.t("budget_names.travel_entertainment"),
          firstTime: true,
          helpLink: "ID229969",
          defaultValue: "expense",
          children: [{
            name: intl.t("budget_names.expense"),
            id: "expense"
          }, {
            name: intl.t("budget_names.expense-group"),
            id: "group-expense"
          }, {
            name: intl.t("budget_names.per-employee-expense"),
            id: "per-employee"
          }]
        }, {
          name: intl.t("budget_names.programs"),
          firstTime: true,
          helpLink: "ID229970",
          defaultValue: "expense",
          children: [{
            name: intl.t("budget_names.expense"),
            id: "expense"
          }, {
            name: intl.t("budget_names.expense-group"),
            id: "group-expense"
          }]
        }, {
          placeholder: intl.t("placeholders.enter-name"),
          defaultValue: "expense",
          wizardAddLabel: intl.t("wizard_labels.expense_line"),
          showOnAddLine: true,
          children: [{
            name: intl.t("budget_names.expense"),
            id: "expense"
          }, {
            name: intl.t("budget_names.expense-group"),
            id: "group-expense"
          }, {
            name: intl.t("budget_names.per-employee-expense"),
            id: "per-employee"
          }, {
            name: intl.t("budget_names.per-new-employee-expense"),
            id: "per-new-employee"
          }, {
            name: intl.t("budget_names.expense-from-model"),
            id: "expense-from-model",
            excludeFromWizard: true
          }, {
            name: intl.t("budget_names.reserve"),
            unavailableInNonProfitBudget: true,
            id: "expense-buffer"
          }, {
            name: intl.t("budget_names.derived_expense"),
            id: "expense-calculated"
          }, {
            name: intl.t("budget_names.allocated_expense"),
            id: "expense-allocated"
          }, {
            name: intl.t("budget_names.past_balances"),
            helpLink: intl.t("general.past_balances_1698234257389"),
            showOnce: true,
            hasSubGroup: true,
            subGroup: [{
              name: intl.t("budget_names.pre_payments"),
              id: "bs-prepayments"
            }, {
              name: intl.t("budget_names.assets"),
              id: "bs-assets"
            }, {
              name: intl.t("budget_names.accounts_payable"),
              id: "bs-accounts-payable"
            }, {
              name: intl.t("budget_names.accrued_compensation"),
              id: "bs-accrued-comp"
            }, {
              name: intl.t("budget_names.other_current_liabilities"),
              id: "bs-current-liabilities"
            }, {
              name: intl.t("budget_names.other_long_term_liabilities"),
              id: "bs-lt-liabilities"
            }, {
              name: intl.t("budget_names.other_current_assets"),
              id: "bs-current-assets"
            }, {
              name: intl.t("budget_names.other_long_term_assets"),
              id: "bs-lt-assets"
            }, {
              name: intl.t("budget_names.other_shareholders_equity"),
              unavailableInNonProfitBudget: true,
              id: "bs-equity"
            }]
          }, {
            name: intl.t("budget_names.pre_payments"),
            id: "bs-prepayments",
            isSubitem: true
          }, {
            name: intl.t("budget_names.assets"),
            id: "bs-assets",
            isSubitem: true
          }, {
            name: intl.t("budget_names.accounts_payable"),
            id: "bs-accounts-payable",
            isSubitem: true
          }, {
            name: intl.t("budget_names.accrued_compensation"),
            id: "bs-accrued-comp",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_current_liabilities"),
            id: "bs-current-liabilities",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_long_term_liabilities"),
            id: "bs-lt-liabilities",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_current_assets"),
            id: "bs-current-assets",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_long_term_assets"),
            id: "bs-lt-assets",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_shareholders_equity"),
            unavailableInNonProfitBudget: true,
            id: "bs-equity",
            isSubitem: true
          }, {
            name: intl.t("budget_names.expense-adjustment"),
            id: "expense-adjustment"
          }, {
            name: intl.t("budget_names.salary-and-wages"),
            id: "group-payroll"
          }, {
            name: intl.t("budget_names.professional-services"),
            id: "group-ps"
          }]
        }]
      },
      "group-department-multi": {
        name: intl.t("budget_names.multi-department"),
        wizardType: "group-department",
        id: "group-expense",
        typeGroup: "group-department",
        wizardImportEnabled: false,
        wizardTypeChange: {
          question: intl.t("wizard.department_contain_other_departments"),
          options: [{
            id: "group-expense",
            wizardType: "group-department-multi",
            text: intl.t("popup_buttons.yes")
          }, {
            id: "group-expense",
            wizardType: "group-department",
            text: intl.t("popup_buttons.no")
          }],
          helpLink: intl.t("general.multi_department_1698234257388")
        },
        tipId: "Multi department",
        wizardAddChildTypeQuestion: intl.t("wizard.add-name-your-departments"),
        wizardAddChildType: "group-department",
        wizardAddChildBudgetType: "group-expense",
        template: [{
          placeholder: intl.t("placeholders.enter-name"),
          defaultValue: "group-department",
          showOnAddLine: true,
          excludeFromWizard: true,
          children: [{
            name: intl.t("budget_names.department"),
            id: "group-expense",
            wizardType: "group-department"
          }, {
            name: intl.t("budget_names.multi-department"),
            id: "group-expense",
            wizardType: "group-department-multi"
          }]
        }]
      },
      "group-expense-op-bydepartment": {
        wizardType: "group-expense-op-bydepartment",
        id: "group-expense",
        tipId: "ID229961",
        excludeFromWizard: false,
        wizardTip: "ID229352",
        wizardAddChildType: "group-department",
        wizardAddChildBudgetType: "group-expense",
        wizardAddChildTypeQuestion: intl.t("wizard.name-your-departments"),
        wizardAddChildBeforeImport: true,
        wizardImportEnabled: false,
        template: [{
          name: intl.t("budget_names.research_development"),
          id: "group-expense",
          wizardType: "group-department",
          firstTime: true,
          excludeFromWizard: true,
          showOnAddLine: false
        }, {
          name: intl.t("budget_names.sales_marketing"),
          id: "group-expense",
          wizardType: "group-department",
          firstTime: true,
          excludeFromWizard: true
        }, {
          name: intl.t("budget_names.general_administration"),
          id: "group-expense",
          wizardType: "group-department",
          firstTime: true,
          excludeFromWizard: true
        }, {
          name: intl.t("budget_names.cross_department_expenses"),
          helpLink: intl.t("general.cross_department_expense_1698234257388"),
          type: "group-expense",
          firstTime: true,
          wizardQuestion: intl.t("wizard.add-cross-department-expenses")
        }, {
          placeholder: intl.t("placeholders.enter-name"),
          defaultValue: "group-department",
          showOnAddLine: true,
          children: [{
            name: intl.t("budget_names.department"),
            id: "group-expense",
            wizardType: "group-department"
          }, {
            name: intl.t("budget_names.multi-department"),
            id: "group-expense",
            wizardType: "group-department-multi"
          }, {
            name: intl.t("budget_names.expense-group"),
            id: "group-expense"
          }, {
            name: intl.t("budget_names.expense"),
            id: "expense"
          }, {
            name: intl.t("budget_names.reserve"),
            unavailableInNonProfitBudget: true,
            id: "expense-buffer"
          }, {
            name: intl.t("budget_names.expense-from-model"),
            id: "expense-from-model",
            excludeFromWizard: true
          }, {
            name: intl.t("budget_names.past_balances"),
            hasSubGroup: true,
            subGroup: [{
              name: intl.t("budget_names.pre_payments"),
              id: "bs-prepayments"
            }, {
              name: intl.t("budget_names.assets"),
              id: "bs-assets"
            }, {
              name: intl.t("budget_names.accounts_payable"),
              id: "bs-accounts-payable"
            }, {
              name: intl.t("budget_names.accrued_compensation"),
              id: "bs-accrued-comp"
            }, {
              name: intl.t("budget_names.other_current_liabilities"),
              id: "bs-current-liabilities"
            }, {
              name: intl.t("budget_names.other_long_term_liabilities"),
              id: "bs-lt-liabilities"
            }, {
              name: intl.t("budget_names.other_current_assets"),
              id: "bs-current-assets"
            }, {
              name: intl.t("budget_names.other_long_term_assets"),
              id: "bs-lt-assets"
            }, {
              name: intl.t("budget_names.other_shareholders_equity"),
              unavailableInNonProfitBudget: true,
              id: "bs-equity"
            }]
          }, {
            name: intl.t("budget_names.pre_payments"),
            id: "bs-prepayments",
            isSubitem: true
          }, {
            name: intl.t("budget_names.assets"),
            id: "bs-assets",
            isSubitem: true
          }, {
            name: intl.t("budget_names.accounts_payable"),
            id: "bs-accounts-payable",
            isSubitem: true
          }, {
            name: intl.t("budget_names.accrued_compensation"),
            id: "bs-accrued-comp",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_current_liabilities"),
            id: "bs-current-liabilities",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_long_term_liabilities"),
            id: "bs-lt-liabilities",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_current_assets"),
            id: "bs-current-assets",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_long_term_assets"),
            id: "bs-lt-assets",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_shareholders_equity"),
            unavailableInNonProfitBudget: true,
            id: "bs-equity",
            isSubitem: true
          }]
        }]
      },
      "group-expense-op-bydepartment-non-profit": {
        wizardType: "group-expense-op-bydepartment",
        id: "group-expense",
        tipId: "ID229961",
        excludeFromWizard: false,
        wizardTip: "ID229352",
        wizardAddChildType: "group-department",
        wizardAddChildBudgetType: "group-expense",
        wizardAddChildTypeQuestion: intl.t("wizard.name-your-departments"),
        wizardAddChildBeforeImport: true,
        wizardImportEnabled: false,
        template: [{
          name: intl.t("budget_names.programs"),
          id: "group-expense",
          wizardType: "group-department",
          firstTime: true,
          excludeFromWizard: true,
          showOnAddLine: false
        }, {
          name: intl.t("budget_names.general_administration"),
          id: "group-expense",
          wizardType: "group-department",
          firstTime: true,
          excludeFromWizard: true
        }, {
          name: intl.t("budget_names.fundraising"),
          id: "group-expense",
          wizardType: "group-department",
          firstTime: true,
          excludeFromWizard: true
        }, {
          name: intl.t("budget_names.cross_department_expenses"),
          helpLink: intl.t("general.cross_department_expense_1698234257388"),
          type: "group-expense",
          firstTime: true,
          wizardQuestion: intl.t("wizard.add-cross-department-expenses")
        }, {
          placeholder: intl.t("placeholders.enter-name"),
          defaultValue: "group-department",
          showOnAddLine: true,
          children: [{
            name: intl.t("budget_names.department"),
            id: "group-expense",
            wizardType: "group-department"
          }, {
            name: intl.t("budget_names.multi-department"),
            id: "group-expense",
            wizardType: "group-department-multi"
          }, {
            name: intl.t("budget_names.expense-group"),
            id: "group-expense"
          }, {
            name: intl.t("budget_names.expense"),
            id: "expense"
          }, {
            name: intl.t("budget_names.reserve"),
            unavailableInNonProfitBudget: true,
            id: "expense-buffer"
          }, {
            name: intl.t("budget_names.expense-from-model"),
            id: "expense-from-model",
            excludeFromWizard: true
          }, {
            name: intl.t("budget_names.past_balances"),
            hasSubGroup: true,
            subGroup: [{
              name: intl.t("budget_names.pre_payments"),
              id: "bs-prepayments"
            }, {
              name: intl.t("budget_names.assets"),
              id: "bs-assets"
            }, {
              name: intl.t("budget_names.accounts_payable"),
              id: "bs-accounts-payable"
            }, {
              name: intl.t("budget_names.accrued_compensation"),
              id: "bs-accrued-comp"
            }, {
              name: intl.t("budget_names.other_current_liabilities"),
              id: "bs-current-liabilities"
            }, {
              name: intl.t("budget_names.other_long_term_liabilities"),
              id: "bs-lt-liabilities"
            }, {
              name: intl.t("budget_names.other_current_assets"),
              id: "bs-current-assets"
            }, {
              name: intl.t("budget_names.other_long_term_assets"),
              id: "bs-lt-assets"
            }, {
              name: intl.t("budget_names.other_shareholders_equity"),
              unavailableInNonProfitBudget: true,
              id: "bs-equity"
            }]
          }, {
            name: intl.t("budget_names.pre_payments"),
            id: "bs-prepayments",
            isSubitem: true
          }, {
            name: intl.t("budget_names.assets"),
            id: "bs-assets",
            isSubitem: true
          }, {
            name: intl.t("budget_names.accounts_payable"),
            id: "bs-accounts-payable",
            isSubitem: true
          }, {
            name: intl.t("budget_names.accrued_compensation"),
            id: "bs-accrued-comp",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_current_liabilities"),
            id: "bs-current-liabilities",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_long_term_liabilities"),
            id: "bs-lt-liabilities",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_current_assets"),
            id: "bs-current-assets",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_long_term_assets"),
            id: "bs-lt-assets",
            isSubitem: true
          }, {
            name: intl.t("budget_names.other_shareholders_equity"),
            unavailableInNonProfitBudget: true,
            id: "bs-equity",
            isSubitem: true
          }]
        }]
      },
      "group-ps": {
        tipId: "ID229965",
        wizardImportEnabled: true,
        wizardImportQuestion: intl.t("wizard.add_professional_services_expenses"),
        template: [{
          placeholder: intl.t("placeholders.enter_expense_name"),
          defaultValue: "contractor",
          showOnAddLine: true,
          wizardAddLabel: intl.t("wizard_labels.expense_line"),
          wizardAddQuestion: "".concat(intl.t("wizard.which_lines_to_budget"), "<br/><small>").concat(intl.t("wizard.add_what_you_think"), "<br/>").concat(intl.t("wizard.use_drag_and_drop"), "</small>"),
          children: [{
            name: intl.t("budget_names.contractor"),
            id: "contractor"
          }, {
            name: intl.t("budget_names.expense"),
            id: "expense"
          }, {
            name: intl.t("budget_names.expense-group"),
            id: "group-expense"
          }, {
            name: intl.t("budget_names.per-employee-expense"),
            id: "per-employee"
          }, {
            name: intl.t("budget_names.per-new-employee-expense"),
            id: "per-new-employee"
          }, {
            name: intl.t("budget_names.allocated_expense"),
            id: "expense-allocated"
          }, {
            name: intl.t("budget_names.derived_expense"),
            id: "expense-calculated"
          }, {
            name: intl.t("budget_names.reserve"),
            unavailableInNonProfitBudget: true,
            id: "expense-buffer"
          }, {
            name: intl.t("budget_names.expense-adjustment"),
            id: "expense-adjustment"
          }]
        }]
      }
    },
    getBudgetLineStepName: function getBudgetLineStepName(budgetLine) {
      var templateMap = {
        "group-expense": function groupExpense() {
          if (budgetLine.get("wizardType") === "group-expense-op-simple") {
            return WizardUtil.questionsGroups["group-expense-op-simple"];
          }
          if (budgetLine.get("wizardType") === "group-expense-op-bygeo") {
            return WizardUtil.questionsGroups["group-expense-op-bygeo"];
          }
          if (budgetLine.get("wizardType") === "group-expense-department-geo") {
            return WizardUtil.questionsGroups["group-department-geo"];
          }
          if (budgetLine.get("wizardType") === "group-department") {
            return WizardUtil.questionsGroups["group-department"];
          }
          if (budgetLine.get("wizardType") === "group-department-multi") {
            return WizardUtil.questionsGroups["group-department-multi"];
          }
          if (budgetLine.get("wizardType") === "group-expense-op-bydepartment") {
            if (budgetLine.get("absoluteRoot.isNonProfitBudget")) {
              return WizardUtil.questionsGroups["group-expense-op-bydepartment-non-profit"];
            }
            return WizardUtil.questionsGroups["group-expense-op-bydepartment"];
          }
          if (budgetLine.get("wizardType") === "group-expense-op-bygeo-bydep") {
            if (budgetLine.get("absoluteRoot.isNonProfitBudget")) {
              return WizardUtil.questionsGroups["group-expense-op-bygeo-bydep-non-profit"];
            }
            return WizardUtil.questionsGroups["group-expense-op-bygeo-bydep"];
          }
          return WizardUtil.questionsGroups["group-expense"];
        },
        "group-subsidiary": function groupSubsidiary() {
          return WizardUtil.questionsGroups["group-company"];
        },
        "group-company": function groupCompany() {
          if (budgetLine.get("absoluteRoot.isNonProfitBudget")) {
            return WizardUtil.questionsGroups["group-company-non-profit"];
          }
          return WizardUtil.questionsGroups["group-company"];
        },
        "group-revenue": function groupRevenue() {
          return WizardUtil.questionsGroups["group-revenue"];
        },
        "group-expense-costofsale": function groupExpenseCostofsale() {
          return WizardUtil.questionsGroups["group-expense-costofsale"];
        },
        "group-payroll": function groupPayroll() {
          return WizardUtil.questionsGroups["group-payroll"];
        },
        "group-ps": function groupPs() {
          return WizardUtil.questionsGroups["group-ps"];
        },
        "group-expense-other": function groupExpenseOther() {
          return WizardUtil.questionsGroups["group-expense-other"];
        }
      };
      if (templateMap[budgetLine.get("budgetType")]) {
        return templateMap[budgetLine.get("budgetType")]();
      }
      return undefined;
    }
  };
  var _default = _exports.default = WizardUtil;
});