define("budgeta/controllers/main/budget/actuals-accounts", ["exports", "budgeta/services/ember-service-container", "budgeta/utils/const", "budgeta/utils/budget-utils", "lodash/lodash", "budgeta/mixins/query-param-delegate-fix"], function (_exports, _emberServiceContainer, _const, _budgetUtils, _lodash, _queryParamDelegateFix) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/avoid-leaking-state-in-ember-objects, ember/no-side-effects */
  // TODO: should be removed

  var Line = Ember.Object.extend({
    name: Ember.computed.alias("budgetLine.title"),
    budgetLine: {}
  });
  var _default = _exports.default = Ember.Controller.extend(_queryParamDelegateFix.default, {
    queryParams: ["type"],
    type: "",
    mainController: Ember.inject.controller("main"),
    store: Ember.inject.service(),
    treeManager: Ember.inject.service(),
    budgetService: Ember.inject.service(),
    isBS: Ember.computed("type", function () {
      return this.get("type") === "bs";
    }),
    lastFocusLineElement: undefined,
    lastFocusElement: Ember.computed("lastFocusLineElement", {
      get: function get() {
        return this.get("lastFocusLineElement");
      },
      set: function set(key, value) {
        this.set("lastFocusLineElement", value);
        return value;
      }
    }),
    lines: Ember.computed("model", "type", "treeManager.budgetsList.length", function () {
      if (!this.get("model")) {
        return [];
      }
      if (this.get("type") === "bs") {
        var result = this.get("model.bsAccounts");
        var line;
        for (var i = 0; i < result.length; i++) {
          line = result[i];
          Ember.setProperties(line, {
            isCollapse: true,
            duplicateAccounts: undefined,
            startValue: line.accountId
          });
          var thisLine = _const.default.BALANCE_SHEET_LINES.findBy("name", line.lineId);
          if (thisLine) {
            Ember.set(line, "name", thisLine.displayName);
          }
        }
        return result;
      } else {
        var _result = [];
        this.get("treeManager.budgetsListWithoutModel").filter(function (treeNode) {
          return treeNode.get("budget.budgetType").indexOf("employee") !== 0;
        }).forEach(function (treeNode) {
          if (treeNode.get("budget.budgetType") === "group-payroll") {
            _result.push(Line.create({
              isCollapse: true,
              budgetLine: Ember.get(treeNode, "budget"),
              level: treeNode.level,
              hideAccount: true,
              accountId: null,
              startValue: null
            }));
            _const.default.DEFAULT_SALARY_CATEGORIES_ARRAY.forEach(function (cat, idx) {
              var accountIdPerCategory = Ember.get(treeNode, "budget.budgetAttributesWithScenarios.accountIdPerCategory");
              var categories = (Ember.get(treeNode, "budget.budgetAttributesWithScenarios.category") || "").split(",");
              if (!accountIdPerCategory) {
                accountIdPerCategory = new Array(_const.default.DEFAULT_SALARY_CATEGORIES_ARRAY.length);
                Ember.set(treeNode, "budget.budgetAttributesWithScenarios.accountIdPerCategory", accountIdPerCategory);
              }
              _result.push({
                isCollapse: true,
                name: categories[idx] || cat,
                idx: idx,
                budgetLine: Ember.get(treeNode, "budget"),
                level: treeNode.level + 1,
                accountId: accountIdPerCategory[idx],
                startValue: accountIdPerCategory[idx]
              });
            });
            var benefits = _budgetUtils.default.getInheritedBenefits(Ember.get(treeNode, "budget"));
            benefits.pushObjects(Ember.getWithDefault(treeNode, "budget.benefits", []));
            Ember.getWithDefault(treeNode, "budget.children", []).filterBy("isEmployee").forEach(function (child) {
              benefits.pushObjects(child.getWithDefault("benefits", []));
            });
            var accountIdPerBenefit = Ember.get(treeNode, "budget.budgetAttributesWithScenarios.accountIdPerBenefit");
            if (!accountIdPerBenefit) {
              accountIdPerBenefit = {};
              Ember.set(treeNode, "budget.budgetAttributesWithScenarios.accountIdPerBenefit", accountIdPerBenefit);
            }
            var benefitNames = benefits.mapBy("name");
            benefitNames.addObjects(Object.keys(accountIdPerBenefit));
            benefitNames = _lodash.default.uniq(benefitNames);
            benefitNames.forEach(function (benefit) {
              if (!accountIdPerBenefit[benefit]) {
                accountIdPerBenefit[benefit] = "";
              }
              _result.push({
                isCollapse: true,
                name: benefit,
                isBenefit: true,
                budgetLine: Ember.get(treeNode, "budget"),
                level: treeNode.level + 1,
                accountId: accountIdPerBenefit[benefit],
                startValue: accountIdPerBenefit[benefit]
              });
            });
          } else {
            _result.push(Line.create({
              isCollapse: true,
              budgetLine: Ember.get(treeNode, "budget"),
              level: treeNode.level,
              accountId: Ember.get(treeNode, "budget.budgetAttributesWithScenarios.accountId"),
              startValue: Ember.get(treeNode, "budget.budgetAttributesWithScenarios.accountId")
            }));
          }
        });
        return _result;
      }
    }),
    validateAccountsAndMarkDuplications: function validateAccountsAndMarkDuplications(line) {
      var lines = this.get("lines"),
        hasDuplicateAccounts = false,
        duplicateAccounts = [],
        allDuplications = [],
        lineAccounts,
        tempLineAccounts;
      if (this.get("isBS") && !Ember.isEmpty(line.accountId)) {
        lineAccounts = this.getAccountsArray(line);
        var duplicationsInLine = this.getDuplicationsInLine(lineAccounts);
        if (duplicationsInLine.length > 0) {
          Ember.setProperties(line, {
            duplicateAccounts: duplicationsInLine,
            isCollapse: false
          });
          allDuplications.pushObjects(duplicationsInLine);
          hasDuplicateAccounts = true;
        } else {
          Ember.set(line, "duplicateAccounts", undefined);
        }
        for (var i = 0; i < lines.length; i++) {
          duplicateAccounts = [];
          if (lines[i].lineId !== line.lineId && !Ember.isEmpty(lines[i].accountId)) {
            tempLineAccounts = this.getAccountsArray(lines[i]);
            for (var key = 0; key < lineAccounts.length; key++) {
              if (tempLineAccounts.indexOf(lineAccounts[key]) !== -1) {
                duplicateAccounts.push(lineAccounts[key]);
                hasDuplicateAccounts = true;
              }
            }
            if (duplicateAccounts.length > 0) {
              allDuplications.pushObjects(duplicateAccounts);
              Ember.setProperties(lines[i], {
                duplicateAccounts: duplicateAccounts,
                isCollapse: false
              });
            } else {
              Ember.set(lines[i], "duplicateAccounts", undefined);
            }
          }
        }
        Ember.set(line, "duplicateAccounts", allDuplications);
        if (allDuplications.length > 0) {
          noty({
            type: "error",
            text: "Account number".concat(allDuplications.length > 1 ? "s" : "", " ").concat(allDuplications.join(", "), " ").concat(allDuplications.length > 1 ? intl.t("general.were_1698234256411") : intl.t("general.was_1698234256411"), " mapped more than once. Please correct it before proceeding"),
            timeout: null
          });
        }
        if (hasDuplicateAccounts) {
          this.set("lastFocusElement", Ember.$());
        }
        return hasDuplicateAccounts;
      } else {
        for (var _i = 0; _i < lines.length; _i++) {
          if (lines[_i].lineId !== line.lineId && !Ember.isEmpty(lines[_i].accountId)) {
            Ember.set(lines[_i], "duplicateAccounts", undefined);
          }
        }
      }
      if (hasDuplicateAccounts) {
        this.set("lastFocusElement", Ember.$());
      }
      return hasDuplicateAccounts;
    },
    getDuplicationsInLine: function getDuplicationsInLine(accounts) {
      return _lodash.default.filter(accounts, function (value, index, iteratee) {
        return _lodash.default.includes(iteratee, value, index + 1);
      });
    },
    getAccountsArray: function getAccountsArray(line) {
      var lineAccounts = [];
      if (!Ember.isEmpty(line.accountId)) {
        var tempIds = line.accountId.split(",");
        lineAccounts = _lodash.default.compact(tempIds);
      }
      return lineAccounts;
    },
    isValidBSAccounts: false,
    saveQueue: [],
    actions: {
      changed: function changed(line) {
        if (this.get("type") === "bs") {
          if (!this.validateAccountsAndMarkDuplications(line)) {
            Ember.set(line, "startValue", Ember.get(line, "accountId"));
            this.set("isValidBSAccounts", true);
          } else {
            this.set("isValidBSAccounts", false);
          }
        } else {
          if (Ember.get(line, "budgetLine.budgetType") === "group-payroll") {
            if (Ember.get(line, "isBenefit")) {
              Ember.get(line, "budgetLine.budgetAttributesWithScenarios.accountIdPerBenefit")[line.name] = line.accountId;
            } else {
              Ember.get(line, "budgetLine.budgetAttributesWithScenarios.accountIdPerCategory")[line.idx] = line.accountId;
            }
          } else {
            Ember.set(line, "budgetLine.budgetAttributesWithScenarios.accountId", line.accountId);
          }
          Ember.set(line, "startValue", Ember.get(line, "accountId"));
          var newQueue = this.get("saveQueue").filter(function (item) {
            return Ember.get(item, "budgetLine.id") !== Ember.get(line, "budgetLine.id");
          });
          newQueue.push(line);
          this.set("saveQueue", newQueue);
        }
      },
      showLess: function showLess() {
        this.get("lines").forEach(function (line) {
          if (Ember.get(line, "accountId.length") && Ember.get(line, "accountId").split(",").length > 1 && (!Ember.get(line, "duplicateAccounts") || Ember.get(line, "duplicateAccounts").length === 0)) {
            Ember.set(line, "isCollapse", true);
          }
        });
      },
      showMore: function showMore() {
        this.get("lines").forEach(function (line) {
          if (Ember.get(line, "accountId.length") && Ember.get(line, "accountId").split(",").length > 1) {
            Ember.set(line, "isCollapse", false);
          }
        });
      }
    }
  });
});