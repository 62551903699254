define("budgeta/generic-file/components/file-upload-intro/file-upload-intro", ["exports", "@sibp/ui-components", "react", "react-redux", "budgeta/store/actions/stepsActions", "budgeta/services/ember-service-container", "budgeta/utils/const", "budgeta/config/environment"], function (_exports, _uiComponents, _react, _reactRedux, _stepsActions, _emberServiceContainer, _const, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = FileUploadIntro;
  /* eslint-disable import/no-unresolved, react/jsx-no-target-blank */

  var intl = new _emberServiceContainer.default().lookup("intl");
  var trackEvents = new _emberServiceContainer.default().lookup("trackingEvents");
  function FileUploadIntro() {
    var dispatch = _reactRedux.default.useDispatch();
    var options = {
      hideClose: true,
      height: "675px"
    };
    var header = {
      text: intl.t("generic-file.step-file-upload.header")
    };
    var body = _react.default.createElement("div", {
      className: "file-upload-intro-body-container"
    }, _react.default.createElement(_uiComponents.default.COMPONENTS.LottieAnimation, {
      animationData: _uiComponents.default.LOTTIE.FileUpload
    }), _react.default.createElement("div", {
      className: "description description-center"
    }, _react.default.createElement("div", {
      className: "intro"
    }, intl.t("generic-file.step-file-upload.intro-instructions")), _react.default.createElement("ul", {
      className: "list"
    }, _react.default.createElement("li", null, _react.default.createElement(_uiComponents.default.IMAGES.CheckSvg, null), intl.t("generic-file.step-file-upload.intro-requirment-1"), _react.default.createElement(_uiComponents.default.COMPONENTS.PopupWithLabel, {
      dataHook: "popup-1",
      popupTimeout: 1,
      tooltipContent: _react.default.createElement(_uiComponents.default.COMPONENTS.Tooltip, null),
      popupContent: _react.default.createElement(_uiComponents.default.IMAGES.TableAcrossDatesSvg, null)
    })), _react.default.createElement("li", null, _react.default.createElement(_uiComponents.default.IMAGES.CheckSvg, null), intl.t("generic-file.step-file-upload.intro-requirment-2"), _react.default.createElement(_uiComponents.default.COMPONENTS.PopupWithLabel, {
      dataHook: "popup-2",
      popupTimeout: 1,
      tooltipContent: _react.default.createElement(_uiComponents.default.COMPONENTS.Tooltip, null),
      popupContent: _react.default.createElement(_uiComponents.default.IMAGES.TableAcrossCategorySvg, null)
    })), _react.default.createElement("li", null, _react.default.createElement(_uiComponents.default.IMAGES.CheckSvg, null), intl.t("generic-file.step-file-upload.intro-requirment-3")), _react.default.createElement("li", {
      className: "download-template-link"
    }, _react.default.createElement("a", {
      href: "".concat(_environment.default.serverAddress, "/download/en-us/planning-wizard-template.xlsx"),
      target: "_blank"
    }, _react.default.createElement(_uiComponents.default.IMAGES.FileDownloadSvg, null), intl.t("generic-file.step-file-upload.download-template"))), _react.default.createElement("li", {
      className: "more-details-link"
    }, _react.default.createElement("a", {
      href: "".concat(_const.default.HELP_URL, "/intacct-planning/SIP_Topics/Upload_excel_file.htm?tocpath=Create%20your%20plan%7CUpload%20an%20Excel%20file%7C_____0"),
      target: "_blank"
    }, _react.default.createElement(_uiComponents.default.IMAGES.OpenPopupSvg, null), intl.t("generic-file.step-file-upload.read-more-details"))))));
    var button = _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      dataHook: "footer-primary-button",
      className: "footer-button primary approve big-button",
      text: intl.t("generic-file.got-it"),
      onClick: function onClick() {
        trackEvents.trackEvent(trackEvents.events.GENERIC_FILE.COMPLETED_START_SCREEN);
        dispatch(_stepsActions.default.nextStep());
      }
    }));
    var footer = {
      buttons: button
    };
    return _react.default.createElement(_uiComponents.default.COMPONENTS.ModalBase, {
      content: {
        header: header,
        footer: footer,
        body: body
      },
      options: options
    });
  }
});