define("budgeta/components/budget-select", ["exports", "budgeta/services/ember-service-container", "budgeta/utils/const", "budgeta/utils/budget-utils", "budgeta/utils/assumption", "ember-select-2/components/select-2", "lodash/lodash"], function (_exports, _emberServiceContainer, _const, _budgetUtils, _assumption, _select, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/require-super-in-init, ember/no-observers */
  var _default = _exports.default = _select.default.extend({
    store: Ember.inject.service(),
    cache: Ember.inject.service(),
    treeManager: Ember.inject.service(),
    budgetService: Ember.inject.service(),
    classNameBindings: ["budget-select", "fullSize:full-size", "isEmpty:empty-select"],
    excludeTypes: null,
    excludeSiblings: false,
    searchEnabled: true,
    mapByDatabaseId: false,
    placeholder: Ember.computed.alias("prompt"),
    selectedNotShared: false,
    isPctFixed: null,
    title: Ember.computed("selectedLabel", function () {
      var _this2 = this;
      var selectedLabel = this.get("selectedLabel");
      Ember.run.scheduleOnce("afterRender", function () {
        if (_this2.$()) {
          _this2.$().siblings().attr("title", selectedLabel);
        }
      });
      return selectedLabel;
    }),
    isEmpty: Ember.computed("showEmptyError", "selectedBudgets.length", function () {
      return this.get("showEmptyError") && !this.get("selectedBudgets.length");
    }),
    mappingProperty: Ember.computed("mapByDatabaseId", function () {
      return this.get("mapByDatabaseId") ? "id" : "uniqueId";
    }),
    mapLine: function mapLine(item) {
      return {
        id: Ember.get(item, this.get("mappingProperty")) || Ember.get(item, "uniqueId"),
        uniqueId: Ember.get(item, "uniqueId") || Ember.get(item, "id"),
        name: Ember.get(item, "uniqueName") || Ember.get(item, "name")
      };
    },
    selectedLabel: Ember.computed("content", "value", "placeholder", "optionValuePath", function () {
      var content = this.get("content");
      var selected = content && content.findBy(this.get("optionValuePath"), this.get("value"));
      if (selected) {
        return Ember.get(selected, this.get("optionLabelPath") || "name");
      }
      return "";
    }),
    init: function init() {
      var _this3 = this;
      var _this = this;
      this._super.apply(this, arguments);
      this.set("content", this.calcContent());
      if (this.get("content")) {
        this.set("content", this.get("content").map(function (line) {
          return _this3.mapLine(line);
        }));
      }
      if (this.get("selectedBudgets") && typeof this.get("selectedBudgets") === "string") {
        this.set("selectedBudgets", [this.get("selectedBudgets")]);
      }
      if (this.get("selectedBudgets.length")) {
        this.get("selectedBudgets").forEach(function (lineId) {
          var found = _this3.get("readyContent") && _this3.get("readyContent").findBy(_this.get("mappingProperty"), lineId) || _this3.get("content") && _this3.get("content").findBy(_this.get("mappingProperty"), lineId);
          if (!found) {
            var missingLine = _this3.get("mapByDatabaseId") ? _this3.get("store").peekRecord("budget", lineId) : _budgetUtils.default.findLineByUniqueId(_this3.get("store"), _this3.get("cache"), lineId);
            if (missingLine) {
              // if line is in the store, it means that it's out of the scope of the partial share
              var newItem = {
                id: lineId,
                uniqueId: lineId,
                name: intl.t("general.name_1695611601169")
              };
              (_this3.get("readyContent") || _this3.get("content")).pushObject(newItem);
              _this3.set("selectedNotShared", true);
            } else {
              _this3.get("selectedBudgets").removeObject(lineId);
            }
          } else if (Ember.get(found, "name") === "Not shared") {
            _this3.set("selectedNotShared", true);
          }
        });
      }
    },
    enabled: Ember.computed("readOnly", function () {
      return this.get("readOnly") || this.get("selectedNotShared") ? false : true;
    }),
    valueLimitObserver: Ember.observer("limit", "value.length", function () {
      if (this.get("limit") && this.get("value") && this.get("value.length") > this.get("limit")) {
        this.set("value", this.get("value").splice(1));
      }
    }),
    calcContent: function calcContent() {
      var _this = this,
        result = [],
        scenario = this.get("budgetService.scenario"),
        options = scenario ? {
          scenario: scenario
        } : {},
        contentFilter = function contentFilter(l) {
          return l && l.get("budgetModule.showInTree") && (!l.get("addedForScenarios.length") || l.get("isAddedToCurrentScenario"));
        };
      if (this.get("readyContent")) {
        var _content = this.get("readyContent").filter(contentFilter);
        if (!_content || !_content.length) {
          _content = this.get("readyContent").filterBy("budget.budgetModule.showInTree");
        }
        return _content.map(function (line) {
          return _this.get("rootName") && line.get("isRoot") ? {
            id: line.get(_this.get("mappingProperty")),
            uniqueId: line.get("uniqueId"),
            name: _this.get("rootName")
          } : _this.mapLine(line);
        });
      }
      var showAccountId = _this.get("showAccountId"),
        content = _this.get("store").peekAll("budget").filter(contentFilter);
      if (_this.get("type")) {
        var types = _this.get("type").split(",");
        if (Ember.isEmpty(_this.get("root")) || _this.get("root.isRoot")) {
          var rootId = _this.get("root.absoluteRoot.id") || _this.get("controller.model.absoluteRoot.id");
          result = content.filter(function (item) {
            var reject = false;
            if (!item || item.get("absoluteRoot.id") !== rootId) {
              reject = true;
            }
            if (item.get("uniqueId") !== _this.get("selectedBudgets") && !_const.default.isLineIncludedInScenario(item, _this.get("budgetService.scenario"))) {
              return false;
            }
            if (!Ember.isEmpty(_this.get("isPctFixed"))) {
              var lineCurrency = _assumption.default.getModelLineCurrency(_this.get("store"), item, options);
              if (!lineCurrency || _this.get("isPctFixed") && lineCurrency.length < 3 || _this.get("isPctFixed") === 0 && lineCurrency.length > 1) {
                reject = true;
              }
            }
            if (item.get(_this.get("mappingProperty")) === _this.get("value") || Ember.isArray(_this.get("selectedBudgets")) && _this.get("selectedBudgets").includes(item.get(_this.get("mappingProperty")))) {
              // never filter out a line that is selected
              if (reject) {
                if (Ember.isArray(_this.get("selectedBudgets")) && _this.get("selectedBudgets.length") > 1) {
                  _this.get("selectedBudgets").removeObject(item.get(_this.get("mappingProperty")));
                } else {
                  _this.setProperties({
                    selectedBudgets: null,
                    value: null
                  });
                }
                return false;
              } else {
                return true;
              }
            }
            if (reject || _this.get("excludeSiblings") && _this.get("sourceLine.parent") === item.get("parent")) {
              return false;
            }
            if (_this.get("onlyCurrencyModelLines")) {
              var _lineCurrency = _assumption.default.getModelLineCurrency(_this.get("store"), item, options);
              if (!_lineCurrency || _lineCurrency.length < 3) {
                return false;
              }
            }
            // BUD-4402 if employee is transferred don't show it
            if (_this.get("type") === "employee") {
              var transfer = _this.get("treeManager.budgetsList").find(function (emp) {
                return emp && Ember.get(emp, "budget.id") !== _this.get("sourceLine.id") && Ember.get(emp, "budget.budgetType") === "employee-transferred" && Ember.get(emp, "budget.budgetAttributes.allocatedFrom") === item.get(_this.get("mappingProperty"));
              });
              if (transfer) {
                return false;
              }
            }
            return (item.get("id") !== _this.get("budget.id") && item.get("id") !== _this.get("forLine.id") || _this.get("includeSelf")) && types.indexOf(item.get("budgetType")) >= 0 && (Ember.isEmpty(_this.get("excludeBy")) || !item.get(_this.get("excludeBy")));
          });
        } else {
          result = _const.default.findBudgetsByTypes(_this.get("root"), types);
        }
        var tree = _budgetUtils.default.buildTreeForBudgetsArray(result);
        var duplicateNames = _lodash.default.filter(result.mapBy("name"), function (value, index, iteratee) {
          return _lodash.default.includes(iteratee, value, index + 1);
        });
        result = result.filter(function (item) {
          return !item.get("name").startsWith("ENCRYPTED_");
        }).map(function (item) {
          var name = item.get("name");
          if (item.get("isModelLine")) {
            name = name.startsWith("ENCRYPTED_") ? "Not shared" : item.get("parent.name") + " > " + item.get("name");
          } else if (duplicateNames.includes(name)) {
            name = _budgetUtils.default.getBudgetPathFromTree(tree, item, {
              includeSelf: true
            });
          }
          return {
            id: item.get(_this.get("mappingProperty")),
            uniqueId: item.get("uniqueId"),
            name: name.startsWith("ENCRYPTED_") ? "Not shared" : (showAccountId ? (item.get("accountIds") || "") + " " : "") + name
          };
        });
      } else {
        if (_this.get("includeEmployeeCount")) {
          if (Ember.isEmpty(_this.get("exclude")) || _this.get("exclude").indexOf("#employee-count#") < 0) {
            result.push(Ember.Object.create({
              id: "#employee-count#",
              uniqueId: "#employee-count#",
              name: intl.t("general.name_1695611601215")
            }));
          }
          if (Ember.isEmpty(_this.get("exclude")) || _this.get("exclude").indexOf("#employee-count-new#") < 0) {
            result.push(Ember.Object.create({
              id: "#employee-count-new#",
              uniqueId: "#employee-count-new#",
              name: intl.t("general.name_1695611601202")
            }));
          }
        }
        content = content.filter(function (item) {
          return item && (item.get("id") !== _this.get("budget.id") || _this.get("includeSelf")) && (_this.get("root.isRoot") && item.get("root.id") === _this.get("root.id") || !_this.get("root.isRoot") && _const.default.isAncestor(_this.get("root"), item)) && (Ember.isEmpty(_this.get("exclude")) || _this.get("exclude").indexOf(item.get("id")) < 0) && (Ember.isEmpty(_this.get("excludeTypes")) || _this.get("excludeTypes").split(",").indexOf(item.get("budgetType")) < 0);
        });
        result = result.concat(content.map(function (item) {
          var name = item.getWithDefault("uniqueName", item.get("budgetModule.name") + intl.t("general._(not_shared)_1698234255146"));
          if (item.get("isRoot") && _this.get("rootName")) {
            name = _this.get("rootName");
          }
          return {
            id: item.get(_this.get("mappingProperty")),
            uniqueId: item.get("uniqueId"),
            name: (showAccountId ? (item.get("accountIds") || "") + " " : "") + name
          };
        })).sortBy("name");
        if (_this.get("showUncategorizedActuals")) {
          result.insertAt(0, Ember.Object.create({
            id: "#uncat-rev#",
            uniqueId: "#uncat-rev#",
            name: intl.t("general.name_1695611601192")
          }));
          result.insertAt(0, Ember.Object.create({
            id: "#uncat-exp#",
            uniqueId: "#uncat-exp#",
            name: intl.t("general.name_1695611601180")
          }));
        }
      }
      if (this.get("selectedBudgets") && !Ember.isArray(this.get("selectedBudgets")) && !result.mapBy("id").includes(this.get("selectedBudgets"))) {
        var missingLine = this.get("mapByDatabaseId") ? this.get("store").peekRecord("budget", this.get("selectedBudgets")) : _budgetUtils.default.findLineByUniqueId(this.get("store"), this.get("cache"), this.get("selectedBudgets"));
        if (missingLine) {
          // if line is in the store, it means that it's out of the scope of the partial share
          var newItem = {
            id: this.get("selectedBudgets"),
            uniqueId: this.get("selectedBudgets"),
            name: intl.t("general.name_1695611601169")
          };
          result.pushObject(newItem);
        } else {
          this.set("selectedBudgets", undefined);
        }
      }
      return result;
    },
    recalcContent: Ember.observer("isPctFixed", function () {
      this.set("content", this.calcContent());
    }),
    optionLabelPath: "name",
    optionValuePath: "id",
    value: Ember.computed.alias("selectedBudgets")
  });
});