define("budgeta/templates/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "c5ok7OWo",
    "block": "{\"statements\":[[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"main-content login-page\"],[\"flush-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"content-page centerElement\"],[\"flush-element\"],[\"text\",\"\\n    \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"page-header\"],[\"flush-element\"],[\"append\",[\"helper\",[\"t\"],[\"error.oops\"],null],false],[\"close-element\"],[\"text\",\"\\n    \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"center\"],[\"flush-element\"],[\"text\",\"\\n      \"],[\"append\",[\"helper\",[\"t\"],[\"error.unexpected-error\"],null],false],[\"text\",\"\\n      \"],[\"open-element\",\"br\",[]],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n      \"],[\"append\",[\"helper\",[\"t\"],[\"error.go-back\"],null],true],[\"text\",\"\\n      \"],[\"open-element\",\"h5\",[]],[\"flush-element\"],[\"append\",[\"helper\",[\"t\"],[\"error.additional-information\"],null],false],[\"close-element\"],[\"text\",\"\\n      \"],[\"append\",[\"unknown\",[\"model\",\"responseJSON\",\"message\"]],false],[\"text\",\"\\n      \"],[\"open-element\",\"br\",[]],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n      \"],[\"append\",[\"unknown\",[\"model\",\"responseText\"]],false],[\"text\",\"\\n    \"],[\"close-element\"],[\"text\",\"\\n  \"],[\"close-element\"],[\"text\",\"\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/error.hbs"
    }
  });
});