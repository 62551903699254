define("budgeta/generic-file/components/table-row-column-step/table-row-column-step", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "@babel/runtime/helpers/esm/objectWithoutProperties", "react", "@sibp/ui-components", "react-redux", "budgeta/store/actions/stepsActions", "budgeta/store/actions/budgetActions", "budgeta/services/ember-service-container", "budgeta/utils/useEmberBridge", "budgeta/utils/import", "lodash/lodash"], function (_exports, _slicedToArray2, _objectWithoutProperties2, _react, _uiComponents, _reactRedux, _stepsActions, _budgetActions, _emberServiceContainer, _useEmberBridge, _import, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.HeadersSelectionUpdatesTypes = void 0;
  _exports.default = TableRowColumnStep;
  var _excluded = ["startTableAction", "getDataAction", "endTableAction", "updateDataAction"];
  /* eslint-disable no-console */
  var intl = new _emberServiceContainer.default().lookup("intl");
  var trackEvents = new _emberServiceContainer.default().lookup("trackingEvents");
  var HeadersSelectionUpdatesTypes = _exports.HeadersSelectionUpdatesTypes = {
    OLD_VALUES: "OLD_VALUES",
    DROP_DOWN_CHANGED: "DROP_DOWN_CHANGED",
    NEXT_STEP: "NEXT_STEP"
  };
  function TableRowColumnStep(_ref) {
    var startTableAction = _ref.startTableAction,
      getDataAction = _ref.getDataAction,
      endTableAction = _ref.endTableAction,
      updateDataAction = _ref.updateDataAction,
      rest = (0, _objectWithoutProperties2.default)(_ref, _excluded);
    var dispatch = _reactRedux.default.useDispatch();
    var stepName = _reactRedux.default.useSelector(function (state) {
      return state.stepsStore.currentStep;
    });
    var fileData = _reactRedux.default.useSelector(function (state) {
      return state.fileStore.file;
    });
    var numberOfRows = _reactRedux.default.useSelector(function (state) {
      return state.fileStore.formattedFile.numOfRows;
    });
    var numberOfColumns = _reactRedux.default.useSelector(function (state) {
      return state.fileStore.formattedFile.numOfColumns;
    });
    var headerLineNumber = _reactRedux.default.useSelector(function (state) {
      return state.budgetStore.headerLineNumber;
    });
    var nameColumn = _reactRedux.default.useSelector(function (state) {
      return state.budgetStore.nameColumn;
    });
    var nameColumnIsAccount = _reactRedux.default.useSelector(function (state) {
      return state.budgetStore.nameColumnIsAccount;
    });
    var _React$useState = _react.default.useState(headerLineNumber),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      currHeaderLine = _React$useState2[0],
      setCurrHeaderLine = _React$useState2[1];
    var _React$useState3 = _react.default.useState(nameColumn),
      _React$useState4 = (0, _slicedToArray2.default)(_React$useState3, 2),
      currMainColumn = _React$useState4[0],
      setCurrMainColumn = _React$useState4[1];
    var _React$useState5 = _react.default.useState(nameColumnIsAccount),
      _React$useState6 = (0, _slicedToArray2.default)(_React$useState5, 2),
      currMainColumnIsAccountColumn = _React$useState6[0],
      setMainColumnIsAccountColumn = _React$useState6[1];
    var columnsNames = [];
    (0, _useEmberBridge.default)("headerLineNumber", function () {
      var data = getDataAction(stepName);
      setCurrHeaderLine(data.headerLineNumber);
    });
    (0, _useEmberBridge.default)("nameColumn", function () {
      var data = getDataAction(stepName);
      setCurrMainColumn(data.nameColumn);
    });
    (0, _useEmberBridge.default)("currentStep", function () {
      var stepData = getDataAction(stepName);
      stepData["nameColumnIsAccount"] = currMainColumnIsAccountColumn;
      endTableAction(stepName, {
        removeListeners: true,
        closeTable: true
      });
      updateDataAction(stepName, stepData, {
        updateType: HeadersSelectionUpdatesTypes.NEXT_STEP
      });
      if (!_lodash.default.isEqual(headerLineNumber, stepData.headerLineNumber) || !_lodash.default.isEqual(nameColumn, stepData.nameColumn) || !_lodash.default.isEqual(nameColumnIsAccount, stepData.nameColumnIsAccount)) {
        dispatch(_budgetActions.default.setHeaderRowMainColumn(stepData));
        rest.clearDependencies();
      }
      dispatch(_stepsActions.default.nextStep());
    });
    _react.default.useEffect(function () {
      startTableAction(stepName, {
        file: fileData,
        currentStep: 0,
        clear: !headerLineNumber && !nameColumn
      });
      if (headerLineNumber || nameColumn || nameColumnIsAccount) {
        var updateData = {};
        headerLineNumber && (updateData["headerLineNumber"] = headerLineNumber);
        nameColumn && (updateData["nameColumn"] = nameColumn);
        nameColumnIsAccount && (updateData["nameColumnIsAccount"] = nameColumnIsAccount);
        updateDataAction(stepName, updateData, {
          updateType: HeadersSelectionUpdatesTypes.OLD_VALUES
        });
      } else {
        var data = getDataAction(stepName);
        setCurrMainColumn(data.nameColumn);
        setCurrHeaderLine(data.headerLineNumber);
      }
    }, [fileData]);
    var handleDataValidation = function handleDataValidation() {
      return true;
    };
    var handleNextButton = function handleNextButton() {
      trackEvents.trackEvent(trackEvents.events.GENERIC_FILE.COMPLETED_HEADERS_SELECT);
      var stepData = getDataAction(stepName);
      stepData["nameColumnIsAccount"] = currMainColumnIsAccountColumn;
      if (handleDataValidation(stepData)) {
        endTableAction(stepName);
      } else {
        console.log("Validation Error");
      }
    };
    var getColumnsNames = function getColumnsNames(columnsAmount) {
      return Array.from(Array(columnsAmount).keys()).map(function (i) {
        return _import.default.colToLetterRef(i + 1);
      });
    };
    var getColumnLocation = function getColumnLocation(column) {
      return column && _import.default.getCol(column);
    };
    var headerLineChangedHandler = function headerLineChangedHandler(selection) {
      var newHeaderLine = selection.value;
      var updatedData = {
        headerLineNumber: newHeaderLine,
        nameColumn: getColumnLocation(currMainColumn) + newHeaderLine
      };
      updateDataAction(stepName, updatedData, {
        updateType: HeadersSelectionUpdatesTypes.DROP_DOWN_CHANGED
      });
      setCurrHeaderLine(newHeaderLine);
    };
    var mainColumnChangedHandler = function mainColumnChangedHandler(selection) {
      var updatedData = {
        headerLineNumber: currHeaderLine,
        nameColumn: selection.value + currHeaderLine
      };
      updateDataAction(stepName, updatedData, {
        updateType: HeadersSelectionUpdatesTypes.DROP_DOWN_CHANGED
      });
      setCurrMainColumn(selection.value + currHeaderLine);
    };
    var checkboxChangeHandler = function checkboxChangeHandler(checked) {
      setMainColumnIsAccountColumn(checked);
    };
    var getSelectionBox = function getSelectionBox() {
      columnsNames.length === 0 && numberOfColumns && (columnsNames = getColumnsNames(numberOfColumns));
      return _react.default.createElement("div", {
        className: "selection-box"
      }, _react.default.createElement("div", {
        className: "headers-selectors"
      }, _react.default.createElement("div", {
        className: "main-row"
      }, _react.default.createElement("label", null, intl.t("generic-file.step-row-column-selection.header-line-select-label")), _react.default.createElement(_uiComponents.default.COMPONENTS.Select, {
        classNames: "dropdown-container main-row-dropdown",
        dataHook: "main-row",
        value: {
          label: "".concat(intl.t("generic-file.step-row-column-selection.row-option"), " ").concat(currHeaderLine),
          value: currHeaderLine
        },
        options: Array.from({
          length: numberOfRows - 1
        }, function (_, i) {
          return {
            label: "".concat(intl.t("generic-file.step-row-column-selection.row-option"), " ").concat(i + 1),
            value: i + 1
          };
        }),
        onChange: function onChange(e) {
          return headerLineChangedHandler(e);
        },
        menuPlacement: "auto"
      })), _react.default.createElement("div", {
        className: "main-column"
      }, _react.default.createElement("label", null, intl.t("generic-file.step-row-column-selection.main-column-select-label")), _react.default.createElement(_uiComponents.default.COMPONENTS.Select, {
        classNames: "dropdown-container main-column-dropdown",
        dataHook: "main-column",
        value: {
          label: "".concat(intl.t("generic-file.step-row-column-selection.column-option"), " ").concat(getColumnLocation(currMainColumn)),
          value: getColumnLocation(currMainColumn)
        },
        options: columnsNames.map(function (column) {
          return {
            label: "".concat(intl.t("generic-file.step-row-column-selection.column-option"), " ").concat(column),
            value: column
          };
        }),
        onChange: function onChange(e) {
          return mainColumnChangedHandler(e);
        },
        menuPlacement: "auto"
      }))), _react.default.createElement("div", {
        className: "default-selection-checkbox checkbox-label"
      }, _react.default.createElement(_uiComponents.default.COMPONENTS.Checkbox, {
        label: intl.t("generic-file.step-row-column-selection.checkbox-label"),
        dataHook: "main-column-is-account",
        checked: currMainColumnIsAccountColumn,
        onChange: checkboxChangeHandler
      })));
    };
    var buttons = _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      dataHook: "footer-secondary-button",
      className: "footer-button secondary back big-button",
      text: intl.t("generic-file.back"),
      onClick: function onClick() {
        var stepData = getDataAction(stepName);
        stepData["nameColumnIsAccount"] = currMainColumnIsAccountColumn;
        if (!_lodash.default.isEqual(headerLineNumber, stepData.headerLineNumber) || !_lodash.default.isEqual(nameColumn, stepData.nameColumn) || !_lodash.default.isEqual(nameColumnIsAccount, stepData.nameColumnIsAccount)) {
          dispatch(_budgetActions.default.setHeaderRowMainColumn(stepData));
          rest.clearDependencies();
        }
        endTableAction(stepName, {
          removeListeners: true,
          closeTable: true,
          back: true
        });
        dispatch(_stepsActions.default.prevStep());
      }
    }), _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      dataHook: "footer-primary-button",
      className: "footer-button primary approve big-button",
      text: intl.t("generic-file.next"),
      onClick: handleNextButton,
      disabled: false
    }));
    var footer = {
      buttons: buttons
    };
    return _react.default.createElement(_react.default.Fragment, null, currMainColumn && currHeaderLine && _react.default.createElement(_uiComponents.default.COMPONENTS.MoveablePlaceholder, {
      dataHook: "headers-moveable-placeholder",
      content: getSelectionBox()
    }), _react.default.createElement(_uiComponents.default.COMPONENTS.Footer, footer), ";");
  }
});