define("budgeta/utils/budget-modules-util", ["exports", "budgeta/services/ember-service-container", "budgeta/utils/const", "budgeta/utils/object-util"], function (_exports, _emberServiceContainer, _const, _objectUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _translateObject = function _translateObject(object) {
    var intl = new _emberServiceContainer.default().lookup("intl");
    var isCondition = function isCondition(_ref) {
      var key = _ref.key;
      return key === _const.default.BUDGET_MODULES_TRANSLATIONS.TRANSLATION_KEYS;
    };
    var onConditionFulfilled = function onConditionFulfilled(_ref2) {
      var key = _ref2.key,
        object = _ref2.object;
      for (var innerKey in object[key]) {
        var translationKey = object[key][innerKey];
        var translationValue = intl.t(translationKey);
        if (translationValue.startsWith("[")) {
          object[innerKey] = JSON.parse(translationValue);
        } else {
          object[innerKey] = translationValue;
        }
      }
      delete object[key];
    };
    _objectUtil.default.iterateAllKeys({
      object: object,
      isCondition: isCondition,
      onConditionFulfilled: onConditionFulfilled
    });
    _translateSectionsKeys(object.sections);
    return object;
  };
  var _translateSectionsKeys = function _translateSectionsKeys(sections) {
    if (!sections) {
      return;
    }
    var intl = new _emberServiceContainer.default().lookup("intl");
    var sectionsTranslationObject = sections[_const.default.BUDGET_MODULES_TRANSLATIONS.TRANSLATION_SECTIONS_KEYS];
    for (var sectionName in sectionsTranslationObject) {
      if (!sections[sectionName]) {
        continue;
      }
      var translationKey = sectionsTranslationObject[sectionName];
      var translationValue = intl.t(translationKey);
      if (sectionName !== translationValue) {
        sections[translationValue] = sections[sectionName];
        delete sections[sectionName];
      }
    }
    delete sections[_const.default.BUDGET_MODULES_TRANSLATIONS.TRANSLATION_SECTIONS_KEYS];
  };
  var translateBudgetModules = function translateBudgetModules(budgetModules) {
    budgetModules.forEach(function (module) {
      var translatedObject = _translateObject(module.data);
      for (var key in translatedObject) {
        module.set(key, translatedObject[key]);
      }
    });
  };
  var _default = _exports.default = {
    translateBudgetModules: translateBudgetModules
  };
});