define("budgeta/app", ["exports", "budgeta/resolver", "ember-load-initializers", "budgeta/config/environment"], function (_exports, _resolver, _emberLoadInitializers, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var App;
  Ember.MODEL_FACTORY_INJECTIONS = true;
  Ember.HELPER_PARAM_LOOKUPS = true;
  App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default,
    customEvents: {
      touchend: "click"
    }
  });
  var popupError = function popupError() {
    var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "An unexpected error has occurred. <a href='/'>Click here to reload the application.</a>";
    var timeout = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    noty({
      type: "error",
      timeout: timeout,
      text: message
    });
  };
  Ember.onerror = function (error) {
    if (Ember.testing) {
      throw error;
    }
    Ember.Logger.error(error.stack);
    // don't popup error for "Unknown name" error on IE
    var status = error && Ember.get(error, "errors.firstObject.status");
    if (error && error.status === 503) {
      popupError("The server is temporarily unavailable, please try again", 5000);
    } else if (error && error !== true && status !== "503" && (!error.message || error.message.indexOf("Unknown name.") !== 0) && error.message !== "missing" && error.message !== "Assertion Failed: calling set on destroyed object" && error.message !== "Cannot use 'in' operator to search for 'id' in null" && error.message !== "Cannot read property 'removeClass' of null") {
      Ember.run.throttle(this, popupError, 60000);
    }
  };

  // Log Ember promise errors
  Ember.RSVP.on("error", function (error) {
    if (error && error !== true && error.name !== "TransitionAborted") {
      Ember.Logger.warn(error);
    }
  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = _exports.default = App;
});