define("budgeta/components/budgeta-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNameBindings: [":budgeta-checkbox", "disabled:disabled"],
    attributeBindings: ["placeholder", "data-hide", "data-hide-unless", "data-key", "data-order", "data-type-ref", "data-hook"],
    "data-hook": Ember.computed("title", function () {
      return "budgeta-checkbox-value-for-" + this.get("title");
    }),
    inheritChecked: Ember.computed("placeholder", "checked", function () {
      return this.get("checked") === null && (typeof this.get("placeholder") === "string" && this.get("placeholder") === "true" || typeof this.get("placeholder") !== "string" && this.get("placeholder"));
    }),
    inputId: Ember.computed("elementId", function () {
      return "cb" + this.get("elementId");
    }),
    change: function change() {
      if (this.get("action") && !this.get("disabled")) {
        this.sendAction("action", !this.get("checked"), this.get("actionArg"), this.get("actionArg2"));
      }
    }
  });
});