define("budgeta/utils/import", ["exports", "@babel/runtime/helpers/esm/typeof", "budgeta/services/ember-service-container", "budgeta/utils/const", "budgeta/utils/assumption", "budgeta/utils/budget-utils", "lodash/lodash"], function (_exports, _typeof2, _emberServiceContainer, _const, _assumption, _budgetUtils, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/new-module-imports, ember/no-observers, no-useless-escape */

  var statementTypes = {
    pnl: intl.t("general.p&l_1698234256719"),
    cash: intl.t("general.name_1695611602487"),
    both: intl.t("general.p&l_and_cash_1698234257341"),
    bs: intl.t("general.name_1695611604193"),
    stat: intl.t("general.name_1695611604096")
  };
  var hebrewMonthsMap = {
    ינואר: "01",
    פברואר: "02",
    מרץ: "03",
    אפריל: "04",
    מאי: "05",
    יוני: "06",
    יולי: "07",
    אוגוסט: "08",
    ספטמבר: "09",
    אוקטובר: "10",
    נובמבר: "11",
    דצמבר: "12"
  };
  var fullMonthNameRegExp = /(january|february|march|april|may|june|july|august|september|october|november|december)/gi;
  var partialMonthNameRegExp = /\b(jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)\b/gi;
  var Item = Ember.Object.extend({
    ctl: null,
    store: null,
    mapping: null,
    bsLine: "assets",
    category: Ember.computed("isCategoryDimensionMapped", "showValues", "ctl.categoryHeaderKey", function () {
      var _this2 = this;
      if (this.get("ctl.isCategoryDimensionMapped")) {
        var categoryColumn = this.get("showValues.length") && this.get("showValues").find(function (value) {
          return value.headerKey === _this2.get("ctl.categoryHeaderKey");
        });
        return categoryColumn && categoryColumn.value && categoryColumn.value.w;
      }
      return null;
    }),
    selected: Ember.computed.notEmpty("mapping"),
    selectedFirst: false,
    value: null,
    location: null,
    statement: "both",
    isBalanceSheet: Ember.computed.equal("statement", "bs"),
    displayType: Ember.computed("statement", function () {
      return statementTypes[this.get("statement")];
    }),
    currency: null,
    parent: null,
    budgetType: null,
    isPayroll: Ember.computed("mapping", function () {
      var budgetLine = this.get("mapping") && this.get("store").peekRecord("budget", this.get("mapping"));
      return budgetLine && budgetLine.get("budgetType") === "group-payroll";
    }),
    selectedObserver: Ember.observer("selectedFirst", function () {
      if (this.get("selectedFirst") && !this.get("budgetType")) {
        this.set("budgetType", this.get("ctl.budgetTypes.firstObject.id"));
      }
    }),
    statementObserver: Ember.observer("statement", function () {
      this.set("ctl.checkBalances", true);
      if (this.get("ctl.disableMultiEdit") || this.get("ctl.noMultiChangeStatement")) {
        return;
      }
      if (this.get("selectedFirst")) {
        this.set("ctl.disableMultiEdit", true);
        var thisStatement = this.get("statement");
        this.get("ctl.lineNames").forEach(function (line) {
          if (line && Ember.get(line, "selectedFirst") && Ember.get(line, "statement") !== thisStatement) {
            Ember.set(line, "statement", thisStatement);
          }
        });
        this.set("ctl.disableMultiEdit", false);
      }
      if (this.get("statement") === "bs" && !this.get("bsLine")) {
        this.set("bsLine", "assets");
      }
    }),
    mappedBudget: Ember.computed("mapping", "statement", "bsLine", function () {
      if (this.get("statement") === "bs") {
        return this.get("ctl.balanceSheetLines").findBy("id", this.get("bsLine"));
      }
      if (this.get("mapping") === "#uncat-rev#") {
        return {
          name: intl.t("general.name_1695611601192")
        };
      }
      if (this.get("mapping") === "#uncat-exp#") {
        return {
          name: intl.t("general.name_1695611601180")
        };
      }
      return this.get("mapping") ? this.get("store").peekRecord("budget", this.get("mapping")) : null;
    }),
    mappingOptions: Ember.computed("isBalanceSheet", function () {
      return this.get("isBalanceSheet") ? this.get("ctl.balanceSheetLines") : this.get("ctl.budgetLinesForMapping");
    }),
    selectedMapping: Ember.computed("isBalanceSheet", "bsLine", "mapping", {
      get: function get() {
        return this.get("isBalanceSheet") ? this.get("bsLine") : this.get("mapping");
      },
      set: function set(key, value) {
        if (this.get("isBalanceSheet")) {
          this.set("bsLine", value);
        } else {
          this.set("mapping", value);
        }
        return value;
      }
    }),
    categoryDisplay: Ember.computed("category", function () {
      var _this3 = this;
      if (!this.get("category")) return "";
      var categoryDimension = this.get("ctl.budgetService.categoryDimension");
      var categoryValue = categoryDimension.get("values").find(function (value) {
        return value.name === _this3.get("category");
      });
      return categoryValue && categoryValue.displayName;
    })
  });
  var keysDiff = function keysDiff(key1, key2) {
    return moment(key1, _const.default.MomentYearMonthDateFormat).diff(moment(key2, _const.default.MomentYearMonthDateFormat), "months");
  };
  var getCellValue = function getCellValue(cell, multiplier, keepNegative, isNumeric) {
    if (!cell) {
      return null;
    }
    var value;
    if (isNumeric) {
      value = Ember.get(cell, "v");
    }
    if (!value || Math.round(value) === Math.round(Ember.get(cell, "w"))) {
      value = Ember.get(cell, "w") ? Ember.get(cell, "w").replace(/,/g, "") * 1 : null;
    }
    if (!value || isNaN(value)) {
      value = Ember.get(cell, "v");
      if (isNaN(value)) {
        value = Ember.get(cell, "w").replace(/[^0-9.,]/g, "");
        Ember.set(cell, "v", value ? Number(value) : NaN);
      }
    }
    if (Ember.get(cell, "w") && Ember.get(cell, "w").endsWith("%")) {
      var realValue = Number(Ember.get(cell, "w").replace("%", ""));
      if (!isNaN(realValue)) {
        value = _const.default.round(realValue / 100, 6);
      }
    }
    var result = value * multiplier;
    if (!keepNegative) {
      result = Math.abs(result);
    }
    return _const.default.round(result, 6);
  };
  var getTypeOfAmount = function getTypeOfAmount(root, values, checkType) {
    var type;
    var symbol;
    values.forEach(function (value) {
      if (!value || value.v === 0) {
        return;
      } else if (checkType) {
        if (value.w.includes("%")) {
          type = "%";
        } else if (value.w.includes(".")) {
          type = ".";
        }
      }
      if (!symbol) {
        symbol = (value.w || "").replace(/[0-9,.%()-\s]/g, "");
      }
    });
    return symbol ? _const.default.getCurrnecyName(root, symbol) || "#" : type ? type : "#";
  };
  var toCustomAmounts = function toCustomAmounts(values, rowNum, dateCols, date1904, multiplier, keepNegative) {
    var result = {};
    var keys = [];
    dateCols.forEach(function (col) {
      var keyMoment = Ember.get(col, "selectedMonth");
      if (!moment.isMoment(keyMoment)) {
        keyMoment = moment(keyMoment);
      }
      var key = keyMoment.format("[m]MMYYYY");
      if (key && keyMoment.isValid()) {
        while (keys.indexOf(key) >= 0) {
          keyMoment.add(1, "year");
          key = keyMoment.format("[m]MMYYYY");
        }
        keys.push(key);
        var location = ImportUtils.getCol(Ember.get(col, "key")) + rowNum;
        result[key] = getCellValue(values[location], multiplier, keepNegative, true);
      }
    });
    return result;
  };
  var getKeyMoment = function getKeyMoment(dateCols, loc, date1904, defaultYear) {
    var col = ImportUtils.getCol(loc);
    var dateCol = dateCols.find(function (i) {
      return ImportUtils.getCol(i.get("key")) === col;
    });
    if (dateCol) {
      return ImportUtils.getKeyMoment(dateCol.value, date1904, defaultYear);
    }
    return null;
  };
  var filterHidenColumns = function filterHidenColumns(dateCols, itemValues) {
    var returnItems = {},
      keys = dateCols.map(function (ele) {
        return ImportUtils.getCol(ele.key);
      });
    for (var k in itemValues) {
      if (keys.indexOf(ImportUtils.getCol(k)) !== -1) {
        returnItems[k] = itemValues[k];
      }
    }
    return returnItems;
  };
  var getStartlocation = function getStartlocation(dateCols, item) {
    var startLocation,
      itemValues = filterHidenColumns(dateCols, item.values);
    for (var k in itemValues) {
      if (ImportUtils.colToNumber(ImportUtils.getCol(k)) >= ImportUtils.colToNumber(ImportUtils.getCol(dateCols[0].key)) && itemValues[k] && itemValues[k].v !== 0) {
        startLocation = k;
        break;
      }
    }
    return startLocation;
  };
  var _getRealMoment = function getRealMoment(passYears, keyMoment) {
    var newMoment, newYear, newMonth, oldYear, oldMonth;
    if (!keyMoment) {
      return keyMoment;
    }
    newMoment = moment(keyMoment);
    newYear = newMoment.year();
    newMonth = newMoment.month();
    oldYear = Object.keys(passYears)[Object.keys(passYears).length - 1];
    oldMonth = passYears[Object.keys(passYears)[Object.keys(passYears).length - 1]];
    if (typeof oldYear !== "undefined" && typeof oldMonth !== "undefined") {
      oldYear *= 1;
      oldMonth *= 1;
      if (newYear > oldYear) {
        passYears[newYear] = newMonth;
      } else if (newYear === oldYear && newMonth > oldMonth && newMonth <= 12) {
        passYears[newYear] = newMonth;
      } else {
        // increase year
        if (newMonth > oldMonth && newMonth <= 12) {
          newYear = oldYear;
        } else {
          newYear = oldYear + 1;
        }
        passYears[newYear] = newMonth;
        newMoment.year(newYear);
      }
    } else {
      passYears[newYear] = newMonth;
    }
    return newMoment;
  };
  var numOfNonZeros = function numOfNonZeros(tempCustomAmounts) {
    var diffThenZero = 0;
    for (var key in tempCustomAmounts) {
      var tempValue = tempCustomAmounts[key];
      if (tempValue !== 0 && tempValue) {
        diffThenZero++;
      }
    }
    return diffThenZero;
  };
  var getFirstNonEmptyMonth = function getFirstNonEmptyMonth(tempCustomAmounts) {
    for (var key in tempCustomAmounts) {
      var tempValue = tempCustomAmounts[key];
      if (tempValue !== 0 && tempValue) {
        return {
          d: Date.create(key.substr(1).replace(/^(\d{2})(\d{4})/, "$1-$2")),
          v: tempValue
        };
      }
    }
    return null;
  };
  var fixedItemValues = function fixedItemValues(item) {
    if (item.get("values")) {
      for (var key in item["values"]) {
        if (item.get("values.".concat(key, ".v"))) {
          Ember.set(item, "values.".concat(key, ".v"), _const.default.toFixed(item.values[key].v, 2));
        }
      }
    }
  };
  var ImportUtils = {
    Item: Item,
    HeaderColumn: Ember.Object.extend({
      ctl: null,
      col: null,
      hide: Ember.computed.alias("col.hide"),
      selectedAttribute: Ember.computed.alias("col.selectedAttribute"),
      displaySelectedAttribute: Ember.computed("col.selectedAttribute", "ctl.mapByDepartment", function () {
        if (!this.get("col.selectedAttribute")) {
          return null;
        }
        if (this.get("ctl.mapByDepartment")) {
          var selectedDep = (this.get("ctl.departmentDimension.values") || Ember.A([])).findBy("uniqueId", this.get("col.selectedAttribute"));
          if (selectedDep) {
            return Ember.get(selectedDep, "name");
          }
        }
        var result;
        if (this.get("col.selectedAttribute").startsWith("dimensions.")) {
          result = this.get("ctl.budgetService.dimensions").findBy("uniqueId", this.get("col.selectedAttribute").split(".")[1]).get("name");
        } else {
          result = _const.default.camelize(this.get("col.selectedAttribute"));
        }
        return result;
      }),
      firstDateColSelected: Ember.observer("col.hide", "isDate", function () {
        if (this.get("ctl.autoDateSelection") || this.get("ctl.importColumnBeforeRows") && this.get("ctl.currentStep") < 3 && !this.get("ctl.step2") || !this.get("ctl.importColumnBeforeRows") && this.get("ctl.currentStep") < 2) {
          return;
        }
        if (this.get("col.hide") && this.get("ctl.firstColumnHide")) {
          this.get("ctl.modal").show({
            text: "<h1>".concat(intl.t("general.would_you_like_us_to_unselect_all_the_columns_for_you_1695803192796"), "</h1>"),
            selector: ".import-preview .scroll-list.fixed.top.main",
            backupSelector: ".import-top",
            controller: this.get("ctl"),
            okLabel: intl.t("general.confirmActionName_1695611613620"),
            cancelLabel: intl.t("general.no_1698234256618"),
            okAction: "unselectAllColumns"
          });
          this.set("ctl.firstColumnHide", false);
        }
        if (this.get("isDate") && !this.get("col.hide") && this.get("ctl.currentTypeMappings").filterBy("hasValidDateValue").length > 1 && this.get("ctl.currentTypeMappings").filter(function (col) {
          return !Ember.get(col, "col.hide") && Ember.get(col, "isDate");
        }).length === 1) {
          var messageSelector = ".import-preview .scroll-list.fixed.top.main .column.date-column:has(.selected-column):eq(0)";
          var rangeCheck = ImportUtils.checkImportRange(this.get("ctl.headerColumns"), this.get("ctl.model.root"), this.get("ctl.date1904"), this.get("ctl.customStartDate"), this.get("ctl.customEndDate"));
          if (rangeCheck && !isNaN(rangeCheck.diff) && !this.get("ctl.importModelLines")) {
            var firstCol = this.get("ctl.currentTypeMappings").filterBy("isDate")[0];
            if (firstCol) {
              var newDate = firstCol.get("originalMonth") || firstCol.get("selectedMonth");
              newDate = Date.create(newDate.toDate ? newDate.toDate() : newDate).addMonths(rangeCheck.diff);
              firstCol.set("autoAdjust", true);
              firstCol.set("selectedMonth", newDate);
            }
            this.get("ctl").send("selectAllDates");
            this.get("ctl.modal").show({
              text: "<h1>".concat(intl.t("general.there_is_a_problem_with_the_dates_of_your_imported_document_1695803192109"), "</h1>").concat(intl.t("the_date_outside_range__1695803192098", {
                importedDate: rangeCheck.importedDate,
                startDate: rangeCheck.start,
                endDate: rangeCheck.end
              })),
              selector: messageSelector,
              backupSelector: ".import-top",
              controller: this.get("ctl"),
              okLabel: intl.t("general.looks_good,_thanks!_1698234256617"),
              cancelLabel: intl.t("general.i'll_do_it_myself_1698234256617"),
              cancelAction: "revertPastDateAdjustment",
              timeout: false
            });
          } else {
            this.get("ctl.modal").show({
              text: "<h1>".concat(intl.t("general.would_you_like_us_to_automatically_select_all_other_date_columns_for_you_1695803192785"), "</h1>"),
              selector: messageSelector,
              backupSelector: ".import-top",
              controller: this.get("ctl"),
              okLabel: intl.t("general.confirmActionName_1695611613620"),
              okAction: "selectAllDates",
              cancelLabel: intl.t("general.no_1698234256618")
            });
          }
        }
      }),
      key: Ember.computed.alias("col.key"),
      selectedAttributeObserver: Ember.observer("selectedAttribute", function () {
        var _this4 = this;
        /* When the user selected a new attribute for a column,
         we would like to show a new errors, and not currently disabking them*/
        this.set("ctl.dismissEmptyDims", false);
        this.set("ctl.dismissDuplicateDims", false);
        if (this.get("ctl.importActuals") && this.get("selectedAttribute") === "accountId") {
          this.set("ctl.mappingColumn", this.get("key"));
        } else if (this.get("ctl.importAccounts") || this.get("ctl.importDimensionsMappings")) {
          ImportUtils.matchLines(this.get("ctl"), this.get("ctl.sheet"));
        }
        Ember.set(this.col, "isDate", this.get("isDate"));
        var currentDate = moment(this.get("selectedMonth"));
        var sameMonth;
        sameMonth = !this.get("hide") && this.get("ctl.currentTypeMappings").find(function (c) {
          var iterateDate = moment(c.get("selectedMonth"));
          return c !== _this4 && !c.get("col.hide") && currentDate.isSame(iterateDate);
        });
        this.get("ctl").send("checkDuplicateDates", this, sameMonth);
        if (sameMonth && sameMonth.get("selectedMonth")) {
          this.get("ctl").send("showDuplicateDates", this, sameMonth);
        }
      }),
      isDate: Ember.computed("selectedAttribute", function () {
        return this.get("selectedAttribute") === "#date#" || this.get("ctl.importActuals") && !this.get("ctl.dataTypeData.trxDateInLine") && !this.get("ctl.dataTypeData.showFiscalDate") && this.get("selectedAttribute") === "amount";
      }),
      hasValidDateValue: Ember.computed("col.value", function () {
        return ImportUtils.getKeyMoment(this.get("col.value"), this.get("ctl.date1904")).isValid();
      }),
      label: Ember.computed("ctl.attributeListForDate", "selectedAttribute", "selectedMonth", function () {
        var result = null,
          _this = this;
        if (this.get("selectedMonth")) {
          result = _const.default.formatDate(this.get("selectedMonth"), true);
        } else if (this.get("selectedAttribute")) {
          var attr = this.get("ctl.attributeListForDate").findBy("id", this.get("selectedAttribute"));
          if (!attr) {
            this.get("ctl.attributeListForDate").forEach(function (attrGroup) {
              var found = (attrGroup.children || Ember.A([])).findBy("id", _this.get("selectedAttribute"));
              if (found) {
                attr = found;
              }
            });
          }
          result = attr ? Ember.get(attr, "text") : _this.get("selectedAttribute");
        }
        return result;
      }),
      selectedMonth: Ember.computed("ctl.date1904", "col.value", "isDate", {
        get: function get() {
          var result = null,
            isReverse = this.get("ctl.dataTypeData.isMonthOrderReversed");
          if (this.get("isDate")) {
            var budgetStart = new Date(this.get("ctl.budgetService.selectedRoot.absoluteRoot.budgetAttributes." + (isReverse ? "endDate" : "startDate")));
            var budgetEnd = new Date(this.get("ctl.budgetService.selectedRoot.absoluteRoot.budgetAttributes." + (isReverse ? "startDate" : "endDate")));
            var unhidedMonths = this.get("ctl.currentTypeMappings").filter(function (mapping) {
              return mapping.get("isDate") && !mapping.get("hide");
            });
            var selectedMonths = unhidedMonths.length || !this.get("ctl.importActuals") ? unhidedMonths : this.get("ctl.currentTypeMappings").filterBy("isDate");
            var thisIndex = selectedMonths.indexOf(this);
            var lastSelectedMonth = thisIndex === 0 || selectedMonths.length === 0 ? null : selectedMonths[thisIndex > 0 ? thisIndex - 1 : selectedMonths.length - 1];
            lastSelectedMonth = lastSelectedMonth && Ember.get(lastSelectedMonth, "selectedMonth");
            lastSelectedMonth = lastSelectedMonth && moment.isMoment(lastSelectedMonth) ? lastSelectedMonth.toDate() : lastSelectedMonth;
            result = ImportUtils.getKeyMoment(this.get("col.value"), this.get("ctl.date1904"), lastSelectedMonth ? lastSelectedMonth.getFullYear() + (lastSelectedMonth.getMonth() === 11 ? 1 : 0) : budgetStart.getFullYear());
            if (!result.isValid() || this.get("ctl.importActuals") && (result.isAfter(budgetEnd) || result.isBefore(budgetStart.addYears(-2)))) {
              if (this.get("ctl.importActuals") && lastSelectedMonth) {
                result = Date.create(lastSelectedMonth).addMonths(isReverse ? -1 : 1);
              } else {
                result = budgetStart;
              }
            }
          }
          return result;
        },
        set: function set(key, value) {
          var _this5 = this;
          if (value && value.toDate) {
            value = value.toDate();
          }
          var dateCols = this.get("ctl.currentTypeMappings").filterBy("isDate");
          // check if already selected
          var currentDate = moment(value);
          var sameMonth;
          sameMonth = !this.get("hide") && this.get("ctl.currentTypeMappings").find(function (c) {
            var iterateDate = moment(c.get("selectedMonth"));
            return c !== _this5 && !c.get("col.hide") && currentDate.isSame(iterateDate);
          });
          var firstColAndAutoComplete = (this.get("ctl.importLines") || this.get("ctl.importGenericFile")) && dateCols.length > 1 && dateCols.indexOf(this) === 0;
          if (sameMonth && !(this.get("ctl.ignoreDuplicateDates") || firstColAndAutoComplete)) {
            this.get("ctl").send("showDuplicateDates", this, sameMonth);
          }
          if (this.get("duplicateDates")) {
            this.get("ctl").send("checkDuplicateDates", this, sameMonth);
          }
          if (!this.get("originalMonth")) {
            this.set("originalMonth", this.get("selectedMonth"));
          }
          if (this.get("revert")) {
            this.set("revert", false);
            return value;
          }
          if (firstColAndAutoComplete) {
            Ember.run.next(function () {
              _this5.get("ctl").send("adjustDates", _this5, _this5.get("autoAdjust"), true);
            });
          }
          return value;
        }
      }),
      isPastDate: Ember.computed("selectedMonth", "isDate", "selectedAttribute", "ctl.fiscalDate", "ctl.importActuals", function () {
        var startDate = this.get("ctl.budgetService.selectedRoot.absoluteRoot.budgetAttributes.startDate");
        var selectedDate;
        if (this.get("isDate") && this.get("selectedMonth")) {
          selectedDate = this.get("selectedMonth");
        } else if ((this.get("selectedAttribute") === "debit" || this.get("selectedAttribute") === "credit") && this.get("ctl.fiscalDate")) {
          selectedDate = this.get("ctl.fiscalDate");
        }
        var date = selectedDate ? moment(selectedDate).startOf("month").toDate().isBefore(moment(startDate).startOf("month").toDate()) : selectedDate;
        //enable diffrent color only when import actuals
        return this.get("ctl.importActuals") ? date : false;
      })
    }),
    DateColumn: Ember.Object.extend({
      value: null,
      location: null,
      hide: false,
      key: Ember.computed("value", function () {
        if (typeof this.get("value") === "string") {
          return this.get("value");
        }
        return this.get("value").format(_const.default.MomentYearMonthDateFormat);
      }),
      newKey: Ember.computed("date", function () {
        return this.get("date") ? moment(this.get("date")).format(_const.default.MomentYearMonthDateFormat) : null;
      }),
      date: Ember.computed("value", {
        get: function get() {
          return this.get("value").toDate ? this.get("value").toDate() : null;
        },
        set: function set(key, value) {
          var m = moment(value);
          this.set("newFormatted", m.format(_const.default.MomentMonthYearDisplayFormat));
          return m.toDate();
        }
      }),
      formatted: Ember.computed("value", function () {
        if (typeof this.get("value") === "string") {
          return this.get("value");
        }
        return this.get("value").format(_const.default.MomentMonthYearDisplayFormat);
      }),
      selected: true
    }),
    NumericFields: ["base", "base!m", "base!y", "rate", "rate!h", "rate!m", "rate!f", "amount", "bonus", "benefits", "maxAccruedVacation", "yearlyVacationDays"],
    setFileModified: function setFileModified(file) {
      if (file.uploaded < file.lastModifiedDate) {
        Ember.set(file, "modified", true);
      } else {
        var reader = new FileReader();
        reader.onerror = function () {
          Ember.set(file, "modified", true);
        };
        reader.onloadend = function () {
          if (reader.error) {
            Ember.set(file, "modified", true);
          }
        };
        reader.readAsArrayBuffer(file.slice(0, 1));
      }
    },
    getRealMoment: function getRealMoment(passYears, keyMoment) {
      return _getRealMoment(passYears, keyMoment);
    },
    getRowNumber: function getRowNumber(cell) {
      return cell.replace(/[^\d]/g, "") * 1;
    },
    getCol: function getCol(cell) {
      return cell ? cell.replace(/[\d]/g, "") : null;
    },
    checkImportRange: function checkImportRange(headerColumns, root, date1904, customStartData, customEndData) {
      try {
        if (Ember.isEmpty(root) && !customStartData && !customEndData) {
          return false;
        }
        var headerDates = headerColumns.filter(function (col) {
          return !col.get("hide") && col.get("selectedAttribute") === "#date#";
        });
        if (headerDates.get("length") < 1) {
          // Cannot find dates in the selected sheet
          return false;
        }
        root = !customStartData && !customEndData ? root.get("absoluteRoot") : null;
        if (root && (Ember.isEmpty(root.get("startDateMoment")) || Ember.isEmpty(root.get("endDateMoment")))) {
          return false;
        }
        var rootStartDate = customStartData ? customStartData.beginningOfMonth() : Date.create(root.get("startDateMoment").toDate()).beginningOfMonth();
        var rootEndDate = customEndData ? customEndData.beginningOfMonth() : Date.create(root.get("endDateMoment").toDate()).beginningOfMonth();
        var startMoment = ImportUtils.getKeyMoment(headerDates[0].value, date1904, rootStartDate.getYear() + 1900);
        var endDate = Date.create(-8640000000000000);
        var passYears = {};
        for (var i = 0; i < headerDates.length; i++) {
          var keyMoment = ImportUtils.getKeyMoment(headerDates[i].value, date1904, rootStartDate.getYear() + 1900);
          keyMoment = Date.create(ImportUtils.getRealMoment(passYears, keyMoment).toDate());
          if (endDate.isBefore(keyMoment)) {
            endDate = keyMoment.beginningOfMonth();
          }
        }
        if (endDate.isBefore(rootStartDate)) {
          return {
            diff: -startMoment.diff(rootStartDate, "months"),
            importedDate: startMoment.format("MM/YYYY"),
            start: _const.default.formatDate(rootStartDate),
            end: _const.default.formatDate(rootEndDate)
          };
        }
        return false;
      } catch (e) {
        // we should ignore any errors here
        return false;
      }
    },
    findNamesLoaction: function findNamesLoaction(sheet, datesRow, forceFirstColumn, headerCellFoundByName, includeHidden) {
      if (headerCellFoundByName) {
        return ImportUtils.getCol(headerCellFoundByName.key) + (datesRow * 1 + 1);
      }
      var hiddenCols = includeHidden ? [] : ImportUtils.getHiddenCols(sheet);
      var firstCol = 65;
      for (var line = datesRow * 1 + 1; line <= 100; line++) {
        var colIndex = 0;
        for (var col = firstCol; col <= (forceFirstColumn ? firstCol : 80); col++) {
          if (includeHidden || !hiddenCols.includes(colIndex)) {
            var cell = String.fromCharCode(col) + line;
            if (sheet[cell] && (sheet[cell].t === "s" || sheet[cell].t === "str")) {
              return cell;
            }
          }
          colIndex++;
        }
      }
    },
    lineIsEmpty: function lineIsEmpty(sheet, line) {
      var isEmpty = true;
      for (var i = 1; i <= 26; i++) {
        if (sheet[ImportUtils.colToLetterRef(i) + line]) {
          isEmpty = false;
          break;
        }
      }
      return isEmpty;
    },
    colIsEmpty: function colIsEmpty(sheet, col, startLine) {
      var lines = sheet["!ref"] ? ImportUtils.getRowNumber(sheet["!ref"].split(":")[1]) : 99;
      var isEmpty = true;
      for (var i = startLine; i < lines; i++) {
        if (sheet[col + i] && sheet[col + i].v) {
          isEmpty = false;
          break;
        }
      }
      return isEmpty;
    },
    linesInSheet: function linesInSheet(sheet) {
      var result = sheet["!ref"] ? ImportUtils.getRowNumber(sheet["!ref"].split(":")[1] || sheet["!ref"]) : 99;
      if (result > 1000) {
        // too many lines - try to find the number of lines by scanning the data
        var emptyLines = 0,
          curLine = 1;
        while (emptyLines < 10) {
          if (ImportUtils.lineIsEmpty(sheet, curLine)) {
            emptyLines++;
          } else {
            emptyLines = 0;
            result = curLine;
          }
          curLine++;
        }
      }
      return result;
    },
    colsInSheet: function colsInSheet(sheet) {
      var lastCol = ImportUtils.lastColInSheet(sheet);
      return ImportUtils.colToNumber(lastCol);
    },
    lastColInSheet: function lastColInSheet(sheet) {
      if (!sheet["!ref"]) {
        return "Z";
      }
      var lastCol = ImportUtils.getCol(sheet["!ref"].split(":")[1] || sheet["!ref"]);
      if (ImportUtils.colToNumber(lastCol) > 100) {
        // prevent a case of isolated columns at the end of the sheet
        lastCol = Object.keys(sheet).filter(function (k) {
          return k.substr(0, 1) !== "!";
        }).map(function (k) {
          return k.replace(/\d/g, "");
        }).filter(function (k) {
          return k !== lastCol;
        }).sort(function (a, b) {
          if (a === b) {
            return 0;
          }
          var aNum = ImportUtils.colToNumber(a);
          var bNum = ImportUtils.colToNumber(b);
          return aNum > bNum ? 1 : -1;
        }).get("lastObject");
      }
      return lastCol;
    },
    getHiddenCols: function getHiddenCols(sheet) {
      var hiddenCols = [];
      if (sheet["!cols"]) {
        sheet["!cols"].forEach(function (col, idx) {
          if (col.hidden) {
            hiddenCols.push(idx);
          }
        });
      }
      return hiddenCols;
    },
    getHiddenRows: function getHiddenRows(sheet) {
      var hiddenRows = [];
      if (sheet["!rows"]) {
        sheet["!rows"].forEach(function (row, idx) {
          if (row.hidden) {
            hiddenRows.push(idx);
          }
        });
      }
      return hiddenRows;
    },
    getColValues: function getColValues(sheet, col, startLine, limit, noMapping, ctl) {
      var values = Ember.A([]),
        linesInSheet = ImportUtils.linesInSheet(sheet),
        showHidden = ctl.get("showHiddenCols"),
        hiddenRows = showHidden ? [] : ImportUtils.getHiddenRows(sheet);
      for (var line = startLine * 1; line <= (limit ? startLine * 1 + limit : linesInSheet); line++) {
        if (!hiddenRows.includes(line - 1)) {
          var cell = sheet[col + line];
          var item = {
            value: cell && cell.w ? cell.w : "",
            location: col + line,
            lineNum: line,
            store: ctl.store,
            ctl: ctl,
            multiplyBy: 0,
            visible: false,
            budgetType: "expense"
          };
          if (ctl.get("importTargetModel.budgetType") === "group-payroll") {
            item.budgetType = "employee";
          } else if (ctl.get("importTargetModel.budgetType") === "group-ps") {
            item.budgetType = "contractor";
          } else if (ctl.get("importTargetModel.budgetType") && ctl.get("importTargetModel.budgetType").startsWith("group-revenue")) {
            item.budgetType = "sales";
          }
          if (noMapping) {
            values.addObject(item);
          } else {
            values.addObject(Item.create(item));
          }
        }
      }
      return values;
    },
    getCellsForCol: function getCellsForCol(sheet, col, startLine) {
      var values = Ember.A([]),
        linesInSheet = ImportUtils.linesInSheet(sheet);
      for (var line = startLine * 1; line <= linesInSheet; line++) {
        var cell = sheet[col + line];
        if (cell) {
          cell.lineNum = line;
          values.addObject(cell);
        }
      }
      return values;
    },
    parseDate: function parseDate(value, date1904) {
      var parsed = XLSX.SSF.parse_date_code(value, {
        date1904: date1904
      });
      return parsed ? moment([parsed.y, parsed.m - 1, parsed.d]) : null;
    },
    getKeyMoment: function getKeyMoment(key, date1904, defaultYear, actualDate, strict) {
      if (!defaultYear) {
        defaultYear = new Date().getYear() + 1900;
      }
      var parsed = key.t === "n" && key.v > 30000 ? ImportUtils.parseDate(key.v, date1904) : null;
      var keyW = key.w ? key.w.trim() : "";
      if (hebrewMonthsMap[keyW]) {
        keyW = hebrewMonthsMap[keyW] + "." + defaultYear;
      } else {
        keyW = keyW.replace(/ינו/g, "jan").replace(/פבר/g, "feb").replace(/מרץ/g, "mar").replace(/אפר/g, "apr").replace(/מאי/g, "may").replace(/יונ/g, "jun").replace(/יול/g, "jul").replace(/אוג/g, "aug").replace(/ספט/g, "sep").replace(/אוק/g, "oct").replace(/נוב/g, "nov").replace(/דצמ/g, "dec");
      }
      if (fullMonthNameRegExp.test(keyW) && !/\d{4}/.test(keyW)) {
        // has full month and no year
        keyW = keyW.replace(fullMonthNameRegExp, "$1 " + defaultYear);
      } else if (partialMonthNameRegExp.test(keyW) && !/\d{2}/.test(keyW)) {
        // has full month and no year
        keyW = keyW.replace(partialMonthNameRegExp, "$1 " + defaultYear);
      }
      if (parsed) {
        return actualDate ? parsed : parsed.startOf("month");
      } else if (/(^([1-9]|10|11|12)\.?20\d{2}$)/.test(keyW)) {
        parsed = Date.create(keyW);
        return parsed.isValid() ? actualDate ? moment(parsed) : moment(parsed).startOf("month") : moment.invalid();
      } else if (/^[0-9]\d*\.\d+$/.test(keyW) || /^\d{3}\d*$/.test(keyW)) {
        return moment.invalid();
      } else if (/^(1[0-2])$|^([1-9])$/.test(keyW)) {
        // handle months as numbers
        var result = moment().set("month", keyW * 1 - 1);
        if (defaultYear) {
          result.set("year", defaultYear);
        }
        return actualDate ? result : result.startOf("month");
      } else if (/^((1[0-2])|0?([1-9]))[^\d]\d{4}$/.test(keyW)) {
        // handle MM/YYYY format
        var seperator = keyW.match(/^(?:(?:1[0-2])|0?(?:[1-9]))([^\d])\d{4}$/)[1];
        return moment(keyW, "MM" + seperator + "YYYY");
      } else if (/^([a-zA-Z]{3,9})\s*[^\d]?\s*([0-9]{2,4})/.test(keyW)) {
        // handle MMM*YYYY
        var date = keyW.match(/^([a-zA-Z]{3,9})\s*[^\d]?\s*([0-9]{2,4})/);
        date = date[1].slice(0, 3) + " " + (date[2].length === 2 ? defaultYear.toString().substr(0, 2) + date[2] : date[2]);
        parsed = Date.create(date);
        return parsed.isValid() ? actualDate ? moment(parsed) : moment(parsed).startOf("month") : moment.invalid();
      } else if (strict) {
        return moment.invalid();
      } else {
        try {
          parsed = Date.create(keyW, navigator.language);
        } catch (e) {
          return moment.invalid();
        }
        if (defaultYear && parsed.isValid() && !/\d{4}/.test(keyW)) {
          // year is not included - set year to default
          parsed.setYear(defaultYear);
        }
        return parsed.isValid() ? actualDate ? moment(parsed) : moment(parsed).startOf("month") : moment.invalid();
      }
    },
    getActualsLineValues: function getActualsLineValues(sheet, datesLocationStart, datesLocationEnd, startLocation, date1904) {
      var startCol = ImportUtils.getCol(datesLocationStart);
      var endCol = ImportUtils.getCol(datesLocationEnd);
      var line = ImportUtils.getRowNumber(startLocation);
      var dateLine = ImportUtils.getRowNumber(datesLocationStart);
      var values = {};
      for (var col = startCol.charCodeAt(0); col <= endCol.charCodeAt(0); col++) {
        var colChar = String.fromCharCode(col);
        var cell = sheet[colChar + line];
        var key = sheet[colChar + dateLine];
        if (cell && key) {
          var keyMoment = ImportUtils.getKeyMoment(key, date1904);
          if (cell && cell.v && keyMoment.isValid() && cell.t === "n") {
            var k = keyMoment.format(_const.default.MomentYearMonthDateFormat);
            values[k] = values[k] || cell.v.toFixed(2) * 1;
          }
        }
      }
      return values;
    },
    getLineValues: function getLineValues(sheet, headerLocation, startLocation) {
      var startCol = ImportUtils.getCol(headerLocation.start);
      var endCol = ImportUtils.getCol(headerLocation.end);
      var line = ImportUtils.getRowNumber(startLocation);
      var values = {};
      var addValue = function addValue(colChar) {
        var cell = sheet[colChar + line];
        values[colChar + line] = cell;
      };
      if (endCol.length > 1) {
        for (var col1 = startCol.charCodeAt(0); col1 <= 90; col1++) {
          addValue(String.fromCharCode(col1));
        }
        var currCell;
        for (var firstChar = 65; firstChar <= endCol.charCodeAt(0); firstChar++) {
          for (var c2 = 65; c2 <= 90; c2++) {
            currCell = String.fromCharCode(firstChar) + String.fromCharCode(c2);
            if (currCell > endCol) {
              break;
            }
            addValue(currCell);
          }
          if (currCell > endCol) {
            break;
          }
        }
      } else {
        for (var col = startCol.charCodeAt(0); col <= endCol.charCodeAt(0); col++) {
          var colChar = String.fromCharCode(col);
          addValue(colChar);
        }
      }
      return values;
    },
    getDates: function getDates(sheet, datesLocation, date1904, defaultYear) {
      var startCol = ImportUtils.getCol(datesLocation);
      var dateLine = ImportUtils.getRowNumber(datesLocation);
      var values = [];
      for (var col = ImportUtils.colToNumber(startCol); col <= ImportUtils.colsInSheet(sheet); col++) {
        var colChar = ImportUtils.colToLetterRef(col);
        var location = colChar + dateLine;
        var key = sheet[location];
        if (key) {
          var keyMoment = ImportUtils.getKeyMoment(key, date1904, defaultYear);
          values.push({
            value: keyMoment.isValid() ? keyMoment : key.w,
            location: location
          });
        } else {
          values.push({
            value: colChar,
            location: location
          });
        }
      }
      return values;
    },
    columnHasNonNumericValues: function columnHasNonNumericValues(sheet, col, startLine, selectedLines) {
      var cells = ImportUtils.getCellsForCol(sheet, col, startLine);
      for (var i = 0; i < cells.length; i++) {
        if (selectedLines.includes(cells[i].lineNum) && cells[i].t !== "n") {
          return true;
        }
      }
      return false;
    },
    getHeaderValuesInRange: function getHeaderValuesInRange(importType, sheet, headerLocation, namesLocation, startCol, endCol, date1904, defaultYear, showHiddenCols, columnsWidth) {
      if (endCol.length > 2) {
        endCol = "ZZ";
      }
      var idx = 0,
        namesCol = ImportUtils.getCol(namesLocation),
        dateLine = ImportUtils.getRowNumber(headerLocation.start),
        showHidden = showHiddenCols,
        hiddenCols = showHidden ? [] : ImportUtils.getHiddenCols(sheet),
        lastColWithData = "";
      var values = [];
      var addCol = function addCol(colChar) {
        if (!hiddenCols.includes(idx++)) {
          var location = colChar + dateLine;
          var col = Ember.Object.create({
            key: location,
            hide: importType ? true : false,
            value: sheet[location] && sheet[location].v ? sheet[location] : {
              w: colChar
            }
          });
          if (columnsWidth && columnsWidth[colChar]) {
            col.width = columnsWidth[colChar];
          }
          col.isDate = ImportUtils.isDateCell(col.value, date1904, defaultYear);
          if (colChar === namesCol) {
            col.hide = true;
          }
          if (sheet[location] || Object.keys(sheet).findIndex(function (key) {
            return key.replace(/\d+/g, "") === colChar;
          }) >= 0) {
            lastColWithData = colChar;
          }
          values.push(Ember.Object.create(col));
        }
      };
      if (endCol.length > 1) {
        for (var col1 = startCol.charCodeAt(0); col1 <= 90; col1++) {
          addCol(String.fromCharCode(col1));
        }
        for (var firstChar = 65; firstChar <= endCol.charCodeAt(0); firstChar++) {
          for (var c2 = 65; c2 <= 90; c2++) {
            addCol(String.fromCharCode(firstChar) + String.fromCharCode(c2));
          }
        }
      } else {
        for (var col = startCol.charCodeAt(0); col <= endCol.charCodeAt(0); col++) {
          addCol(String.fromCharCode(col));
        }
      }
      var lastColWithDataNumber = ImportUtils.colToNumber(lastColWithData);
      values = values.slice(0, lastColWithDataNumber);
      return values;
    },
    getHeaderValues: function getHeaderValues(sheet, headerLocation, namesLocation, importType, date1904, defaultYear, showHiddenCols, columnsWidth) {
      var startCol = "A";
      var endCol = ImportUtils.getCol(headerLocation.end);
      return ImportUtils.getHeaderValuesInRange(importType, sheet, headerLocation, namesLocation, startCol, endCol, date1904, defaultYear, showHiddenCols, columnsWidth);
    },
    isDateCell: function isDateCell(cell, date1904, defaultYear) {
      return ImportUtils.getKeyMoment(cell, date1904, defaultYear, undefined, true).isValid();
    },
    findDates: function findDates(sheet) {
      var numFound = 0;
      var firstCell = null;
      var lastCell = null;
      var rowFound = null;
      var prevRow = null;
      var currentRow = null;
      for (var cell in sheet) {
        if (cell.indexOf("!") !== 0) {
          currentRow = ImportUtils.getRowNumber(cell);
          if (currentRow !== prevRow) {
            numFound = 0;
            if (rowFound) {
              break;
            }
          }
          prevRow = currentRow;
          if (ImportUtils.isDateCell(sheet[cell])) {
            firstCell = firstCell || cell;
            numFound++;
          } else if (rowFound) {
            break;
          } else {
            firstCell = null;
            numFound = 0;
          }
          if (numFound === 2) {
            rowFound = currentRow;
          }
          lastCell = cell;
        }
      }
      return {
        start: firstCell,
        end: lastCell
      };
    },
    colToNumber: function colToNumber() {
      var col = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var value = 0;
      for (var i = 1; i <= col.length; i++) {
        var c = col.charCodeAt(col.length - i) - 64;
        value += c * Math.pow(26, i - 1);
      }
      return value;
    },
    colDiff: function colDiff(cell1, cell2) {
      var col1 = ImportUtils.getCol(cell1);
      if (cell2 === null) {
        return 1;
      }
      var col2 = ImportUtils.getCol(cell2);
      return Math.abs(ImportUtils.colToNumber(col1) - ImportUtils.colToNumber(col2));
    },
    findHeader: function findHeader(sheet, dataTypeData, actuals) {
      var numFound = 0;
      var firstCell = null;
      var lastCell = null;
      var rowFound = null;
      var prevRow = null;
      var currentRow = null;
      for (var cell in sheet) {
        if (cell.indexOf("!") !== 0) {
          currentRow = ImportUtils.getRowNumber(cell);
          if (dataTypeData && dataTypeData.headerLineMatch) {
            var matchCell = sheet[dataTypeData.headerLineMatch.col + currentRow];
            if (matchCell && (matchCell.w === dataTypeData.headerLineMatch.value || dataTypeData.headerLineMatch.value === "*" && matchCell.w && matchCell.w.trim())) {
              rowFound = currentRow;
            }
          }
          if (currentRow !== prevRow) {
            if (rowFound) {
              break;
            }
            firstCell = cell;
            numFound = 0;
            if (dataTypeData && dataTypeData.headerLineFirstValue && sheet[firstCell] && sheet[firstCell].w === dataTypeData.headerLineFirstValue) {
              rowFound = currentRow;
            }
          }
          prevRow = currentRow;
          if (!rowFound) {
            var col = ImportUtils.getCol(cell);
            if (col.length < 2 && col < "O" && (ImportUtils.isDateCell(sheet[cell]) || !actuals && sheet[cell].t !== "n") && sheet[cell].v && (actuals || ImportUtils.colDiff(cell, lastCell) < 2)) {
              numFound++;
            } else {
              numFound = 0;
            }
            if (numFound === (actuals ? 2 : 5)) {
              rowFound = currentRow;
            }
          }
          lastCell = cell;
        }
      }
      return {
        start: "A" + (rowFound || "1"),
        end: lastCell
      };
    },
    LETTER_REFS: {},
    colToLetterRef: function colToLetterRef(x) {
      var digit = 1,
        index,
        num = x,
        string = "",
        alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      if (ImportUtils.LETTER_REFS[x]) {
        return ImportUtils.LETTER_REFS[x];
      }
      while (num > 0) {
        num -= Math.pow(26, digit - 1);
        index = num % Math.pow(26, digit);
        num -= index;
        index = index / Math.pow(26, digit - 1);
        string = alphabet.charAt(index) + string;
        digit += 1;
      }
      ImportUtils.LETTER_REFS[x] = string;
      return string;
    },
    positionToLetterRef: function positionToLetterRef(x, y) {
      return ImportUtils.colToLetterRef(x).concat(y);
    },
    isHidenColumn: function isHidenColumn(dateCols, key) {
      return dateCols.map(function (ele) {
        return ele.key;
      }).indexOf(key) === -1;
    },
    fillAttributes: function fillAttributes(item, mapping, ctl, root) {
      var isPeriodValid = function isPeriodValid(yearStart, currMonthNumber, diffFromLastFilledMonth, period, currItem) {
        var periodMonth = (yearStart - currMonthNumber) % period === 0 && diffFromLastFilledMonth === period;
        return !(periodMonth ^ currItem !== 0);
      };
      var budgetModule = ctl.store.peekRecord("budget-module", mapping);
      var keepNegative = mapping === "sales" || mapping === "expense" || budgetModule && budgetModule.get("allowNegative");
      var headerColumns = ctl.get("headerColumns");
      var mappingsForType = ctl.get("currentTypeMappings");
      var attributeList = Ember.A([]);
      var ignoredAttributes = ctl.get("attributeList").filter(function (att) {
        return Ember.get(att, "ignoreAttribute");
      }).map(function (i) {
        return i.id;
      });
      ctl.get("attributeList").forEach(function (item) {
        if (!Ember.get(item, "ignoreAttribute")) {
          if (Ember.get(item, "children")) {
            attributeList.pushObjects(Ember.get(item, "children"));
          } else {
            attributeList.pushObject(item);
          }
        }
      });
      var result = {};
      var lineNum = ImportUtils.getRowNumber(item.location);
      var guessAmounts = true;
      var multiplier = ctl.get("thousands") ? 1000 : 1;
      if (item.multiplyBy && !isNaN(item.multiplyBy * 1)) {
        multiplier *= item.multiplyBy / 100 + 1;
      }
      if (item.notes) {
        result.notes = item.notes;
      }
      fixedItemValues(item);
      if (typeof item.budgetType === "string" && item.budgetType.indexOf("sales") === 0 && item.budgetType !== "sales") {
        return result;
      }
      if (item.budgetType === "contractor") {
        result.per = "m";
      }
      if (mappingsForType) {
        mappingsForType.filter(function (i) {
          return !Ember.get(i, "col.hide") && !ignoredAttributes.includes(Ember.get(i, "col.selectedAttribute"));
        }).forEach(function (i) {
          var col = Ember.get(i, "col"),
            attr = Ember.get(i, "selectedAttribute"),
            v;
          if (attr && !attr.match(/#.*#/)) {
            var loc = ImportUtils.getCol(col.key) + lineNum;
            var attrData = attributeList.findBy("id", attr).data;
            if (item.values[loc]) {
              // append cell comments to notes
              if (item.values[loc].c && item.values[loc].c[0] && item.values[loc].c[0].t) {
                v = item.values[loc].c[0].t;
                result["notes"] = result["notes"] ? result["notes"] + "\n" + v : v;
              }
              if (attrData.isDimension) {
                if (attrData.dimension) {
                  var value = item.values[loc].w;
                  if (value && value.trim()) {
                    var dimensionValue = attrData.dimension.addValue(value, {
                      treeLocation: ctl.get("importTargetModel.location")
                    });
                    if (!result.dimensions) {
                      result.dimensions = [];
                    }
                    result.dimensions.pushObject({
                      dimension: attrData.dimension.get("uniqueId"),
                      values: [{
                        value: Ember.get(dimensionValue, "uniqueId"),
                        alllocation: 100
                      }]
                    });
                  }
                }
              } else if (attr === "comments") {
                result[attr] = item.values[loc].w;
              } else {
                var _value = item.values[loc].w;
                if (ImportUtils.NumericFields.includes(attr)) {
                  if (item.values[loc].t === "n") {
                    _value = item.values[loc].v;
                  } else {
                    _value = typeof item.values[loc].w === "string" ? item.values[loc].w.replace(/[^\d\.]/g, "") * 1 : item.values[loc].v;
                  }
                }
                if (isNaN(_value)) {
                  _value = item.values[loc].w;
                }
                if (attr === "currency") {
                  _value = _value === "NIS" ? "ILS" : _value;
                }
                if (attr.indexOf("!") >= 0) {
                  var extraSettings = attributeList.findBy("id", attr).extra;
                  for (var x in extraSettings) {
                    result[x] = extraSettings[x];
                  }
                  attr = attr.split("!")[0];
                }
                if (mapping === "employee" && attr === "base") {
                  guessAmounts = false;
                }
                if (_value && mapping === "employee" && attr === "paymentAfter") {
                  _value = _value.toString().toLowerCase().indexOf("next") >= 0 ? 1 : null;
                }
                if (attrData && attrData.enum) {
                  if (attrData.enum.indexOf(_value) < 0) {
                    _value = null;
                  }
                }
                if (attrData && attrData.type === "date") {
                  _value = ImportUtils.getKeyMoment(item.values[loc], ctl.get("date1904")).toDate();
                }
                if (ImportUtils.NumericFields.includes(attr)) {
                  _value = _value * 1;
                  if (item.values[loc].w && item.values[loc].w.endsWith("%")) {
                    // BUD-7419 fix value precision issue for percentages
                    var preciseValue = item.values[loc].w.replace("%", "") / 100;
                    if (!isNaN(preciseValue)) {
                      _value = preciseValue;
                    }
                  }
                  if (attr === "amount" || attr === "bonus" || attr.indexOf("base") === 0) {
                    _value *= multiplier;
                    _value = _const.default.round(_value, 6);
                  }
                  if (attr === "benefits" && item.values[loc].w.indexOf("%") >= 0) {
                    _value = _const.default.round(_value * 100, 6);
                  }
                  if (isNaN(_value)) {
                    _value = null;
                  }
                }
                if (mapping === "employee" && attr === "bonus" && _value > 100) {
                  result.bonusIsFixed = true;
                }
                if (typeof _value === "string") {
                  _value = _value.trim();
                }
                if (attr === "tags") {
                  result[attr] = result[attr] || [];
                  _value.split(",").forEach(function (val) {
                    var v = val.trim();
                    if (v && !result[attr].findBy("name", v)) {
                      result[attr].pushObject({
                        name: v
                      });
                    }
                  });
                } else {
                  result[attr] = _value;
                }
              }
            }
          }
        });
        var amountAttribute = "amount";
        var dateObjects = mappingsForType.filter(function (c) {
          return Ember.get(c, "col.selectedAttribute") === "#date#" && !Ember.get(c, "col.hide");
        });
        var dateCols = dateObjects.mapBy("col");
        var showItemsValues = filterHidenColumns(dateCols, item.values);
        if (guessAmounts && dateCols.length > 0 && showItemsValues && !budgetModule.get("isGroup")) {
          if (mapping === "employee") {
            amountAttribute = "base";
          } else if (mapping === "contractor") {
            amountAttribute = "rate";
          }
          var lastDiff = null;
          var repeated = 1;
          var isQuarterly = true;
          var isYearly = true;
          var lastKey = null;
          var lastLoc = null;
          var lastVal = null;
          var yearStart = Ember.get(root, "yearStart");
          var currentRow = ImportUtils.getRowNumber(item.location);
          var startLocation = getStartlocation(dateCols, item, headerColumns);
          var rootStartDate = new Date(root.get("startDateMoment").toDate());
          var rootEndDate = root.get("endDateMoment") ? new Date(root.get("endDateMoment").toDate()) : Date.create(rootStartDate).addMonths(12);
          var startMoment = dateObjects[0] && dateObjects[0].get("selectedMonth") ? moment(dateObjects[0].get("selectedMonth")) : getKeyMoment(dateCols, startLocation, ctl.get("date1904"), rootStartDate.getYear() + 1900);
          var startDate = new Date(startMoment).beginningOfMonth();
          var forceCustomAmounts = false;
          if (mapping === "no-grouping-line") {
            forceCustomAmounts = true;
          }
          var passYears = {};
          if (dateCols.length === 1 && mapping !== "assumption") {
            if (budgetModule.get("budgetAttributes.period")) {
              result.period = "o";
            }
            if (budgetModule.get("budgetAttributes.release")) {
              result.release = "o";
            }
            if (budgetModule.get("budgetAttributes.occursOn")) {
              result.occursOn = startDate;
            }
            result[amountAttribute] = getCellValue(showItemsValues[Object.keys(showItemsValues)[0]], multiplier, keepNegative, true);
          } else {
            for (var loc in showItemsValues) {
              if (showItemsValues[loc]) {
                var keyMoment = mappingsForType.findBy("key", showItemsValues[loc].headerKey).get("selectedMonth");
                if (!lastVal && new Date(keyMoment).beginningOfMonth().valueOf() !== startDate.valueOf()) {
                  startMoment = moment(keyMoment);
                  startDate = new Date(startMoment).beginningOfMonth();
                }
                if (moment.isMoment(keyMoment)) {
                  keyMoment = moment(keyMoment);
                }
                if (isNaN(showItemsValues[loc].v)) {
                  getCellValue(showItemsValues[loc], 1, true, true);
                }
                lastVal = lastVal || showItemsValues[loc].v;
                keyMoment = _getRealMoment(passYears, keyMoment);
                if (keyMoment) {
                  var key = keyMoment.format(_const.default.MomentYearMonthDateFormat);
                  if (lastKey) {
                    var diff = keysDiff(key, lastKey);
                    isQuarterly = (isQuarterly || isQuarterly === null) && isPeriodValid(yearStart, keyMoment._d.getMonth(), diff, 3, showItemsValues[loc].v);
                    isYearly = (isYearly || isYearly === null) && isPeriodValid(yearStart, keyMoment._d.getMonth(), diff, 12, showItemsValues[loc].v);
                    if (showItemsValues[loc] && showItemsValues[lastLoc]) {
                      if (diff === lastDiff && showItemsValues[loc].v === showItemsValues[lastLoc].v || lastDiff === null && showItemsValues[loc].v === showItemsValues[lastLoc].v) {
                        repeated++;
                        lastDiff = diff; // there are consecutive equal values;
                        if (lastDiff > 1 && mapping == "assumption") {
                          forceCustomAmounts = true;
                        }
                      } else if (lastDiff && mapping === "employee") {
                        forceCustomAmounts = true;
                        result.amountChanges = result.amountChanges || Ember.A([]);
                        result.amountChanges.pushObject({
                          amount: showItemsValues[loc].v * multiplier,
                          start: keyMoment.toDate()
                        });
                      } else if ((lastDiff && !Ember.isEmpty(showItemsValues[loc].v) || showItemsValues[loc].v !== showItemsValues[lastLoc].v) && showItemsValues[loc].v !== 0) {
                        // there is a none zero value that is not equal to the sequence  => fast reject for customAmounts
                        forceCustomAmounts = true;
                      } else if (lastDiff && showItemsValues[loc].v !== 0) {
                        break;
                      }
                    }
                  }
                  if (showItemsValues[loc] && showItemsValues[loc].v !== 0) {
                    lastKey = key;
                    lastLoc = loc;
                  }
                }
              }
            }
            if (budgetModule.get("budgetAttributes.release")) {
              result.release = "m";
            } else {
              result.period = "m";
            }
            var pctCurrency;
            if (mapping === "assumption") {
              pctCurrency = getTypeOfAmount(root, Object.values(showItemsValues), true);
              result.pctCurrency = pctCurrency;
            } else {
              pctCurrency = getTypeOfAmount(root, Object.values(showItemsValues), false);
              if (pctCurrency !== "#") {
                result.currency = pctCurrency;
              }
            }
            if (isQuarterly && mapping !== "assumption") {
              if (budgetModule.get("budgetAttributes.release")) {
                result.release = "q";
              } else {
                result.period = "q";
              }
            } else if (isYearly && mapping !== "assumption") {
              if (budgetModule.get("budgetAttributes.release")) {
                result.release = "y";
              } else {
                result.period = "y";
              }
            }
            if (repeated === Object.keys(showItemsValues).length && !forceCustomAmounts && (lastDiff === 1 || result.period !== "m" && mapping !== "assumption")) {
              result[amountAttribute] = getCellValue(showItemsValues[startLocation], multiplier, keepNegative, true);
            } else {
              var tempCustomAmounts = toCustomAmounts(showItemsValues, currentRow, dateObjects, ctl.get("date1904"), pctCurrency === "%" ? 100 : multiplier, keepNegative);
              var onceAmount = numOfNonZeros(tempCustomAmounts);
              if (!forceCustomAmounts && onceAmount === 1) {
                result.amountType = 0;
                if (budgetModule.get("budgetAttributes.release")) {
                  result.release = "o";
                } else {
                  result.period = "o";
                }
                var firstNonEmpty = getFirstNonEmptyMonth(tempCustomAmounts);
                result.occursOn = firstNonEmpty && firstNonEmpty.d;
                result[amountAttribute] = firstNonEmpty && firstNonEmpty.v;
              } else {
                result.amountType = 1;
                result.customAmounts = tempCustomAmounts;
              }
            }
            if (mappingsForType.mapBy("selectedAttribute").indexOf("startDate") < 0 && moment(startDate).diff(moment(rootStartDate), "months", false) !== 0 && lastKey) {
              result.startDate = startMoment ? startMoment.toDate() : null;
            }
            if (mappingsForType.mapBy("selectedAttribute").indexOf("endDate") < 0 && lastKey) {
              var endDate = moment(lastKey, _const.default.MomentYearMonthDateFormat).toDate();
              if (rootEndDate.monthsSince(endDate) !== 0) {
                result.endDate = moment(lastKey, _const.default.MomentYearMonthDateFormat).toDate();
              }
            }
            if (mapping === "employee") {
              result.term = "m";
            }
            if (mapping === "contractor" && !result.per) {
              result.per = "m";
            }
            if (mapping === "employee" || mapping === "contractor") {
              if (result.customAmounts) {
                result.amountChanges = Ember.A([]);
                passYears = {};
                var lastValue, lastMoment;
                for (var _loc in showItemsValues) {
                  var _keyMoment = getKeyMoment(dateCols, _loc, ctl.get("date1904"), rootStartDate.getYear() + 1900);
                  _keyMoment = _getRealMoment(passYears, _keyMoment);
                  if (_keyMoment) {
                    if (showItemsValues[_loc] && lastValue !== showItemsValues[_loc].v) {
                      result.amountChanges.pushObject({
                        amount: showItemsValues[_loc].v * multiplier,
                        start: _keyMoment.toDate().beginningOfMonth()
                      });
                      lastValue = showItemsValues[_loc].v;
                    } else if (showItemsValues[_loc] === undefined && lastValue > 0) {
                      result.amountChanges.pushObject({
                        amount: 0,
                        start: _keyMoment.toDate().beginningOfMonth()
                      });
                      lastValue = 0;
                    }
                    if (showItemsValues[_loc] && showItemsValues[_loc].v) {
                      lastMoment = _keyMoment;
                    }
                  }
                }
                if (result.amountChanges.length && result.amountChanges[0] && !result.amountChanges[0].amount) {
                  // hamdle leading zero amounts
                  result.amountChanges.removeAt(0);
                }
                if (result.amountChanges.length === 1 && result.amountChanges[0].amount === 0) {
                  delete result.amountChanges;
                }
                if (!Ember.isEmpty(result.amountChanges)) {
                  var startObj = result.amountChanges.objectAt(0);
                  var endObj = result.amountChanges.objectAt(result.amountChanges.length - 1);
                  if (root.get("budgetAttributes.startDate") && moment(root.get("budgetAttributes.startDate")).diff(moment(startObj.start), "months") !== 0) {
                    result.startDate = startObj.start;
                  }
                  if (root.get("budgetAttributes.endDate") && endObj.amount === 0 && moment(root.get("budgetAttributes.endDate")).diff(moment(endObj.start), "months") !== 0) {
                    result.endDate = endObj.start.addMonths(-1);
                  } else if (lastMoment && lastMoment.isBefore(root.get("budgetAttributes.endDate"))) {
                    result.endDate = lastMoment.toDate();
                  }
                  result.base = result.amountChanges.objectAt(0).amount;
                  result.amountChanges.removeAt(0);
                  if (!Ember.isEmpty(result.amountChanges) && !result.amountChanges.objectAt(result.amountChanges.length - 1).amount) {
                    result.amountChanges.removeAt(result.amountChanges.length - 1);
                  }
                }
              } else {
                if (root.get("budgetAttributes.startDate") && moment(root.get("budgetAttributes.startDate")).diff(moment(result.startDate), "months") === 0) {
                  delete result.startDate;
                }
                if (root.get("budgetAttributes.endDate") && moment(root.get("budgetAttributes.endDate")).diff(moment(result.endDate), "months") === 0) {
                  delete result.endDate;
                }
                result[amountAttribute] = getCellValue(showItemsValues[startLocation], multiplier, keepNegative, true);
              }
              delete result.customAmounts;
              delete result.period;
            }
          }
        }
      }
      return result;
    },
    getBalances: function getBalances(lines, headerColumns) {
      var amountColumns = headerColumns.filter(function (col) {
        return !Ember.get(col, "hide") && (Ember.get(col, "selectedAttribute") === "amount" || Ember.get(col, "selectedAttribute") === "credit" || Ember.get(col, "selectedAttribute") === "debit");
      });
      var assets = 0;
      var liabilities = 0;
      var bsLines = lines.filterBy("selectedFirst").filterBy("statement", "bs");
      var getTotalValuesForLine = function getTotalValuesForLine(line) {
        var result = 0;
        amountColumns.forEach(function (col) {
          var cell = line.valuesArray && line.valuesArray.findBy("headerKey", Ember.get(col, "key"));
          if (cell) {
            result += (Ember.get(col, "selectedAttribute") === "credit" ? -1 : 1) * getCellValue(Ember.get(cell, "value"), 1, true, true);
          }
        });
        return result;
      };
      if (bsLines.length) {
        var assetsSubtree = _const.default.BALANCE_SHEET_REPORT[0];
        var liabSubtree = _const.default.BALANCE_SHEET_REPORT[1];
        var findInSubtree = function findInSubtree(lineId, subtree) {
          if (subtree === lineId || (0, _typeof2.default)(subtree) === "object" && subtree.id === lineId) {
            return true;
          }
          if (subtree.lines) {
            for (var i = 0; i < subtree.lines.length; i++) {
              if (findInSubtree(lineId, subtree.lines[i])) {
                return true;
              }
            }
          }
          return false;
        };
        bsLines.forEach(function (line) {
          if (findInSubtree(line.bsLine, assetsSubtree)) {
            assets += getTotalValuesForLine(line);
          }
          if (findInSubtree(line.bsLine, liabSubtree)) {
            liabilities -= getTotalValuesForLine(line);
          }
        });

        // add pnl amount to liabilities total
        lines.filter(function (line) {
          return Ember.get(line, "selectedFirst") && !Ember.get(line, "empty") && (Ember.get(line, "statement") === "pnl" || Ember.get(line, "statement") === "both");
        }).forEach(function (line) {
          liabilities -= getTotalValuesForLine(line);
        });
      }
      return {
        assets: assets,
        liabilities: liabilities
      };
    },
    getCellNumbericValue: function getCellNumbericValue(cell) {
      if (!cell) {
        return 0;
      }
      var value = Ember.get(cell, "v");
      if (typeof value === "number") {
        return value;
      }
      if (Math.round(value) === Math.round(Ember.get(cell, "w"))) {
        value = Ember.get(cell, "w") ? Ember.get(cell, "w").replace(/,/g, "") * 1 : 0;
      }
      return isNaN(value) ? 0 : value;
    },
    extractDateFromCell: function extractDateFromCell(sheet, cell) {
      if (!sheet) {
        return null;
      }
      var dateString = sheet[cell] && sheet[cell].w;
      if (dateString && dateString.indexOf(" to ") > 0) {
        dateString = dateString.split(" to ")[1];
      }
      var result = dateString && Date.create(dateString);
      return result && result.isValid() ? result.beginningOfMonth() : null;
    },
    getTrimmedValue: function getTrimmedValue(data) {
      if (typeof data === "string") {
        return data.trim();
      }
      return data;
    },
    saveNewModelLines: function saveNewModelLines(importController) {
      var parent = importController.get("importTargetModel");
      var promises = [];
      var _importController = importController;
      var addedItems = [];
      var addedItemsStorageKey = "".concat(_importController.get("storageKey"), ".added");
      var storedData = localStorage.getItem(addedItemsStorageKey);
      if (storedData) {
        addedItems = JSON.parse(storedData);
      }
      importController.get("lineNamesForImportConsolidated").forEach(function (line) {
        var numDup = 1;
        var name = (line.value || "").trim();
        var originalName = name;
        while (_assumption.default.nameExists(parent, parent, name)) {
          name = "".concat(originalName, " ").concat(numDup++);
        }
        _importController.get("mainController").send("new", parent, "assumption", undefined, undefined, name, ImportUtils.fillAttributes(line, "assumption", _importController, _importController.get("mainController.selectedRoot.absoluteRoot")), undefined, undefined, {
          dontSave: true,
          delayTransition: true,
          noTransition: true
        });
        var newModelLine = _importController.get("importTargetModel.children.lastObject");
        if (newModelLine.get("budgetAttributes.comments")) {
          _importController.set("mainBudgetIndexController.showComments", 1); //display comments
        }
        promises.push(newModelLine.save().then(function (child) {
          _budgetUtils.default.setNewComments(_importController.get("store"), child, "comments");
        }));
        addedItems.addObject(line.get("location"));
      });
      addedItems.addObjects(importController.get("duplicatesRemoved") || []);
      _const.default.localStorageSetItem(addedItemsStorageKey, JSON.stringify(addedItems));
      Ember.RSVP.all(promises).then(function () {
        parent.save().then(function () {
          _importController.get("cache").clearBudgetCache(parent);
          _importController.transitionToRoute("main.budget.index");
          Ember.run.next(function () {
            Ember.run.scheduleOnce("afterRender", function () {
              _importController.get("mainController").set("showImport", false);
              Ember.$(".budgeta-fix-assumptions a").click();
              importController.showSuccessModalMessage();
            });
          });
        });
      });
    },
    consolidateLines: function consolidateLines(list, controller) {
      var distinctProperties = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
      var noCloneProps = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
      var thisUniqueId,
        curVal,
        duplicatesRemoved = [],
        selectedColumns = controller.get("currentTypeMappings").filter(function (col) {
          return !Ember.get(col, "hide");
        });
      var actualColumnHeaderKey = selectedColumns.find(function (col) {
        return Ember.get(col, "selectedAttribute") === "accountId";
      }).get("key");
      var getLineUniqueString = function getLineUniqueString(line) {
        var result = [line.value || ""];
        selectedColumns.forEach(function (col) {
          if (Ember.get(col, "selectedAttribute") === "accountId" || /[a-z0-9]{32}/.test(Ember.get(col, "selectedAttribute"))) {
            var val = line.valuesArray && line.valuesArray.findBy("headerKey", col.get("key"));
            result.push(val && val.value && val.value.w || "");
          }
        });
        distinctProperties.forEach(function (prop) {
          return line.get(prop) && result.push(line.get(prop));
        });
        return result.join(";");
      };
      var findDups = function findDups(item) {
        var itemUniqueId = getLineUniqueString(item);
        return itemUniqueId === thisUniqueId;
      };
      var isActualColumnValid = function isActualColumnValid(line) {
        var value = line.valuesArray && line.valuesArray.findBy("headerKey", actualColumnHeaderKey);
        return value && value.value && value.value.v;
      };
      var consolidatedResult = list.reduce(function (result, val, curIdx, initialArray) {
        curVal = val;
        if (!isActualColumnValid(curVal)) {
          result.pushObject(curVal);
        } else {
          thisUniqueId = getLineUniqueString(curVal);

          // check if line was not already processed
          if (curVal.value && !result.find(findDups)) {
            var duplicates = initialArray.filter(findDups);
            if (duplicates.length === 1) {
              result.pushObject(curVal);
            } else {
              // merge duplicates
              var mergedLine = _const.default.deepClone(duplicates[0], ["ctl", "store"].concat(noCloneProps));
              var targetLineNum = ImportUtils.getRowNumber(mergedLine.location);
              // go through all date columns and add amounts
              selectedColumns.filterBy("selectedAttribute", "#date#").forEach(function (col) {
                for (var i = 1; i < duplicates.length; i++) {
                  var thisLine = duplicates[i];
                  var thisLineNum = ImportUtils.getRowNumber(thisLine.location);
                  var headerKey = Ember.get(col, "col.key");
                  var thisKey = ImportUtils.getCol(headerKey) + thisLineNum;
                  var targetKey = ImportUtils.getCol(headerKey) + targetLineNum;
                  var targetCell = mergedLine.values[targetKey];
                  if (!targetCell) {
                    mergedLine.values[targetKey] = {
                      headerKey: headerKey
                    };
                    targetCell = mergedLine.values[targetKey];
                  }
                  var newValue = ImportUtils.getCellNumbericValue(targetCell) + ImportUtils.getCellNumbericValue(thisLine.values[thisKey]);
                  targetCell.v = newValue;
                  if (!mergedLine.showValues) {
                    targetCell = mergedLine.valuesArray.findBy("headerKey", headerKey);
                  } else {
                    targetCell = mergedLine.showValues.findBy("headerKey", headerKey);
                  }
                  if (targetCell && Ember.get(targetCell, "value")) {
                    Ember.set(targetCell, "value.v", newValue);
                    Ember.set(targetCell, "value.w", newValue);
                  }
                }
              });
              duplicatesRemoved.pushObjects(duplicates.slice(1).mapBy("location"));
              result.pushObject(mergedLine);
            }
          }
        }
        return result;
      }, []);
      return {
        result: consolidatedResult,
        duplicatesRemoved: duplicatesRemoved
      };
    },
    createHeader: function createHeader(key, value) {
      var existingOnly = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      return {
        hide: false,
        isDate: false,
        key: key,
        id: key,
        additionalHeader: true,
        selectedAttribute: key,
        existingOnly: existingOnly,
        value: {
          v: value,
          w: value
        }
      };
    },
    getCellCurrency: function getCellCurrency(amountCell) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var mainCurrency = options.mainCurrency;
      var defaultDuplicatesCurrencies = _lodash.default.invert(_const.default.DEFAULT_FOR_DUPLICATES_CURRENCIES);
      var allCurrenciesInverted = _lodash.default.invert(_const.default.currency_symbols);
      var cellCurrencySign = amountCell && amountCell.w.replace(/[0-9,.%()-\s]/g, "");
      var currency;

      //if there is sign in cell
      if (cellCurrencySign) {
        //if sign one of the duplicates
        if (defaultDuplicatesCurrencies[cellCurrencySign]) {
          currency = mainCurrency && _const.default.currency_symbols[mainCurrency] === cellCurrencySign //in case currency is one of the duplicates and also the mainCurrency -> mainCurrency, else the default for duplicate
          ? mainCurrency : defaultDuplicatesCurrencies[cellCurrencySign];
        } else {
          currency = allCurrenciesInverted[cellCurrencySign];
        }
      }
      return currency;
    },
    getColumnCurrency: function getColumnCurrency(currentTypeMappings, line) {
      var currencyColumn = currentTypeMappings.findBy("col.selectedAttribute", "currency");
      var currencyColumnKey = currencyColumn && currencyColumn["col"]["key"];
      var currencyColumnValue = currencyColumnKey && line.valuesArray.findBy("headerKey", currencyColumnKey);
      if (currencyColumnValue && currencyColumnValue["value"]) {
        return (currencyColumnValue["value"]["w"] || currencyColumnValue["value"]["v"].toString()).trim();
      }
    },
    matchLines: function matchLines(ctl, sheet) {
      if (ctl.get("lineNames") && sheet) {
        ctl.set("disableMultiEdit", true);
        ctl.get("lineNames").forEach(function (item) {
          ImportUtils.matchLine(item, ctl, sheet);
        });
        ctl.set("disableMultiEdit", false);
      }
      return true;
    },
    matchLine: function matchLine(item, ctl, sheet) {
      var pnlTypeRegExp = new RegExp("^(p&l|profit&loss|profit & loss|income statement)$", "gi");
      var bsTypeRegExp = new RegExp("^(balance sheet|bs)$", "gi");
      var singleTypeRegExp = new RegExp("^(single budget line)$", "gi");
      var multiTypeRegExp = new RegExp("^(multiple budget lines)$", "gi");
      var noneTypeRegExp = new RegExp("^(no mapping)$", "gi");
      var name = Ember.get(item, "value").trim();
      var values = ImportUtils.getLineValues(sheet, ctl.get("headerLocation"), Ember.get(item, "location"));
      if (Object.keys(values).length === 0) {
        Ember.set(item, "empty", true);
      } else {
        var isEmpty = true;
        for (var k in values) {
          if (values[k]) {
            isEmpty = false;
            break;
          }
        }
        if (isEmpty) {
          Ember.set(item, "empty", true);
        } else {
          Ember.set(item, "empty", false);
          Ember.set(item, "values", values);
          var lineNumber = ImportUtils.getRowNumber(item.location);
          Ember.set(item, "valuesArray", ctl.get("headerColumns").filter(function (col) {
            return col.key !== ctl.get("nameColumn");
          }).map(function (dateCol) {
            var value = values[ImportUtils.getCol(dateCol.get("key")) + lineNumber];
            return {
              headerKey: Ember.get(dateCol, "key"),
              value: value,
              zeroValue: !value || value.v === 0 || !value.w
            };
          }));
          var matchByAccountId = null;
          var matchingBSLine, matchingLine;
          var root = ctl.get("mainController.selectedRoot");
          if (ctl.get("importAccounts")) {
            Ember.get(item, "valuesArray").forEach(function (curCell) {
              var mapping;
              var mappingType;
              var headerColumn = ctl.get("headerColumns").findBy("key", Ember.get(curCell, "value.headerKey"));
              var text = Ember.get(curCell, "value.w");
              if (headerColumn && headerColumn.get("selectedAttribute") && text) {
                mappingType = headerColumn.get("selectedAttribute");
                var cellContent = text.replace(/\s+/g, " ").trim();
                if (mappingType === "type") {
                  if (cellContent.match(pnlTypeRegExp)) {
                    mapping = "pnl";
                  } else if (cellContent.match(bsTypeRegExp)) {
                    mapping = "bs";
                  }
                } else if (mappingType === "mappingType") {
                  if (cellContent.match(singleTypeRegExp)) {
                    mapping = "single";
                  } else if (cellContent.match(multiTypeRegExp)) {
                    mapping = "multi";
                  } else if (cellContent.match(noneTypeRegExp)) {
                    mapping = "none";
                  }
                } else if (mappingType === "description") {
                  mapping = text;
                }
              }
              Ember.set(curCell, "mapping", mapping);
              Ember.set(curCell, "mappingType", mappingType);
            });
          } else if (ctl.get("importActuals") && ctl.get("currentStep") > 1) {
            if (ctl.get("dataTypeData.statement")) {
              Ember.set(item, "statement", ctl.get("dataTypeData.statement"));
            }
            if (ctl.get("mapByDepartment")) {
              Ember.get(item, "valuesArray").forEach(function (curValue) {
                matchingLine = null;
                if (!curValue.zeroValue) {
                  var headerCol = ctl.get("headerColumns").findBy("key", curValue.headerKey);
                  var departmentId = Ember.get(headerCol, "selectedAttribute");
                  Ember.set(item, "matchedByName", false);
                  if (departmentId) {
                    var department = (ctl.get("departmentDimension.values") || Ember.A([])).findBy("uniqueId", departmentId);
                    var accountId = Ember.get(item, "value").match(/^\s*(\d+)\s+.*/);
                    accountId = accountId && accountId[1] ? accountId[1] : null;
                    // BUD-6146 if the field doesn't have a numbered account, use the whole text for matching
                    if (!accountId) {
                      accountId = Ember.get(item, "value").trim();
                    }
                    if (department) {
                      if (accountId) {
                        matchingLine = ctl.get("actualsMapping").findLineByAccountId(accountId, {
                          "departmentNames.firstObject": Ember.get(department, "name")
                        });
                        if (matchingLine) {
                          Ember.set(item, "accountId", accountId);
                        }
                      }
                      if (matchingLine) {
                        if (Ember.get(matchingLine, "budgetType") === "group-payroll") {
                          // try to find the category
                          var account = ctl.get("actualsMapping").getAccount(accountId, {
                            getFinal: true
                          });
                          var category,
                            categories = _const.default.DEFAULT_SALARY_CATEGORIES_ARRAY;
                          var matchingCategoryIndex = 0;
                          if (account) {
                            var catLine = account.get("budgetLines").find(function (l) {
                              return matchingLine.get("uniqueId") === l.split(":")[0];
                            });
                            if (catLine) {
                              matchingCategoryIndex = catLine.split(":")[1];
                              if (!Ember.isEmpty(matchingCategoryIndex)) {
                                matchingCategoryIndex *= 1;
                                category = categories[matchingCategoryIndex];
                              } else {
                                matchingCategoryIndex = catLine.split(":")[2]; // try to match to advanced benefit
                                if (!Ember.isEmpty(matchingCategoryIndex)) {
                                  var matchedBenefit = matchingLine.get("benefits").findBy("uniqueId", matchingCategoryIndex) || matchingLine.get("inheritedBenefits").findBy("uniqueId", matchingCategoryIndex);
                                  if (matchedBenefit) {
                                    category = matchedBenefit.get("name");
                                  }
                                }
                              }
                            }
                          }
                          if (categories && !category) {
                            category = categories[0];
                          }
                          Ember.set(curValue, "category", category);
                          Ember.set(item, "category", category);
                        }
                        Ember.set(curValue, "department", Ember.get(department, "name"));
                        Ember.set(curValue, "mapping", matchingLine.get("id"));
                        Ember.set(item, "selectedFirst", true);
                      }
                    }
                  }
                }
              });
            } else {
              var accountId,
                accountIdIsFromName = false;
              if (ctl.get("mappingColumn") && ctl.get("mappingColumn") !== "#in-name#") {
                var accountIdLocation = ImportUtils.getCol(ctl.get("mappingColumn")) + ImportUtils.getRowNumber(item.location);
                accountId = sheet[accountIdLocation] ? sheet[accountIdLocation].v : "";
              }
              if (!accountId && (ctl.get("mappingColumn") === "#in-name#" || ctl.get("dataTypeData.extractAccountFromName"))) {
                var match = item.value.match(/(\w?\d+[\d\-\.]*).*/);
                if (match) {
                  accountId = match[1];
                }
              }
              // get accountId from previous lines (SAP JE template)
              if (ctl.get("dataTypeData.accountLine")) {
                // check condition
                var conditionFulfilled = false;
                ctl.get("dataTypeData.accountLine.condition").forEach(function (cond) {
                  var condValue = Ember.get(item, "valuesArray")[cond.columnIndex];
                  if (cond.value && cond.value.contains(Ember.get(condValue, "value.w"))) {
                    conditionFulfilled = true;
                  } else if (cond.isDate && condValue && condValue.value && condValue.value.t === "n" && condValue.value.v > 30000) {
                    conditionFulfilled = true;
                  } else if (cond.isDate === false && condValue && condValue.value && !(condValue.value.t === "n" && condValue.value.v > 30000)) {
                    conditionFulfilled = true;
                  }
                });
                if (conditionFulfilled) {
                  var accountCell = Ember.get(item, "valuesArray")[ctl.get("dataTypeData.accountLine.columnIndex")];
                  var currentActualsAccount = accountCell ? Ember.get(accountCell, "value.w") : null;
                  if (currentActualsAccount && ctl.get("dataTypeData.extractAccountFromName")) {
                    var currentMatch = currentActualsAccount.match(/(\d+[\d\-\.]*).*/);
                    if (currentMatch) {
                      currentActualsAccount = currentMatch[1];
                    }
                  }
                  ctl.set("currentActualsAccount", currentActualsAccount);
                } else {
                  accountId = ctl.get("currentActualsAccount");
                }
              }
              if (!accountId) {
                // if no account ID, try to match by line name
                accountId = item.value;
                accountIdIsFromName = true;
              }
              if (accountId) {
                if (typeof accountId === "string") {
                  accountId = accountId.trim();
                }
                if (!accountIdIsFromName) {
                  Ember.set(item, "accountId", accountId);
                }
                var departmentDimension = ctl.get("budgetService.dimensions").findBy("isDepartment");
                var departmentHeaderCol = departmentDimension && ctl.get("headerColumns").findBy("selectedAttribute", "dimensions.".concat(departmentDimension.get("uniqueId")));
                var thisDepartment = departmentHeaderCol ? item.valuesArray.findBy("headerKey", departmentHeaderCol.key) : null;
                thisDepartment = thisDepartment && thisDepartment.value && thisDepartment.value.w;
                var account = ctl.get("actualsMapping").getAccount(accountId, {
                  getFinal: true
                });
                if (!account) {
                  // try to find account by exact match
                  account = ctl.get("actualsMapping").getAccount(item.value, {
                    getFinal: true
                  });
                  if (account) {
                    accountId = account.get("account");
                    Ember.set(item, "accountId", accountId);
                  }
                }
                matchByAccountId = ctl.get("actualsMapping").findLineByAccountId(accountId, {
                  "departmentNames.firstObject": thisDepartment
                }, ctl.get("headerColumns").filter(function (c) {
                  return c.selectedAttribute && c.selectedAttribute.startsWith("dimensions.");
                }).map(function (c) {
                  var val = item.valuesArray.findBy("headerKey", c.key);
                  return {
                    dimId: c.selectedAttribute.split(".")[1],
                    value: val ? Ember.get(val, "value.w") : ""
                  };
                }), new Set(item.getWithDefault("mappingOptions", []).map(function (option) {
                  return option.id;
                })));
                if (account && account.get("type") === "bs") {
                  Ember.set(item, "statement", "bs");
                  matchingBSLine = account.get("budgetLines.firstObject");
                }
              }
              if (!matchByAccountId && ctl.get("actualsMapping.accounts").findBy("type", "bs")) {
                if (!matchingBSLine) {
                  var _account = ctl.get("actualsMapping").getAccount(item.value, {
                    getFinal: true
                  });
                  if (_account) {
                    matchingBSLine = _account.get("budgetLines.firstObject");
                    Ember.set(item, "accountId", _account.get("account"));
                  }
                }
                if (matchingBSLine) {
                  Ember.set(item, "statement", "bs");
                }
              }
              if (!matchingBSLine && ctl.get("dataTypeData.statement") === "bs" && name) {
                // try to match balance sheet line by name
                var bsLineId = _const.default.DEFAULT_OPENING_BALANCES.find(function (line) {
                  return line.displayName.toLowerCase() === name.toLowerCase();
                });
                if (bsLineId) {
                  Ember.set(item, "matchedByName", true);
                  matchingBSLine = ctl.get("actualsMapping.accounts").findBy("budgetLines.firstObject", bsLineId.name);
                }
              }
              if (!matchByAccountId && !matchingBSLine) {
                Ember.set(item, "matchedByName", true);
                // try to match the name to actuals accounts
                matchByAccountId = ctl.get("actualsMapping").findLineByAccountId(item.value);
              }
              matchingLine = matchByAccountId || ctl.get("dataTypeData.defaultMapToRoot") && ctl.get("model.root");
              if (!matchingLine && (!accountId || accountIdIsFromName)) {
                // match by name only if there's no account number
                matchingLine = name && _const.default.findOneInBudgetFast(ctl.store, root, "name", name, {
                  exclude: {
                    isModelLine: true
                  }
                });
              }
              if (matchByAccountId && Ember.get(matchingLine, "budgetType") === "group-payroll") {
                var matchingCategoryIndex = 0;
                var _account2 = ctl.get("actualsMapping").getAccount(accountId, {
                  getFinal: true
                });
                var category,
                  categories = _const.default.DEFAULT_SALARY_CATEGORIES_ARRAY;
                if (_account2) {
                  var catLine = _account2.get("budgetLines").find(function (l) {
                    return matchingLine.get("uniqueId") === l.split(":")[0];
                  });
                  if (catLine) {
                    matchingCategoryIndex = catLine.split(":")[1];
                    if (!Ember.isEmpty(matchingCategoryIndex)) {
                      matchingCategoryIndex *= 1;
                      category = categories[matchingCategoryIndex];
                    } else {
                      matchingCategoryIndex = catLine.split(":")[2]; // try to match to advanced benefit
                      if (!Ember.isEmpty(matchingCategoryIndex)) {
                        var matchedBenefit = matchingLine.get("benefits").findBy("uniqueId", matchingCategoryIndex) || matchingLine.get("inheritedBenefits").findBy("uniqueId", matchingCategoryIndex);
                        if (matchedBenefit) {
                          category = matchedBenefit.get("name");
                        }
                      }
                    }
                  }
                }
                if (categories && !category) {
                  category = categories;
                }
                Ember.set(item, "category", category);
              }
              if (ctl.get("mapCategories")) {
                var categoryColumn = ctl.get("headerColumns").findBy("selectedAttribute", "category");
                if (categoryColumn && !Ember.get(categoryColumn, "hide")) {
                  // try to match the category from the selected category column
                  var thisCategoryValue = item.valuesArray && item.valuesArray.findBy("headerKey", Ember.get(categoryColumn, "key"));
                  if (thisCategoryValue && thisCategoryValue.value.w && ctl.get("categoryOptions").findBy("value", thisCategoryValue.value.w)) {
                    Ember.set(item, "category", thisCategoryValue.value.w);
                    Ember.set(item, "selectedFirst", true);
                  }
                }
                var recallCategoryMapping = localStorage.getItem("actualImport.categoryMapping.".concat(Ember.get(item, "value").trim()));
                if (recallCategoryMapping && ctl.get("categoryOptions").findBy("value", recallCategoryMapping)) {
                  Ember.set(item, "category", recallCategoryMapping);
                  Ember.set(item, "selectedFirst", true);
                }
              }
            }
          } else if (ctl.get("importDimensionsMappings")) {
            /* In this code, we would like to match the attributes selected for each column to the values
            Regarding the dimVal attribute we want to match the values in the excel to values in the dimension
            If the user selected "dimVal" but the value does not exist, we will use the option of new Value  */
            var dimensionValues = ctl.get("dimensionValues"); // Getting all the dimension values
            Ember.get(item, "valuesArray").forEach(function (curCell) {
              var mapping;
              var mappingType;
              var headerColumn = ctl.get("headerColumns").findBy("key", Ember.get(curCell, "headerKey"));
              var text = Ember.get(curCell, "value.w") || "";
              if (headerColumn && headerColumn.get("selectedAttribute")) {
                mappingType = headerColumn.get("selectedAttribute");
                var cellContent = text.replace(/\s+/g, " ").trim();
                if (mappingType === "financial") {
                  mapping = cellContent;
                } else if (mappingType === "dimVal") {
                  var searchTerm = cellContent.toLowerCase().trim();
                  var valueObject = dimensionValues.find(function (val) {
                    return searchTerm == val.text.trim().toLowerCase();
                  });

                  // If the value wasn't found
                  if (!valueObject) {
                    mapping = "new_val";
                  } else {
                    mapping = valueObject.id; // Setting the id of the dimension value to add
                  }
                }
              }
              Ember.set(curCell, "mapping", mapping);
              Ember.set(curCell, "mappingType", mappingType);
            });
          }
          if (matchingBSLine) {
            Ember.set(item, "bsLine", matchingBSLine);
            Ember.set(item, "selectedFirst", true);
          } else if (matchingLine) {
            Ember.set(item, "mapping", matchingLine.get("id"));
            Ember.set(item, "currency", matchingLine.get("forecastAttributes.currency"));
            Ember.set(item, "selectedFirst", true);
          } else {
            Ember.set(item, "mapping", null);
            Ember.set(item, "currency", ctl.get("model.forecastAttributes.currency"));
          }
        }
      }
    },
    getStepHeaderName: function getStepHeaderName(step, ctl) {
      var func = {
        0: function _() {
          return ctl.get("sheetArray.firstObject.content");
        },
        1: function _() {
          return ctl.get("importActuals") ? ctl.get("sheetArray.firstObject.content") : ctl.get("headerColumns");
        },
        2: function _() {
          if (ctl.get("importActuals")) {
            return ctl.get("headerColumns");
          } else if (ctl.get("importColumnBeforeRows")) {
            return ctl.get("headerColumnsAfterMapping");
          }
          return ctl.get("currentTypeMappings").map(function (item) {
            return item.col;
          });
        },
        3: function _() {
          if (ctl.get("importColumnBeforeRows")) {
            return ctl.get("budgetLinePreviewHeaders").map(function (item) {
              return item.col;
            });
          }
          if (ctl.get("importActuals")) {
            return ctl.get("headerColumnsWithMapping").map(function (item) {
              return item.col;
            });
          }
          return ctl.get("currentTypeMappings").map(function (item) {
            return item.col;
          });
        },
        4: function _() {
          if (ctl.get("importLines")) {
            return ctl.get("budgetLinePreviewHeaders").map(function (item) {
              return item.col;
            });
          }
          if (ctl.get("importActuals")) {
            return ctl.get("previewHeaderColumns").map(function (item) {
              return item.col;
            });
          }
          return ctl.get("currentTypeMappings").map(function (item) {
            return item.col;
          });
        }
      };
      return func[step]();
    }
  };
  var _default = _exports.default = ImportUtils;
});