define("budgeta/services/tree-manager", ["exports", "budgeta/services/ember-service-container", "budgeta/utils/const", "budgeta/utils/forecast", "budgeta/utils/grid/grid-filters-utils"], function (_exports, _emberServiceContainer, _const, _forecast, _gridFiltersUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/no-observers */
  var _default = _exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    budgetService: Ember.inject.service(),
    collapsedBudgets: Ember.inject.service(),
    viewSelections: Ember.inject.service(),
    metricsInputs: Ember.computed.alias("viewSelections.metricsInputs"),
    triggerBudgetsListRecalc: null,
    cache: Ember.inject.service(),
    _budgets: Ember.computed("budgetsList.@each.budget", function () {
      if (!this.get("budgetsList")) {
        return [];
      }
      return this.get("budgetsList").map(function (b) {
        return b.get("budget");
      });
    }),
    setBudget: function setBudget(budget) {
      this.set("budget", budget);
      var budgetInfoLinkTo = this.get("budgetService.budgetInfoLinkTo");
      if (budgetInfoLinkTo) {
        var budgetInfo = this.get("store").peekAll("budget").filter(function (budget) {
          return budgetInfoLinkTo.indexOf(budget.get("uniqueId")) !== -1;
        }).mapBy("name").join(" OR ");
        this.set("searchValue", budgetInfo || "");
      }
    },
    searchValue: "",
    openAllParents: function openAllParents(budget, budgetsList) {
      var temp = budgetsList.findBy("budget", budget.get("budget.parent"));
      if (temp) {
        temp.setProperties({
          isCollapsed: false,
          searchFilter: false
        });
        this.openAllParents(temp, budgetsList);
      }
    },
    openChildren: function openChildren(budget, budgetsList) {
      var _this2 = this;
      var temp = budgetsList.findBy("budget", budget);
      if (temp) {
        temp.setProperties({
          isCollapsed: false,
          searchFilter: false
        });
      }
      if (Ember.get(budget, "children")) {
        Ember.get(budget, "children").forEach(function (child) {
          _this2.openChildren(child, budgetsList);
        });
      }
    },
    applyTreeItemsFilters: function applyTreeItemsFilters(treeItems) {
      var _this3 = this;
      var absoluteRoot = this.get("budgetService.selectedRoot.absoluteRoot");
      var store = this.get("store");
      var isFilterLinesDirty = _gridFiltersUtils.default.isFilterLinesDirty();

      // no filter exists that changes the budget tree
      if (!isFilterLinesDirty) {
        return treeItems;
      }
      var options = _gridFiltersUtils.default.getOptions({
        absoluteRoot: absoluteRoot,
        store: store,
        scenario: this.get("budgetService.scenario")
      });
      var isConditionFulfilled = function isConditionFulfilled(budget) {
        var isNewBudget = !budget.get("id");
        var isLeafBudget = !budget.get("hasChildren");
        return !budget.get("isModelLine") && (isNewBudget || isLeafBudget && _this3.isSearchTermApplied(budget, _this3.get("searchValue")) && _forecast.default.isDimensionOptionApplied(budget, options) && _forecast.default.isAccountOptionApplied(budget, options) && _forecast.default.isTagOptionApplied(budget, options));
      };
      return this.filterTreeItemsByCondition(treeItems, isConditionFulfilled);
    },
    filterTreeItemsByCondition: function filterTreeItemsByCondition(treeItems, isConditionFulfilled) {
      // This function gets tree items and run a condition for every item:
      // a leaf remains if it fulfills the condition,
      // parent remains if one of its children fulfills the condition
      var budgetConditionStatuses = {};
      var checkBudgetCondition = function checkBudgetCondition(budget, isConditionFulfilled) {
        // if no budget, ignore it
        if (!budget) return false;

        // checking if we iterated it before. If so, we know this budget's result.
        if (Object.prototype.hasOwnProperty.call(budgetConditionStatuses, budget.id)) {
          return budgetConditionStatuses[budget.id];
        }

        // if condition is applied, store it.
        if (isConditionFulfilled(budget)) {
          budgetConditionStatuses[budget.id] = true;
          return true;
        }
        var budgetChildren = budget.get("children").toArray();
        // iterate over budget's children
        for (var i = 0; i < budgetChildren.length; ++i) {
          var child = budgetChildren[i];
          if (checkBudgetCondition(child, isConditionFulfilled)) {
            // condition is applied on child. update parent and child status and stop searching.
            budgetConditionStatuses[budget.id] = true;
            budgetConditionStatuses[child.id] = true;
            return true;
          }
        }
        // condition was not applied
        budgetConditionStatuses[budget.id] = false;
        return false;
      };

      // check condition for every budget in tree items
      for (var i = 0; i < treeItems.length; i++) {
        checkBudgetCondition(treeItems[i].budget, isConditionFulfilled);
      }

      // returns the items that are fulfilled the condition
      return treeItems.filter(function (item) {
        return budgetConditionStatuses[item.budget.id];
      });
    },
    getDisplayedTreeItems: function getDisplayedTreeItems() {
      this.set("includeCollapsed", this.get("viewSelections.isLeafDisplay"));
      var treeItems = this.get("inSearchMode") ? this.get("budgetsListSearchMode") : this.get("budgetsListTreeWithoutModel");
      if (this.get("viewSelections.isLeafDisplay")) {
        treeItems = treeItems.filter(function (treeItem) {
          return !treeItem.budget.get("allowChildren");
        });
      }
      if (this.get("viewSelections.isGridView")) {
        treeItems = this.applyTreeItemsFilters(treeItems);
      }
      return treeItems;
    },
    displayedTreeItems: Ember.computed("inSearchMode", "budgetsListSearchMode.length", "budgetsListTreeWithoutModel", "viewSelections.isLeafDisplay", "collapsedBudgets.collapsed_tree_map", "viewSelections.gridFiltersUpdatedTime", function () {
      return this.getDisplayedTreeItems();
    }),
    isSearchTermApplied: function isSearchTermApplied(budget, term) {
      if (!this.get("inSearchMode")) {
        return true;
      }
      term = (term || "").trimStart();
      var searchRegExp = _const.default.safeRegExp(term, "gi", true);
      var budgetInfoLinkTo = this.get("budgetService.budgetInfoLinkTo");
      var shouldFilter = budget.get("accountIds").search(searchRegExp) === -1 && budget.get("title") && budget.get("title").search(searchRegExp) === -1;
      if (!shouldFilter && budgetInfoLinkTo && budgetInfoLinkTo.indexOf(Ember.get(budget, "uniqueId")) === -1) {
        shouldFilter = true;
      }
      return !shouldFilter;
    },
    budgetsListSearchMode: Ember.A([]),
    filterTree: function filterTree() {
      var _this4 = this;
      var budgetsList = Ember.A([]);
      if (this.get("viewSelections.isLeafDisplay") && !this.get("metrics")) {
        budgetsList = this.get("budgetsListTreeWithoutModel");
        budgetsList = budgetsList.filter(function (treeItem) {
          return !treeItem.budget.get("allowChildren");
        });
      } else {
        budgetsList = this.get("budgetsList");
      }
      var inSearchMode = this.get("inSearchMode"),
        collapsedBudgets = this.get("collapsedBudgets"),
        shouldFilter,
        openChildrenArr = [],
        budgetsListSearchMode = Ember.A([]);
      if (inSearchMode) {
        for (var i = 0; i < budgetsList.length; i++) {
          // copy the tree
          // Non scenario - add only base budget lines
          // Active Scenario - add all relevant active scenario budget lines in addition to base
          var budgetLine = Ember.get(budgetsList[i], "budget");
          if (!budgetLine.get("isBudgetAddedForScenario") || budgetLine.get("isAddedToCurrentScenario")) {
            budgetsListSearchMode.pushObject(Ember.Object.create({
              budget: budgetLine,
              level: Ember.get(budgetsList[i], "level"),
              isCollapsed: Ember.get(budgetsList[i], "isCollapsed"),
              isNew: Ember.get(budgetsList[i], "isNew")
            }));
          }
        }
        for (var _i = 0; _i < budgetsListSearchMode.length; _i++) {
          // working on the tree copy
          shouldFilter = !this.isSearchTermApplied(budgetsListSearchMode[_i].get("budget"), this.get("searchValue"));
          budgetsListSearchMode[_i].setProperties({
            searchFilter: shouldFilter,
            searchFound: false
          });
          if (!shouldFilter) {
            budgetsListSearchMode[_i].setProperties({
              isCollapsed: false,
              searchFound: true
            });
            // open all parent
            this.openAllParents(budgetsListSearchMode[_i], budgetsListSearchMode);
            openChildrenArr.push(budgetsListSearchMode[_i]);
          }
        }
        openChildrenArr.forEach(function (budget) {
          _this4.openChildren(budget.get("budget"), budgetsListSearchMode);
        });
        this.set("budgetsListSearchMode", budgetsListSearchMode.filter(function (budgetsListElement) {
          return !Ember.get(budgetsListElement, "isCollapsed") && !Ember.get(budgetsListElement, "searchFilter") && !Ember.get(budgetsListElement, "budget.isModelGroup") && Ember.get(budgetsListElement, "budget.budgetType") !== "assumption" || Ember.get(budgetsListElement, "isNew");
        }));
        var backup = Ember.A([]);
        this.get("budgetsListSearchMode").forEach(function (budgetSearch) {
          backup.pushObject({
            budget: Ember.get(budgetSearch, "budget"),
            level: Ember.get(budgetSearch, "level"),
            isCollapsed: Ember.get(budgetSearch, "isCollapsed"),
            searchFilter: Ember.get(budgetSearch, "searchFilter"),
            searchFound: Ember.get(budgetSearch, "searchFound")
          });
        });
        this.set("backupBudgetsListSearchMode", backup);
      } else {
        for (var _i2 = 0; _i2 < budgetsList.length; _i2++) {
          budgetsList[_i2].setProperties({
            searchFilter: false,
            isCollapsed: collapsedBudgets.getItem(budgetsList[_i2].budget),
            searchFound: false
          });
        }
      }
      Ember.run.scheduleOnce("afterRender", this, function () {
        var treeHeight = Ember.$(".main-content ol.tree").height();
        var position = Ember.$(".main-content ol.tree li.budget-list-item.active").position();
        if (position && (position.top < 0 || position.top > treeHeight)) {
          Ember.$(".main-content ol.tree").scrollTo(Ember.$(".main-content ol.tree li.budget-list-item.active"));
        }
      });
    },
    searchValueObserver: Ember.observer("searchValue", "inSearchMode", "budgetsList.length", "budgetService.scenario", "budgetService.budgetInfoLinkTo.length", function () {
      var _this = this;
      Ember.run.debounce(_this, _this.filterTree, 500, false);
    }),
    searchValueOnlyObserver: Ember.observer("searchValue", function () {
      (this.get("budgetsList").filterBy("isNew") || Ember.A([])).setEach("isNew", false);
    }),
    inSearchMode: Ember.computed("searchValue", "metrics", function () {
      return !(this.get("metrics") === "true" || this.get("metrics") === true) && this.get("searchValue") && this.get("searchValue") !== "";
    }),
    includedModelsObserver: Ember.observer("budgetService.selectedRootPermissions.options.includeModels.length", function () {
      if (this.get("budgetService.selectedRootPermissions.options.includeModels.length")) {
        this.notifyPropertyChange("triggerBudgetsListRecalc");
      }
    }),
    hideEmployeesObserver: Ember.observer("budgetService.hideEmployees", function () {
      if (this.get("budgetService.hideEmployees")) {
        this.notifyPropertyChange("triggerBudgetsListRecalc");
      }
    }),
    addSharedModels: Ember.computed("budgetService.selectedRootPermissions.options.includeModels.length", "budgetService.selectedRoot.absoluteRoot.children.length", "budgetService.selectedRoot.absoluteRoot.children.@each.childrenLength", function () {
      var _this5 = this;
      var result = [];
      if (this.get("budgetService.selectedRootPermissions.options.includeModels.length")) {
        this.get("budgetService.selectedRootPermissions.options.includeModels").forEach(function (modelId) {
          var model = _this5.get("store").peekRecord("budget", modelId);
          if (model) {
            result.pushObject(Ember.Object.create({
              budget: model,
              level: 0,
              isCollapsed: false
            }));
            if (model.get("budgetType") === "group-model-parent") {
              model.get("children").forEach(function (child) {
                result.pushObject(Ember.Object.create({
                  budget: child,
                  level: 1,
                  isCollapsed: false
                }));
              });
            }
          }
        });
      }
      return result;
    }),
    selectedBudgetLis: Ember.observer("budgetService.selectedBudget", function () {
      var budgetsList = this.get("budgetsList");
      var setChildCollapse = function setChildCollapse(child) {
        var tempTreeEle = budgetsList.findBy("budget", child);
        if (tempTreeEle) {
          tempTreeEle.set("isCollapsed", false);
        }
      };
      if (this.get("budgetService.selectedBudget")) {
        var currentBudget = this.get("budgetService.selectedBudget");
        var treeEle = budgetsList.findBy("budget", currentBudget);
        while (treeEle && treeEle.get("isCollapsed")) {
          var parentTreeEle = budgetsList.findBy("budget", currentBudget.get("parent"));
          if (parentTreeEle) {
            parentTreeEle.get("budget.children").forEach(setChildCollapse);
            this.get("collapsedBudgets").removeTreeItem(parentTreeEle.get("budget"), false, false, true);
          }
          currentBudget = currentBudget.get("parent");
          treeEle = parentTreeEle;
        }
      }
    }),
    budgetsList: Ember.computed("budget.id", "triggerBudgetsListRecalc", {
      get: function get() {
        var flattenTree = this.flattenTree(this.get("budget"), Ember.A([]), 0);
        // add shared models
        flattenTree.addObjects(this.get("addSharedModels"));
        var levels = [];
        var numberItems = flattenTree.length;
        flattenTree.forEach(function (item) {
          var itemLevel = item.get("level") * 1;
          if (!levels[itemLevel]) {
            levels[itemLevel] = [];
          }
          levels[itemLevel].push(item);
        });
        var collapseIndex = levels.length - 2;
        while (numberItems > 100 && levels[collapseIndex]) {
          var currentLevel = levels[collapseIndex];
          var prevLevel = levels[collapseIndex + 1];
          for (var i = 0; i < prevLevel.length; i++) {
            prevLevel[i].set("isCollapsed", true);
          }
          numberItems -= prevLevel.length;
          for (var _i3 = 0; _i3 < currentLevel.length; _i3++) {
            this.get("collapsedBudgets").addTreeItem(currentLevel[_i3].get("budget"), true, false, true);
          }
          collapseIndex--;
        }
        return flattenTree;
      },
      set: function set(key, value) {
        try {
          // keep tree scroll position after rerender
          var $treeEl = Ember.$("aside>.tree");
          if ($treeEl.length) {
            var treeEl = $treeEl.get(0);
            var scrollTop = treeEl.scrollTop;
            Ember.run.scheduleOnce("afterRender", function () {
              treeEl.scrollTop = scrollTop;
            });
          }
        } catch (e) {
          // ignore
        }
        return value;
      }
    }),
    budgetsListWithoutModel: Ember.computed("budgetsList.[]", function () {
      var budgetsList = this.get("budgetsList");
      return budgetsList.filter(function (budget) {
        var type = Ember.get(budget, "budget.budgetType");
        return !Ember.get(budget, "budget.isModelGroup") && type !== "assumption";
      });
    }),
    dragBudgetLine: undefined,
    overBudgetLine: undefined,
    includeCollapsed: false,
    budgetsListTreeWithoutModel: Ember.computed("budgetsListWithoutModel.[]", "budgetService.scenario", "includeCollapsed", function () {
      var budgetsList = this.get("budgetsListWithoutModel"),
        scenario = this.get("budgetService.scenario");
      var _includeCollapsed = this.get("includeCollapsed");
      if (!scenario) {
        return budgetsList.filter(function (b) {
          return !b.get("budget.isBudgetAddedForScenario") && (_includeCollapsed || !Ember.get(b, "isCollapsed"));
        });
      }
      return budgetsList.filter(function (b) {
        return (_includeCollapsed || !Ember.get(b, "isCollapsed")) && (!b.get("budget.isBudgetAddedForScenario") || b.get("budget.addedForScenarios").includes(scenario));
      });
    }),
    budgetsListTreeModels: Ember.computed("budgetsList.length", "budgetService.scenario", function () {
      var budgetsList = this.get("budgetsList"),
        scenario = this.get("budgetService.scenario");
      if (!scenario) {
        return budgetsList.filter(function (b) {
          return b.get("budget.isModelGroup") && !b.get("budget.isBudgetAddedForScenario") && !Ember.get(b, "isCollapsed") && !Ember.get(b, "budget.subBudgetType");
        });
      }
      return budgetsList.filter(function (b) {
        return !Ember.get(b, "isCollapsed") && !Ember.get(b, "budget.subBudgetType") && b.get("budget.isModelGroup") && (!b.get("budget.isBudgetAddedForScenario") || b.get("budget.addedForScenarios").includes(scenario));
      });
    }),
    budgetsListTreeStatisticals: Ember.computed("budgetsList.[]", "budgetService.scenario", function () {
      var budgetsList = this.get("budgetsList"),
        scenario = this.get("budgetService.scenario");
      if (!scenario) {
        return budgetsList.filter(function (b) {
          return b.get("budget.isModelGroup") && !b.get("budget.isBudgetAddedForScenario") && !Ember.get(b, "isCollapsed") && Ember.get(b, "budget.subBudgetType") === "stat";
        });
      }
      return budgetsList.filter(function (b) {
        return !Ember.get(b, "isCollapsed") && Ember.get(b, "budget.subBudgetType") === "stat" && b.get("budget.isModelGroup") && (!b.get("budget.isBudgetAddedForScenario") || b.get("budget.addedForScenarios").includes(scenario));
      });
    }),
    budgetsListTreeModelsAll: Ember.computed("budgetsList.length", "budgetService.scenario", "budgetsListTreeModels", function () {
      var budgetsList = this.get("budgetsList"),
        scenario = this.get("budgetService.scenario");
      if (!scenario) {
        return budgetsList.filter(function (b) {
          return b.get("budget.isModelGroup") && !b.get("budget.isBudgetAddedForScenario");
        });
      }
      return budgetsList.filter(function (b) {
        return b.get("budget.isModelGroup") && (!b.get("budget.isBudgetAddedForScenario") || b.get("budget.addedForScenarios").includes(scenario));
      });
    }),
    budgetsListModelLines: Ember.computed("budgetsList", "budgetsList.length", function () {
      var budgetsList = this.get("budgetsList");
      return budgetsList.filterBy("budget.budgetType", "assumption").map(function (obj) {
        return obj.budget;
      });
    }),
    budgetsObjListWithoutModel: Ember.computed("budgetsList", "budgetsList.length", function () {
      var budgetsList = this.get("budgetsList");
      budgetsList = budgetsList.map(function (obj) {
        return obj.budget;
      });
      return budgetsList.filter(function (budget) {
        var type = Ember.get(budget, "budgetType");
        return !Ember.get(budget, "isModelGroup") && type !== "assumption";
      });
    }),
    isBudgetStructureWarning: Ember.computed("totalBudgetAndModelLines", function () {
      return this.get("totalBudgetAndModelLines") >= 3000;
    }),
    totalBudgetAndModelLines: Ember.computed("budgetsObjListWithoutAdvancedBenfites.length", "budgetsModelGroupAndLines.length", function () {
      return this.get("budgetsObjListWithoutAdvancedBenfites").length + this.get("budgetsModelGroupAndLines").length;
    }),
    budgetsObjListWithoutAdvancedBenfites: Ember.computed("budgetsObjListWithoutModel.length", function () {
      var budgetsObjListWithoutModel = this.get("budgetsObjListWithoutModel");
      return budgetsObjListWithoutModel.filter(function (budget) {
        return !["expense-benefit"].includes(budget.get("budgetType"));
      });
    }),
    budgetsModelGroupAndLines: Ember.computed("budgetsList", "budgetsList.length", function () {
      var budgetsList = this.get("budgetsList");
      return budgetsList.filter(function (budget) {
        return Ember.get(budget, "budget.isModelGroup") || Ember.get(budget, "budget.isModelLine");
      });
    }),
    budgetsObjModelList: Ember.computed("budgetsList", "budgetsList.length", function () {
      var budgetsList = this.get("budgetsList");
      return budgetsList.filter(function (budget) {
        return Ember.get(budget, "budget.isModelGroup");
      });
    }),
    recalcBudgetList: function recalcBudgetList() {
      var flattenTree = this.flattenTree(this.get("budget"), Ember.A([]), 0);
      flattenTree.addObjects(this.get("addSharedModels"));
      this.set("budgetsList", flattenTree);
      this.get("budgetService").calcUniqueBudgetName();
      this.get("budgetService.actualsMapping").notifyPropertyChange("recalcAccount"); // Notifing that an account has created
    },
    syncLists: function syncLists(oldList, newList) {
      var oldElement,
        deletedElements = Ember.A([]),
        oldListMap = {};
      // remove deleted objects
      oldList.forEach(function (element) {
        var temp = newList.findBy("budget", element.get("budget"));
        if (!temp) {
          // deleted budget
          deletedElements.pushObject(element);
        }
        oldListMap[element.get("budget")] = element;
      });
      oldList.removeObjects(deletedElements);

      // add new objects
      var temp;
      newList.forEach(function (newElement, index) {
        temp = oldListMap[newElement.get("budget")];
        if (!temp) {
          // new budget
          oldListMap[newElement.get("budget")] = newElement;
          if (index > oldList.length) {
            oldList.pushObject(newElement);
            return;
          }
          oldList.insertAt(index, newElement);
        }
      });
      if (newList.length !== oldList.length) {
        noty({
          type: "error",
          timeout: false,
          text: intl.t("general.text_1695611611060")
        });
      }
      var tempIndex;
      newList.forEach(function (newElement, i) {
        oldElement = oldListMap[newElement.budget];
        tempIndex = oldList.indexOf(oldElement);
        if (tempIndex !== i) {
          oldList.removeAt(tempIndex);
          if (i > oldList.length) {
            oldList.pushObject(oldElement);
          } else {
            oldList.insertAt(i, oldElement);
          }
        }
        oldElement.setProperties({
          isCollapsed: newElement.get("isCollapsed"),
          level: newElement.get("level")
        });
      });
    },
    backupBudgetsListSearchMode: Ember.A([]),
    addElementToTree: function addElementToTree(list, budget, scenario) {
      var parent = this.get("budgetsList").findBy("budget.id", Ember.get(budget, "budget.parent.id"));
      var parentIndex = list.indexOf(parent);
      var children = parent.budget.get("children");
      if (parentIndex !== -1) {
        if (scenario) {
          children = parent.budget.get("children").filter(function (budget) {
            return !Ember.get(budget, "isBudgetAddedForScenario") || Ember.get(budget, "addedForScenarios").includes(scenario);
          });
        } else {
          children = parent.budget.get("children").filter(function (budget) {
            return !Ember.get(budget, "isBudgetAddedForScenario");
          });
        }
        var prevIndex = children.indexOf(budget.budget);
        if (prevIndex >= 1) {
          prevIndex--;
        }

        // prev brother
        var prevBrother = list.findBy("budget.id", children[prevIndex].get("id"));
        var i = parentIndex + 1;
        if (prevBrother) {
          i = list.indexOf(prevBrother) + 1;
        }
        while (list[i] && list[i].get("level") > budget.get("level") && i < list.length) {
          // pass the subtree of child - 1
          i++;
        }
        list.insertAt(i, budget);
      }
    },
    setBudgetCollapsed: function setBudgetCollapsed(budgetId, isCollapsed, isSearchMode) {
      var budget = this.get("budgetsList").findBy("budget.id", budgetId),
        budgetsListSearchMode = this.get("budgetsListSearchMode"),
        budgetSearch = budgetsListSearchMode.findBy("budget.id", budgetId),
        backupBudgetsListSearchMode = this.get("backupBudgetsListSearchMode"),
        backupBudgetSearch = backupBudgetsListSearchMode.findBy("budget.id", budgetId),
        scenario = this.get("budgetService.scenario"),
        metricsInputs = this.get("metricsInputs") === true || this.get("metricsInputs") === "true";
      if (budget && !isSearchMode) {
        var oldValue = Ember.get(budget, "isCollapsed");
        Ember.set(budget, "isCollapsed", isCollapsed);
        if (oldValue !== isCollapsed) {
          if (!(budget.get("budget.budgetType") === "assumption" || !scenario && budget.get("budget.isBudgetAddedForScenario") || scenario && !(!budget.get("budget.isBudgetAddedForScenario") || budget.get("budget.addedForScenarios").includes(scenario)))) {
            var list;
            var listStatistical;
            if (metricsInputs) {
              list = this.get("budgetsListTreeModels");
              listStatistical = this.get("budgetsListTreeStatisticals");
            } else {
              list = this.get("budgetsListTreeWithoutModel");
            }
            if (metricsInputs && budget.get("budget.isModelLine") || !metricsInputs && !budget.get("budget.isModelLine")) {
              if (isCollapsed) {
                list && list.removeObject(budget);
                listStatistical && listStatistical.removeObject(budget);
              } else {
                list && this.addElementToTree(list, budget, scenario);
                listStatistical && this.addElementToTree(listStatistical, budget, scenario);
              }
            }
          }
        }
      }
      if (isCollapsed) {
        if (budgetSearch) {
          this.get("budgetsListSearchMode").removeObject(budgetSearch);
        }
      } else if (backupBudgetSearch) {
        // find the right place for the budget
        var newElement = {
          budget: Ember.get(backupBudgetSearch, "budget"),
          level: Ember.get(backupBudgetSearch, "level"),
          isCollapsed: Ember.get(backupBudgetSearch, "isCollapsed"),
          searchFilter: Ember.get(backupBudgetSearch, "searchFilter"),
          searchFound: Ember.get(backupBudgetSearch, "searchFound")
        };
        var insertAtIndex = 0;
        var parentFound = budgetsListSearchMode.findBy("budget.id", Ember.get(backupBudgetSearch, "budget.parent.id"));
        if (parentFound) {
          var subtree = this.findNumberOfChildrenInList(budgetsListSearchMode, parentFound) + 1;
          // let numberOfChildren = ((budgetsListSearchMode.filterBy('budget.parent.id', Ember.get(parentFound, 'budget.id'))).length || 0) + 1;
          insertAtIndex = budgetsListSearchMode.indexOf(parentFound) + subtree;
        }
        budgetsListSearchMode.insertAt(Math.min(budgetsListSearchMode.length, insertAtIndex), newElement);
        return Ember.get(backupBudgetSearch, "isCollapsed");
      }
    },
    findNumberOfChildrenInList: function findNumberOfChildrenInList(list, budget) {
      var _this6 = this;
      var children = list.filterBy("budget.parent.id", Ember.get(budget, "budget.id"));
      var sum = children.length;
      children.forEach(function (child) {
        return sum += _this6.findNumberOfChildrenInList(list, child);
      });
      return sum;
    },
    setBudgetsCollapsed: function setBudgetsCollapsed(budgetIds, isCollapsed) {
      var _this7 = this;
      budgetIds.forEach(function (budgetId) {
        return _this7.setBudgetCollapsed(budgetId, isCollapsed);
      });
      var budgetsListSearchMode = this.get("budgetsListSearchMode").filter(function (item) {
        return budgetIds.indexOf(Ember.get(item, "budget.id")) !== -1;
      });
      budgetsListSearchMode.setEach("isCollapsed", isCollapsed);
    },
    flattenTree: function flattenTree(budget, list, level) {
      var _this8 = this;
      if (budget && this.get("budgetService.hideEmployees") && budget.get("isEmployee")) {
        return list;
      }
      if (budget && !budget.get("isNameEncrypted") && !budget.get("budgetAttributes.isMissing") && budget.get("name") !== "_Missing Line") {
        var newTreeElement = Ember.Object.create({
          budget: budget,
          level: level,
          isCollapsed: this.get("collapsedBudgets").getItem(budget)
        });
        list.pushObject(newTreeElement);
        if (Ember.get(budget, "children")) {
          Ember.get(budget, "children").filterBy("budgetModule.showInTree").forEach(function (child) {
            _this8.flattenTree(child, list, level + 1);
          });
        }
      }
      return list;
    }
  });
});