define("budgeta/templates/budget-not-loaded", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "V8vgjgel",
    "block": "{\"statements\":[[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"main-content login-page\"],[\"static-attr\",\"data-hook\",\"budget-not-loaded\"],[\"flush-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"content-page centerElement\"],[\"flush-element\"],[\"text\",\"\\n    \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"page-header\"],[\"flush-element\"],[\"append\",[\"helper\",[\"t\"],[\"budget-not-loaded.hmmm\"],null],false],[\"close-element\"],[\"text\",\"\\n    \"],[\"append\",[\"helper\",[\"t\"],[\"budget-not-loaded.budget-not-available.contact\"],null],false],[\"text\",\"\\n    \"],[\"block\",[\"if\"],[[\"get\",[\"model\",\"target\"]]],null,1],[\"open-element\",\"br\",[]],[\"flush-element\"],[\"close-element\"],[\"open-element\",\"br\",[]],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n    \"],[\"block\",[\"link-to\"],[\"main\"],null,0],[\"text\",\"\\n  \"],[\"close-element\"],[\"text\",\"\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"append\",[\"helper\",[\"t\"],[\"budget-not-loaded.next-available-budget\"],null],false]],\"locals\":[]},{\"statements\":[[\"text\",\" \"],[\"append\",[\"helper\",[\"t\"],[\"budget-not-loaded.budget-not-available.specify-id\"],null],false],[\"text\",\" \"],[\"append\",[\"unknown\",[\"model\",\"target\"]],false]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/budget-not-loaded.hbs"
    }
  });
});